import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Popover,
  Snackbar,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import CloseStoreDialogBox from "../../../components/src/commonComponents/CloseStoreDialogBox";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Alert from "@material-ui/lab/Alert";
import {
  conditionalString,
  renderBaseOnConditions,
  sortCondition,
  sortStringCondition
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  HeaderStyledButton,
  MainButtonComponent,
  AvailableButton
} from "../../../components/src/customComponents/CustomTags.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
const CheckTrue = require("../../../components/src/check_true.svg");
const CheckFalse = require("../../../components/src/check_false.svg");
// Customizable Area End

import CfStoreManagmentController, {
  ICloseStoreData,
  Props,
  configJSON,
} from "./CfStoreManagmentController";

export default class CfStoreManagment extends CfStoreManagmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getExpandIcons() {
    const { openProductImpExtPopover } = this.state
    return (
      <>
        {configJSON.importExport} {openProductImpExtPopover ? <ExpandLess /> : <ExpandMore />}
      </>
    )
  }

  renderTableHeader() {
    const sortingData = this.state.sortingData;
    return (
      <TableHead className="stickyHeader">
        <TableRow className="tableRow">
          {renderBaseOnConditions(
            this.checkIsAvailB2bAndB2c(),
            <SortingTableHeader
              sortingData={sortingData}
              title={
                <StyledImageCheckbox
                  src={conditionalString(
                    this.state.selectedStores.length == this.state.stores.length,
                    CheckTrue,
                    CheckFalse
                  )}
                  data-test-id="field-selectAll"
                  onClick={() => this.handleSelectAll()}
                />}
              sortingKey=''
              {...this.sortingProps}
              sortingDisabled
              type="left"
              width={""}
            />,
            <></>
          )}
          <SortingTableHeader
            sortingData={sortingData}
            title='Store Name'
            sortingKey='store_name'
            {...this.sortingProps}
            type={this.returnFirstCellType()}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Email Address'
            sortingKey='email'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='IP Phone No.'
            sortingKey='full_ip_phone_number'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Store ID'
            sortingKey='store_id'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Area'
            sortingKey='area_name'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Status'
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
          <SortingTableHeader          
            sortingData={sortingData}
            title='Available for B2C'
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Available for B2B'
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
          <SortingTableHeader
            sortingData={sortingData}
            title=''
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
            type='action'
          />
        </TableRow>
      </TableHead>
    )
  }
  renderEmptyRow(height: number) {
    return (
      <TableRow
        style={{
          height: 53 * height,
        }}
      >
        <TableCell colSpan={6} />
      </TableRow>
    )
  }

  renderEmptyTable() {
    return (
      <div className="noRecordMessage">No records found!!</div>
    )
  }

  getSkeleton = () => {
    return (
      this.state.isLoading && 
        <>
        {Array(5)
          .fill(1)
          .map((_, index) => (
            <TableBody key={index} data-test-id='tableData'>
              <TableRow>
              {[...Array(9)].map((_, cellIndex) => (
                 <TableRowsSkeleton key={cellIndex} />
                ))}

               
              </TableRow>
            </TableBody>
          ))}
        </>
    )
  }
  
  // Customizable Area End

  render() {
    const {
      disableModalVisible,
      deactivatedStore,
      closedStore,
      permissionStatus,
      customSnackBarMessage,
      customSnackBarOpen,
      customSnackBarSeverity
    } = this.state;
    const storeRows = this.state.stores.map((store) => {
      const varId = store.id;
      const storeName = store.attributes.store_name;
      const email = store.attributes.email;
      const telephone = store.attributes.full_ip_phone_number;
      const storeId = store.attributes.store_id;
      const area = store.attributes.area?.area_name;
      const status = this.getStatus(store);
      const enableB2c= store.attributes.enabled_b2c_order;
      const enableB2b= store.attributes.enabled_b2b_order;

      return this.createData(storeName, email, telephone, storeId, area, status, varId, enableB2c, enableB2b);
    });

    const filteredStoreData = storeRows;
    const sortingData = this.state.sortingData;
    const emptyRowData = this.state.pageSize - filteredStoreData.length;
    const openPopoverAction = Boolean(this.state.openProductImpExtPopover);
    const popOverId = openPopoverAction ? "simple-popover" : undefined;

    return (
      // Customizable Area Start
      <StoreListWrapper>
        
          <Box>
          <Box className="pageWrapper">
            <div className="headerWrapperStore">
              <div className="pageHeaderStore">Stores</div>
              <StoreListSubHeader>
                <div
                  data-test-id="filterBtn"
                  onClick={(event) => {
                    this.setState({
                      filterAnchor: event.currentTarget,
                    });
                  }}
                  className={sortStringCondition(
                    this.state.isAppliedFilter,
                    "filterButtonStore active-border",
                    "filterButtonStore"
                  )}
                >
                  <FilterIcon color={this.handleReturnColorType()} />
                  {renderBaseOnConditions(
                    this.state.isAppliedFilter,
                    <div className="filterActiveIcon"></div>,
                    <></>
                  )}
                </div>
                <FilterPopover
                  onClose={() => {
                    this.setState({ filterAnchor: undefined });
                  }}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onAutoCompleteChange={(title: string, value: string) => {
                    this.setState({ suggestionFieldTitle: title });
                    this.handleStoreAutoComplete(title, value);
                  }}
                  onClearFilter={() => {
                    let updated = this.state.filters.map((item: IFilter) => {
                      item.value = "";
                      item.options = [];
                      return item;
                    });
                    this.setState({ filters: updated });
                  }}
                  onFilterChange={this.handleFilterChangeStore}
                  filters={this.state.filters}
                />
                {
                  renderBaseOnConditions(
                    permissionStatus.exportPermission as boolean,
                    <HeaderStyledButton
                      data-test-id={`export-import-btn`}
                      id={"IconButton"}
                      aria-describedby={popOverId}
                      onClick={(event) => this.handleOpenExportPopver(event.currentTarget)}
                      className="ml-12"
                    >
                      {this.getExpandIcons()}                    
                    </HeaderStyledButton>,
                    <></>
                  )
                }
                {
                sortCondition(
                  this.state.permissionStatus.createPermission,
                  <HeaderStyledButton
                    data-test-id='addStore'
                    onClick={this.handleAddStore}
                    className="ml-12"
                  >
                    Add Store
                  </HeaderStyledButton>,
                  <></>
                )
              }
              <ExportImportPopover
                popOverId={popOverId}
                openAction={openPopoverAction}
                popoverOpen={this.state.openProductImpExtPopover}
                popoverClose={() => this.handleCloseImpExpPopover()}
                exportTemplateButtonClick={() => this.handleExportTemplate()}
                importCsvButtonClick={() => this.handleOpenImportModal()}
                exportCsvButtonClick={() => this.handleExportCsvFile()}
              />
              </StoreListSubHeader>
            </div>
            <div className="availableBtnSection" data-test-id="available-test-button">
              <AvailableButton className="mr-0">
                <span style={{marginRight:"20px"}}>
                  {configJSON.storeListString.availableForB2c}
                </span>
                <CustomSwitch
                  data-test-id='field-status-switch'
                  checked={this.state.isAvailableForB2c}
                  onChange={(checked) => this.handleChangeAvailableButton(checked, "AVAILABLE_B2C")}
                />
              </AvailableButton>
              <AvailableButton className="mr-0">
                <span style={{marginRight:"20px"}}>
                {configJSON.storeListString.availableForB2b}
                </span>
                <CustomSwitch
                  data-test-id='field-status-switch'
                  checked={this.state.isAvailableForB2b}
                  onChange={(checked) => this.handleChangeAvailableButton(checked, "AVAILABLE_B2B")}
                />
              </AvailableButton>
              {renderBaseOnConditions(
                this.checkIsAvailB2bAndB2c(),
                <MainButtonComponent
                  className="mr-0"
                  onClick={() => this.handleUpdateStoreStatus()}
                  data-test-id="update-status-button"
                >
                  {configJSON.storeListString.update}
                </MainButtonComponent>,
                <></>
              )}
            </div>
            <TableContainer component={Paper} className="flexItem">
              <Table className="tableStore">

                {this.renderTableHeader()}

                {!this.state.isLoading && filteredStoreData.length > 0 && (
                  <TableBody>
                    {filteredStoreData.map((rowData, index) => (
                      <TableRow key={index + "TableRow"}>
                        {renderBaseOnConditions(
                          this.checkIsAvailB2bAndB2c(),
                          <SortingTableCell
                            type='left'
                            content={
                              <StyledImageCheckbox
                                src={sortStringCondition(
                                  this.checkIsOrderSelect(rowData.recid),
                                  CheckTrue,
                                  CheckFalse
                                )}
                                data-test-id={`singleCheckbox${index}`}
                                onClick={() => this.onSelectSingleOrder(rowData.recid)}
                              />
                            } 
                            width={""}
                          />,
                          <></>
                        )}
                        <SortingTableCell
                          width='18vw'
                          type={this.returnFirstCellType()}
                          content={rowData.storeName}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.email}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.telephone}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.storeId}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='middle'
                          content={rowData.area}
                        />
                        <SortingTableCell
                          width='18vw'
                          type='right'
                          content={rowData.status}
                        />
                         <SortingTableCell
                          data-test-id={`field-status-switch-${rowData.recid}`}
                          width='10vw'
                          type='middle'
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 8,
                            paddingBottom: 7,
                          }}
                          content={
                            <CustomSwitch
                              checked={rowData.enableB2c}
                              onChange={(checked) => {
                                this.handleSingleChangeSetting(
                                  rowData,
                                  checked,
                                  "B2C_SETTING"
                                )
                              }}
                            />
                          }
                        />
                        <SortingTableCell
                          data-test-id='customswitchCell'
                          width='10vw'
                          type='right'
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 8,
                            paddingBottom: 7,
                          }}
                          content={
                            <CustomSwitch
                              data-test-id='field-status-switch'
                              checked={rowData.enableB2b}
                              onChange={(checked) => {
                                this.handleSingleChangeSetting (
                                  rowData,
                                  checked,
                                  "B2B_SETTING"
                                )
                              }}
                            />
                          }
                        />
                        <SortingTableCell
                          width='18vw'
                          type='action'
                          data-test-id='moreCell'
                          content={
                            <IconButton
                              data-test-id={`moreButton_${rowData.recid}`}
                              id={"IconButton" + index}
                              onClick={(event) => {
                                const position = event.currentTarget.getBoundingClientRect();
                                this.setState({
                                  popOverItemId: rowData.recid,
                                  popOverItemStatus: rowData.status,
                                  popOverOpenedStore: true,
                                  popOverLeftStore:
                                    position.left + window.scrollX,
                                  popOverTopStore:
                                    position.top + window.scrollY,
                                });
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    ))}
                    {emptyRowData > 0 && (
                      this.renderEmptyRow(emptyRowData)
                    )}
                  </TableBody>
                )}

                {this.getSkeleton()}
              </Table>
              {!this.state.isLoading && filteredStoreData.length === 0 && (
                this.renderEmptyTable()
              )}
            </TableContainer>
            {filteredStoreData.length > 0 && (
              <TablePagination
                component='div'
                data-test-id='pagination'
                rowsPerPageOptions={[10]}
                page={this.state.page - 1}
                count={this.state.totalCounts}
                rowsPerPage={this.state.pageSize}
                onPageChange={(demo, page) => this.handlePageChange(page + 1)}
              />
            )}
          </Box>
          </Box>

          <StoreListPopover
            data-test-id={configJSON.popoverTest}
            anchorReference='anchorPosition'
            open={this.state.popOverOpenedStore}
            onClose={() => this.setState({ popOverOpenedStore: false })}
            anchorPosition={{
              top: this.state.popOverTopStore + 40,
              left: this.state.popOverLeftStore - 80,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                  permissionStatus.editPermission ||
                  permissionStatus.deactivatePermission || 
                permissionStatus.activatePermission as boolean),
                  <div className='popoverContainerStore'>
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.viewPermission,
                        <Box
                          data-test-id={`viewButton_${this.state.popOverItemId}`}
                          onClick={() => this.handleViewStore(this.state.popOverItemId)}
                          className="popoverButtonStore"
                        >
                        View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.editPermission,
                        <Box
                          data-test-id={`editButton_${this.state.popOverItemId}`}
                          onClick={() => this.handleEditStore(this.state.popOverItemId)}
                          className="popoverButtonStore"
                        >
                        Edit
                      </Box>,
                        <></>
                      )
                    }
                     {
                      renderBaseOnConditions(
                        permissionStatus.deactivatePermission && this.state.popOverItemStatus === "Active",
                        <>
                        <Box
                        data-test-id='activateButton'
                        className="popoverButtonStore"
                        onClick={() => {
                            this.handleDeactivate(this.state.popOverItemId);
                        }}
                        >
                          Deactivate
                        </Box>
                         <Box
                         data-test-id={`closeButton_${this.state.popOverItemId}`}
                         onClick={() => this.handleCloseButton(this.state.popOverItemId)}
                         className='popoverButtonStore'
                       >
                         Close
                       </Box>
                       </>
                        ,
                        <>
                        {!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          permissionStatus.activatePermission &&
                          this.state.popOverItemStatus === "Active"
                        && !permissionStatus.deactivatePermission && <div className="popoverContainer1">
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </div>}
                        </>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== "Active",
                        <>
                        <Box
                        data-test-id='activateButton'
                        className="popoverButtonStore"
                        onClick={() => {
                          this.changeActivateStore();
                        }}
                        >
                          Activate
                        </Box>
                        <Box
                         data-test-id={`closeButton_${this.state.popOverItemId}`}
                         onClick={() => this.handleCloseButton(this.state.popOverItemId)}
                         className='popoverButtonStore'
                       >
                         Close
                       </Box>
                        </>,
                        <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          !permissionStatus.activatePermission &&
                          this.state.popOverItemStatus !== "Active"
                        && permissionStatus.deactivatePermission && <div className="popoverContainer">
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </div>}</>
                      )
                    }
                  </div>,
                  <div className="popoverContainer">
                    <div className="noPermissions">
                      No Permissions
                    </div>
                  </div>
                )
            }
          </StoreListPopover>
        
        <ConfirmationModal
          open={disableModalVisible}
          handleClose={this.handleDeactivateClose}
          handleConfirm={this.changeActivateStore}
          title='Confirm Deactivate'
          message={{
            id: this.state.clickedStore?.id,
            title: this.state.clickedStore?.attributes.store_name,
          }}
          dynamic={true}
          confirmText='Deactivate'
        />

        {deactivatedStore ||
          (closedStore && (
            <ConfirmationModal
              dynamic={true}
              customMessage={`${this.state.clickedStore?.attributes.store_name} one has been closed successfully`}
              open={deactivatedStore || closedStore}
              handleClose={this.handleDeactivatedStoreClose}
              handleConfirm={this.handleGoList}
              title={deactivatedStore ? "Store Deactivated" : "Store Closed"}
              message={{
                id: this.state.clickedStore?.id,
                title: this.state.clickedStore?.attributes.store_name,
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
          ))}
        {this.state.popOverCloseStore && (
          <CloseStoreDialogBox
            open={this.state.popOverCloseStore}
            handleClose={() => this.handleStoreCloseCancel()}
            isAlert={true}
            handleConfirm={(data: ICloseStoreData) =>
              this.handlePopStoreConfirm(data)
            }
            reasonList={this.state.reasonList}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity='error'>{this.state.snackbarMessage}</Alert>
        </Snackbar>
        <CustomSnackbar     
          open={customSnackBarOpen}
          errorMessage={customSnackBarMessage}
          severity={customSnackBarSeverity}
          onClose={this.handleCloseCustomSnackbar}
          autoHideDuration={2000}
        />
      </StoreListWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const StoreListWrapper = styled(Box)({
  fontFamily: "Montserrat",
  "& .contentWrapperStore": {
  },
  "& .headerWrapperStore": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0 32px 0",
    alignItems: "center",
  },
  "& .pageHeaderStore": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .tableStore": {
    minWidth: 700,
  },
  "& .tableRow": {
    background: "#204B9C",
    color: "#FFFFFF"
  },
  "& .noRecordMessage": {
    textAlign: "center",
    fontSize: 16,
    width: "100%",
    fontWeight: 604,
    margin:"1rem 0"
  },
  "& .availableBtnSection":{
    width:"100%",
    display:'flex',
    justifyContent:"flex-end",
    marginBottom:"24px",
    gap: "12px",
  }
});

export const StoreListPopover = styled(Popover)({
  boxShadow: "none",
  "& .popoverContainerStore": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .popoverButtonStore": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .noPermissions": {
    padding: "0 8px",
  },
  "& .popoverContainer1": {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
})

export const StoreListSubHeader = styled(Box)({
    display: "flex",
    "& .filterButtonStore": {
      position: "relative",
      justifyContent: "center",
      fontSize: "24px",
      background: "#FFFFFF",
      height: "56px",
      color: "#4d4d4d",
      width: "56px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #ECECEC",
      cursor: "pointer",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "16px",
        width: "44px",
        height: "44px",
      },
    },
    "& .active-border": {
      border: "1px solid #204B9C",
    },
    "& .filterActiveIcon": {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      minWidth: "15px",
      background: "#204B9C",
      minHeight: "15px",
      borderRadius: "50%"
    },
    "& .addButtonStore": {
      background: "#204B9C",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      height: "56px",
      width: "184px",
      borderRadius: "8px",
      cursor: "pointer",
      textTransform: "unset",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
        width: "160px",
        height: "44px",
      }
    },
});

export const StyledImageCheckbox = styled("img")({
  padding: 0,
  paddingLeft: 9,
  cursor: "pointer",
});
// Customizable Area End
