// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IProductItems } from "../../CfPriceList/src/PriceListViewController.web";
import { debounce, uniqBy } from "lodash";
import { ClassNameMap } from "@material-ui/styles";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap
  // Customizable Area End
}

export interface B2BProductResponse {
  id: string;
  type: string;
  attributes: {
    catalogue:{
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
      };
    }
  }
}

interface S {
  // Customizable Area Start
  price_id:string,
  productOptions: Options[]
  b2bproductOptions: Options[]
  sectionOptions: { id: number, label: string, option: string, active?: boolean }[]
  serviceOptions: Options[]
  isOpenSection: boolean
  selectedSectionOPtions: number[]
  isOpenService: boolean
  selectedServiceOPtions: number[]
  selectedProductOPtions: number[]
  isOpenProduct: boolean
  newItem: string
  items: Items[]
  itemCount: number
  itemsIds: {
    label: string,
    _destroy: boolean | undefined
  }[]
  specificationFirstName: string
  specificationSecondName: string
  showInputError: boolean
  showOptionError: boolean
  showSectionOptionError: boolean
  showProdutOptionError: boolean
  showServiceOptionError: boolean
  openSnackbar: boolean
  addBtnLoading: boolean;
  message: string
  severity: string;
  isSectionSelectAll: boolean;
  isServiceSelectAll: boolean;
  isLoading: boolean;
  initialValues: {
    section: string[] | {}[] | { id: string, label: string, option: string, active?: boolean }[],
    service: string[] | {}[] | { id: string, label: string, option: string, active?: boolean }[],
    product: string[] | []
  };
  autoCopmpleteValue: string;
  b2bautoCopmpleteValue: string;
  selectedForAdd: { id: string | number, option: string }[];
  selectedForAddB2B: { id: string | number, option: string }[];
  addSpecSelectedOptionForList: { id: string | number, option: string }[];
  addB2BSpecSelectedOptionForList: { id: string | number, option: string }[];
  productListItemsDropdown: IProductItems[];
  b2bproductListItemsDropdown: IProductItems[];
  isSelectedAll: boolean;
  isSelectedAllB2B: boolean;
  productNextPage: number;
  b2bproductNextPage: number;
  sectionNextPage: number;
  serviceNextPage: number;
  currentProductData: IProductItems[];
  currentB2BProductData: IProductItems[];
  searchQuery: boolean;
  selectedProductWhileEdit: { id: string | number, option: string }[];
  sectionAutoCopmpleteValue: string;
  selectedSections: { id: string | number, option: string }[];
  selectedSectionsForB2B: { id: string | number, option: string }[];
  selectedSectionsForList: { id: string | number, option: string }[];
  serviceAutoCopmpleteValue: string;
  selectedServices: { id: string | number, option: string }[];
  selectedServicesForList: { id: string | number, option: string }[];
  specificationError:{
    specificationFirstNameError:string|null;
    specificationSecondNameError:string|null;
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
interface Options {
  id: number
  label: string
  active: boolean
  option: string
}
interface Items {
  _destroy?: boolean;
  data: {
    attributes: {
      id?: string;
      label: string;
      _destroy: boolean
    }
  }
}
// Customizable Area End

// Customizable Area Start
export default class AddSpecificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  createSpecificationListCallId: string = "";
  getsectionItemCallId: string = "";
  getserviceItemCallId: string = "";
  getProductItemCallId: string = "";
  getMasterPriceIdCallId: string = "";
  getB2BProductItemCallId: string = "";
  disableLoadMoreSection = false;
  disableLoadMoreService = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      price_id:'',
      productOptions: [],
      b2bproductOptions: [],
      sectionOptions: [],
      serviceOptions: [],
      isOpenSection: false,
      selectedSectionOPtions: [],
      isOpenService: false,
      selectedServiceOPtions: [],
      selectedProductOPtions: [],
      isOpenProduct: false,
      addBtnLoading: false,
      newItem: "",
      items: [],
      itemCount: 1,
      itemsIds: [],
      specificationFirstName: "",
      specificationSecondName: "",
      showInputError: false,
      showOptionError: false,
      showSectionOptionError: false,
      showProdutOptionError: false,
      showServiceOptionError: false,
      openSnackbar: false,
      message: "",
      severity: "",
      isSectionSelectAll: false,
      isServiceSelectAll: false,
      isLoading: false,
      initialValues: {
        section: [],
        service: [],
        product: []
      },
      autoCopmpleteValue: '',
      b2bautoCopmpleteValue:'',
      selectedForAdd: [],
      selectedForAddB2B: [],
      addSpecSelectedOptionForList: [],
      addB2BSpecSelectedOptionForList: [],
      productListItemsDropdown: [],
      b2bproductListItemsDropdown:[],
      isSelectedAll: false,
      isSelectedAllB2B:false,
      productNextPage: 1,
      b2bproductNextPage:1,
      sectionNextPage: 1,
      serviceNextPage: 1,
      currentProductData: [],
      currentB2BProductData: [],
      searchQuery: false,
      selectedProductWhileEdit: [],
      sectionAutoCopmpleteValue: '',
      selectedSections: [],
      selectedSectionsForB2B:[],
      selectedSectionsForList: [],
      serviceAutoCopmpleteValue: '',
      selectedServices: [],
      selectedServicesForList: [],
      specificationError:{
        specificationFirstNameError: null,
        specificationSecondNameError: null
      }

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getSectionDropDownListItem()
    this.getProductDropDownListItem("")
    this.getMasterPriceId()
    this.getItemsIds()

    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.items !== this.state.items) {
      this.getItemsIds()
    }
  }
  handleSpecificationFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex =/^[A-Za-z]+(\s+[A-Za-z]+)*\s*$/;
    const error:{specificationFirstNameError?:string,specificationSecondNameError?:string} = {}
    if(event.target.value===""){
      error.specificationFirstNameError="Please enter your first specification name."
      this.setState((prevValue)=>({
        specificationError:{
         ...prevValue.specificationError,
         specificationFirstNameError:"Please enter your first specification name."
        }
       }))
    } else if(!regex.test(event.target.value)){
      error.specificationFirstNameError="Comment is Required"
      this.setState((prevValue)=>({
       specificationError:{
        ...prevValue.specificationError,
        specificationFirstNameError:"Please enter valid first specification name."
       }
      }))
    }else {
      this.setState((prevValue)=>({
        specificationError:{
         ...prevValue.specificationError,
         specificationFirstNameError:null
        }
       }))
    }
    this.setState({
      specificationFirstName: event.target.value,
      isOpenProduct: false,
      isOpenSection: false,
      isOpenService: false
    });
  };
  handleSpecificationSecondNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex =/^[A-Za-z]+(\s+[A-Za-z]+)*\s*$/;
      this.setState((prevValue)=>({
        specificationError:{
         ...prevValue.specificationError,
         specificationSecondNameError:null
        }
       }))
    this.setState({
      specificationSecondName: event.target.value,
      isOpenProduct: false,
      isOpenSection: false,
      isOpenService: false
    });
  };
  handleCancelButton = () => {
    this.props.navigation.history.push("/Products-SpecificationList")
  }
  handleAddInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newItem: event.target.value });
  };
  handleClickAdItems = () => {
    const { newItem } = this.state;
    if (newItem.trim() !== "") {
      const newItemWithList = {
        data: {
          attributes: {
            label: newItem,
            _destroy: false
          }
        }
      };
      this.setState((prevState) => ({
        newItem: "",
        items: [...prevState.items, newItemWithList],
        itemCount: prevState.itemCount + 1,
      }));

    }
  };
  handleDeleteItemList = (itemId?: string, index?: number) => {
    this.setState((prevState) => ({
      items: prevState.items.filter((item, indexes) => indexes !== index),
      itemCount: prevState.itemCount - 1,
    }));
  };
  getItemsIds = () => {
    const { items } = this.state;
    this.setState({
      itemsIds: items.map(item => {
        return {
          label: item.data.attributes.label,
          _destroy: item._destroy
        }
      }),
    })
  }

  checkFormErrors = () => {
    const { specificationFirstName, specificationSecondName } = this.state;
    return specificationFirstName !== '' && specificationSecondName !== ""
  }

  handleCheckValidatin = () => {
    const { specificationFirstName, specificationSecondName, selectedSectionOPtions, selectedProductOPtions, selectedServiceOPtions } = this.state;

    const regex =/^[A-Za-z]+(\s+[A-Za-z]+)*\s*$/;
    const error:{specificationFirstNameError?:string|null,specificationSecondNameError?:string|null} = {}
    if(specificationFirstName===""){
      error.specificationFirstNameError="Please enter your first specification name."
      this.setState((prevValue)=>({
        specificationError:{
         ...prevValue.specificationError,
         specificationFirstNameError:"Please enter your first specification name."
        }
       }))
    } else if(!regex.test(specificationFirstName)){
      error.specificationFirstNameError="Please enter valid first specification name."
      this.setState((prevValue)=>({
       specificationError:{
        ...prevValue.specificationError,
        specificationFirstNameError:"Please enter valid first specification name."
       }
      }))
    }else {
      error.specificationFirstNameError=null
      this.setState((prevValue)=>({
        specificationError:{
         ...prevValue.specificationError,
         specificationFirstNameError:null
        }
       }))
    }

    return error
  }

  handleClickAdd = () => {
    const { specificationFirstName, specificationSecondName } = this.state;
   let error = this.handleCheckValidatin()
    if (specificationFirstName === '' || specificationSecondName === '') {
      this.setState({ showInputError: true });
    } else {
      this.setState({ showInputError: false });
    }    
    if (error.specificationFirstNameError ===null) {
      this.setState({ isLoading: true })
      this.createSpecificationList()
    }
  };
  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
  }
  getSectionDropDownListItem = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const sectionListDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.disableLoadMoreSection = true;
    this.getsectionItemCallId = sectionListDataMsg.messageId;
    const searchQuery = this.state.sectionAutoCopmpleteValue ? `&filter_by[query]=${this.state.sectionAutoCopmpleteValue}` : ''

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sectionDropDownListEndPoint}&page_no=${this.state.sectionNextPage}${searchQuery}&per_page=${this.itemsPerPage}`
    );

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(sectionListDataMsg.id, sectionListDataMsg);
  }
  getMasterPriceId()
  {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const productListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMasterPriceIdCallId = productListDataMessage.messageId;
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMasterPriceId}`
    );
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(productListDataMessage.id, productListDataMessage);
  }
  getProductDropDownListItem = (searchQuery: string) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const productListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductItemCallId = productListDataMessage.messageId;

    const searchQueryString = this.state.autoCopmpleteValue ? `&filter_by[query]=${this.state.autoCopmpleteValue}` : ''
    const sectionSelectedIds = this.state.selectedSections.filter(item => item.id !== "-1").map((item) => item.id)

    const filterBySectionIds = !this.state.isSectionSelectAll ? `&filter_by[section_ids][]=${sectionSelectedIds}` : "";
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productListDropdownApiEndPoint}${searchQueryString}${filterBySectionIds}&page_no=${this.state.productNextPage}`
    );

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(productListDataMessage.id, productListDataMessage);
  }
  getB2BProductDropDownListItem = (searchQuery: string, price_list_id:string) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const productListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getB2BProductItemCallId = productListDataMessage.messageId;
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.b2bproductListDropdownApiEndPoint}?page_no=${this.state.b2bproductNextPage}&price_list_id=${price_list_id}`
    );
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    productListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(productListDataMessage.id, productListDataMessage);
  }
  getServiceDropDownListItem = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const serviceItemDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getserviceItemCallId = serviceItemDataMessage.messageId;
    this.disableLoadMoreService = true;
    const sectionSelectedIds = this.state.selectedSections.filter(item => item.id !== "-1").map((selectedSection) => selectedSection.id)

    const endpoint = !this.state.isSectionSelectAll ?
      configJSON.getServiceListDropDownEndpoint + `&filter_by[section_ids][]=${sectionSelectedIds}` :
      configJSON.getServiceListDropDownEndpoint
    const searchQuery = this.state.serviceAutoCopmpleteValue ? `&filter_by[query]=${this.state.serviceAutoCopmpleteValue}` : ''

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}${searchQuery}&page_no=${this.state.serviceNextPage}&per_page=${this.itemsPerPage}`
    );

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    serviceItemDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(serviceItemDataMessage.id, serviceItemDataMessage);
  }
  createSpecificationList = () => {
    this.setState({addBtnLoading:true})
    let headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    let httpBody = {}
    httpBody = {
      data: {
        attributes: {
          name: this.state.specificationFirstName,
          second_name: this.state.specificationSecondName,
          options_attributes: this.state.itemsIds,
          section_ids: this.state.isSectionSelectAll ? ['All'] : this.state.selectedSections.map(item => Number(item.id)),
          service_ids: this.state.isServiceSelectAll ? ['All'] : this.state.selectedServices.map((item) => Number(item.id)),
          product_ids: this.state.isSelectedAll ? ['All'] : this.state.selectedForAdd.map((item) => Number(item.id)),
          b2b_product_ids: this.state.isSelectedAllB2B ? ['All'] : this.state.selectedForAddB2B.map((item) => Number(item.id)),
        }
      }

    }
    const createSpecificationDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSpecificationListCallId = createSpecificationDataMessage.messageId;

    createSpecificationDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSpecificationApiEndPoint
    );

    createSpecificationDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createSpecificationDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    createSpecificationDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(createSpecificationDataMessage.id, createSpecificationDataMessage);

  }
  handleResponseForSectionList = (from: string, message: Message) => {
    if (this.getsectionItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const receivedOptions = apiResponse.data?.map((item: { id: string; attributes: { section_name: string; active: boolean; }; }) => {
          return {
            id: item.id,
            label: item.attributes.section_name,
            active: item.attributes.active,
            option: item.attributes.section_name,
          }
        }) || []
        this.setState({
          sectionOptions: this.state.sectionNextPage === 1 ? receivedOptions : [...this.state.sectionOptions, ...receivedOptions],
          selectedSectionsForList: this.state.isSectionSelectAll ? uniqBy([
            ...this.state.selectedSectionsForList,
            ...receivedOptions
          ], "id") : this.state.selectedSectionsForList,
        })
        this.disableLoadMoreSection = receivedOptions.length < this.itemsPerPage
      }
    }
  }
  handleResponseForMasterPriceId = (message:Message)=>{
    if (this.getMasterPriceIdCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
  this.setState({price_id:apiResponse.data.id})
  this.getB2BProductDropDownListItem('',apiResponse.data.id)

      }
    }
  }
  handleResponseForProductList = (from: string, message: Message) => {
    if (this.getProductItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const existingData = this.state.productListItemsDropdown;
        const responseData = apiResponse.data || []
        this.setState({
          productOptions: responseData.map((item: {
            id: number; attributes: {
              catalogue_variants: { attributes: { active: boolean } }; product_name: string;
            }
          },
          ) => {
            return {
              id: item.id,
              label: item.attributes.product_name,
              option: item.attributes.product_name,
            }
          }),
          productListItemsDropdown: this.handleProductListResAsPerQuery(existingData, responseData),
          currentProductData: responseData
        }, () => {
          this.state.isSelectedAll && this.setState({
            addSpecSelectedOptionForList: [...this.state.addSpecSelectedOptionForList, ...responseData.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
          })
        })
        this.setState({ isLoading: false })
      }
    }
  }
  handleResponseForB2BProductList = (from: string, message: Message) => {
    if (this.getB2BProductItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const existingData = this.state.b2bproductListItemsDropdown;
        const responseData = this.fetchDatafromResponse(apiResponse.data) || []
        
        this.setState({
          b2bproductOptions: responseData.map((item: {
            id: number; attributes: {
              catalogue_variants: { attributes: { active: boolean } }; product_name: string;
            }
          },
          ) => {
            return {
              id: item.id,
              label: item.attributes.product_name,
              option: item.attributes.product_name,
            }
          }),
          b2bproductListItemsDropdown: this.handleProductListResAsPerQuery(existingData, responseData),
          currentB2BProductData: responseData
        }, () => {
          this.state.isSelectedAllB2B && this.setState({
            addB2BSpecSelectedOptionForList: [...this.state.addB2BSpecSelectedOptionForList, ...responseData.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
          })
        })
        this.setState({ isLoading: false })
      }
    }
  }


  fetchDatafromResponse(apiResponse: B2BProductResponse[]) {
    let arrayResponse: any = [];
    apiResponse.forEach((value: B2BProductResponse) => {
      arrayResponse.push(value.attributes.catalogue);
    });
    return arrayResponse;
  }


  handleProductListResAsPerQuery(existingData: IProductItems[], responseOptionList: IProductItems[]) {
    if (this.state.searchQuery) {
      return existingData.concat(responseOptionList)
    }
    return responseOptionList
  }

  handleResponseForServiceItemList = (from: string, message: Message) => {
    if (this.getserviceItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const receivedServices = apiResponse.data?.map((item: { id: string; attributes: { name: string; active: boolean; }; }) => (
          {
            id: item.id,
            label: item.attributes.name,
            active: item.attributes.active,
            option: item.attributes.name,
          }
        )) || []
        this.setState({
          serviceOptions: this.state.serviceNextPage === 1 ? receivedServices : [...this.state.serviceOptions, ...receivedServices],
          selectedServicesForList: this.state.isServiceSelectAll ?
            uniqBy([...this.state.selectedServicesForList, ...receivedServices], "id")
            : this.state.selectedServicesForList
        })
        this.disableLoadMoreService = receivedServices.length < this.itemsPerPage
      }
    }
  }

  handleResponseForCreateSpecificationList = (from: string, message: Message) => {
    if (this.createSpecificationListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data) {
        this.setState({
          openSnackbar: true,
          message: configJSON.specificationMessage,
          severity: configJSON.severitySuccess
        }, () => {
          setTimeout(() => {
          this.setState({addBtnLoading:false})
          navigateTo({
            props: this.props,
            screenName: "SpecificationList",
          })
        }, 1000)
        })
      }
      else {

        this.setState({
          isLoading: false,
          message: `${Object.keys(apiResponse.errors[0])[0]} ${Object.values(apiResponse.errors[0])[0]}`,
          severity: configJSON.typeError,
          openSnackbar: true,
          addBtnLoading: false
        })
      }
    }
  }

  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCopmpleteValue) return;
    this.setState({ sectionAutoCopmpleteValue: getValue, sectionNextPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getSectionDropDownListItem()
      }
    });
  }

  handleServiceAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.serviceAutoCopmpleteValue) return;
    this.setState({ serviceAutoCopmpleteValue: getValue, serviceNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getServiceDropDownListItem()
      }
    }
  }

  handleSpecAutoCompltProductChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.getProductDropDownListItem(getValue)
    }
  }

  handleSpecAutoCompltB2BProductChange = (getValue: string) => {
    if (getValue === this.state.b2bautoCopmpleteValue) return;
    this.setState({ b2bautoCopmpleteValue: getValue, b2bproductNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.getB2BProductDropDownListItem(getValue,this.state.price_id)
    }
  }

  checkSpecificationSelectAllText = (getValue: { id: string | number, option: string }[]) => {
    this.setState({ selectedForAdd: getValue })
  };

  checkB2BSpecificationSelectAllText = (getValue: { id: string | number, option: string }[]) => {
    this.setState({ selectedForAddB2B: getValue })
  };

  handleSpecificationClickOnSelectAll = () => {
    this.setState({ isSelectedAll: true, selectedForAdd: [] })
  };

  handleB2BSpecificationClickOnSelectAll = () => {
    this.setState({ isSelectedAllB2B: true, selectedForAddB2B: [] })
  };


  handleSpecificationEmptyAutoSelectValue = () => {
    this.setState({ autoCopmpleteValue: "" })
  };

  handleB2BSpecificationEmptyAutoSelectValue = () => {
    this.setState({ b2bautoCopmpleteValue: "" })
  };

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleSpecificationSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    if (option.id == "-1") {
      if (this.state.isSelectedAll) {
        this.setState({ isSelectedAll: false, selectedForAdd: [], addSpecSelectedOptionForList: [] });
      } else {
        this.setState({
          isSelectedAll: true,
          selectedForAdd: [{ id: "-1", option: "Select All" }],
          addSpecSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.productListItemsDropdown.map((item) => { return { id: item.id, option: item.attributes.product_name } })]
        });
        setFieldValue('product', ["All"])
      }
      return;
    }

    const isValueContained = value.some((item) => item.id == option.id)

    if (isValueContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addSpecSelectedOptionForList: value.filter((item) => item.id != option.id), selectedForAdd: value.filter((item) => item.id != option.id), isSelectedAll: false }, () => setFieldValue('product', value.filter((item) => item.id != option.id).map((item) => item.id)))
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addSpecSelectedOptionForList: [...value, option], selectedForAdd: [...value, option], isSelectedAll: false }, () => setFieldValue('product', [...value, option].map((item) => item.id)))
    }
  };

  handleB2BSpecificationSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    if (option.id == "-1") {
      if (this.state.isSelectedAllB2B) {
        this.setState({ isSelectedAllB2B: false, selectedForAddB2B: [], addB2BSpecSelectedOptionForList: [] });
      } else {
        this.setState({
          isSelectedAllB2B: true,
          selectedForAddB2B: [{ id: "-1", option: "Select All" }],
          addB2BSpecSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.b2bproductListItemsDropdown.map((item) => { return { id: item.id, option: item.attributes.product_name } })]
        });
        setFieldValue('B2Bproduct', ["All"])
      }
      return;
    }

    const isValueContained = value.some((item) => item.id == option.id)

    if (isValueContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addB2BSpecSelectedOptionForList: value.filter((item) => item.id != option.id), selectedForAddB2B: value.filter((item) => item.id != option.id), isSelectedAllB2B: false }, () => setFieldValue('B2Bproduct', value.filter((item) => item.id != option.id).map((item) => item.id)))
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addB2BSpecSelectedOptionForList: [...value, option], selectedForAddB2B: [...value, option], isSelectedAllB2B: false }, () => setFieldValue('B2Bproduct', [...value, option].map((item) => item.id)))
    }
  };


  handleSelectSectionOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {
      isServiceSelectAll: false,
      isSelectedAll: false,
      selectedServices: [],
      selectedServicesForList: [],
      serviceOptions: [],
      selectedForAdd: [],
      addSpecSelectedOptionForList: [],
      productListItemsDropdown: [],
      serviceNextPage: 1,
      productNextPage: 1
    }
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { ...updateStateData, isSectionSelectAll: false, selectedSections: [], selectedSectionsForList: [] }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isSectionSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedSections = newItems
      updateStateData.selectedSectionsForList = newItems
      if(newItems?.length === this.state.sectionOptions?.length) {
        updateStateData = {
          ...updateStateData,
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      }
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("section", fieldValue)
      this.getServiceDropDownListItem()
      this.getProductDropDownListItem("")
    })
  };

  handleSelectServiceOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {}
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isServiceSelectAll ?
        {
          ...updateStateData,
          isServiceSelectAll: false,
          selectedServices: [],
          selectedServicesForList: []
        }
        :
        {
          ...updateStateData,
          isServiceSelectAll: true,
          selectedServices: [{ id: "-1", option: "Select All" }],
          selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOptions]
        }
      fieldValue = updateStateData.isServiceSelectAll ? ["All"] : []
    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isServiceSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedServices = newItems
      updateStateData.selectedServicesForList = newItems
      if(newItems?.length === this.state.serviceOptions?.length) {
        updateStateData = {
          ...updateStateData,
          isServiceSelectAll: true,
          selectedServices: [{ id: "-1", option: "Select All" }],
          selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOptions]
        };
      }
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("service", fieldValue)
    })
  };

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionNextPage: prevState.sectionNextPage + 1 }), () => {
        this.getSectionDropDownListItem()
      })
    }
  };
  handleScrollServiceDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreService) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ serviceNextPage: prevState.serviceNextPage + 1 }), () => {
        this.getServiceDropDownListItem()
      })
    }
  };
  handleSpecificationScrollProductDropdown = (event: React.SyntheticEvent) => {
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30 && this.state.currentProductData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getProductDropDownListItem("")
      })
    }
  };
  handleB2BSpecificationScrollProductDropdown = (event: React.SyntheticEvent) => {
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30 && this.state.currentB2BProductData.length !== 0) {
      this.setState({ b2bproductNextPage: this.state.b2bproductNextPage + 1 }, () => {
        this.getB2BProductDropDownListItem("",this.state.price_id)
      })
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForSectionList(from, message)
    this.handleResponseForProductList(from, message)
    this.handleResponseForMasterPriceId(message)
    this.handleResponseForB2BProductList(from, message)
    this.handleResponseForServiceItemList(from, message)
    this.handleResponseForCreateSpecificationList(from, message)
    // Customizable Area End
  }
}
// Customizable Area End
