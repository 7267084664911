import React from "react";

// Customizable Area Start
import { Box, Button, styled, Portal, TextField } from "@material-ui/core";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import CustomSelect from "../../../components/src/customComponents/CustomSelect.web";
import { getEnvironment } from "../../../framework/src/Utilities";
import { colors } from "../../utilities/src/Colors";
import { Formik } from "formik";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import PrintRaw from "../../../components/src/PrintRaw.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

const SWITCH_ITEMS = [
  {
    label: "Receipt to Print from Clean Page",
    fieldName: "receipt_print_clean_page"
  },
  {
    label: "Receipt to Print from Ready Page",
    fieldName: "receipt_print_ready_page"
  },
  {
    label: "Receipt to Print from Delivered Page",
    fieldName: "receipt_print_delivered_page"
  },
] as const
// Customizable Area End

import PrintController, { Props, configJSON } from "./PrintController";

export default class Print extends PrintController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { snackbarMode } = this.props
    const environment = getEnvironment()
    const isDev = environment === "dev"
    const isTestPrintPage = this.props.navigation?.history?.location?.pathname?.toLowerCase() === "/print"

    const snackbar = this.state.snackbarMessage && (
      <Portal>
        <CustomSnackbar
          data-test-id='print-snackbar'
          open
          onClose={this.handleCloseSnackbar}
          errorMessage={this.state.snackbarMessage}
          severity={successMessages.includes(this.state.snackbarMessage) ? "success" : "error"}
          autoHideDuration={3000}
        />
      </Portal>
    )

    if ((isTestPrintPage && !isDev) || snackbarMode)
      return snackbar

    if (isTestPrintPage && isDev) return (
      <Box data-test-id={configJSON.testPageTestId}>
        <div>
          <p>Image URL</p>
          <input type="text" ref={this.imageInputRef} data-test-id="print-input-image"/>
          <button data-test-id="btn-print-image" onClick={() => this.testPrintFile(true)}>{configJSON.printImageLabel}</button>
          <p>PDF URL</p>
          <input type="text" ref={this.pdfInputRef} data-test-id="print-input-pdf"/>
          <button onClick={() => this.testPrintFile()} data-test-id="btn-print-pdf">Print pdf file</button>
          <Box display="flex" flexDirection="column" data-test-id={configJSON.testRawBox}>
            <PrintRaw onPrint={this.testPrintRaw}/>
          </Box>
        </div>
        { snackbar }
      </Box>
    )
    
    return (
      <>
        <StyledWrapper>
          <CustomLoader loading={this.state.loading} />
          <Box className="header" data-test-id={configJSON.headerTest}>
            <p className="header-title" data-test-id={configJSON.headerTitleTest}>
              {this.props.id || configJSON.receiptPrinter}
            </p>
            <CustomSelect
              data-test-id={configJSON.stationDropdownTestId}
              options={this.state.stations} 
              placeholder={configJSON.stationPlaceholder} 
              value={this.state.selectedStation}
              onChange={this.changeStation}
            />
          </Box>
          <p className="note">
            To setup your printer please follow the guide for your printer on our <span className="note-link">Help Desk</span><br />
            If your printer does not work after following the instructions then request assistance by <span className="note-link">Scheduling a remote support session in our Help Desk</span><br />
            You must inform us what printer model you have, and whether it is USB / Ethernet / Serial or Parallel
          </p>
          <Formik
            initialValues={this.state.initialFormValue}
            onSubmit={this.handleSave}
            enableReinitialize
            innerRef={this.formRef}
          >
            {({
              handleSubmit,
              values,
              setFieldValue,
              handleChange
            }) => (
              <form
                data-test-id={configJSON.formTestId}
                onSubmit={handleSubmit}
              >
                <Box className="paper" marginBottom={3}>
                  <div>
                    <p className="label" data-test-id={configJSON.printerDropdownTest}>
                      {configJSON.printerDropdownLabel}
                    </p>
                    <CustomSelect
                      data-test-id="selectPrinter"
                      placeholder="Select Printer"
                      variant="outlined"
                      options={this.state.printers}
                      onChange={this.changePrinter}
                      value={values.printer_name}
                    />
                  </div>
                </Box>

                {
                  renderBaseOnConditions(
                    Boolean(this.props.isGarmentTagSetting),
                    null,
                    <Box className="paper" marginBottom={4} data-test-id={configJSON.orderPrintSettingTest}>
                      {
                        SWITCH_ITEMS.map((switchItem) => (
                          <div className="switch-item" key={switchItem.fieldName}>
                            <p className="switch-label">
                              {switchItem.label}
                            </p>
                            <CustomSwitch
                              onChange={(newValue) => setFieldValue(switchItem.fieldName, newValue)}
                              checked={Boolean(values[switchItem.fieldName])}
                            />
                          </div>
                        ))
                      }
                      <div />
                      <div>
                        <p className="label">{configJSON.numOfCopies}</p>
                        <StyledTextField
                          variant="outlined"
                          name="no_of_copies"
                          type="number"
                          placeholder=""
                          value={values.no_of_copies}
                          inputProps={{
                            type: 'number',
                            min: "1",
                            step: "1" 
                          }}
                          onChange={handleChange}
                        />
                      </div>
                    </Box>
                  )
                }

                <Box className="actions" data-test-id={configJSON.actionBtnsTest}>
                  <Button
                    data-test-id={configJSON.btnCancelTest}
                    variant="outlined"
                    className="cancel-btn"
                    onClick={() => this.handleRedirect()}
                  >
                    <span>{configJSON.cancel}</span>
                  </Button>
                  {renderBaseOnConditions(
                    (!this.props.isGarmentTagSetting && this.state.permissionStatus?.receiptPrinterActionPermission as boolean) ||
                    (this.props.isGarmentTagSetting ? this.state.permissionStatus?.garmentTagPrinterActionPermission as boolean : false),
                    <Button
                      className="save-btn"
                      data-test-id={configJSON.btnSaveTest}
                      type="submit"
                    >
                      <span>Save</span>
                    </Button>
                    , <></>)
                  }
                </Box>
              </form>
            )
            }
          </Formik>
        </StyledWrapper>
        { snackbar }
      </>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const successMessages = [configJSON.writeFileSuccessMsg, configJSON.saveSettingSuccessMsg]

export const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputBase-root": {
      minWidth: 264,
    },
  },
  "& .header-title": {
    fontSize: 24,
    fontWeight: 600
  },
  "& .note": {
    borderRadius: 4,
    borderLeft: `4px solid ${colors().cyancobaltblue}`,
    background: "rgba(32, 75, 156, 0.10)",
    padding: "12px 16px",
    fontSize: 14,
    lineHeight: "22px",
    color: colors().darkliver,
    "& .note-link": {
      fontWeight: 700,
      color: colors().cyancobaltblue
    },
  },
  "& form": {
    "& .paper": {
      borderRadius: 12,
      padding: 24,
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
      display: "grid",
      gridTemplateColumns: "0.5fr 0.5fr",
      gap: 24,
    },
    "& .label": {
      marginBottom: 4,
      fontWeight: 600,
      lineHeight: 1.5
    },
    "& .MuiInputBase-root": {
      maxWidth: "unset",
      width: "100%"
    },
    "& .switch-item": {
      borderRadius: 8,
      border: `solid 1px ${colors().lightborder}`,
      padding: "10px 16px",
      display: "flex",
      gap: 8,
      justifyContent: "space-between",
      alignItems: "center"
    },
    "& .switch-label": {
      fontWeight: 500,
      lineHeight: 1.4
    },
    "& .actions": {
      paddingBottom: 20,
      "& > button": {
        padding: 16,
        borderRadius: 8,
        width: 126,
        border: 0,
        fontWeight: 700,
        lineHeight: 1.5,
        textTransform: "none",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
          padding: "10px 0"
        },
      },
      "& .cancel-btn": {
        backgroundColor: colors().antiFlashWhite,
        color: colors().slateGray,

      },
      "& .save-btn": {
        backgroundColor: colors().cyancobaltblue,
        color: "white",
        marginLeft: 32,
      }
    }
  }
})

export const StyledTextField = styled(TextField)({
  width: "100%",
  paddingTop: 10,
  borderRadius: 8,
  fontFamily: "Montserrat",
  "& .MuiOutlinedInput-root": {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
    borderRadius: 8,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 12,
    },
    "& fieldset": {
      fontSize: 14,
      borderRadius: 8,
      borderWidth: 1,
      fontWeight: 400,
      fontFamily: "Montserrat",
      borderColor: colors().lightborder
    },
  },
  "& ::placeholder": {
    opacity: 1,
    color: "#4D4D4D",
    fontSize: "14px",
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
        fontSize: 12,
    },
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: 12,
      padding:'14.9px 14px'
    },
  },
});
// Customizable Area End
