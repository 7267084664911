// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { debounce } from "lodash";
import { apiCall,handleLogout, makeApiMessage } from "../../../components/src/common";
import {
  checkIsFilterApplied,
  navigateTo,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  checkForImportExportPermissionStatus,
  getCustomEnumName,
  CustomEnums,
  randomNumberGenerator,
  conditionalString
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";
export const configJSON = require("./config");

export interface IBusinessCustomer {
  id: number;
  business_customer: string;
}

export interface IPriceListAttributes {
  id: number;
    name: string;
    price_list_type: string;
    product_currency_id: number | null;
    created_at: string;
    updated_at: string;
    tax_type: string;
    activated: boolean;
    price_list_to_copy: {
      name: string
    } | null;
    type: string;
    company_id: number;
    region_ids: number[];
    area_ids: number[];
    store_management_ids: number[];
    business_account_ids: number[];
    model_name: string;
    company_name: string;
    business_accounts:IBusinessCustomer[];
    is_master_price_list: boolean;
    sections: Record<string, unknown>;
    products: unknown[];
    products_count: number
}

export interface IPriceList {
  id: string;
  type: string;
  attributes: IPriceListAttributes;
}
interface IMetaData {
  "total_pages": number,
  "total_count": number,
  "current_page": number,
  "next_page": number| null,
  "pervious_page": number | null
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  priceList: IPriceList[];
  priceListPagination: IMetaData;
  sortingData: ISortingData;
  isAppliedFilter: boolean;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  page: number;
  pageSize: number;
  isB2bActive: boolean
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  query: string;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string | boolean;
  popOverTop: number;
  popOverLeft: number;
  rowData: IPriceList;
  pricelistrQuery: string;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  openDeactiveModel: boolean;
  confirmDeactiveModalOpen: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "warning" | "error" | "success";
  openImportExportPopover: (EventTarget & HTMLButtonElement) | null;
  setLoaded: number;
  uploadedFile: File | null;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BPriceListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  getPriceListApiCallId: string = "";
  filterSuggestionApiCallId = "";
  exportTemplateFileApiId = "";
  exportCsvApiId = "";
  importFileApiId = "";
  adapter = new ImportExportWebAdapter();
  suggestionFieldTitle = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      priceList: [],
      sortingData: {
        name: "",
        company: "",
        business_account: "",
        activated: "",
        no_of_products: "",
        price_list_to_copy: "",
      },
      isAppliedFilter: false,
      filterAnchor: undefined,
      filters: [
        {
          title: "Name",
          type: "autocompolete",
          value: "",
          apiKey: "name",
          options: [],
        },
        {
          title: "Company Name",
          type: "autocompolete",
          value: "",
          apiKey: "company_name",
          options: [],
        },
        {
          title: "Price List To Copy",
          type: "text",
          value: "",
          apiKey: "price_list_to_copy_name",
        },
      ],
      priceListPagination: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": 0,
        "pervious_page": null
      },
      page: 0,
      pageSize: 10,
      isB2bActive: false,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      query: "",
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      rowData: {
        id: "0",
        type: "price_list",
        attributes: {
          id: 0,
          name: "",
          price_list_type: "",
          product_currency_id: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          tax_type: "",
          activated: false,
          price_list_to_copy: null,
          type: "",
          company_id: 0,
          region_ids: [],
          area_ids: [],
          store_management_ids: [],
          business_account_ids: [],
          model_name: "",
          company_name: "",
          business_accounts: [],
          is_master_price_list: false,
          sections: {},
          products: [],
          products_count: 0
        }
      },
      pricelistrQuery: "",
      permissionStatus: {
        mainPermission: true,
        createPermission: true,
        viewPermission: true,
        editPermission: true,
        deactivatePermission: true,
        activatePermission: true,
      },
      isLoadingPermission: true,
      openDeactiveModel: false,
      confirmDeactiveModalOpen: false,
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      openImportExportPopover: null,
      setLoaded: 0,
      uploadedFile: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    this.receiveHeaerSearchText(message);
    this.receiveDataFromLayout(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.receiveApiResponse(message)
    }

    this.handleImportExportActions(from, message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.handleStorageFilter();
    // Customizable Area End
  }
  // Customizable Area Start
  receiveApiResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    switch(apiRequestCallId){
      case this.getPriceListApiCallId:
        this.getPriceListApiCallResponse(apiRequestCallId, responseJson);
        break;
      case this.exportTemplateFileApiId:
        this.handleResForExportTemplateFile(responseJson)
        break;
      case this.exportCsvApiId:
        this.exportCSVfileRes(responseJson)
        break;
      case this.importFileApiId:
        this.handleImportCsvFileResponse(responseJson)
        break;
      case this.filterSuggestionApiCallId:
        this.handleFilterSuggResp(responseJson)
        break;
      default: 
        break;
    }
    
  }

  receiveHeaerSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }

  onChangeValue = (value: string) => {
    this.setState({pricelistrQuery: value,page: 0},()=>this.getB2BPriceList())
  }

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  checkOpacity(activated: string | boolean) {
    if(activated) {
      return 1
    }
    return 0.6
  }

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getB2BPriceList());
  };

  handlePopoverMenuClose = () => {
    this.setState({ popOverOpened: false })
  }

  handleMoreMenu = (item: IPriceList, position: DOMRect) => {
    this.setState({
      popOverOpened: true,
      popOverItemId: item.id,
      popOverItemStatus: item.attributes.activated,
      popOverLeft: position.left + window.scrollX,
      popOverTop: position.top + window.scrollY,
      rowData: item
    });
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("b2b_price_list_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("b2b_price_list_filter_value");
    };

    this.setState({
      filters,
      isAppliedFilter: checkIsFilterApplied(filters),
      page: 0
    },
    this.getB2BPriceList
);
  };

  handleFilterClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleClearFilter = () => {
    let updated = this.state.filters.map((item: IFilter) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({ filters: updated });
  }

  
  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const storedFilter = localStorage.getItem("b2b_price_list_filter_value");
    if (storedFilter) {
      const filters = JSON.parse(storedFilter)
      this.setState(
        {
          filters,
          isAppliedFilter: checkIsFilterApplied(
            filters
          ),
        },
        this.getB2BPriceList
      );
    } else {
      this.getB2BPriceList();
    }
  };

  handleNavigateAddCustomer = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BPriceListCreation",
    })
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getB2BPriceList() })
  }

  getB2BPriceList = () => {
    this.setState({ isLoading: true });
    const {filters} = this.state

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;

    const filterByName = conditionalString(
      filters[0].value,
      `&filter_by[name]=${filters[0].value}`,
      ""
    )
    const filterByCompany = conditionalString(
      filters[1].value,
      `&filter_by[company_name]=${filters[1].value}`,
      ""
    )
    const filterByPriceListToCopy = conditionalString(
      filters[2].value,
      `&filter_by[price_list_to_copy_name]=${filters[2].value}`,
      ""
    )

    url = configJSON.B2BPriceListAPIEndpoint +
      (`?page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
      (this.state.pricelistrQuery ? `&filter_by[query]=${this.state.pricelistrQuery}` : '') 
      + conditionalString(this.state.query, this.state.query, "")
      + filterByName + filterByCompany + filterByPriceListToCopy

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPriceListApiCallResponse(apiRequestCallId: string, responseJson: { data: IPriceList[], status: number,meta: IMetaData, message?: string, errors?: { message: string } }) {
    if (apiRequestCallId === this.getPriceListApiCallId) {
      if(responseJson?.status == 500) {
        this.setState({
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error'
        })
      } 
      else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({ isLoading: false, priceList: responseJson.data , priceListPagination: responseJson.meta});
      } else {
        this.setState({ isLoading: false })
      }
      }
    }
  }

  handleViewCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BPriceList",
    });
  }

  handleEditCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BPriceListEdit",
    });
  };

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext);
      }
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const userData = userContext.user?.attributes.permission_groups;
    const apiKey = customPermissionApiKey.b2BPriceListPermission;
    const permissionVal = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );

    const apiKeyImportExport =
      customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(
      apiKeyImportExport,
      userData as Array<PermissionGroupArray>
    );
    this.setState({
      permissionStatus: {
        ...permissionVal,
        exportPermission: valueImportExportPermission.exportPermission,
      },
      isLoadingPermission: false,
    });
  };


  handleCloseConfirmModal = () => {
    this.setState({ openDeactiveModel: false });
  };


  handleSnackbarClose = () => {
    this.setState({ snackBarOpen: false, snackBarMessage: "" });
  };

  handleViewProductList = (priceListId: string) => {
    navigateTo({
      props: this.props,
      id: priceListId,
      screenName: "B2BPriceListProducts"
    })
  }

  openImportExportPopover = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openImportExportPopover: event?.currentTarget || null,
    });
  };

  handleExportCsv = () => {
    this.setState({ openImportExportPopover: null });

    const apiUrl = configJSON.exportB2BPriceListCsvApiurl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.validationApiMethodType,
    });

    this.exportCsvApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }

  exportCSVfileRes = (responseJson: { file_url: string; message: string }) => {
    if (responseJson?.file_url) {
      location.href = responseJson.file_url
       this.setState({ 
        severity: 'success', 
        snackBarOpen: true, 
        snackBarMessage: conditionalString(
          responseJson.message,
          responseJson.message,
          configJSON.successExcelExportMsg
        ) 
      });
    }
  }

  handleOpenImportModal = () => {
    this.setState({ openImportExportPopover: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  }

  handleImportFile = () => {
    const apiUrl = configJSON.importCsvApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.exampleAPiMethod,
      body: formData,
      isHeader: true
    });
    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  };

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "B2BPriceList" })
    this.getB2BPriceList();
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updateLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    function updateLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = file && file[0];
    this.setState({ uploadedFile: checkFile as File})
  }

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'B2BPriceList')
    runEngine.sendMessage(message.id, message)
  }

  handleImportCsvFileResponse(response: IImportCSVResponse) {
    if (response) {
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
      message.addData('APIresponse', response)
      runEngine.sendMessage(message.id, message);
      if (response?.message) {
        this.setState({ severity: 'success', snackBarOpen: true, snackBarMessage: response.message })
        navigateTo({ props: this.props, screenName: "B2BPriceList" })
        this.getB2BPriceList();
      } else if (response?.status == 500) {
        this.setState({
          severity: 'error',
          snackBarOpen: true,
          snackBarMessage: response?.error || 'An unexpected error has happened'
        });
        navigateTo({ props: this.props, screenName: "B2BPriceList" })
      } else {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
        message.addData('APIresponse', response);
        runEngine.sendMessage(message.id, message);
        navigateTo({ 
          props: this.props,
          screenName: "ImportExportErrorModal",
          raiseMessage: message
        })
      }
    }
  }

  handleExportTemplate = () => {
    this.setState({ openImportExportPopover: null })
    const apiUrl = configJSON.exportTemplateEndPoint;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.exportTemplateFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForExportTemplateFile = async (responseJson: {url?: string; message?:string}) => {
    if (responseJson?.url) {
      location.href = responseJson.url;
      this.setState({ 
        severity: 'success', 
        snackBarOpen: true, 
        snackBarMessage: conditionalString(
          responseJson.message,
          responseJson.message as string,
          "Template file has been exported successfully"
        )
      });
    }
  }

  handleImportExportActions(from: string, message: Message) {
    switch (from) {
      case getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked):
        this.handleImportExportModalClose();
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage):
        this.handleFileUpload(message.properties.fileEvent)        
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage):
        this.handleImportFile()
        break;

      case getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack): 
        this.handleBackToListPage();
        break;

      default:
        break;
        
    }    
  }

  handleFilterAutoComplete = (title: "Company Name" | "Name", value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
    });

    const apiUrl = configJSON.B2BPriceListFilterSuggestion + {
      "Name": "?name=",
      "Company Name": "?company_name=",
    }[title]
    let requestMessage = makeApiMessage({
      url: apiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    this.suggestionFieldTitle = title
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedFilterSuggestion = debounce(
    this.handleFilterAutoComplete,
    700,
    { maxWait: 2000 }
  );

  handleFilterSuggResp = (responseJson: { suggestion?: string[] }) => {
    const list = responseJson.suggestion?.map((value: string) => {
      return {
        label: value,
        value,
      }
    }) || []
    const updatedFilters = this.state.filters.map((item: IFilter) => {
      if (item.value) {
        if (item.title === this.suggestionFieldTitle) return { ...item, options: list };
        return item
      } else {
        if (item.title === this.suggestionFieldTitle) return { ...item, options: [] };
        return item;
      }
    });
    this.setState({ filters: updatedFilters });
  }
  // Customizable Area End
}