import React from "react";
// Customizable Area Start
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  TableContainer,
  styled,
  Typography,
  Tooltip,
  Button
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
const theme = createTheme({});
import { colors } from "../../../blocks/utilities/src/Colors";
import FilterIcon from '@material-ui/icons/FilterListRounded';
import FilterPopover from "../../../components/src/FilterPopover";
import { DashboardHorizontalBarChartBox } from "../../../components/src/CustomCharts";
import { saleByIcon } from "./assets";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import { getGroupNameDisplay  } from "./utils";
import ExportModal from "../../../components/src//ExportModal";
export const configJSON = require("./config");
import { storeNameBasedOnGroup } from "../../../blocks/dashboard/src/utils";
import { conditionalString, renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers"
import { LoadingComponent } from "../../../components/src/Loader.web";
// Customizable Area End

import Cfsalesdashboard3Controller, {
  Props,
} from "./Cfsalesdashboard3Controller";

export default class Cfsalesdashboard3 extends Cfsalesdashboard3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <DashboardTabPanel navigation={this.props.navigation} id="SectionSales" permissionStatus={this.state.permissionStatus} />
        <ThemeProvider theme={theme}>
          <SaleWrapper id={this.state.openExportModal ? "print-wrapper" : undefined}>
            <Box className="headingContainer">
              <Box className="leftHeading">
                <Box className="rowHeading">
                  <Box className="fieldBox">
                    <Typography className="label">Region:</Typography>
                    <Typography className="value">{this.state.regionName}</Typography>
                  </Box>
                  <Box className="fieldBox">
                    <Typography className="label">Store:</Typography>
                    <Typography className="value">{storeNameBasedOnGroup(this.state.storeId)}</Typography>
                  </Box>
                  {this.state.isB2bActive || <Box className="fieldBox">
                    <Typography className="label">Group:</Typography>
                    <Typography className="value" data-test-id="sales-group-id">{getGroupNameDisplay(this.state.groupId)}</Typography>
                  </Box>}
                </Box>
                <Box className="rowHeading">
                  <Box className="fieldBox">
                    <Typography className="label">Period:</Typography>
                    <Typography className="value">{this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="actionBtnContainer" sx={{alignItems: 'flex-end !important'}}>
                <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                  <LoadingComponent
                    disableParentWidth
                    isLoading={this.state.isLoadingDryCleaning || this.state.isLoadingLaundry || this.state.isLoadingPressing}
                    height={44}
                    width={44}
                    isAllowed={true}
                    children={
                      <IconButton data-test-id="filterIconBtn" className={sortStringCondition(
                        this.state.isAppliedFilter,
                        "filterBtn active-border",
                        "filterBtn"
                      )}>
    
                        <FilterIcon color={this.handleReturnColorType()} />
    
                        {renderBaseOnConditions(
                          this.state.isAppliedFilter,
                          <div id="activeIcon" className="filterActiveIcon"></div>,
                          <></>
                        )}
                      </IconButton>
                    }
                  />
                </div>
                {this.state.isB2bActive || <button
                  onClick={this.handleOpenExportModal}
                  data-test-id='export-all'
                  className='exportAll'
                >
                  {configJSON.exportAll}
                </button>}
              </Box>
              <FilterPopover
                data-test-id="filterPopover"
                onClose={() => { this.handleCloseFilterPopover() }}
                anchor={this.state.filterAnchor}
                onFilterChange={this.handleFilterChangeAccount}
                filters={this.state.filters}
              />
            </Box>
            <Box className="barChart" sx={{ marginBottom: conditionalString(this.state.isB2bActive, '40px', '') }}>
              <DashboardHorizontalBarChartBox height={200} data={this.state.sectionWiseLaundryData} dataHeader={this.state.sectionWiseLaundryDataHeader} title={"Laundry"} isLoading={this.state.isLoadingLaundry}/>
              <DashboardHorizontalBarChartBox height={200} data={this.state.sectionWiseDryCleaningData} dataHeader={this.state.sectionWiseDryCleaningDataHeader} title={"Dry Cleaning"} color_1="#EC4E20" color_2="#F6AE98" isLoading={this.state.isLoadingDryCleaning}/>
              <DashboardHorizontalBarChartBox height={200} data={this.state.sectionWisePressingData} dataHeader={this.state.sectionWisePressingDataHeader} title={"Pressing"} color_1="#204B9C" color_2="#98ACD1" isLoading={this.state.isLoadingLaundry}/>
            </Box>
            {this.state.isB2bActive || <Box className="section-service" data-test-id="section-service">
              <Box className="section">
                <Box className="header">
                  <img src={saleByIcon} alt="" />
                  <Box>Sale by Service</Box>
                </Box>
                <Box className="content-service">
                  {
                    this.state.saleByService?.data.map((service, index) => {
                      return (

                        <Box className={`item`} key={index}>
                          <Box className="label">
                            {service.service_name}
                          </Box>
                          <Box className="value">
                          <Tooltip title={service.total_price} arrow>
                            <p>
                             {service.total_price}
                            </p>
                           </Tooltip>
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Box>
              <Box className="section">
                <Box className="header">
                  <img src={saleByIcon} alt="" />
                  <Box>Sale by Section</Box>
                </Box>
                <Box className="content-section">
                  {
                    this.state.saleBySection?.data.map((service, index) => {
                      return (
                        <Box className={`item`} key={index}>
                          <Box className="label">
                            {service.section_name}
                          </Box>
                          <Box className="value">
                          <Tooltip title={service.total_price} arrow>
                            <p>
                             {service.total_price}
                            </p>
                           </Tooltip>
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Box>
            </Box>}
              <Box className="summary">
                <Box className="title">Summary</Box>
                <TableContainer component={Paper}>
                  <Table className="table">
                    <TableHead className="tableHead">
                      <TableRow>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="left">Discount</TableCell>
                        <TableCell align="left">Subtotal</TableCell>
                        <TableCell align="left">VAT</TableCell>
                        <TableCell align="left">Net</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                      {this.state.saleSummary?.data.map((row, index) => (
                        <TableRow
                          key={index}

                        >
                          <TableCell className="category">
                            {row.section}
                          </TableCell>
                          <TableCell align="left" className="item">{row.discount}</TableCell>
                          <TableCell align="left" className="item">{row.sub_total}</TableCell>
                          <TableCell align="left" className="item">{row.vat}</TableCell>
                          <TableCell align="left" className="item">{row.net}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
        {
          this.state.openExportModal && <ExportModal isOpen onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        }
          </SaleWrapper>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const SaleWrapper = styled("div")({
  "& .headingContainer": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .leftHeading": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: '1rem'
  },
  "& .rowHeading": {
    display: "flex",
    gap: "2rem"
  },
  "& .fieldBox": {
    display: "flex",
    gap: "0.5rem",
    fontSize: "1rem",
    lineHeight: "24px",
    "& .label": {
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "& .value": {
      fontWeight: 400,
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen
    }
  },
  "& .actionBtnContainer": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginBottom: '1rem',
    "& .exportAll": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    justifyContent: "center",
    padding: "10px 16px",
    border: 0,
    lineHeight: 1.5,
    borderRadius: 8,
    background: colors().lightgray,
    color: colors().darkliver,
    fontWeight: 600,
    fontSize: "16px",
    cursor: "pointer",
    }
  },
  "& .barChart": {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap:"1rem",
    "@media only screen and (max-width: 1280px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
  },
  "& .section-service": {
    display: "grid",
    gap: "1rem",
    gridTemplateColumns:"repeat(2, minmax(0, 1fr))",
    "@media only screen and (max-width: 1280px)": {
      justifyContent: "flex-start",
      gridTemplateColumns:"repeat(1,1fr)",
    },
  },
  "& .section": {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    border: `1px solid ${colors().lightborder}`,
    padding: "12px",
    margin: "1rem 0",
    "& .header": {
      display: "flex",
      gap: "1rem",
      justifyContent: "flex-start",
      padding: "4px",
      fontWeight: 500,
      fontSize: "19px",
      fontFamily: "Montserrat",
    },
    "& .content-service": {
      display: "flex",
      marginTop: "0.5rem",
      fontFamily: "Montserrat",
      overflowX: "auto",
      "& .item": {
        display: "flex",
        flex:1,
        flexDirection: "column",
        alignItems: "start",
        gap: "0.5rem",
        padding: "12px",
        borderRadius: "8px 0 0 8px",
        minWidth: "33%",
        "& .label": {
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 400,
          whiteSpace: "nowrap"
        },
        "& .value": {
          fontWeight: 600,
          fontSize: "16px",
          maxWidth: "15ch",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontFamily: "Montserrat",
          "@media only screen and (max-width: 1024px)": {
            maxWidth: "10ch",
           },
        }
      },
      "& > div:nth-child(1)": {
        background: colors().mainCardLoadArea,
      },
      "& > div:nth-child(2)": {
        background: colors().lavenderblue,
        marginLeft: "-10px"
      },
      "& > div:nth-child(3)": {
        background: colors().lightyellow,
        marginLeft: "-10px"
      },
      "& > div:nth-child(4)": {
        background: colors().mainCardDispatched,
        marginLeft: "-10px"
      },
      "& > div:nth-child(5)": {
        background: colors().cardHeaderMetal,
        marginLeft: "-10px"
      },
      "& > div:nth-child(6)": {
        background: colors().brightgray,
        marginLeft: "-10px"
      },
      "& > div:nth-child(7)": {
        background: colors().mainCardLoadArea,
        marginLeft: "-10px"
      },
    },
    "& .content-section": {
      display: "flex",
      marginTop: "0.5rem",
      fontFamily: "Montserrat",
      overflowX:"auto",
      "& .item": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        flex:1,
        alignItems: "start",
        padding: "12px",
        borderRadius: "8px 0 0 8px",
        minWidth:"33%",
        "& .label": {
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "Montserrat",
          whiteSpace:"nowrap",
        },
        "& .value": {
          fontSize: "16px",
          fontWeight: 600,
          maxWidth: "15ch",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontFamily: "Montserrat",
          "@media only screen and (max-width: 1024px)": {
            maxWidth: "10ch",
           },
        }
      },
      "& > div:nth-child(1)": {
        background: colors().mainCardDispatched,
      },
      "& > div:nth-child(2)": {
        background: colors().cardHeaderMetal,
        marginLeft: "-10px"
      },
      "& > div:nth-child(3)": {
        background: colors().brightgray,
        marginLeft: "-10px"
      },
      "& > div:nth-child(4)": {
        background: colors().mainCardLoadArea,
        marginLeft: "-10px"
      },
      "& > div:nth-child(5)": {
        background: colors().lavenderblue,
        marginLeft: "-10px"
      },
      "& > div:nth-child(6)": {
        background: colors().brightgray,
        marginLeft: "-10px"
      },
    }
  },
    "& .summary": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      padding: "12px 16px",
      minHeight: "300px",
      background: colors().brightgray,
      borderRadius: "12px",
      marginBottom:"1rem",
      "& .title": {
        padding: "8px 12px",
        width: "100%",
        fontSize: "19px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        borderBottom: `1px solid ${colors().dividerColor}`
      },
      "& .table": {
        background: colors().brightgray,
        "& .MuiTableCell-head": {
          fontWeight: "400 !important",
          color: `${colors().coolgray} !important`
        },
        "& .MuiTableCell-root": {
          border: "none",
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Montserrat",
          color: colors().darkjunglegreen,
          padding: "4px 8px"
        },
        "& .category": {
          width: "250px",
        },
        "& .item": {
          maxWidth: "5ch",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }
      }
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none"
  },
  "&#print-wrapper": {
    gap: 30,
    "& .section-service": {
      display: "grid",
      gap: "1rem",
      gridTemplateColumns:"repeat(1,1fr)",
    },
    "& .content-section": {
      "& .item": {
        minWidth:"10%",
      },
    },
    "& .actionBtnContainer": {
      display:"none"
     }
  },
  "& .filterBtn": {
      height: "44px",
      position: "relative",
      backgroundColor: "#fff",
      width: "44px",
      borderRadius: "8px",
      border: "1px solid #ECECEC",
      "&.active-border": {
        borderColor: colors().cyancobaltblue,
      },
      "& .filterActiveIcon": {
        position: "absolute",
        background: "#204B9C",
        top: "-5px",
        right: "-5px",
        minWidth: "15px",
        minHeight: "15px",
        borderRadius: "50%"
      },
      "& .MuiSvgIcon-root": {
        color: colors().darkliver
      }
    }
});


// Customizable Area End