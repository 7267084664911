import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { makeApiMessage as MakeApiMessage } from '../../../components/src/common'
import { PriceList, emptyPriceListItem } from "./PriceListController.web";
import { cloneDeep, debounce } from "lodash";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IMyUser, PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const Strings = configJSON.Strings.priceCodeListView;

export interface IProductItems {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_name: string;
    product_second_name: string;
    name: string;
  };
}

export interface IProductData {
  data: IProductItems[];
}


export interface CategoryDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    section_name: string;
    name: string;
  };
}

export interface Product {
  name: string;
  id: number;
  image: string;
  catalogue_variants_services: CatalogueVariantService[];
  measurement_type: string[];
}

export interface CatalogueVariantService {
  id: number;
  catalogue_variant_id: number;
  service_id: number;
  name: string;
  icon: string;
  price: string;
  sqm_price: string;
  sqm: string;
  weight_price: string;
  weight: string;
  default: boolean;
  services: {
    data: IServiceData[];
  };
}

export interface IServiceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    icon: string;
    name_translation: string;
  };
}

export interface IPriceList {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      price_list_type: string;
      product_currency_id: string;
      created_at: string;
      updated_at: string;
      tax_type: string;
      activated: boolean;
      price_list_to_copy: {id: number; name: string} | null;
      model_name: string;
      add_by: string;
      is_master_price_list: boolean;
      sections: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            section_name: string;
            name: string;
          };
        }[];
      };
      products: Product[];
    };
  };
  status: number;
  errors: Array<{}>;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  viewPriceListModal: boolean;
  expanded: boolean;
  viewPriceListItem: PriceList;
  productListItemsDropdown: IProductItems[];
  currentProductData: IProductItems[];
  isAddProductClicked: boolean;
  isPriceListEdit: boolean;
  isDeactivate: boolean;
  selectedForAdd: {id: string | number, option: string}[];
  selectedOptionForList: {id: string | number, option: string}[];
  selectedProductForDeactivate: (string | number)[];
  priceListProductList: Product[];
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  productUpdateId: number | null;
  isSelectedAll: boolean;
  autoCopmpleteValue: string;
  priceListServiceIds: (number | string)[];
  productNextPage: number;
  searchQuery: boolean;
  permissionStatus: PermissionStatus;
  isLoading: boolean;
  // Customizable Area End
}

export interface SS {}

export default class PriceListViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  showPriceListDataID: string = "";
  getProductListDataId: string = "";
  deactivateProductsApiId: string = "";
  addProductsApiId: string = "";
  deleteProductApiId: string = "";
  updateServiceApiId: string = "";
  getEmployeeDataId: string = "";
  pricelistID: number = 0;

  state: S = {
    viewPriceListModal: false,
    expanded: true,
    viewPriceListItem: emptyPriceListItem,
    productListItemsDropdown: [],
    currentProductData: [],
    isAddProductClicked: false,
    isPriceListEdit: false,
    isDeactivate: false,
    selectedForAdd: [],
    selectedOptionForList: [],
    selectedProductForDeactivate: [],
    priceListProductList: [],
    snackbarMessage: '',
    isSnackbarOpen: false,
    productUpdateId: null,
    isSelectedAll: false,
    autoCopmpleteValue: '',
    priceListServiceIds: [],
    productNextPage: 1,
    searchQuery: false,
    permissionStatus: {
      mainPermission: false,
      createPermission: false,
      viewPermission: false,
      editPermission: false,
      deactivatePermission: false
    },
    isLoading: false
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestId === this.showPriceListDataID) {
      this.handlePriceListItemDataResp(apiSuccessResponse);
    }
   
    if(apiRequestId === this.deleteProductApiId) {
      this.handleDeleteProductResponse(apiSuccessResponse);
    }
    
    if(apiRequestId === this.getProductListDataId) {
      this.handleProductListDataResp(apiSuccessResponse);
    }

    if(apiRequestId === this.getEmployeeDataId) {
      this.handleEmployeeApiResp(apiSuccessResponse);
    }
    
    if(apiRequestId === this.deactivateProductsApiId) {
      this.handleDeactivatedProductResp(apiSuccessResponse);
    }
    
    if(apiRequestId === this.updateServiceApiId) {
      this.handleUpdateServiceResp(apiSuccessResponse);
    }
    
    if(apiRequestId === this.addProductsApiId) {
      this.handleAddProductResp(apiSuccessResponse);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.pricelistID = this.props.navigation.getParam("id");
    this.getPriceListItemData();
    this.setState({viewPriceListModal: true});
    this.getMyUserData();
    // Customizable Area End
  }

  // Customizable Area Start
  getPriceListItemData = () => {
    const apiUrl = configJSON.ApiUrls.priceListEndPoint + this.pricelistID
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.validationApiMethodType,
    });
    this.setState({ isLoading: true });
    this.showPriceListDataID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMyUserData = () => {
    const apiUrl = configJSON.ApiUrls.employeeDataEndpoint;
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.validationApiMethodType,
    });
    this.getEmployeeDataId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  deleteProducts = (getProductId: number | string) => {
    const apiUrl = configJSON.ApiUrls.priceListEndPoint + this.pricelistID + `/delete_catalogue?product_id=${getProductId}`
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.deleteApiMethod,
    });
    this.deleteProductApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  addProductsToPricelist = () => {
    this.setState({ isLoading: true });
    const apiUrl = configJSON.ApiUrls.priceListEndPoint + this.pricelistID
    const httpBody = {
      data: {
        attributes: {
          product_ids: this.state.isSelectedAll ? ['All'] : this.state.selectedForAdd.map((item) => item.id)
        }
      }
    }
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.editProductListApiMethod,
      body: JSON.stringify(httpBody)
    });
    this.addProductsApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllProductData = (service_ids: (number | string)[], searchQuery: string) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const apiUrl = configJSON.ApiUrls.productListEndPoint + `?page_no=${this.state.productNextPage}&dropdown=true&price_list_id=${this.pricelistID}&exclude_price_list_id=true&filter_by[section_ids]=${service_ids}&filter_by[query]=${searchQuery}`;
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.validationApiMethodType,
    });
    this.getProductListDataId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleDeactivateProducts = () => {
    const apiUrl = configJSON.ApiUrls.deactivateProductsEndPoint + `?price_list_id=${this.pricelistID}&product_ids[]=${this.state.selectedProductForDeactivate}`;
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.editProductListApiMethod,
    });
    this.deactivateProductsApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateListingPage = () => {
    this.props.navigation.navigate("PriceList");
  };

  handleAlphabeticalProductOrder = (productlist: Product[]) => {
    return productlist.sort((first, second) =>
      first.name.localeCompare(second.name)
    );
  };

  handlePriceListItemDataResp = (responseJson: IPriceList) => {
    if(responseJson.status === 500) {
      this.setState({ isLoading: false });
      return;
    };
    if(responseJson) {
      let service_ids = responseJson.data.attributes.sections.data.map((item) => item.id)
      this.setState({
        priceListServiceIds: service_ids,
        viewPriceListItem: responseJson.data,
        priceListProductList: this.handleAlphabeticalProductOrder(
          responseJson.data.attributes.products
        ),
        selectedForAdd: [],
        productNextPage: 1,
        isLoading: false
      })
    }
  };

  handleProductListDataResp = (responseJson: { data: IProductItems[] }) => {
    if (responseJson) {
      const existingData = this.state.productListItemsDropdown;
      this.setState({
        productListItemsDropdown: this.state.searchQuery ? existingData.concat(responseJson.data) : responseJson.data,
        currentProductData: responseJson.data
      }, () => {
        this.state.isSelectedAll && this.setState({
          selectedForAdd: [...this.state.selectedForAdd, ...responseJson.data.map((item) => { return { id: item.id, option: item.attributes.product_name } })],
          selectedOptionForList: [...this.state.selectedOptionForList, ...responseJson.data.map((item) => { return { id: item.id, option: item.attributes.product_name } })],
        })
      })
    }
  };

  handleEmployeeApiResp = (
    responseJson: { data: IMyUser}
  ) => {
    const apiKey = customPermissionApiKey.priceList;
    const userData = responseJson.data.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  }
  
  handleDeactivatedProductResp = (responseJson: {message: string}) => {
    if (responseJson) {
      this.setState({
        snackbarMessage: responseJson.message,
        isSnackbarOpen: true,
        isDeactivate: false,
        selectedProductForDeactivate: [],
        isAddProductClicked: false,
        productNextPage: 1,
        productListItemsDropdown: []
      }, () => { this.getPriceListItemData() })
    }
  };

  handleUpdateServiceResp = (responseJson: IPriceList) => {
    if (responseJson) {
      this.setState({
        snackbarMessage: Strings.pricelistSuccess,
        isSnackbarOpen: true,
        productUpdateId: null
      })
    }
  };
  
  handleDeleteProductResponse = (responseJson: {message: string}) => {
    if (responseJson) {
      this.setState({
        snackbarMessage: responseJson.message,
        isSnackbarOpen: true,
        isAddProductClicked: false,
        productNextPage: 1,
        productListItemsDropdown: []
      }, () => { this.getPriceListItemData() })
    }
  };
  
  handleAddProductResp = (responseJson: IPriceList) => {
    if(responseJson.errors) return;
    if (responseJson) {
      this.setState({
        viewPriceListItem: responseJson.data,
        priceListProductList: responseJson.data.attributes.products,
        selectedForAdd: [],
        isAddProductClicked: false,
        productNextPage: 1,
        productListItemsDropdown: [],
        selectedOptionForList: []
      },()=>this.getPriceListItemData())
    }
  };

  handleAccordianChange = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  handleCloseModal = () => {
    this.setState({viewPriceListModal: false}, () => this.handleNavigateListingPage());
  };

  handleAddProductButtonClick = () => {
    this.setState({ isAddProductClicked: true }, () => {
      this.getAllProductData(this.state.priceListServiceIds, "")
    })
  }

  handlePriceListEdit = () => {
    this.setState({ isPriceListEdit: true, isAddProductClicked: false })
  }
  
  handlePriceListSave = () => {
    const token = localStorage.getItem('token');
    this.setState({ isPriceListEdit: false })
    const apiUrl = configJSON.getProductListApiEndPoint + `/${this.state.productUpdateId}?token=${token}`;
    let catalogueId;
    let updatedPriceData = this.state.priceListProductList.find((item) => item.id === this.state.productUpdateId)?.catalogue_variants_services.map((item) => {
      catalogueId = item.catalogue_variant_id;
      return { id: item.id, service_id: item.service_id, price: item.price }
    });
    
    const httpBody = {
      data: {
        attributes: {
          catalogue_variants_attributes: [
            {
              id: catalogueId,
              catalogues_services_attributes: updatedPriceData
            }
          ]
        }
      }
    };
    
    const requestMessage = MakeApiMessage({
      url: apiUrl,
      method: configJSON.editProductListApiMethod,
      body: JSON.stringify(httpBody)
    });
    this.updateServiceApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeactivateClick = () => {
    this.setState({ isDeactivate: true, isAddProductClicked: false, isPriceListEdit: true })
  }

  handleQueueForDeactivation = (getNewId: number | string) => {
    if (this.state.selectedProductForDeactivate.includes(getNewId)) {
      this.setState({ selectedProductForDeactivate: this.state.selectedProductForDeactivate.filter((itemId) => itemId !== getNewId) })
    } else {
      this.setState({ selectedProductForDeactivate: [...this.state.selectedProductForDeactivate, getNewId] })
    }
  }

  handleCloseSnackbar = () => { this.setState({ isSnackbarOpen: false, snackbarMessage: '' }) }

  handleAddNewProduct = () => {
    console.log("checking confirtion", this.state.isSelectedAll, this.state.selectedForAdd.length)
    if(this.state.isSelectedAll || this.state.selectedForAdd.length > 0) this.addProductsToPricelist()
  }

  handleServiceChange = (itemId: number, productId: number, newValue: string | number) => {
    let newProductList = cloneDeep(this.state.priceListProductList);
    let updatedPricelist: Product[] = newProductList.map((products) => {
      if(products.id == productId) { 
        let updatedServiceList = products.catalogue_variants_services.map((services) => {
          if(services.id == itemId) {
            return {...services, price: newValue}
          }
          return services
        }) as CatalogueVariantService[];
        return {...products, catalogue_variants_services: updatedServiceList}
      }
      return products
    })
    this.setState({ priceListProductList: updatedPricelist })
  }

  handleProductIdForUpdateService = (getProductId: number) => {
    if (this.state.productUpdateId === null) {
      this.setState({ productUpdateId: getProductId })
    }
  }

  handleCheckValueIsNotEmpty = (checkValue: string) => {
    if(checkValue.length == 0 || checkValue == "0") {
      return true
    }
    return false
  }

  handleClickOnSelectAll = () => {
    this.setState({isSelectedAll: true, selectedForAdd: []})
  }

  handleAutoCompleteChange = (getValue: string) => {
    this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.getAllProductData(this.state.priceListServiceIds, getValue)
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string) =>
      this.handleAutoCompleteChange(newInputValue),
    700,
    { maxWait: 2000 }
  );

  checkSelectAllText(value: {id: string | number, option: string}[]) {
    this.setState({ selectedForAdd: value })
  }

  handleScrollProductDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentProductData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getAllProductData(this.state.priceListServiceIds, "")
      })
    }
  }

  handleEmptyAutoSelectValue = () => {
    this.setState({ autoCopmpleteValue: "" })
  }

  handleSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => {
    if (option.id == "-1") {
      if(this.state.isSelectedAll) {
        this.setState({ isSelectedAll: false, selectedForAdd: [], selectedOptionForList: [] });
      } else {
        this.setState({
          selectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.productListItemsDropdown.map((item) => { return { id: item.id, option: item.attributes.product_name } })],
          isSelectedAll: true,
          selectedForAdd: [{ id: "-1", option: "Select All" }],
        });
      }
      return;
    }

    const isContained = value.some((item) => item.id == option.id)
    if (isContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({
        selectedForAdd: value.filter((item) => item.id != option.id),
        selectedOptionForList: value.filter((item) => item.id != option.id),
        isSelectedAll: false
      })
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({
        selectedForAdd: [...value, option],
        selectedOptionForList: [...value, option],
        isSelectedAll: false
      })
    }
  };
  // Customizable Area End
}

