// Customizable Area Start
import React from "react";
import B2bAddCustomerGroupController, {
  Props,
  configJSON,
} from "./B2bAddCustomerGroupController";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import {
  styled,
  Box,
  Grid,
  InputLabel,
  Checkbox,
  Typography,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import {
  InputField,
  ListingPageHeading,
  MainButtonComponent,
} from "../../../components/src/customComponents/CustomTags.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.B2bAddCustomerGroup;
// Customizable Area End

export default class B2bAddCustomerGroup extends B2bAddCustomerGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkError(error: any, touch: any) {
    if (error && touch) {
      return true;
    }
  }

  finalErrorMessage(error: any, touch: any) {
    if (error) {
      if (touch) {
        return <div className={"fieldError"}>{error}</div>;
      } else {
        return null;
      }
    } else {
      return;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const validationSchema = Yup.object().shape({
      customer_group_name: Yup.string().required(
        "Customer group name is required."
      ),
      no_item_per_wash: Yup.number()
        .typeError("Value must be a number")
        .min(0, "Value must be 0 or greater"),
      no_pieces_per_item: Yup.number()
        .typeError("Value must be a number")
        .min(0, "Value must be 0 or greater"),
      company: Yup.string().required("Please select company"),
    });
    // Customizable Area End
    return (
      // Customizable Area Start
      <B2bAddCustomerGroupWrapper>
        <CustomLoader loading={this.state.isDataLoading} />
        <ListingPageHeading className={"addPageTitle"}>
          {sortStringCondition(
            this.state.isEditForm,
            Strings.editCustomerGroup,
            Strings.addCustomerGroup
          )}
        </ListingPageHeading>
        <Formik
          data-test-id={"formikAddCustomerGroup"}
          initialValues={this.state.addCustomerGroupIntialValue}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmitForm.bind(this)}
          enableReinitialize
        >
          {({
            values,
            resetForm,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            errors,
            touched,
          }: FormikProps<FormikValues>) => (
            <form onSubmit={handleSubmit}>
              <Box className={"create-group-form"}>
                <Grid container spacing={3} id={"add-customer-group"}>
                  <Grid item xs={12} md={6}>
                    <InputLabel className={"fieldLabel"}>
                      {`${Strings.customerGroupName}*`}
                    </InputLabel>
                    <InputField
                      error={this.checkError(
                        errors.customer_group_name,
                        touched.customer_group_name
                      )}
                      data-test-id={"customer_group_name"}
                      id={"customer_group_name"}
                      fullWidth={true}
                      placeholder={Strings.enterCustomerGroupName}
                      variant={"outlined"}
                      {...getFieldProps("customer_group_name")}
                    />
                    {this.finalErrorMessage(
                      errors.customer_group_name,
                      touched.customer_group_name
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className={"fieldLabel"}>
                      {`${Strings.company}*`}
                    </InputLabel>
                    <AutocompleteSelect
                      data-test-id={"company-select"}
                      fieldName={"company"}
                      error={this.checkError(errors.company, touched.company)}
                      className={"simple-selection-box"}
                      placeholder={Strings.selectCompany}
                      handleScroll={this.handleScrollCompanyDropdown.bind(this)}
                      debouncedFunction={(getValue: string) => {
                        this.debouncedFunction(
                          getValue,
                          this.handleAutoCompleteChange
                        );
                      }}
                      onBlur={() =>
                        this.debouncedFunction(
                          "",
                          this.handleAutoCompleteChange
                        )
                      }
                      onChange={(
                        _blank: unknown,
                        value: { id: number; option: string } | null
                      ) => {
                        this.handleChangeCompany(value, setFieldValue);
                      }}
                      value={values.company}
                      options={this.state.companyDropdownList}
                      listBoxStyle={{ maxHeight: 270 }}
                    />
                    {this.finalErrorMessage(errors.company, touched.company)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className={"fieldLabel"}>
                      {Strings.designation}
                    </InputLabel>
                    <InputField
                      data-test-id={"designation"}
                      id={"designation"}
                      fullWidth={true}
                      placeholder={Strings.enterDesignation}
                      variant={"outlined"}
                      {...getFieldProps("designation")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className={"fieldLabel"}>
                      {Strings.noItemPerWash}
                    </InputLabel>
                    <InputField
                      data-test-id={"no_item_per_wash"}
                      error={this.checkError(
                        errors.no_item_per_wash,
                        touched.no_item_per_wash
                      )}
                      id={"no_item_per_wash"}
                      fullWidth={true}
                      placeholder={Strings.enterItemsPerWash}
                      variant={"outlined"}
                      {...getFieldProps("no_item_per_wash")}
                    />
                    {this.finalErrorMessage(
                      errors.no_item_per_wash,
                      touched.no_item_per_wash
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className={"fieldLabel"}>
                      {Strings.noPieacePerOrder}
                    </InputLabel>
                    <InputField
                      error={this.checkError(
                        errors.no_pieces_per_item,
                        touched.no_pieces_per_item
                      )}
                      data-test-id={"no_pieces_per_item"}
                      id={"no_pieces_per_item"}
                      fullWidth={true}
                      placeholder={Strings.emterPieacePerWash}
                      variant={"outlined"}
                      {...getFieldProps("no_pieces_per_item")}
                    />
                    {this.finalErrorMessage(
                      errors.no_pieces_per_item,
                      touched.no_pieces_per_item
                    )}
                  </Grid>
                </Grid>
                <Box className={"checkbox-wrap"}>
                  <StyledCheckbox
                    data-test-id={"vip-check-box"}
                    checked={values.vip_previleges}
                    onChange={(
                      event: React.ChangeEvent<{ checked: boolean }>
                    ) => setFieldValue("vip_previleges", event.target.checked)}
                  />
                  <Typography className={"checkbox-label"}>
                    {Strings.vipPrivileges}
                  </Typography>
                </Box>
                <TextareaAutosize
                  minRows={3}
                  className={"description-box"}
                  placeholder={Strings.groupDescription}
                  {...getFieldProps("group_description")}
                />
              </Box>
              <Box className={"create-group-form mt-16"}>
                <Box className={"add-customer-flex"}>
                  <Box className={"max-w-470"}>
                    <CustomSearchableMultiSelect
                      keepSelectAll={false}
                      id={"customer"}
                      name={"customer"}
                      dataTestId={"customerDropdown"}
                      checkSelectAllText={() => {}}
                      value={this.state.selectedCustomersForAdd}
                      optionList={this.state.customersDropdownList}
                      placeHolderText={Strings.typeToSearchCustomer}
                      changeInputOnClear
                      handleScroll={this.handleScrollCustomerDropdown}
                      handleClickOnSelectAll={() => {}}
                      isOnBlurFunction={() =>
                        this.debouncedFunction(
                          "",
                          this.handleAutoCompltCustomerChange
                        )
                      }
                      debouncedFunction={(getValue: string) => {
                        if (getValue === '') {
                          this.handleClearCustomerGroup()
                        }
                        this.debouncedFunction(
                          getValue,
                          this.handleAutoCompltCustomerChange
                        );
                      }}
                      handleSelectOptions={(
                        value: { id: string; option: string }[],
                        option: { id: string; option: string }
                      ) => {
                        this.handleCustomerSelectOptions(value, option);
                      }}
                      emptyDataMessage={Strings.noCustomerFound}
                      handleEmptyAutoSelectValue={() => {}}
                      selectedOptionForList={
                        this.state.addCustomerSelectedOptionForList
                      }
                    />
                  </Box>
                  <Typography className={"add-customer-txt"}>
                    {Strings.add}
                  </Typography>
                </Box>
              </Box>
              <Box className={"buttonBox"}>
                <MainButtonComponent
                  data-test-id={"cancel-button"}
                  className={"buttonSecondary w-126"}
                  onClick={() => this.handleGoBackToListing()}
                >
                  {Strings.cancel}
                </MainButtonComponent>
                <MainButtonComponent
                  type={"submit"}
                  className={"w-126"}
                  disabled={this.state.formSubmitLoading}
                  data-test-id={"submit-button"}
                >
                  {this.state.formSubmitLoading ? (
                    <CircularProgress className={"button-loader"} />
                  ) : (
                    sortStringCondition(
                      this.state.isEditForm,
                      Strings.save,
                      Strings.add
                    )
                  )}
                </MainButtonComponent>
              </Box>
            </form>
          )}
        </Formik>
        <CustomSnackbar
          data-test-id={"custom-snackbar"}
          open={this.state.snackBarOpen}
          onClose={() => this.handleSnackbarClose()}
          errorMessage={this.state.snackBarMessage}
          autoHideDuration={1500}
          severity={this.state.severity}
          hideIcon={true}
        />
      </B2bAddCustomerGroupWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const B2bAddCustomerGroupWrapper = styled(Box)({
  width: "100%",
  "& .create-group-form": {
    width: "100%",
    padding: "32px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)",
    background: colors().white,
  },
  "& .mt-16": {
    margin: "16px 0 0 0",
  },
  "& .fieldLabel": {
    fontFamily: "Montserrat",
    marginBottom: "4px",
    color: colors().charcoal,
    fontSize: "16px",
    fontWeight: 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      fontWeight: 600,
      color: colors().charcoal,
    },
  },
  "& .fieldError": {
    fontFamily: "Montserrat",
    color: colors().error,
    fontSize: "14px",
    margin: "5px 5px 5px 0",
  },
  "& .buttonBox": {
    margin: "24px 0 32px 0",
  },
  "& .checkbox-wrap": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "24px 0",
  },
  "& .checkbox-label": {
    fontFamily: "Montserrat",
    color: "#OF172A",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .description-box": {
    fontFamily: "Montserrat",
    color: "#OF172A",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "8px",
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${colors().lightborder}`,
  },
  "& .add-customer-flex": {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  "& .max-w-470": {
    maxWidth: "470px",
    width: "100%",
  },
  "& .add-customer-txt": {
    display: "none",
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "&.Mui-checked": {
    color: `${colors().cyancobaltblue} !important`,
  },
});
// Customizable Area End
