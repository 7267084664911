// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { makeApiMessage } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number;
}

interface BusinessAccount {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    sub_company_name: string;
    sub_company_id: null | number;
    business_customer: string;
    contract_person: string;
    email: string;
    phone_no: string;
    business_start_date: string;
    business_end_date: string;
    total_no_of_pieces_for_contract:string;
    total_no_of_order_for_contract:string;
    auto_renewal: boolean;
    company: {
      id: number;
      name: string;
    };
    price_list: {
      id: number;
      name: string;
    };
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  companyId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sortingData: ISortingData;
  page: number;
  per_page: number;
  meta: IMeta;
  businessAccountList: Array<BusinessAccount>;
  companyId: string;
  dataLoading: boolean;
  query: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BusinessAccountsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyBusinessAccountCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      sortingData: {
        company: "",
        sub_company: "",
        business_customer: "",
        business_start_date : ""
      },
      page: 1,
      per_page: 10,
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      businessAccountList: [],
      companyId: "",
      dataLoading: false,
      query: "",
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState(
      {
        companyId: this.props.companyId,
        dataLoading: true,
      },
      () => {
        this.getCompanyBusinessAccounts();
      }
    );
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCompanyBusinessAccountCallId) {
        this.handleBusinessAccountListResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(`${query}`),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 6) {
      return "right";
    } else {
      return "middle";
    }
  };

  getCompanyBusinessAccounts = () => {
    const { page, per_page, companyId, query } = this.state;

    const pageQuery = `?page_no=${page}&per_page=${per_page}&company_id=${companyId}`;

    const sortingQuery = query ? `&${query}` : "";

    const apiUrl = configJSON.companyBusinessAccountEndPoint + pageQuery + sortingQuery;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCompanyBusinessAccountCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleBusinessAccountListResponse = (response: {
    data: BusinessAccount[];
    meta: IMeta;
    status: number;
  }) => {
    if (response && response.data) {
      this.setState({
        businessAccountList: response.data,
        meta: response.meta,
        dataLoading: false,
      });
    } else {
      this.setState({
        dataLoading: false,
      });
    }
  };

  handleQueryChange = (query: string) => {
    this.setState(
      {
        query,
        dataLoading: true,
      },
      () => this.getCompanyBusinessAccounts()
    );
  };
  // Customizable Area End
}
