// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { cloneDeep, debounce, uniqBy, isNumber, toString } from "lodash";
import * as Yup from "yup"
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { makeApiMessage } from "../../../components/src/common";
import moment from "moment";
import { IFilter } from "../../../components/src/FilterPopover";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey,conditionalString, navigateTo, sortStringCondition, CustomEnums, getCustomEnumName, randomNumberGenerator } from "../../utilities/src/CustomBlockHelpers";
import { RequestBody } from "./B2bBusinessAccountAddController";
import { FormikHelpers } from "formik";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IMeta {
  next_page: number | null;
  pervious_page: number | null;
  total_pages: number;
  total_count: number;
  current_page: number;
}
interface BusinessAccountAtrribute {
  activated: boolean,
  sub_company_name: string | null,
  sub_company_id: number | null,
  business_customer: string,
  business_account_no: string
  contract_person: string,
  email: string,
  phone_no: string,
  business_start_date: string,
  business_end_date: string | null,
  auto_renewal: boolean,
  total_no_of_order_for_contract: number | null
  order_period_type_for_contract: string
  total_no_of_pieces_for_contract: number | null
  pieces_period_type_for_contract: string
  company: {
    "id": number,
    "name": string
  },
  price_list: {
    "id": number,
    "name": string
  } | null
  business_available_times: {
    id?: number
    start_time: string
    end_time: string
  }[]
  available_days: string[]
}
interface BusinessAccountListApiResponse {
  data?: {
    id: string
    attributes: BusinessAccountAtrribute
  }[]
  meta?: IMeta
}

export type BusinessAccountRecord = {
  id: string
  used_orders?: number
  used_pieces?: number
  available_times: {
    id?: number
    from: string
    to: string
  }
} & BusinessAccountAtrribute

const DEFAULT_META = {
  next_page: null,
  pervious_page: null,
  total_pages: 1,
  total_count: 0,
  current_page: 1,
}

const STATUS_FILTER_OPTIONS = [
  {
    label: "Enabled",
    value: "enabled",
  },
  {
    label: "Disabled",
    value: "disabled",
  },
]

const DEFAULT_FILTERS : IFilter[] = [
  {
    title: "Company Name",
    type: "autocompolete",
    value: "",
    apiKey: "company_name",
    options: [],
  },
  {
    title: "Sub-Company",
    type: "autocompolete",
    value: "",
    apiKey: "sub_company_name",
    options: [],
  },
  {
    title: "Catalogue",
    type: "autocompolete",
    value: "",
    apiKey: "price_list",
    options: [],
  },
  {
    title: "Date",
    type: "dateselect",
    value: "specific",
    datevalue: { from: "", to: "" },
    apiKey: "date",
    options: [],
    customSpecificRange: {
      fromLabel: "Business Start Date",
      toLabel: "Business End Date",
      isSpecificOptionOnly: true
    }
  },
  {
    title: "Status",
    type: "select",
    value: "",
    apiKey: "status",
    options: STATUS_FILTER_OPTIONS,
  },
]

export type DialogPayload = {
  selectedBusinessAccount?: BusinessAccountRecord;
  dialogTitle?: string;
  apiMessage?: string;
  action: "View" | "Renew" | "Activate" | "Deactivate" | "Done"
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAppliedFilter: boolean;
  sortingData: ISortingData;
  openActionPopover: (EventTarget & HTMLButtonElement) | null;
  popoverItem: BusinessAccountRecord | null
  page: number;
  sortQuery: string;
  rowPerPage: number;
  meta: IMeta;
  isLoadingPermission: boolean;
  isLoadingTable: boolean;
  isLoadingDialog: boolean;
  openImportExportPopover: (EventTarget & HTMLButtonElement) | null;
  tableData: BusinessAccountRecord[]
  filters: IFilter[]
  catalogues: { id: number; option: string }[]
  filterAnchor: HTMLDivElement | undefined
  searchText: string
  dialogPayload: DialogPayload | null
  errorMessage: string
  permissionStatus: PermissionStatus;
  setLoaded: number;
  uploadedFile: File | null;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorSnackbarOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bBussinessAccountListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBusinessAccountListCallId = "";
  filterSuggestionApiCallId = "";
  updateStatusCallId = "";
  getUsedOrdersPiecesCallId = "";
  suggestionFieldTitle = "";
  renewBusinessAccountCallId = ""
  getPriceListsCallId = ""
  exportTemplateFileApiId = "";
  exportCsvApiId = "";
  importFileApiId = "";
  FormSchema = Yup.object().shape({
    business_start_date: Yup.string().trim().required("Contract start date is required"),
  })
  catalogueQuery = ""
  cataloguePage = 1
  disableLoadMoreCatalogue = false

  weekdayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
    weekday => ({
      id: weekday,
      option: weekday
    })
  )
  adapter: ImportExportWebAdapter;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isAppliedFilter: false,
      openActionPopover: null,
      popoverItem: null,
      sortingData: {
        company: "",
        sub_company: "",
        phone_no: "",
        email: "",
        price_list: "",
        business_start_date: "",
        business_end_date: "",
        activated: "",
        business_customer: ""
      },
      page: 1,
      sortQuery: "",
      rowPerPage: 10,
      meta: DEFAULT_META,
      isLoadingPermission: true,
      isLoadingTable: true,
      isLoadingDialog: false,
      openImportExportPopover: null,
      tableData: [],
      filters: cloneDeep(DEFAULT_FILTERS),
      filterAnchor: undefined,
      catalogues: [],
      searchText: "",
      dialogPayload: null,
      errorMessage: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      setLoaded: 0,
      uploadedFile: null,
      snakcbarSeverity: "error",
      errorSnackbarOpen: false
    };
    this.adapter = new ImportExportWebAdapter()
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter()
    setTimeout(() => this.setState({ isLoadingPermission: false }), 2000);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveSearchTextData(message)
    this.receiveLayoutInfoData(message)
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.receiveApiMessage(message)
    }
    this.handleImportExportActions(from, message)
    // Customizable Area End
  }

// Customizable Area Start

  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.SearchMessageText)
      );
      if (receivedData) {
        this.setState({ page: 1 }, () => {
          this.getBusinessAccountList(receivedData.searchText)
        })
      }
    }
  }

  handleQueryChange = (sortQuery: string) => {
    this.setState({ sortQuery }, () => this.getBusinessAccountList());
  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(query),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 8) {
      return "right";
    } else {
      return "middle";
    }
  };

  handleCloseActionPopver = () => {
    this.setState({ openActionPopover: null, popoverItem: null });
  };

  handleOpenActionPopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    popoverItem: BusinessAccountRecord
  ) => {
    this.setState({
      openActionPopover: event.currentTarget,
      popoverItem
    });
  };

  openImportExportPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openImportExportPopover: event.currentTarget,
    });
  };

  handleCloseImportExportPopver = () => {
    this.setState({ openImportExportPopover: null });
  };

  getBusinessAccountList = (searchText?: string) => {
    this.setState({ isLoadingTable: true })
    const { page, sortQuery, filters } = this.state
    const filterByCompany = conditionalString(
      filters[0].value,
      `&filter_by[company_name]=${filters[0].value}`,
      ""
    )
    const filterBySubCompany = conditionalString(
      filters[1].value,
      `&filter_by[sub_company_name]=${filters[1].value}`,
      ""
    )
    const filterByPriceList = conditionalString(
      filters[2].value,
      `&filter_by[price_list_name]=${filters[2].value}`,
      ""
    )
    const filterByDate = conditionalString(filters[3].datevalue?.from, `&filter_by[start_date]=${this.formatDate(filters[3].datevalue?.from, "YYYY-MM-DD")}`, '')
      + conditionalString(filters[3].datevalue?.to, `&filter_by[end_date]=${this.formatDate(filters[3].datevalue?.to, "YYYY-MM-DD")}`, '')
    const filterByStatus = conditionalString(filters[4].value, `&filter_by[status]=${filters[4].value}`, "")

    const filterBySearchText = conditionalString(searchText, `&filter_by[query]=${searchText}`, "")


    const queryString = `?page_no=${page}`
      + filterByCompany + filterBySubCompany + filterByPriceList + filterByDate + filterByStatus
      + filterBySearchText + conditionalString(sortQuery, `&${sortQuery}`, "")

    const apiMessage = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: configJSON.apiEndPoints.businessAccount + queryString
    })
    this.getBusinessAccountListCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  getTime = (inputString: string) => {
    if (!inputString) return "";
    const parsedTime = moment.parseZone(inputString);
    return parsedTime.format("HH:mm")
  }

  formatDate = (inputDate: string | null | undefined, format?: string) => inputDate ? moment(inputDate).format(format || "DD-MM-YYYY") : ""

  formatNumber = (inputValue?: number | null) => isNumber(inputValue) ? inputValue.toLocaleString("en-US") : toString(inputValue)

  checkIsFilterApplied = (filters: IFilter[]) => {
    return filters.some(filter =>
      (filter.type !== "dateselect" && filter.value)
      || (filter.type === "dateselect" && (filter.datevalue?.from || filter.datevalue?.to))
    )
  }

  handleFilterChange = (filterValues: IFilter[]) => {
    const filters = filterValues.map(filter => (
      filter.type === "dateselect" && filter.value !== "specific" ? ({...filter, value: "specific"}) 
      : filter
    ))
    if (this.checkIsFilterApplied(filters)) {
      localStorage.setItem(configJSON.b2bBusinessAccountFilterStorageKey, JSON.stringify(filters));
    } else {
      localStorage.removeItem(configJSON.b2bBusinessAccountFilterStorageKey);
    };

    this.setState(
      {
        filters,
        isAppliedFilter: this.checkIsFilterApplied(filters),
        page: 1,
      },
      this.getBusinessAccountList
    );
  };

  handleClearFilter = () => {
    this.setState({filters: cloneDeep(DEFAULT_FILTERS)})
  }

  handleStorageFilter = () => {
    const storedFilter = localStorage.getItem(configJSON.b2bBusinessAccountFilterStorageKey);
    if (storedFilter) {
      const filters = JSON.parse(storedFilter)
      this.setState(
        {
          filters,
          isAppliedFilter: this.checkIsFilterApplied(
            filters
          ),
        },
        this.getBusinessAccountList

      );
    } else {
      this.getBusinessAccountList();
    }
  };

  handleFilterAutoComplete = (title: "Company Name" | "Sub-Company" | "Catalogue", value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
    });

    const apiUrl = configJSON.apiEndPoints.businessAccountFilterSuggestion + {
      "Company Name": "?company_name=",
      "Sub-Company": "?sub_company_name=",
      "Catalogue": "?price_list_name="
    }[title]
    let requestMessage = makeApiMessage({
      url: apiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    this.suggestionFieldTitle = title
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedFilterSuggestion = debounce(
    this.handleFilterAutoComplete,
    700,
    { maxWait: 2000 }
  );

  handleFilterSuggResp = (responseJson: { suggestion?: string[] }) => {
    const list = responseJson.suggestion?.map((value: string) => {
      return {
        label: value,
        value,
      }
    }) || []
    const updatedFilters = this.state.filters.map((item: IFilter) => {
      if (item.value) {
        if (item.title === this.suggestionFieldTitle) return { ...item, options: list };
        return item
      } else {
        if (item.title === this.suggestionFieldTitle) return { ...item, options: [] };
        return item;
      }
    });
    this.setState({ filters: updatedFilters });
  }

  openFilterPopver = (event: React.SyntheticEvent<HTMLDivElement>) => {
    this.setState({ filterAnchor: event.currentTarget });
  }

  closeFilterPopover = () => {
    this.setState({ filterAnchor: undefined });
  }

  redirect = (screenName: string, id?: string) => {
    navigateTo({
      props: this.props,
      screenName,
      id
    })
  }

  handlePageChange = (event: unknown, page: number) => {
    this.setState({ page: page + 1 }, this.getBusinessAccountList)
  }

  handleToggleDialog = (dialogPayload?: DialogPayload) => {
    this.setState({ dialogPayload: dialogPayload || null })
  }

  handleViewStatus = (account: BusinessAccountRecord) => {
    this.setState({
      dialogPayload: {
        action: "View",
        selectedBusinessAccount: account
      },
      isLoadingDialog: true
    })
    const apiMessage = makeApiMessage({
      url: configJSON.apiEndPoints.businessAccount + `/${account.id}/get_business_account_orders_data`,
      method: configJSON.httpGetMethod
    })
    this.getUsedOrdersPiecesCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  handleUpdateAccountStatus = (account: BusinessAccountRecord) => {
    this.setState({ isLoadingDialog: true })
    const apiUrl = configJSON.apiEndPoints.businessAccount + `/${account.id}/${conditionalString(!account.activated, "activate", "deactivate")}`
    const apiMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpPutMethod
    })
    this.updateStatusCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  handleConfirmation = (selectedBusinessAccount?: DialogPayload["selectedBusinessAccount"]) => {
    if (selectedBusinessAccount) {
      this.handleUpdateAccountStatus(selectedBusinessAccount)
    } else {
      this.setState({ dialogPayload: null, popoverItem: null, openActionPopover: null })
      this.getBusinessAccountList()
    }
  }

  handleCloseSnackbar = () => this.setState({ errorMessage: "" })

  handleSubmitRenewForm = (formValues: BusinessAccountRecord) => {
    this.setState({ isLoadingDialog: true })
    const requestBody: Partial<RequestBody> = {
      business_account_no: formValues.business_account_no,
      business_start_date: formValues.business_start_date,
      business_end_date: toString(formValues.business_end_date),
      b2b_price_list_id: formValues.price_list?.id || null,
      available_days: formValues.available_days,
      business_available_times_attributes: [
        {
          id: formValues.available_times.id,
          start_time: formValues.available_times.from,
          end_time: formValues.available_times.to,
          _destroy: !formValues.available_times.from || !formValues.available_times.to
        }
      ],
    }
    const url = configJSON.apiEndPoints.businessAccount + `/${formValues.id}`
    const apiMessage = makeApiMessage({
      url,
      method: "PUT",
      body: JSON.stringify({
        data: requestBody
      })
    })
    this.renewBusinessAccountCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  handleScrollCatalogueDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreCatalogue) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.cataloguePage++
      this.getCatalogues()
    }
  };

  handleBlurCatalogueDropdown = () => {
    this.catalogueQuery = ""
    this.cataloguePage = 1
    this.disableLoadMoreCatalogue = false
  }

  handleCatalogueAutoCompleteChange = (getValue: string) => {
    if (getValue === this.catalogueQuery) return;
    this.catalogueQuery = getValue
    this.cataloguePage = 1
    if (getValue.length < 1 || getValue.length > 2) {
      this.getCatalogues()
    }
  }

  getCatalogues = () => {
    this.disableLoadMoreCatalogue = true
    const paginationQuery = sortStringCondition(this.cataloguePage > 1, `&page_no=${this.cataloguePage}`, "")
    const nameQuery = sortStringCondition(this.catalogueQuery.length > 0, "&filter_by[query]=" + this.catalogueQuery, "")
    const message = makeApiMessage({
      url: configJSON.apiEndPoints.priceList + paginationQuery + nameQuery,
      method: "GET"
    })
    this.getPriceListsCallId = message.messageId
    this.send(message)
  }

  debouncedCatalogueAutocompleteHandler = debounce(
    (newInputValue: string) => this.handleCatalogueAutoCompleteChange(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleOpenRenewAccountForm = (account: BusinessAccountRecord) => {
    const initialFormValue = cloneDeep(account)
    this.cataloguePage = 1
    this.catalogueQuery = ""
    this.setState({
      dialogPayload: { action: "Renew", selectedBusinessAccount: initialFormValue },
      catalogues: []
    }, this.getCatalogues)
  }

  getCatalogueOptions = (currentPriceList: { id: number; name: string } | null) => {
    const { catalogues } = this.state
    if (
      !currentPriceList
      || (
        currentPriceList && catalogues.some(catalogue => catalogue.id === currentPriceList.id)
      )
    ) return catalogues
    return [{ id: currentPriceList.id, option: currentPriceList.name }, ...catalogues]
  }

  handleSelectWeekdayOptions = (
    optionId: string,
    currentValue: string[],
    setFieldValue: FormikHelpers<BusinessAccountRecord>["setFieldValue"]
  ) => {
    let newValues: Array<string | number> = []
    if (optionId === "-1") {
      if (currentValue.length < this.weekdayOptions.length) {
        newValues = this.weekdayOptions.map(item => item.id)
      }
    } else {
      const isSelected = currentValue.includes(optionId)
      newValues = isSelected ? currentValue.filter((selected) => selected != optionId) : [...currentValue, optionId]
    }
    setFieldValue("available_days", newValues)
  }

  receiveApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    switch (apiRequestCallId) {
      case this.getBusinessAccountListCallId:
        const { data = [], meta = DEFAULT_META } = responseJson as BusinessAccountListApiResponse
        this.setState({
          tableData: data.map(item => ({
            ...item.attributes,
            id: item.id,
            business_start_date: item.attributes.business_start_date,
            business_end_date: item.attributes.business_end_date,
            available_times: {
              id: item.attributes.business_available_times[0]?.id,
              from: this.getTime(item.attributes.business_available_times[0]?.start_time),
              to: this.getTime(item.attributes.business_available_times[0]?.end_time)
            }
          })),
          meta,
          isLoadingTable: false
        })
        break;
      case this.renewBusinessAccountCallId:
      case this.updateStatusCallId:
        const { data: successMessage = "", full_messages = [] } = responseJson
        this.setState((prev) => ({
          isLoadingDialog: false,
          errorMessage: full_messages[0] || conditionalString(successMessage, "", "An error has happened"),
          dialogPayload: {
            apiMessage: successMessage,
            dialogTitle:
              successMessage && ["Activate", "Deactivate", "Renew"].includes(prev.dialogPayload?.action as Exclude<DialogPayload["action"], undefined>) ?
                {
                  "Activate": "Account Activated",
                  "Deactivate": "Account Deactivated",
                  "Renew": "Account Renewed"
                }[prev.dialogPayload?.action as "Activate" | "Deactivate" | "Renew"] :
                "",
            action: successMessage ? "Done" : prev.dialogPayload?.action as Exclude<DialogPayload["action"], undefined>,
            selectedBusinessAccount: successMessage ? undefined : prev.dialogPayload?.selectedBusinessAccount
          }
        }))
        break;
      case this.getUsedOrdersPiecesCallId:
        const { data: ordersData = {} } = responseJson
        const { used_orders, used_pieces } = ordersData
        if (this.state.dialogPayload?.selectedBusinessAccount) {
          this.setState(({
            dialogPayload: {
              ...this.state.dialogPayload,
              selectedBusinessAccount: {
                ...this.state.dialogPayload.selectedBusinessAccount,
                used_orders,
                used_pieces
              }
            },
            isLoadingDialog: false
          }))
        }
        break;
      case this.getPriceListsCallId:
        const parsedOptions = responseJson.data?.map((item: { attributes: { id: number; name: string } }) => ({
          id: item.attributes.id,
          option: item.attributes.name
        })) || []
        this.disableLoadMoreCatalogue = parsedOptions.length < 10
        this.setState((prev) => ({ catalogues: uniqBy([...prev.catalogues, ...parsedOptions], "id") }))
        break;
      case this.filterSuggestionApiCallId:
        this.handleFilterSuggResp(responseJson)
        break;
      case this.exportTemplateFileApiId:
        this.handleResForExportTemplateFile(responseJson)
        break;
      case this.exportCsvApiId:
        this.exportCSVfileRes(responseJson)
        break;
      case this.importFileApiId:
        this.handleImportCsvFileResponse(responseJson)
        break;
      default:
        break;
    }
  }

  receiveLayoutInfoData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if(recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.b2bBusinessAccountPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
    })
  };

  handleExportCsv = () => {
    
    this.setState({ openImportExportPopover: null });
    const { page, sortQuery, filters } = this.state
    const filterByCompanyEx = conditionalString(
      filters[0].value,
      `&filter_by[company_name]=${filters[0].value}`,
      ""
    )
    const filterBySubCompanyEx = conditionalString(
      filters[1].value,
      `&filter_by[sub_company_name]=${filters[1].value}`,
      ""
    )
    const filterByPriceListEx = conditionalString(
      filters[2].value,
      `&filter_by[price_list_name]=${filters[2].value}`,
      ""
    )
    const filterByDateEx = conditionalString(filters[3].datevalue?.from, `&filter_by[start_date]=${this.formatDate(filters[3].datevalue?.from, "YYYY-MM-DD")}`, '')
      + conditionalString(filters[3].datevalue?.to, `&filter_by[end_date]=${this.formatDate(filters[3].datevalue?.to, "YYYY-MM-DD")}`, '')
    const filterByStatus = conditionalString(filters[4].value, `&filter_by[status]=${filters[4].value}`, "")
    const queryString = `?page_no=${page}`
      + filterByCompanyEx + filterBySubCompanyEx + filterByPriceListEx + filterByDateEx + filterByStatus
       + conditionalString(sortQuery, `&${sortQuery}`, "")
    const apiUrl = configJSON.exportBusinessAccountTemplateCsvApiurl;

    const requestMessage = makeApiMessage({
      url: apiUrl + queryString ,
      method: configJSON.validationApiMethodType,
    });

    this.exportCsvApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }

  exportCSVfileRes = (responseJson: { file_url: string;message: string  }) => {
    if (responseJson) {
      // location.href = responseJson.file_url
       this.setState({ 
        snakcbarSeverity: 'success', 
        errorSnackbarOpen: true, 
        errorMessage: responseJson.message
      });
    }
  }

  handleOpenImportModal = () => {
    this.setState({ openImportExportPopover: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  }

  handleImportFile = () => {
    const apiUrl = configJSON.importCsvApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.exampleAPiMethod,
      body: formData,
      isHeader: true
    });
    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  };

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "B2bBusinessAccountList" })
    this.getBusinessAccountList();
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updateLoadingTime(this.state.setLoaded)
      }, () => {
        const importExportPopupMessage = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        importExportPopupMessage.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(importExportPopupMessage.id, importExportPopupMessage)
      })

    }, delay);

    // for adding 20 percent every time
    function updateLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = file && file[0];
    this.setState({ uploadedFile: checkFile as File})
  }

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'B2bBusinessAccountList')
    runEngine.sendMessage(message.id, message)
  }

  handleImportCsvFileResponse(response: IImportCSVResponse) {
    if (response) {
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
      message.addData('APIresponse', response)
      runEngine.sendMessage(message.id, message);
      if (response?.message) {
        this.setState({ snakcbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: response.message })
        navigateTo({ props: this.props, screenName: "B2bBusinessAccountList" })
        this.getBusinessAccountList();
      } else if (response?.status == 500) {
        this.setState({
          snakcbarSeverity: 'error',
          errorSnackbarOpen: true,
          errorMessage: response?.error || ''
        });
        navigateTo({ props: this.props, screenName: "B2bBusinessAccountList" })
      } else {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
        message.addData('APIresponse', response);
        runEngine.sendMessage(message.id, message);
        navigateTo({ 
          props: this.props,
          screenName: "ImportExportErrorModal",
          raiseMessage: message
        })
      }
    }
  }

  handleExportTemplate = () => {
    this.setState({ openImportExportPopover: null })
    const apiUrl = configJSON.exportTemplateEndPoint;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.exportTemplateFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForExportTemplateFile = async (responseJson: {data: {url?: string;message?:string}}) => {
    if (responseJson.data?.url) {
      location.href = responseJson.data.url;
      this.setState({ 
        snakcbarSeverity: 'success', 
        errorSnackbarOpen: true, 
         errorMessage: conditionalString(
          responseJson?.data.message,
          responseJson?.data.message as string,
          "Template file has been exported successfully"
        )
      });
    }
  }

  handleImportExportActions(from: string, message: Message) {
    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }
    switch (from) {
      case getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage):
        this.handleFileUpload(message.properties.fileEvent)        
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage):
        this.handleImportFile()
        break;
        
      case getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked):
        this.handleImportExportModalClose();
        break;

      case getCustomEnumName(CustomEnums.ImportExportClearFileMessage):
        this.setState({ uploadedFile: message.properties.uploadedFile, setLoaded: message.properties.setLoaded });
        break;
        
      default:
        break;
        
    }    
  }

  // Customizable Area End
}
