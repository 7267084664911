import React, { useMemo, useState } from "react"
import { toString } from "lodash"
import { styled, Dialog, TextField, Select, MenuItem } from "@material-ui/core"
import { colors } from "../../../blocks/utilities/src/Colors"
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers"

export type Item = {
    product_master_id: number
    name: string
    no_of_pieces: number | null
}

type Props = {
    unit: string
    items: Array<Item>
    onDone?: (newItems: Array<Item>, periodType: string) => void
    isView?: boolean;
    buttonName?:string
    showPeriodType?: boolean
}

const ITEMS_PER_PAGE = 10

const NumberOfPiecesPerOrder = (props: Props) => {
    const { isView = false, showPeriodType = true, onDone } = props
    const [showModal, setShowModal] = useState(false)
    const [items, setItems] = useState<Array<Item>>([])
    const [page, setPage] = useState(5)
    const [unit, setUnit] = useState("year")

    const displayedItems = useMemo(() => (page * ITEMS_PER_PAGE) > items.length ?
        items
        : items.slice(0, page * ITEMS_PER_PAGE)
        , [items, page])

    const openModal = () => {
        setItems(props.items.slice())
        setPage(5)
        setUnit(props.unit)
        setShowModal(true)
    }

    const hanldeChangePieces = (value: string, item: Item) => {
        const numericValue = value.replace(/[^\d]/g, "");
        setItems((prevItems) => prevItems
            .map(prevItem =>
                prevItem === item ?
                    ({ ...prevItem, no_of_pieces: parseInt(numericValue) || null })
                    : prevItem
            ))
    }

    const closeModal = () => setShowModal(false)

    return (
        <>
            <StyledButton
                type="button"
                onClick={openModal}
                className={isView ? "view-button" : undefined}
            >
                {
                    sortStringCondition(isView, "View", props.buttonName ? props.buttonName : "No. of Pieces Per Order")
                }
            </StyledButton>
            {
                showModal &&
                <StyledDialog open onClose={closeModal}>
                    <h2 className="modal-title">
                        {
                            sortStringCondition(
                                isView,
                                "Items",
                                props.buttonName ? props.buttonName : "No. of Pieces Per Order"
                            )
                        }
                    </h2>
                    {
                        renderBaseOnConditions(
                            isView || !showPeriodType,
                            null,
                            <Select
                                className="custom-unit-select"
                                variant="outlined"
                                renderValue={(value) => <StyledText>Per {toString(value)}</StyledText>}
                                value={unit}
                                onChange={(event, value) => setUnit(toString(event.target.value))}
                                MenuProps={
                                    {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }
                                }
                            >
                                <StyledMenuItem value="month">Month</StyledMenuItem>
                                <StyledMenuItem value="year">Year</StyledMenuItem>
                            </Select>
                        )
                    }

                    <div className="table">
                        <div className="table-header">
                            <p>Item</p>
                            <p>Pieces</p>
                        </div>
                        <div
                            className="table-body"
                            onScroll={(event: React.SyntheticEvent<HTMLDivElement>) => {
                                if (displayedItems.length === items.length) return;
                                const checkListboxNode = event.currentTarget;
                                const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
                                if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
                                    setPage((prevPage) => prevPage + 1)
                                }
                            }}
                        >
                            {
                                displayedItems.map(item => (
                                    <div className="table-row">
                                        <div className="table-cell first-column">{item.name}</div>
                                        <div className="table-cell second-column">
                                            {
                                                renderBaseOnConditions(
                                                    isView,
                                                    toString(item.no_of_pieces),
                                                    <TextField
                                                        fullWidth
                                                        value={toString(item.no_of_pieces)}
                                                        variant="outlined"
                                                        onChange={(event) => hanldeChangePieces(toString(event.target.value), item)}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="actions">
                        <button type="button" className="cancel-btn" onClick={closeModal} data-test-id="modal-cancel-btn">
                            Cancel
                        </button>
                        {
                            onDone && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        onDone(items, unit)
                                        closeModal()
                                    }
                                    }
                                    data-test-id="modal-save-btn"
                                >
                                    Done
                                </button>
                            )
                        }

                    </div>
                </StyledDialog>
            }
        </>
    )
}

const StyledText = styled("span")({
    textTransform: "capitalize"
})

const StyledButton = styled("button")({
    border: 0,
    width: "100%",
    background: colors().brightgray,
    color: colors().cyancobaltblue,
    padding: 18,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "19.2px",
    "&.view-button": {
        width: 96,
        padding: "6px 10px",
        "@media only screen and (max-width: 1024px)": {
            fontSize: 12,
            padding: 4,
            fontWeight: 400
        },
    }
})

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: 24,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
        minWidth: "fit-content",
        maxWidth: 1280
    },
    "& h2.modal-title": {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 1.25
    },
    "& .custom-unit-select": {
        minWidth: 164,
        alignSelf: "flex-end",
        borderRadius: 8,
        borderColor: colors().lightborder,
        "& .MuiSelect-select": {
            padding: "10px 32px 10px 16px",
            fontWeight: 400,
            fontFamily: "Montserrat"
        }
    },
    "& .actions": {
        display: "flex",
        gap: 24,
        justifyContent: "center",
        "& > button": {
            padding: 16,
            borderRadius: 8,
            width: 186,
            border: 0,
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1.5,
            textTransform: "none",
            cursor: "pointer",
            backgroundColor: colors().cyancobaltblue,
            color: "white",
            "&.cancel-btn": {
                backgroundColor: colors().antiFlashWhite,
                color: colors().slateGray,
            },
            "@media (max-width: 1024px)": {
                padding: "10px 0"
            },
        },
    },
    "& .table": {
        minWidth: 756,
        maxWidth: 900,
        "& .table-header": {
            display: "grid",
            gridTemplateColumns: "0.8fr 0.2fr",
            gap: 4,
            fontWeight: 600,
            color: "white",
            backgroundColor: colors().cyancobaltblue,
            padding: "4px 24px",
            borderRadius: "12px 12px 0 0",
            "& > p": {
                padding: "8px 12px"
            }
        },
        "& .table-body": {
            fontWeight: 500,
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: 16,
            maxHeight: 470,
            overflow: "auto",
            boxShadow: "0px 2px 8px 0px #00000014",
            "&::-webkit-scrollbar": {
                width: 5,
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
            },
        },
        "& .table-row": {
            display: "grid",
            gridTemplateColumns: "0.8fr 0.2fr",
            gap: 4,
            borderRadius: 12,
            border: `solid 1px ${colors().lightborder}`,
            alignItems: "center",
            padding: "4px 0 4px 16px",
            "& .table-cell": {
                fontSize: 14,
                padding: 4,
                wordBreak: "break-word",
                "&.first-column": {
                    padding: "8px 4px"
                },
                "& .MuiInputBase-root": {
                    borderRadius: 8,
                },
                "& .MuiInputBase-input": {
                    padding: "10px 8px",
                    fontFamily: "Montserrat",
                    fontSize: 14
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: colors().lightborder
                }
            }
        }
    }
})

const StyledMenuItem = styled(MenuItem)({
    fontFamily: "Montserrat",
    fontSize: 13,
    fontWeight: 500,
    color: colors().darkliver,
    "&.Mui-selected": {
        backgroundColor: colors().cyancobaltblue,
        color: "white"
    }
})

export default NumberOfPiecesPerOrder