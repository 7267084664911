import React, { useState } from "react"
import { uniq } from "lodash";
import { styled, Box, TextField } from "@material-ui/core"
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import ClearIcon from '@material-ui/icons/Clear';
import { colors } from "../../../blocks/utilities/src/Colors"

type Props = {
    value: string[] 
    placeholder?: string
    onChange: (newValue: string[]) => void
    error?: boolean
}

const SalesOrders = (props: Props) => {
    const [inputValue, setInputValue] = useState("")

    return (
        <Box>
            <Box display="flex" gridGap={16}>
                <Box flex={1}>
                    <StyledTextField
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value.trim())}
                        fullWidth={true}
                        placeholder={props.placeholder}
                        variant='outlined'
                        error={props.error}
                    />
                </Box>

                <Box paddingTop="6px">
                    <StyledButton type="button" data-test-id="add-sales-order" onClick={(event) => {
                        event.preventDefault()
                        if (inputValue) {
                            props.onChange(uniq([...props.value, inputValue]))
                            setInputValue("")
                        }
                    }}>
                        <ControlPointIcon />
                    </StyledButton>
                </Box>
            </Box>
            <Box display="flex" gridGap={8} marginTop={0.5} flexWrap="wrap">
                {
                    props?.value?.map((item, index) => (
                        <StyledChip key={index}>
                            {item}
                            <ClearIcon
                                data-test-id={`delete-sales-order-${index}`}
                                className="delete-icon"
                                color="primary"
                                onClick={() => props.onChange(props.value.filter(salesOrder => salesOrder !== item))}
                            />
                        </StyledChip>
                    ))
                }
            </Box>
        </Box>
    )
}

const StyledChip = styled("div")({
    borderRadius: "26px",
    padding: "6px 8px 6px 12px",
    display: "flex",
    gap: 4,
    alignItems: "center",
    backgroundColor: colors().brightgray,
    color: colors().cyancobaltblue,
    fontSize: 14,
    "& .delete-icon": {
        width: 16,
        height: 16
    }
})

const StyledButton = styled("button")({
    borderRadius: 8,
    width: 56,
    height: "100%",
    border: 0,
    fontWeight: 700,
    lineHeight: 1.5,
    textTransform: "none",
    cursor: "pointer",
    backgroundColor: colors().cyancobaltblue,
    color: "white",
    fontSize: 14,
    "@media only screen and (max-width: 1024px)": {
        width: 51,
    },
})

const StyledTextField = styled(TextField)({
    width: "100%",
    paddingTop: 6,
    borderRadius: 8,
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
        fontWeight: 500,
        fontSize: 16,
        fontFamily: "Montserrat",
        borderRadius: 8,
        "&.MuiInputBase-root.Mui-disabled": {
            color: "unset",
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors().lightborder
            }
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: 13,
        },
        "& fieldset": {
            fontSize: "14px",
            borderRadius: "8px",
            borderWidth: 1,
            fontWeight: 400,
            fontFamily: "Montserrat",
            borderColor: colors().lightborder
        },
    },
    "& ::placeholder": {
        opacity: 1,
        color: colors().darkliver,
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: 400,
        "@media only screen and (max-width: 1024px)": {
            fontSize: 12,
        },
    },
    "@media only screen and (max-width: 1024px)": {
        '& .MuiInputBase-input': {
            fontSize: 12,
        },
    },
},
)

export default SalesOrders