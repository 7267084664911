import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { CustomerPrefData } from "./CreateCustomerPreferenceController.web";

// Customizable Area Start
import { uniqBy } from "lodash";
export interface IPreferenceItem {
  id: string;
  type: string;
  attributes: {
    label: string | null;
    notes: "it is precious" | null;
    product: null | {
      id: string;
      attributes: {
        name: string;
      };
    };
    save_for_future: boolean;
    service: null | {
      id: string;
      attributes: {
        name: string;
      };
    };
    services:
      | null
      | {
          icon: string;
          id: number | null;
          name: string;
          selected: boolean;
        }[];
    specifications: null | ISpecificationItem[];
    upcharge_lists:
      | null
      | {
          checked: boolean;
          currency: string;
          icon: string;
          id: number | null;
          name: string;
          price: number;
          upcharge_type: string;
        }[];
    preferences: {
      checked: boolean;
      icon: string;
      id: number | null;
      name: string;
    }[];
  };
}

export interface ISpecificationItem {
  id: number | null;
  name: string;
  options_attributes: ISpecificationOptionItem[];
}

export interface ISpecificationOptionItem {
  id: number | null;
  label: string;
  selected: boolean;
}

export interface ICustomerDataWithPreference {
  id: string;
  type: string;
  attributes: {
    id: number | null;
    customer_preferences_attributes: IPreferenceItem[];
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  customerId: string;
  classes?: any;
  onCancel: () => void;
  priceListId?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerDataWithPreference: CustomerPrefData | undefined;
  editModalOpen: boolean;
  snackbarOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerPreferenceViewController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start
  getCustomerPreferenceListCallId: string = "";
  updateCustomerPreferenceListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      customerDataWithPreference: undefined,
      editModalOpen: false,
      snackbarOpen: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.updateCustomerPreferenceListCallId) {
        if (responseJson) {
          this.getCustomerPreferenceList();
          this.setState({
            snackbarOpen: true,
          }, () => {
            this.handleEditModalClose();
            setTimeout(() => {this.props.onCancel()}, 2000);
          })
        }
      } else if (apiRequestCallId === this.getCustomerPreferenceListCallId) {
        if (responseJson) {
          this.setState({
            customerDataWithPreference: responseJson.data,
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEditModalOpen = () => {
    this.setState({ editModalOpen: true });
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleEditModalClose = () => {
    this.setState({
      editModalOpen: false,
    });
  }

  handleEditPreferenceForCustomer = (data: any) => {
    const {customerDataWithPreference} = this.state
    const specifications = uniqBy(
      (customerDataWithPreference?.attributes.customer_preferences_attributes || []).flatMap(
        customer_preferences_attribute => customer_preferences_attribute.attributes.specifications
      ),
      "id"
    )

    const bodyData = {
      data: {
        attributes: {
          preferences_attributes: data?.map((item: any) => {
            const selectedOptions = (item.selectedOptions || []) as {
              specification_id: number;
              option_id: number[];
            }[]
            const unselectedSpecifitionOptionIds = specifications.flatMap(
              specification => {
                const newSelected = selectedOptions.find(spec => spec.specification_id === specification.id)?.option_id || []
                return specification.options_attributes
                  .filter(options_attribute => options_attribute.selected && !newSelected.includes(options_attribute.id))
                  .map(options_attribute => ({
                    specification_option_id: options_attribute.id,
                    id: options_attribute.pref_spec_id?.id, 
                    _destroy: true
                  }))
              }
            )
            return {
              upcharge_list_ids: item.upchargeListIds,
              id: Number(item.id),
              preference_id: Number(item.preferenceId),
              catalogue_variant_id: Number(item.variant_id),
              notes: item.notes,
              pref_spec_options_attributes: [
                ...selectedOptions
                .filter((item) => item.option_id)
                .flatMap((item) => item.option_id.map((optionId) => ({
                  specification_option_id: Number(optionId),
                }))),
                ...unselectedSpecifitionOptionIds
              ],
              service_id: item.serviceId,
              save_for_future: item.saveForFuture,
            }
          }),
          customer_id: parseInt(this.props.customerId)
        }
      } 
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile_bio/customer_preferences/update_preferences'
    );

    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    this.updateCustomerPreferenceListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCustomerPreferenceList = () => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile_bio/customer_preferences?customer_id=${
        this.props.customerId
      }`
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    this.getCustomerPreferenceListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  async componentDidMount() {
    this.getCustomerPreferenceList();
  }
  // Customizable Area End
}
