import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Modal,
  Grid,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { CustomPaper, CustomTypography, CustomTabs, CustomTab, MainButtonComponent } from '../../../components/src/customComponents/CustomTags.web';

const addCustomerModalStyle: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: '900px',
    maxHeight: "92%",
    bgcolor: "#fff",
    borderRadius: 24,
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "hidden",
    "@media (max-width: 1092px)": {
      minWidth: "90%"
    },
    "@media (max-width: 768px)": {
        padding: "20px",
        width: "75%"
    }
};

const modalScrollbar: any = {
  padding: "24px 36px",
  overflowY: "auto",
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d1d1d1',
    borderRadius: '10px'
  },
  "@media (max-width: 1280px)": {
    padding: "24px",
  },
  "@media (max-width: 1024px)": {
    padding: "16px",
  },
}

import ShowCustomerDetailsModalController, {
  Props,
  configJSON
} from "./ShowCustomerDetailsModalController";
import OrderInformation from "../../catalogue/src/OrderInformation.web";
import CustomerPreferenceView from '../../ProductDescription/src/CustomerPreferenceView.web';
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import B2BCustomerShowModal from "../../customform/src/B2BCustomerShowModal.web";
import B2BCustomerShowModalStatusTab from "../../../blocks/customform/src/B2BCustomerShowModalStatusTab.web";
import { isNumber, toString } from "lodash";
// Customizable Area End

export default class ShowCustomerDetailsModal extends ShowCustomerDetailsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showCustomerData(data: any) {
    if(data){
      return <CustomTypography style={ViewCustomerWebStyle.data}>{data}</CustomTypography>
    }
    else {
      return <CustomTypography>--</CustomTypography>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { customerData, handleIsEdit } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <Modal
            data-test-id="showCustomerDetailsModal"
            open={this.props.open}
            onClose={() => {this.props.handleClose()}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="MuiModal-root"
        >
          <Box sx={addCustomerModalStyle}>
            <Box sx={modalScrollbar}>
                <Typography style={ViewCustomerWebStyle.addPriceHeader}>
                    Customer Information
                </Typography>
                <Paper style={ViewCustomerWebStyle.tabbingSection}>
                  <CustomTabs
                    data-test-id="tabGroup"
                    value={this.state.selectedTab}
                    onChange={(event:any, value:any) => {this.handleTabChange(event, value)}}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    TabIndicatorProps={{style: {display: "none"}}}
                  >
                    <CustomTab label="Customer" />
                    <CustomTab label="Orders" style={{margin: '0 3px'}}/>
                    <CustomTab
                      data-test-id='tab-preference'
                      label={sortStringCondition(
                        !this.props.isB2bActive,
                        "Preference",
                        "Status"
                      )}
                    />
                  </CustomTabs>
                </Paper>
                {this.state.selectedTab === 0 &&
                  <>
                    {renderBaseOnConditions(
                    !this.props.isB2bActive,
                    <div style={{ maxWidth: '822px', width: '100%' }}>
                      <CustomPaper style={ViewCustomerWebStyle.paper}>
                        <Grid container spacing={2} id="modal-content">
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Name</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.full_name)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Mobile Number</InputLabel>
                            {this.showCustomerData(
                              sortStringCondition(
                                isNumber(customerData?.attributes?.country_code),
                                `+${customerData?.attributes?.country_code} `,
                                ""
                              ) + toString(customerData?.attributes?.phone_number)
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Email ID</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.email)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Business Area/Region</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.region?.data?.attributes.branch_name)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Gender</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.gender_id?.label)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Birthday</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.date_of_birth)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Organisation</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.organization?.data?.attributes?.name)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Address</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data.attributes.street_address)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>City</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data.attributes?.city?.name_en)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.blueFieldLabel}>Postal Code</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data.attributes?.post_code)}
                          </Grid>
                        </Grid>
                      </CustomPaper>
                      <CustomPaper>
                        <Grid container spacing={2} id="modal-content">
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Id</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.employee_id)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Business</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.business_id?.label)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Price List</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.price_list?.data?.attributes?.name)}
                          </Grid>
                          {renderBaseOnConditions (
                            !customerData?.attributes?.customer_profile?.data?.attributes?.price_list?.data?.attributes?.is_master_price_list,
                            <>
                              <Grid item xs={6}>
                                <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Select Price list expiry date</InputLabel>
                                <CustomTypography style={ViewCustomerWebStyle.data}>
                                  {this.showExpiryData(customerData?.attributes?.customer_profile?.data?.attributes?.price_list_expiry_date)}
                                </CustomTypography>
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Select Price List Region</InputLabel>
                                {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.region?.name)}
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Select Price List Area</InputLabel>
                                {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.area?.name)}
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Select Price List Store</InputLabel>
                                {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.store_name)}
                              </Grid>
                            </>,
                            <></>
                          )}
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Payment Type</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.payment?.name)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Profession</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.profession?.data?.attributes?.profession_name)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Note</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data.attributes?.notes)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>Private Note</InputLabel>
                            {this.showCustomerData(customerData?.attributes?.customer_profile?.data?.attributes?.private_note)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>
                              {configJSON.txtLblCompensationCredit}
                            </InputLabel>
                            {this.showCustomerData(customerData?.attributes?.total_compensation_credit)}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel style={ViewCustomerWebStyle.redFieldLabel}>
                              {configJSON.txtLblLoyaltyPoints}
                            </InputLabel>
                            {this.showCustomerData(customerData?.attributes?.total_reward_points)}
                          </Grid>
                        </Grid>
                      </CustomPaper>
                    </div>,
                      <B2BCustomerShowModal customerId={customerData?.id} navigation={undefined} id={""} />
                    )}
                    <Box style={{ marginTop: '40px', marginBottom: '20px', textAlign: 'center' }}>
                      <Button
                        data-test-id="cancleBtn"
                        onClick={() => { this.props.handleClose() }}
                        style={{ ...ViewCustomerWebStyle.addButton, textTransform: "unset", backgroundColor: '#F1F5F9', color: '#64748B' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        style={{ ...ViewCustomerWebStyle.addButton, textTransform: "unset" }}
                        onClick={() => { handleIsEdit() }}
                        data-test-id="editBtn"
                      >
                        Edit
                      </Button>
                    </Box>
                  </>
                }
                {
                  this.state.selectedTab === 1 && <Box style={{ width: "100%" }}>
                    <OrderInformation navigation={undefined} id={""} customerId={this.props?.customerData?.id} />
                    <Box style={{ marginTop: '40px', marginBottom: '20px', textAlign: 'center' }}>
                      <Button
                        data-test-id="cancleOrderBtn"
                        onClick={() => { this.props.handleClose() }}
                        style={{ ...ViewCustomerWebStyle.addButton, textTransform: "unset", backgroundColor: '#F1F5F9', color: '#64748B' }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                }
                {this.state.selectedTab === 2 && (
                  <>
                  {renderBaseOnConditions(
                    !this.props.isB2bActive,
                  <CustomerPreferenceView
                    data-test-id={'customerpreferenceview'}
                    priceListId={this.props.customerData?.attributes?.customer_profile?.data?.attributes?.price_list?.data?.id}
                    customerId={this.props.customerData?.id}
                    onCancel={this.props.handleClose}
                  />,
                    <>
                      <B2BCustomerShowModalStatusTab customerId={customerData?.id} navigation={undefined} id={""} />
                      <Box style={{ marginTop: '40px', marginBottom: '20px', textAlign: 'center' }}>
                        <MainButtonComponent
                          className="buttonSecondary"
                          data-test-id="cancleOrderBtn"
                          onClick={() => { this.props.handleClose() }}
                        >
                          Cancel
                        </MainButtonComponent>
                      </Box>
                    </>
                  )}
                  </>
                )}
            </Box>
          </Box>
        </Modal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ViewCustomerWebStyle:any = {
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    marginLeft: 10
  },
  blueFieldLabel: {
    marginBottom: "2px",
    marginTop: '10px',
    color: "#204B9C",
    fontSize: "24px",
    fontWeight: 600,
  },
  redFieldLabel: {
    marginBottom: "2px",
    marginTop: '10px',
    color: "#DC1E36",
    fontSize: "24px",
    fontWeight: 600,
  },
  addPriceHeader: {
    color: '#1a1a1a',
    fontWeight: 600,
    marginBottom: '24px',
    fontSize: '24px',
    textAlign: 'center'
  },
  paper: {
    marginBottom: '32px'
  },
  tabbingSection: {
    width: '55%',
    backgroundColor: '#f8fafc',
    borderRadius: '40px',
    margin: '0 auto',
    boxShadow: 'none'
  },
  data: {
    wordBreak: 'break-word'
  }
};
// Customizable Area End
