// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import StorageProvider from "../../../framework/src/StorageProvider";
import { apiCall,handleLogout, makeApiMessage } from "../../../components/src/common";
import {
  navigateTo, PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey,
  conditionalString,
  getCustomEnumName,
  CustomEnums,
  randomNumberGenerator
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import ImportExportWebAdapter from "../../../../packages/blocks/adapters/src/ImportExportWebAdapter";
import { IImportCSVResponse } from "packages/blocks/importexportdata/src/ImportExportData.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IMeta {
  next_page: number;
  pervious_page: number|null;
  total_pages: number;
  total_count: number;
  current_page: number;
}

interface Section {
  id: number;
  name: string;
}

export interface Service {
  id: number;
  price: string;
  sqm: number | null;
  sqm_price: string;
  weight: number | null;
  weight_price: string;
  name: string;
}

interface CatalogueVariant {
  id: number;
  price_list_id: number;
  active: boolean;
  measurement_type: string[];
}

interface ProductType {
  id: number;
  value: string;
}

interface Attributes {
  id: number;
  product_name: string;
  product_second_name: string;
  order_number: number;
  created_at: string;
  request_type: string;
  approx_weight: number;
  sections: Section[];
  services: Service[];
  name: string;
  catalogue_variants: CatalogueVariant[];
  product_type: ProductType;
}

export interface Product {
  id: string;
  type: string;
  attributes: Attributes;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: Function
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAppliedFilter: boolean;
  sortingData: ISortingData;
  openActionPopover: (EventTarget & HTMLButtonElement) | null;
  page: number;
  productPagination: IMeta;
  selectedProductId: string;
  rowPerPage: number;
  meta: IMeta;
  isLoadingPermission: boolean;
  openImportExportPopover: (EventTarget & HTMLButtonElement) | null;
  isLoading: boolean;
  dragItem: Product[];
  productListPagination: IMeta
  isDragableDisabled: boolean;
  isB2bActive: boolean;
  pageSize: number;
  optionsList: any;
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  query: string;
  searchQuery: string
  sectionPopupId: number | string;
  sectionPopup: boolean;
  servicePopup: boolean;
  servicePopupId: number | string;
  selectedPriceList: string;
  permissionStatus: PermissionStatus;
  severity: "warning" | "error" | "success";
  snackBarOpen: boolean;
  snackBarMessage: string;
  uploadedFile:File | null;
  setLoaded:number;
  openSortingFilterAction: any;
  dropdownOptions:any;
  nameFilter: string;
  sectionFilter: any,
  productTypeFilter: string,
  preferenceFilter: any,
  selectedFilter: string | number,
  selectedFilterDrag: string | number,
  priceRangeValue: number[],
  mainFilterName: string | number,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bMasterProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  authToken: string = ""
  getB2BProductListApiCallId: string = "";
  exportTemplateFileApiId: string = "";
  exportCsvApiId: string = "";
  productSuggessionApiCallId: string = "";
  getSectionListApiCallId: string = "";
  getPreferenceListApiCallId: string = "";
  adapter = new ImportExportWebAdapter();
  isAppliedFilter: boolean = false;
  importFileApiId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccountTypeInfoMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isB2bActive: false,
      isDragableDisabled: false,
      dragItem: [],
      query: "",
      selectedProductId: "",
      isLoading: false,
      isAppliedFilter: false,
      openActionPopover: null,
      openSortingFilterAction: null,
      sortingData: {
        name: "",
        service: "",
        sections: "",
        approx_weight: "",
        type: "",
      },
      page: 0,
      productPagination: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1
      },
      severity: "error",
      rowPerPage: 10,
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      isLoadingPermission: true,
      openImportExportPopover: null,
      pageSize: 10,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      productListPagination: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": 0,
        "pervious_page": null
      },
      snackBarOpen: false,
      searchQuery:"",
      sectionPopupId: 0,
      sectionPopup: false,
      permissionStatus:{
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      servicePopup: false,
      servicePopupId: 0,
      selectedPriceList: "",
      snackBarMessage: "",
      uploadedFile:null,
      setLoaded:0,
      dropdownOptions: {
        priceList: [],
        productsList: [],
        currency: [
          {id: 'SAR', option: 'SAR'},
          {id: '%', option: '%'},
        ],
        section: [],
        preference: [],
        productNameOptionList: []
      },
      nameFilter: "",
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
      optionsList: [],
      selectedFilter: 0,
      selectedFilterDrag: '',
      priceRangeValue: [0, 100],
      mainFilterName: 'name',
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await StorageProvider.get("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getB2bProductList()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveHeaerSearchText(message)
    this.receiveLayoutInfo(message)
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.getProductListApiCallResponse(apiRequestCallId, responseJson);
    this.handleApiResponses(apiRequestCallId, message);
    this.getSectionListApiCallResponse(apiRequestCallId, responseJson)
    this.getPreferenceListApiCallResponse(apiRequestCallId, responseJson)
    this.handleImportExportActions(from, message)
    this.productSuggesstionApiCallResponse(apiRequestCallId, responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  receiveHeaerSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }

  onChangeValue = (value: string) => {
    this.setState({searchQuery: value,page: 0},()=>this.getB2bProductList())
  }

    getSectionList() {
      let apiUrl = `${configJSON.getSectionListApiEndPoint}?token=${this.authToken}&dropdown=true`;
  
      const getAccount = apiCall({
        header: {},
        httpBody: {},
        url: apiUrl,
        httpMethod: configJSON.getSectionListApiMethod,
      });
  
      this.getSectionListApiCallId = getAccount.messageId;
      runEngine.sendMessage(getAccount.id, getAccount);
    }
  
    getPreferenceList() {
      let apiUrl = `${configJSON.getPreferenceListApiEndPoint}?dropdown=true`;
      let headers = { token: this.authToken }
  
      const getAccount = apiCall({
        header: headers,
        httpBody: {},
        url: apiUrl,
        httpMethod: configJSON.getPreferenceListApiMethod,
      });
  
      this.getPreferenceListApiCallId = getAccount.messageId;
      runEngine.sendMessage(getAccount.id, getAccount);
    }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(query),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getB2bProductList());
  };


  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleCloseActionPopver = () => {
    this.setState({ openActionPopover: null });
  };

  handleOpenActionPopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    productId: string,
    priceListId: string | number
  ) => {
    this.setState({
      openActionPopover: event.currentTarget,
      selectedProductId: productId,
      selectedPriceList: String(priceListId)
    });
  };

  openImportExportPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openImportExportPopover: event.currentTarget,
    });
  };

  handleCloseImportExportPopver = () => {
    this.setState({ openImportExportPopover: null });
  };

  onDragEnd = () => {};

  checkDisabled(isActive: boolean) {
    if (isActive !== false) {
      return 1;
    } else {
      return 0.6;
    }
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ setLoaded: 0 })
      const file = event.target.files;
  
      let randomNumber = randomNumberGenerator(1, 9);
      const delay = randomNumber * 25;
      const uploadInterval = setInterval(() => {
        this.setState({
          setLoaded: updateLoadingTime(this.state.setLoaded)
        }, () => {
          const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
          message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
          runEngine.sendMessage(message.id, message)
        })
  
      }, delay);
  
      function updateLoadingTime(prevLoaded: number) {
        if (prevLoaded >= 100) {
          clearInterval(uploadInterval);
          return 100;
        }
        return prevLoaded + 20
      }
  
      const checkFile = file && file[0];
      this.setState({ uploadedFile: checkFile as File})
    }

    handleImportFile = () => {
        const apiUrl = configJSON.importCsvProductApiurl;
        
        const formData = new FormData();
        formData.append('data[file]', this.state.uploadedFile as File);
        const requestMessage = makeApiMessage({
          url: apiUrl,
          method: configJSON.exampleAPiMethod,
          body: formData,
          isHeader: true
        });
        this.importFileApiId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);    
      };

      handleBackToListPage = () => {
        navigateTo({ props: this.props, screenName: "B2bMasterProduct" })
        this.getB2bProductList()
      }

  productSuggesstionApiCallResponse(apiRequestCallId: any, responseJson: any) {
        if (apiRequestCallId === this.productSuggessionApiCallId) {
          if (this.checkGetResponse(responseJson)) {
            const list = responseJson?.filter_names?.map((value: string) => ({ label: value, value })) || [];
              this.setState({ optionsList: list });
            }
        }
  }

  getSectionListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getSectionListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            section: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.section_name, checked: false } })
          }}))
      }
    }
  }

  getPreferenceListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPreferenceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({dropdownOptions: {
            ...this.state.dropdownOptions,
            preference: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.preference_first_name, image: item?.attributes?.gallery_icon?.image } })
          }}))
      }
    }
  }

  handleImportExportActions(from: string, message: Message) {
    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }
    switch (from) {
      case getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked):
        this.handleImportExportModalClose();
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage):
        this.handleFileUpload(message.properties.fileEvent)        
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage):
        this.handleImportFile()
        break;

      case getCustomEnumName(CustomEnums.ImportExportClearFileMessage):
        this.setState({ uploadedFile: message.properties.uploadedFile, setLoaded: message.properties.setLoaded });
        break;

      default:
        break;
        
    }    
  }

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'B2bMasterProduct')
    runEngine.sendMessage(message.id, message)
  }

  handleOpenImportModal = () => {
    this.setState({ openImportExportPopover: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  }

    handleExportCsv = () => {
      this.setState({ openImportExportPopover: null });
      const isAppliedFilterExists = localStorage.getItem("b2bstoragefilter");
      let storageFilter = {nameFilter: "",priceFilter: {order: "",range: [0, 100]},
        sectionFilter: [],productTypeFilter: '',preferenceFilter: {},
      };
      if(isAppliedFilterExists) {
        storageFilter = JSON.parse(isAppliedFilterExists);
        this.setState({ 
          nameFilter: storageFilter?.nameFilter,
          sectionFilter: storageFilter?.sectionFilter,
          preferenceFilter: storageFilter?.preferenceFilter,
          productTypeFilter: storageFilter?.productTypeFilter,
          })
      };
    
      let nameText = this.state.nameFilter;
      let productTypeText = this.state.productTypeFilter;
      let sectionText = this.state.sectionFilter;
      let preferenceText = Object.keys(this.state.preferenceFilter);
      this.isAppliedFilter = this.handleCheckAppliedFilter();
      let apiUrl;
      apiUrl = configJSON.exportProductEndPoint +
        (`?page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
        (nameText ? `&filter_by[name]=${this.state.nameFilter}` : '') +
        (productTypeText ? `&filter_by[product_type]=${this.state.productTypeFilter}` : '') +
        (this.state.query ? `${this.state.query}` : '') +
        (sectionText?.length !== 0 ? `&filter_by[section_ids][]=${this.state.sectionFilter}` : '') +
        (preferenceText?.length !== 0 ? `&filter_by[preference]=${this.state.preferenceFilter.option}` : '') +
        (this.state.searchQuery ? `&filter_by[query]=${this.state.searchQuery}` : '');
    
      const requestMessage = makeApiMessage({
        url: apiUrl,
        method: configJSON.validationApiMethodType,
      });
  
      this.exportCsvApiId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage); 
    }

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getB2bProductList = () => {
    this.setState({ isLoading: true });
    const isAppliedFilterExist = localStorage.getItem("b2bstoragefilter");
    let storageFilter = {
      nameFilter: "",
      priceFilter: {
        order: "",
        range: [0, 100]
      },
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
    };
    if(isAppliedFilterExist) {
      storageFilter = JSON.parse(isAppliedFilterExist);
      this.setState({ 
        nameFilter: storageFilter?.nameFilter,
        productTypeFilter: storageFilter?.productTypeFilter,
        sectionFilter: storageFilter?.sectionFilter,
        preferenceFilter: storageFilter?.preferenceFilter
        })
    };
  
    let name = this.state.nameFilter;
    let productType = this.state.productTypeFilter;
    let section = this.state.sectionFilter;
    let preference = Object.keys(this.state.preferenceFilter);
       // Check any filter applied or not to show active
    this.isAppliedFilter = this.handleCheckAppliedFilter();
    
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;
 
    url = configJSON.getB2bMasterProductListingEndPoint +
      (`&page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
      (name ? `&filter_by[name]=${this.state.nameFilter}` : '') +
      (productType ? `&filter_by[product_type]=${this.state.productTypeFilter}` : '') +
      (this.state.query ? `${this.state.query}` : '') +
      (section?.length !== 0 ? `&filter_by[section_ids][]=${this.state.sectionFilter}` : '') +
      (preference?.length !== 0 ? `&filter_by[preference]=${this.state.preferenceFilter.option}` : '') +
      (this.state.searchQuery ? `&filter_by[query]=${this.state.searchQuery}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getB2BProductListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductListApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getB2BProductListApiCallId) {
      if(responseJson?.status == 500) {
        this.setState({
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error',
          isLoadingPermission: false 
        })
      } 
      else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({isLoadingPermission: false, isLoading: false, dragItem: responseJson.data , productListPagination: responseJson.meta,openSortingFilterAction:null});
      } else {
        this.setState({isLoadingPermission: false, isLoading: false,openSortingFilterAction:null })
      }
      const isAppliedFilter = localStorage.getItem("b2bstoragefilter");
      let b2bstoragefilter = {
        nameFilter: "",
        priceFilter: {
          order: "",
          range: [0, 100]
        },
        sectionFilter: [],
        productTypeFilter: '',
        preferenceFilter: {},
      };
      if(isAppliedFilter) {
        b2bstoragefilter = JSON.parse(isAppliedFilter);
      };
      this.setState({  
        nameFilter: b2bstoragefilter?.nameFilter,
        productTypeFilter: b2bstoragefilter?.productTypeFilter,
        sectionFilter: b2bstoragefilter?.sectionFilter,
        preferenceFilter: b2bstoragefilter?.preferenceFilter
        } )
      }
    }
    if(apiRequestCallId === this.importFileApiId){
      this.handleImportCsvFileResponse(responseJson)
    }
    if(apiRequestCallId === this.exportCsvApiId){
      this.exportCSVfileRes(responseJson)
    }
  }  

  exportCSVfileRes = (responseJson: { file_url: string; message: string }) => {
      if (responseJson?.file_url) {
        location.href = responseJson.file_url
         this.setState({ 
          severity: 'success', 
          snackBarOpen: true, 
          snackBarMessage: conditionalString(
            responseJson.message,
            responseJson.message,
            configJSON.successExcelExportMsg
          ) 
        });
      }
    }

   handleImportCsvFileResponse(response: IImportCSVResponse) {
      if (response) {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
        message.addData('APIresponse', response)
        runEngine.sendMessage(message.id, message);
        if (response?.message) {
          this.setState({ severity: 'success', snackBarOpen: true, snackBarMessage: response.message })
          navigateTo({ props: this.props, screenName: "B2bMasterProduct" })
          this.getB2bProductList();
        } else if (response?.status == 500) {
          this.setState({
            severity: 'error',
            snackBarOpen: true,
            snackBarMessage: response?.error || 'An unexpected error has happened'
          });
          navigateTo({ props: this.props, screenName: "B2bMasterProduct" })
        } else {
          const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
          message.addData('APIresponse', response);
          runEngine.sendMessage(message.id, message);
          navigateTo({ 
            props: this.props,
            screenName: "ImportExportErrorModal",
            raiseMessage: message
          })
        }
      }
    }

  handleApiResponses = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.exportTemplateFileApiId) {
      this.handleResForExportTemplateFile(responseJson)
    }
  }

    handleResForExportTemplateFile = async (responseJson: {data: {url?: string;message?:string}}) => {
      if (responseJson.data?.url) {
        location.href = responseJson.data.url;
        this.setState({ 
          severity: 'success', 
          snackBarOpen: true, 
           snackBarMessage: conditionalString(
            responseJson?.data.message,
            responseJson?.data.message as string,
            "Template file has been exported successfully"
          )
        });
      }
    }

    handleSnackbarClose = () => {
      this.setState({ snackBarOpen: false, snackBarMessage: "" });
    };

    handleExportTemplate = () => {
      this.setState({ openImportExportPopover: null })
      const apiUrl = configJSON.exportTemplateProductEndPoint;
  
      const requestMessage = makeApiMessage({
        url: apiUrl,
        method: configJSON.apiMethodTypeGet,
      });
  
      this.exportTemplateFileApiId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

  handleAddB2BProduct = () => {
    navigateTo({
      props: this.props,
      screenName: "CfAddProduct",
    })
  }

  handleAddB2BPriceList = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BPriceListCreation",
    })
  }

  handleEditProductMaster = () => {
    this.props.navigation.navigate("CfEditProduct", { productId: this.state.selectedProductId })
  }

  handleViewProductMaster = () => {
    localStorage.setItem("selectedPricelistId", this.state.selectedPriceList);
    this.props.navigation.navigate("CfViewProduct", {productId: this.state.selectedProductId})
  }

  handleSectionPopupOpen = (checkid: string | number) => {
    if(this.state.sectionPopupId === checkid) {
      this.setState({sectionPopup: true})
    }
  }

  handleSectionPopupClose = () => {
    this.setState({sectionPopup: false})
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getB2bProductList() })
  }

  receiveLayoutInfo = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if(recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.b2bProductMasterPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
    })
  };
  generateStrSARMeasue = (service: any,measurementTypes: any) => {
    const strings = []
    let str = "";
    if (measurementTypes.includes("SQM")) {
      str += `SAR ${parseFloat(service.sqm_price)} - ${parseFloat(service.sqm)} SQM, `;
    }
    if (measurementTypes.includes("Weight")) {
      str += `SAR ${parseFloat(service.weight_price)} - ${parseFloat(service.weight)} Kg, `;
    }
    if (measurementTypes.includes("Quantity")) {
      str += `SAR ${parseFloat(service.price)} - 1 QTY, `;
    }
    str = str.slice(0, -2);
    strings.push(str);
    return strings;
  }

  hoverToGetRowId = (getRowId: string | number) => {
    this.setState({ sectionPopupId: getRowId })
  }

  removeRowId = (getRemoveId: string | number) => {
    this.setState({ sectionPopupId: getRemoveId })
  }

  handleOpenFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ openSortingFilterAction: event?.currentTarget }, () => {
      if(this.state.dropdownOptions.section.length === 0) this.getSectionList();
      if(this.state.dropdownOptions.preference.length === 0) this.getPreferenceList();
    });
  }

  handleFilterPopupClose(closeValue: boolean) {
    this.setState({
      openSortingFilterAction: null,
      page: 0,
    })
    if (closeValue === true) {
      this.handleClearSelectedFilter();

      let newArray = this.state.dropdownOptions?.section?.map((item: any) => {
        item.checked = false
        return item
      })
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          section: newArray,
        }
      }))
      this.setState({
        nameFilter: "",
        sectionFilter: [],
        productTypeFilter: '',
        preferenceFilter: {},
        selectedFilter: 0,
        selectedFilterDrag: '',
        priceRangeValue: [0, 100],
      },() => {
        this.getB2bProductList()
      })
    }
  }

  handleClearSelectedFilter() {
    localStorage.removeItem("b2bstoragefilter");
    this.setState({
      nameFilter: "",
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
      selectedFilter: 0,
      selectedFilterDrag: '',
      priceRangeValue: [0, 100],
    });
  };

  handleFilterChange(fitlerValue: string | number, mainFilterNameValue: string | number) {
    this.setState({ selectedFilter: fitlerValue, mainFilterName: mainFilterNameValue, selectedFilterDrag: fitlerValue })
  }

  checkObjectTrueFalse(object: {id: number, option: string ,checked: boolean}) {
    if (object.checked === true) {
      object.checked = false
      return object
    }
    else {
      object.checked = true
      return object
    }
  }

  matchObject(object: {id: number, option: string ,checked: boolean}, subFitlerValue: {id: number, option: string ,checked: boolean}) {
    if (object.id === subFitlerValue.id) {
      this.checkObjectTrueFalse(object);
    }
  }

  handleApplyFilterChange = () => {
    // set applied filter to local storage
    const {
      nameFilter,
      sectionFilter,
      productTypeFilter,
      preferenceFilter,
    } = this.state;

    let b2bstoragefilter = {
      nameFilter: nameFilter,
      sectionFilter: sectionFilter,
      productTypeFilter: productTypeFilter,
      preferenceFilter: preferenceFilter,
    };
    localStorage.setItem("b2bstoragefilter", JSON.stringify(b2bstoragefilter));

    this.setState({
      page: 0
    },
    () => this.getB2bProductList()
    )
  }

  handleCheckAppliedFilter = () => {
    const isAppliedFilter = localStorage.getItem("b2bstoragefilter");
    let b2bstoragefilter = {
      nameFilter: "",
      priceFilter: {
        order: "",
        range: [0, 100]
      },
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
    };
    if (isAppliedFilter) {
      b2bstoragefilter = JSON.parse(isAppliedFilter);
      console.log("rkp",b2bstoragefilter.productTypeFilter)
    }

    // price range filter
    const defaultPriceRange = JSON.stringify([0, 100]);
    const havePriceRangeFilter =
      defaultPriceRange !== JSON.stringify(b2bstoragefilter?.priceFilter?.range);

    // section filter length
    const sectionFilter = b2bstoragefilter?.sectionFilter.length > 0;

    // preferences filter
    const preference = Object.keys(b2bstoragefilter?.preferenceFilter);
    const preferenceFilter = preference?.length !== 0;

    // return true if any filter applied
    if (
      b2bstoragefilter?.nameFilter ||
      b2bstoragefilter?.productTypeFilter ||
      sectionFilter ||
      havePriceRangeFilter ||
      b2bstoragefilter?.priceFilter?.order ||
      preferenceFilter
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleInputChangeDebounced = () => {
      this.getB2bProductList()
    }

  setPreference = (value: string | number) => {
    this.setState({preferenceFilter: value})
  }
  
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.query !== this.state.query 
      || prevState.selectedFilterDrag !== this.state.selectedFilterDrag
       
        ) {
      this.handleDeActivateDragable()
    }
  }

  handleDeActivateDragable = () => {
    if (this.state.query === "" && this.state.selectedFilterDrag === ""
    ) {
      this.setState({ isDragableDisabled: false })
    } else {
      this.setState({ isDragableDisabled: true })
    }
  }

  setProductType = (value: string) => {
    this.setState({productTypeFilter: value})
  }
  
  setName = (value: string) => {
     this.setState({ nameFilter: value, selectedFilterDrag: 0 });
 
     let headers = {
       "Content-type": "application/json", 
       token: this.authToken
     }
     let apiUrl;
     apiUrl = `${configJSON.productSuggesstionApiEndPoint}?product_name=${value}&request_type=B2b`;
     const getAccount = apiCall({
       header: headers,
       httpBody: {},
       url: apiUrl,
       httpMethod: configJSON.productSuggesstionApiMethod,
     });
 
     this.productSuggessionApiCallId = getAccount.messageId;
     runEngine.sendMessage(getAccount.id, getAccount);
   }

    handleClearFitler() {
      const { selectedFilter } = this.state;
      
      if (selectedFilter == 0) {
        this.setState({ nameFilter: "" });
      } else if (selectedFilter == 2) {
        let newArray = this.state.dropdownOptions?.section?.map(
          (item: { id: number; option: string; checked: boolean }) => {
            item.checked = false;
            return item;
          }
        );
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            section: newArray,
          },
          sectionFilter: [],
        }));
      } else if (selectedFilter == 3) {
        this.setState({ productTypeFilter: "" });
      } else if (selectedFilter == 4) {
        this.setState({ preferenceFilter: {} });
      }
    }

    setSection = (value: {id: number, option: string, checked: boolean}) => {
      let newArray: [];
      let selectedSection: {id: number, option: string, checked: boolean}[] = [];
      if(this.state.dropdownOptions?.section.some((object:{ id: number, option: string, checked: boolean }) => object.id === value.id)) {
        newArray = this.state.dropdownOptions?.section?.map((objct:{ id: number, option: string, checked: boolean }) => {
          this.matchObject(objct, value)
          return objct
        });
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            section: newArray
          }
        }))
        newArray.map((item:any) => {
          if(item.checked === true) {
            return selectedSection.push(item.id)
          }
        })
      }
      this.setState({sectionFilter: selectedSection})
    }
  // Customizable Area End
}
