Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.txtCreateCustomer = "Add Customer"
exports.txtEditCustomer = "Edit Customer"
exports.btnTxtSave = "Save"
exports.btnTxtAdd = "Add"
exports.customerFields = {
  txtLblName: "Name",
  txtLblMobileNumber: "Mobile Number",
  txtLblEmail: "Email ID",
  txtLblCompany: "Company Name",
  txtLblBusinessCustomer: "Business Customer Name",
  txtLblDepartmentName: "Department Name",
  txtLblDepartmentId: "Department ID",
  txtLblEmployeeId: "Employee ID",
  txtLblCostCenter: "Cost Center",
  txtLblSubCostCenter: "Sub-Cost Center",
  txtLblGender: "Gender",
  txtLblAddress: "Address",
  txtLblCity: "City",
  txtLblPinCode: "Pin Code",
  txtLblNotes: "Notes",
  txtLblPrivateNotes: "Private Notes",
  txtLblPriceList: "Price List",
  txtLblNoOfOrders: "No. of Orders",
  txtLblNoOfPiecesOrders: "No. of Pieces per Order",
  txtLblSelectCustomerGroup: "Customer Group",
}
exports.customerValidation = {
  nameValidation: "Name is required.",
  invalidMobilePrefix: "Invalid",
  mobileRequired: "Mobile number is required.",
  compayNameRequired: "Company name is required.",
  customerNameRequired: "Customer name is required.",
  employeeIdValidation: "Employee id is required.",
  genderRequired: "Gender is required."
}
exports.B2BCustomerAPIEndpoint = "bx_block_account_groups/business_account_customers";
exports.B2BCustomerNameSuggestionListEndPoint ="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?name=";
exports.B2BCustomerOrgSuggestionListEndPoint ="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?company_name=";
exports.B2BCustomerMobSuggestionListEndPoint="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?full_phone_number="
exports.B2BCustomerCitySuggestionListEndPoint="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?city_name="
exports.B2BCustomerDepSuggestionListEndPoint="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?department_name="
exports.B2BCustomerEmailSuggestionListEndPoint="bx_block_account_groups/business_account_customers/business_account_customer_suggestion?email="
exports.btnTxtCancel = "Cancel"
exports.B2BCompanyDropdownAPIEndPoint = "bx_block_company/companies?dropdown=true&type=b2b_company"
exports.B2BCustomerGroupDropdownAPIEndPoint = "bx_block_account_groups/business_customer_groups?dropdown=true"
exports.B2BPriceListDropdownAPIEndPoint = "bx_block_cfpricelist/b2b_price_lists?dropdown=true"
exports.getDropdownOptionApiEndPoint = "bx_block_drop_down/drop_down";
exports.getCityListApiEndPoint = "bx_block_city/cities";
exports.B2BBusinessCustomerDropdownAPIEndPoint = "bx_block_cfcontractmanagement7/business_accounts?dropdown=true";
exports.B2BBusinessAccountsAPIEndPoint = "bx_block_cfcontractmanagement7/business_accounts";
exports.exportTemplateCustomerListExcelApiurl = "bx_block_data_import_export/business_account_customers_import_export/business_account_customers_template"
exports.importCustomerListExcelApiurl = "/bx_block_data_import_export/business_account_customers_import_export/import_business_account_customers"
exports.exportCustomerListExcelApiUrl = "/bx_block_data_import_export/business_account_customers_import_export/export_business_account_customers"
exports.viewPageTitle = {
  mainTitle: "Customer",
  customerName: "Customer Name",
  mobileNo: "Mobile No.",
  emailID: "Email ID",
  companyName: "Company Name",
  businessCustomerName: "Business Customer Name",
  departmentName: "Department Name",
  departmentID: "Department ID",
  employeeID: "Employee ID",
  costCenter: "Cost Center",
  subCostCenter: "Sub-Cost Center",
  gender: "Gender",
  address: "Address",
  city: "City",
  pinCode: "Pin code",
  notes: "Notes",
  privateNotes: "Private Notes",
  catalogue: "Catalogue",
  noofOrders: "No. of Orders",
  noofPiecesPerOrder: "No. of Pieces per Order",
  customerGroup: "Customer Group",
  btnTxtEdit: "Edit"
}
exports.btnCustomer = "Click Customer";
exports.btnCustomerView = "Click Customer View";

exports.B2BCustomerViewModal = {
  priceList: "Price List",
  noOfOrders: "No. of Orders",
  noOfPieces: "No. of Pieces",
  labelName: "Name",
  labelMobileNumber: "Mobile Number",
  labelEmailId: "Email ID",
  labelCompanyName: "Company Name",
  labelBusinessCustomerName: "Business Customer Name",
  labelDepartmentName: "Department Name",
  labelDepartmentId: "Department ID",
  labelEmployeeId: "Employee ID",
  labelCostCenter: "Cost Center",
  labelSubCostCenter: "Sub-Cost Center",
  labelGender: "Gender",
  labelAddress: "Address",
  labelCity: "City",
  labelPinCode: "Pin Code",
  labelNotes: "Notes",
  labelPrivateNotes: "Private Notes"
}
exports.B2BCustomerViewModalStatusTab = {
  orders: "Orders",
  noOfOrders: "No. of Orders",
  noOfPieces: "No. of Pieces",
  availableOrders: "Available Orders",
  availablePieces: "Available Pieces",
  customerLabel: "Customer",
  companyLabel: "Company",
  businessAccountNameLabel: "Business Account Name",
  MobileNumberLabel: "Mobile Number",
  notesLabel: "Notes",
  privateNotesLabel: "Private Notes"
}
exports.googleMapAPI = "AIzaSyCDyTXkJeXRkdotyRAv5m1NmvaTQ5yuCLE";

// Customizable Area End
