// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/common";
export const configJSON = require("./config");
import {
  conditionalString,
  navigateTo,
} from "../../utilities/src/CustomBlockHelpers";
import { uniqBy, concat, toString, debounce } from "lodash";
import { ISortingData } from "../../../components/src/SortingTableHeader";

interface IPriceListDetails {
  "data": {
    "id": string,
    "type": string,
    "attributes": {
      "id": number,
      "name": string,
      "price_list_type": string,
      "product_currency_id": null,
      "created_at": string,
      "updated_at": string,
      "tax_type": string,
      "activated": boolean,
      "price_list_to_copy": string,
      "type": string,
      "company_id": number | null,
      "region_ids": number[],
      "area_ids": number[],
      "store_management_ids": number[],
      "business_account_ids": number[],
      "model_name": string,
      "company_name": string,
      "is_master_price_list": boolean,
      "sections": {
        "data": []
      },
      products: {
        id: number;
        catalogue_variant_id: number;
        service_id: number;
        name: string;
        icon: string;
        price: string;
        sqm: number | null;
        sqm_price: string;
        weight: number | null;
        weight_price: string;
        default: boolean;
        services: {
          data: Array<{
            id: string;
            type: string;
            attributes: {
              id: number;
              name: string;
              icon: string;
              name_translation: string;
            };
          }>;
        };
      }[]
    }
  }
}

export type Option = {
  id: string | number
  option: string
}

export type PriceListDetail = {
  id: number
  name: string
  company_name: string
  price_list_to_copy: string
  product_master_ids: number[]
  status: string
}

export interface IB2BCompanyDropDown {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "number_of_vats": string
  },
}

interface IEditApiResponse {
  message: string;
  data: {
    id: number;
    catalogue_label: string;
    active: boolean;
  };
  errors?: string[] | string
  error?: string
  success?: string
}

export interface IB2BProductList {
  id: string;
  type: string;
  attributes: {
    id: number;
    price_list: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      product_currency_id: string | null;
      tax_type: string;
      activated: boolean;
      price_list_type: string;
      price_list_to_copy: number | null;
      add_by: string | null;
      company_id: number | null;
    };
    pieces: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    measurement_type: string[];
    catalogue_label: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
        product_type: {
          id: number;
          value: string;
        };
      };
    };
    name: string;
    image: {
      id: number;
      name: string;
      image: string;
    };
    measurement: {
      id: number | null;
      value: number | null;
    };
    product_currency_id: string | null;
  };
}

interface ProductMeta {
  "total_pages": number,
  "total_count": number,
  "current_page": number,
  "next_page": number | null,
  "pervious_page": number | null
}

const DEFAULT_META = {
  "total_pages": 0,
  "total_count": 0,
  "current_page": 0,
  "next_page": null,
  "pervious_page": null
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  snackbarServerity: 'success' | 'error' | 'info';
  snackbarMessage: string;
  priceListDetail: PriceListDetail,
  sortingData: ISortingData;
  query: string
  prodNextPage: number
  prodDropDownNextPage: number
  productList: IB2BProductList[]
  productListMeta: ProductMeta,
  productListLoading: boolean
  productModalOpen: boolean
  productModalType: string
  productSelectToggle: boolean
  productSelectedIds: number[]
  editProductDetails: {
    id: string,
    name?: string
    catalogueId: string
  }
  productName: string
  productNameError: boolean
  productAddEditLoader: boolean
  isProductSwitchActive: boolean
  productListDropdown: IB2BProductList[]
  productListDropdownMeta: ProductMeta
  productListDropdownSearchText: string
  masterPriceID:number | undefined
  hasMore: boolean
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BPriceListProductsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  itemsPerPage = 10;
  addB2BPriceListProductsApiCallId: string = ""
  priceListId: string | undefined = this.props.navigation?.getParam('navigationBarTitleText');
  getPriceListDetailsApiCallId: string = ""
  getB2BProductListApiCallId: string = ""
  addDeleteProductApiCallId: string = ""
  editProductApiCallId: string = ""
  getB2BProdListDropDownCallId: string = ""
  getB2BMasterPriceListApiCallId2: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      snackbarServerity: 'success',
      snackbarMessage: "",
      priceListDetail: {
        id: 0,
        name: "",
        company_name: "",
        price_list_to_copy: "",
        product_master_ids: [],
        status: ''
      },
      sortingData: {
        name: "",
        active: ""
      },
      query: "",
      prodNextPage: 1,
      prodDropDownNextPage: 1,
      productList: [],
      productListLoading: true,
      productModalOpen: false,
      productModalType: '',
      productListMeta: DEFAULT_META,
      productSelectToggle: false,
      productSelectedIds: [],
      editProductDetails: {
        id: '',
        name: '',
        catalogueId: ""
      },
      productName: '',
      productAddEditLoader: false,
      productNameError: false,
      isProductSwitchActive: false,
      productListDropdown: [],
      productListDropdownMeta: DEFAULT_META,
      productListDropdownSearchText: "",
      masterPriceID:0,
      hasMore: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getPriceListDetailsApiCallId:
          this.getPricelistDetailsApiCallResponse(responseJson)
          break;
        case this.getB2BProductListApiCallId:
          this.getProductListApiCallResponse(responseJson);
          break;
        case this.addDeleteProductApiCallId:
        case this.editProductApiCallId:
          this.handleProductAddEditApiCallResponse(responseJson, apiRequestCallId === this.addDeleteProductApiCallId);
          break;
        case this.getB2BProdListDropDownCallId:
          this.getProdDropdownApiCallResponse(responseJson);
          break;
          case this.getB2BMasterPriceListApiCallId2:
            this.getB2BMasterPriceListApiCallResponse2(responseJson)
          break;
        default:
          break;
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    if (this.priceListId) {
      this.getPriceListDetailAPI(this.priceListId)
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: "" })
  }

  handleRedirect = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BPriceList",
    });
  }

  getPriceListDetailAPI = (priceListId: string) => {
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const url = configJSON.B2BPriceListAPIEndpoint + "/" + priceListId

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPricelistDetailsApiCallResponse = (responseJson: IPriceListDetails) => {
    if (responseJson && responseJson.data) {
      const {
        id,
        name,
        company_name,
        activated,
        price_list_to_copy
      } = responseJson.data.attributes
      this.setState({
        priceListDetail: {
          name: name,
          company_name,
          price_list_to_copy,
          id,
          product_master_ids: responseJson.data.attributes.products.map(product => product.id),
          status: activated ? "Active" : "DeActive",
        },
      }, () => {
        this.handleProductList(id)
        this.getB2BMasterPriceListId2();
      })
    }
  }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(`${query}`),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.handleProductList(this.state.priceListDetail.id));
  };

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 1) {
      return "right";
    } else {
      return "middle";
    }
  };

  handleProductList = (priceId: string|number, isMasterPriceList?: boolean) => {
    if (!priceId) return;
    const { prodDropDownNextPage, prodNextPage, query, productListDropdownSearchText } = this.state
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const sortQuery = conditionalString(!isMasterPriceList && query, `&${query}`, "")
    const pageNo = isMasterPriceList ? prodDropDownNextPage : prodNextPage;
    const masterPriceListQuery = isMasterPriceList 
      ? `&dropdown=true&master_price_list_id=${this.state.masterPriceID}` 
      : '';
    const filterByQuery = conditionalString(
      isMasterPriceList && productListDropdownSearchText,
      `&filter_by[query]=${productListDropdownSearchText}`,
      ""
    )
    
    const url = `${configJSON.B2bGetProductListAPIEndpoint}?price_list_id=${priceId}${sortQuery}${masterPriceListQuery}${filterByQuery}&page_no=${pageNo}`;

    const message = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    if (isMasterPriceList) {
      this.getB2BProdListDropDownCallId = message.messageId;
    } else {
      this.setState({ productListLoading: true })
      this.getB2BProductListApiCallId = message.messageId;
    }
    this.send(message)
  }

   getB2BMasterPriceListApiCallResponse2 = (responseJson?: { data? : {id: number; name: string}}) => {
     this.setState({
      masterPriceID:responseJson?.data?.id
     },()=>{
      if (responseJson?.data?.id) {
        this.handleProductList(this.state.priceListDetail.id, true);
      }
     })
    }

   getB2BMasterPriceListId2 = () => {
      const apiMessage = apiCall({
        url: configJSON.getB2BMasterPriceListEndPoint,
        httpBody: {},
        httpMethod: "GET",
        header: {
          token: this.authToken
        }
      })
      this.getB2BMasterPriceListApiCallId2 = apiMessage.messageId
      this.send(apiMessage)
    }

  getProductListApiCallResponse(responseJson: { data: IB2BProductList[], meta: ProductMeta, status: number, message?: string, errors?: { message: string } }) {
    const dataList = responseJson.data ?? []

    this.setState({
      productList: dataList,
      productListMeta: responseJson.meta ?? DEFAULT_META,
      productListLoading: false,
    });
  }

  getProdDropdownApiCallResponse = (responseJson: { data: IB2BProductList[], meta: ProductMeta, status: number, message?: string, errors?: { message: string } }) => {
    const meta = responseJson?.meta ?? DEFAULT_META
    this.setState((prev) => ({
      productListDropdown: responseJson?.data?.length ? uniqBy(concat(prev.productListDropdown, responseJson.data), "id") : prev.productListDropdown,
      productListDropdownMeta: meta,
      hasMore: Boolean(meta.next_page)
    }));
  }

  handleOpenModal = (type: string, data?: IB2BProductList) => {
    this.setState({
      productModalOpen: true,
      productModalType: type,
      editProductDetails: {
        id: data?.id as string,
        name: data?.attributes.name as string,
        catalogueId: toString(data?.attributes.catalogue?.id)
      },
      productName: data?.attributes.name as string
    })
  }

  handleClose = (shouldFetchDropdownList?: boolean) => {
    this.setState((prev) => ({
      productModalOpen: false,
      productModalType: '',
      productSelectToggle: false,
      productAddEditLoader: false,
      productSelectedIds: [],
      productName: '',
      prodDropDownNextPage: (prev.productListDropdownSearchText || shouldFetchDropdownList) ? 1 : prev.prodDropDownNextPage,
      productListDropdownSearchText: '',
      editProductDetails: {
        id: '',
        name: '',
        catalogueId: ''
      },
      productListDropdown: shouldFetchDropdownList ? [] : prev.productListDropdown,
      hasMore: true
    }), () => {
      shouldFetchDropdownList && this.handleProductList(this.state.priceListDetail.id, true)
    })
  }

  fetchMoreProducts = (page: number) => {
    if (
      page > this.state.productListMeta.total_pages
    ) {
      return;
    }
    this.setState(
      {
        prodNextPage: page,
      },
      () => {
        this.handleProductList(this.state.priceListDetail.id.toString());
      }
    );
  };

  handleProductSelectToggle = () => {
    this.setState({ productSelectToggle: true })
  }

  handleSearchProduct = (inputValue: string) => {
    this.setState({
      productListDropdownSearchText: inputValue
    }, this.debouncedSearchProduct)
  }

  debouncedSearchProduct = debounce(() => {
    this.handleProductList(this.state.priceListDetail.id, true)
  }, 700)

  handleProductCheck = (productId: number) => {
    this.setState((prevState) => {
      const { productSelectedIds } = prevState;
      const isAlreadySelected = productSelectedIds.includes(productId);
      const updatedIds = isAlreadySelected
        ? productSelectedIds.filter((productSelectedId) => productSelectedId !== productId)
        : [...productSelectedIds, productId];

      return {
        productSelectedIds: updatedIds,
      };
    });
  }

  handleEditChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value
    this.setState({
      productName: value,
      productNameError: value === ""
    })
  }

  hanldeEditProductAPICall = (isEditSwitch: boolean) => {
    const { productName, editProductDetails, isProductSwitchActive } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const body = {
      "data": !isEditSwitch ? {
        "catalogue_label": productName,
      } : {
        "active": isProductSwitchActive,
      }
    }

    const url = configJSON.B2bProductEditAPIEndpoint + `/${editProductDetails.id}/update_catalogue_label`

    const message = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: configJSON.editProductListApiMethod,
    });

    this.editProductApiCallId = message.messageId;
    this.send(message)
  }

  hanldeDeleteProductAPICall = () => {
    const { editProductDetails, priceListDetail } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const url = `${configJSON.ApiUrls.priceListEndPoint}/${priceListDetail.id}/delete_catalogue?product_id=${editProductDetails.catalogueId}`

    const message = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.deleteApiMethod,
    });

    this.addDeleteProductApiCallId = message.messageId;
    this.send(message)
  }

  showEditErrorMessage = (errorMessage?: unknown) => {
    if (errorMessage && typeof errorMessage === "string") {
      this.setState({
        snackbarMessage: errorMessage,
        snackbarServerity: "error",
        productAddEditLoader: false,
      })
    }
  }

  handleProductAddEditApiCallResponse = (responseJson: IEditApiResponse, isAddOrDelete: boolean) => {
    if (responseJson.error || responseJson.errors?.length) {
      const errorMessage = conditionalString(
        responseJson.error,
        responseJson.error as string,
        conditionalString(
          typeof responseJson.errors === "string",
          responseJson.errors as string,
          responseJson.errors?.[0] as string
        )
      )
      this.showEditErrorMessage(errorMessage)
      return;
    }
    if (responseJson.message || responseJson.success) {
      this.setState({
        snackbarMessage: responseJson.message,
        snackbarServerity: "success",
        productAddEditLoader: false,
      }, () => {
        this.handleProductList(this.state.priceListDetail.id)
        this.handleClose(isAddOrDelete)
      })
    }
  }

  hanldeAddProductAPICall = () => {
    const { productSelectedIds, priceListDetail } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    const body = {
      "data": {
        "id": priceListDetail.id,
        "product_ids": productSelectedIds.map(Number)
      }
    }

    const url = configJSON.B2bProductAddAPIEndpoint

    const message = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: configJSON.exampleAPiMethod,
    });

    this.addDeleteProductApiCallId = message.messageId;
    this.send(message)
  }


  handleSwitch = (checked: boolean, productId: string) => {
    this.setState({
      editProductDetails: {
        id: productId,
        catalogueId: ""
      },
      productModalType: "Switch",
      isProductSwitchActive: checked
    },
      () => this.hanldeSubmit())
  }

  hanldeSubmit = () => {
    const { productModalType } = this.state;
    switch (productModalType) {
      case "Add":
        this.hanldeAddProductAPICall();
        break;
      case "Edit":
        this.hanldeEditProductAPICall(false);
        break;
      case "Switch":
        this.hanldeEditProductAPICall(true);
        break;
      case "Delete":
        this.hanldeDeleteProductAPICall();
        break;
    }
  }

  fetchMoreDropdownProducts = (event: React.SyntheticEvent) => {
    if (!this.state.hasMore) {
      return;
    }
    const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        if (listboxNode.scrollHeight - position <= 1.30) {
        this.setState(
          {
            prodDropDownNextPage: this.state.prodDropDownNextPage + 1,
          },
          () => {
            this.handleProductList(this.state.priceListDetail.id, true);
          }
        );
    }
  };

  getFilteredProducts = () => !this.state.productListDropdownSearchText
    ? this.state.productListDropdown
    : this.state.productListDropdown.filter(product => product.attributes.name.toLowerCase().includes(this.state.productListDropdownSearchText.toLowerCase()))
  // Customizable Area End
}