import React from "react";
// Customizable Area Start
import B2BCustomerShowModalController, {
  Props,
  configJSON,
} from "./B2BCustomerShowModalController";
import { Box, styled, Typography } from "@material-ui/core";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions } from "../../utilities/src/CustomBlockHelpers";
import { Skeleton } from "@material-ui/lab";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.B2BCustomerViewModal;
// Customizable Area End

export default class B2BCustomerShowModal extends B2BCustomerShowModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnValue = (value: string | number | null, fallbackValue: string | number | null = "---") =>
    typeof value === "number" ? (value || value === 0 ? value : fallbackValue ?? "---") 
    : value != null ? value 
    : fallbackValue ?? "---";

  handleCheckCityVal = (
    city: {
      id: number;
      name_en: string;
    } | null
  ) => {
    if (city == null) {
      return "---";
    } else {
      return city.name_en;
    }
  };

  handleCheckPricelistVal = (
    pricelist: {
      id: number;
      name: string;
    } | null
  ) => {
    if (pricelist == null) {
      return "---";
    } else {
      return pricelist.name;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isDataLoading, customerData } = this.state;
    const customerDetails = customerData.attributes;
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomerViewWrapper>
        {renderBaseOnConditions(
          isDataLoading,
          <Skeleton animation='wave' variant='rect' height={79} />,
          <Box className={"header-wrapper"}>
            <Box className={"header-box"}>
              <Typography className={"header-title"}>
                {Strings.priceList}
              </Typography>
              <Typography className={"header-name"}>
                {this.handleCheckPricelistVal(customerDetails.price_list)}
              </Typography>
            </Box>
            <Box className={"header-box"}>
              <Typography className={"header-title"}>
                {Strings.noOfOrders}
              </Typography>
              <Typography className={"header-name"}>
              {this.handleReturnValue(
                  customerDetails.no_of_order,
                  customerDetails.contract_no_of_order
                )}
              </Typography>
            </Box>
            <Box className={"header-box"}>
              <Typography className={"header-title"}>
                {Strings.noOfPieces}
              </Typography>
              <Typography className={"header-name"}>
              {this.handleReturnValue(
                  customerDetails.no_of_pieces,
                  customerDetails.contract_no_of_pieces
                )}
              </Typography>
            </Box>
          </Box>
        )}
        {renderBaseOnConditions(
          isDataLoading,
          <Skeleton
            animation='wave'
            variant='rect'
            height={475}
            style={{ margin: "24px 0" }}
          />,
          <Box className={"customer-detail-wrapper"}>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelName}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.name)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelMobileNumber}
              </Typography>
              <Typography className={"customer-name"}>
                +{this.handleReturnValue(customerDetails.full_phone_number)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelEmailId}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.email)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelCompanyName}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.company.name)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelBusinessCustomerName}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(
                  customerDetails.business_account.business_customer
                )}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelDepartmentName}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.department_name)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelDepartmentId}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.department_id)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelEmployeeId}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.employee_id)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelCostCenter}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.cost_center)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.labelSubCostCenter}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.sub_cost_center)}
              </Typography>
            </Box>
          </Box>
        )}
        {renderBaseOnConditions(
          isDataLoading,
          <Skeleton
            animation='wave'
            variant='rect'
            height={301}
            style={{ margin: "24px 0" }}
          />,
          <Box className={"customer-detail-wrapper"}>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelGender}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.gender?.label)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelAddress}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.address)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelCity}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleCheckCityVal(customerDetails.city)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelPinCode}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.postal_code)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelNotes}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.notes)}
              </Typography>
            </Box>
            <Box className={"customer-detail-box"}>
              <Typography className={"customer-title-gray"}>
                {Strings.labelPrivateNotes}
              </Typography>
              <Typography className={"customer-name"}>
                {this.handleReturnValue(customerDetails.private_notes)}
              </Typography>
            </Box>
          </Box>
        )}
      </CustomerViewWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomerViewWrapper = styled(Box)({
  width: "100%",
  "& .header-wrapper": {
    padding: "8px 24px",
    border: `1px solid ${colors().lightborder}`,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "8px",
    borderRadius: "8px",
  },
  "& .header-box": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .header-title": {
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: colors().pendingColor,
  },
  "& .header-name": {
    fontSize: "19px",
    lineHeight: "23px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: colors().darkliver,
  },
  "& .customer-detail-wrapper": {
    padding: "32px",
    background: colors().ghostwhite,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    rowGap: "24px",
    columnGap: "8px",
    margin: "24px 0",
  },
  "& .customer-detail-box": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .customer-title": {
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
  "& .customer-title-gray": {
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: colors().slateGray,
  },
  "& .customer-name": {
    fontSize: "19px",
    lineHeight: "23px",
    fontWeight: 500,
    color: colors().darkliver,
    fontFamily: "Montserrat",
  },
});
// Customizable Area End
