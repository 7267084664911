export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const defaultIcon = require("../assets/default_icon.png");
export const filterIcon = require("../assets/filter_icon.png");
export const activeFilterIcon = require("../assets/active_filter_icon.png");
export const addIcon = require("../assets/add_icon.png");
export const calenderIcon = require("../assets/calender_icon.png");
export const customerAddIcon = require("../assets/customer_add_icon.png");
export const customerViewIcon = require("../assets/customer_view_icon.png");
export const sortIcon = require("../assets/sort_arrow.png");
export const CollapseIcon = require("../assets/collapse_icon.png");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const plusIcon = require("../assets/plus_icon.svg");
export const trashIcon = require("../assets/trash_icon.svg");
