// Customizable Area Start
import React from "react";
import B2bBusinessAccountAddController, {
  BusinessAccountForm,
  ManagerOption,
  Props,
  configJSON,
} from "./B2bBusinessAccountAddController";
import {
  styled,
  Box,
  Button,
  Paper,
  PaperProps
} from "@material-ui/core";
import moment, { Moment } from "moment";
import { toString } from "lodash";
import { colors } from "../../utilities/src/Colors";
import { FieldInputProps, Form, Formik, FormikProps } from "formik";
import { renderBaseOnConditions, sortStringCondition } from "../../utilities/src/CustomBlockHelpers";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import CustomComboBox from "../../../components/src/CustomComboBox.web"
import CustomTimeRangePicker from "../../../components/src/CustomTimeRangePicker.web";
import StyledInlineDatePicker from "../../../components/src/StyledInlineDatepicker.web";
import { calendarIcon } from "./assets"
import * as commonAsset from "../../../components/src/commonAssets/assets"
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import SalesOrders from "../../../components/src/customComponents/SalesOrders.web";
import BusinessAccountCustomerGroupConfig, { StyledBox } from "../../../components/src/customComponents/BusinessAccountCustomerGroupConfig.web";
import { CustomTextField as StyledTextField, PhoneNumberContainer } from "../../../components/src/customComponents/CustomTags.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
// Customizable Area End

// Customizable Area Start
type OptionWithNumberId = {
  id: number
  option: string
}

const INPUT_FIELDS: Array<{
  label: string
  placeholder: string
  fieldName: keyof BusinessAccountForm
  isNumericField?: boolean
}> = [
    { label: "Business Customer*", placeholder: "Enter Business Customer", fieldName: "business_customer" },
    { label: "Business Account No.*", placeholder: "Enter Business Account Number", fieldName: "business_account_no" },
    { label: "Business Address", placeholder: "Enter Address", fieldName: "business_address" },
    { label: "Contact Person", placeholder: "Enter Name", fieldName: "contract_person" },
    { label: "Email ID", placeholder: "Enter Email ID", fieldName: "email" },
    { label: "Phone No.", placeholder: "Enter Phone Number", fieldName: "phone_no", isNumericField: true },
    { label: "Purchase Order*", placeholder: "Enter Purchase Order Number", fieldName: "purchase_order" },
  ]
// Customizable Area End

export default class B2bBusinessAccountAdd extends B2bBusinessAccountAddController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderErrorMessage = (errorMessage: string) =>
    renderBaseOnConditions(
      Boolean(errorMessage),
      <span className="error-message">{errorMessage}</span>,
      null
    )

  renderInputField(
    fieldName: keyof BusinessAccountForm,
    label: string,
    placeholder: string,
    getErrorMessage: (field: keyof BusinessAccountForm) => string,
    getFieldProps: (prop: string) => FieldInputProps<BusinessAccountForm>,
    setFieldValue: (field: string, value: string) => void,
    isNumberInput?: boolean
  ) {
    const handleChangeNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      value = value.replace(/[^\d]/g, "");
      setFieldValue(fieldName, value);
    };
    const fieldProps = getFieldProps(fieldName)
    const errorMessage = getErrorMessage(fieldName)
    const FieldContainer = fieldName === "phone_no" ? PhoneNumberContainer : React.Fragment

    return (
      <>
        <p className='label'>{label}</p>
        <FieldContainer>
          <StyledTextField
            error={Boolean(errorMessage)}
            id={fieldName}
            fullWidth={true}
            placeholder={placeholder}
            variant='outlined'
            {...fieldProps}
            onChange={isNumberInput ? handleChangeNumberInput : fieldProps.onChange}
          />
        </FieldContainer>
        {
          this.renderErrorMessage(errorMessage)
        }
      </>
    );
  }

  renderMultiSelectDropdown = (
    label: string,
    placeholder: string,
    fieldName: keyof BusinessAccountForm,
    values: BusinessAccountForm,
    options: { id: number | string; option: string }[],
    errorMessage?: string
  ) => {
    const selectedOptionIds = values[fieldName] as Array<string | number>
    const selectedOptions = options.filter(option => selectedOptionIds && selectedOptionIds.includes(option.id))
    const isSelectedAll = selectedOptions.length && selectedOptions.length === options.length
    const value = isSelectedAll ? [{ id: "-1", option: "Select All" }] : selectedOptions
    const selectedOptionForList = isSelectedAll ? [{ id: "-1", option: "Select All" }, ...selectedOptions] : value

    return (
      <Box className="autocomplete-field">
        <p className="label">{label}</p>
        <CustomSearchableMultiSelect
          dataTestId={`dropdown_${fieldName}`}
          id={fieldName}
          name={fieldName}
          checkSelectAllText={() => { }}
          value={value}
          optionList={options}
          placeHolderText={placeholder}
          changeInputOnClear
          handleScroll={() => { }}
          handleClickOnSelectAll={() => { }}
          isOnBlurFunction={() => { }}
          debouncedFunction={(getValue: string) => {
            if (getValue === '') {
              this.handleClearMultiSelect(fieldName)
            }
          }}
          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string, option: string }) => {
            this.handleChangeMultipleSelectDropdown(option, options, fieldName)
          }}
          emptyDataMessage={configJSON.noDataMsg}
          handleEmptyAutoSelectValue={() => { }}
          selectedOptionForList={selectedOptionForList}
          isError={false}
          endAdornment={fieldName === "available_days" && !value.length ? <Box position="absolute" right={16}><img src={calendarIcon} /></Box> : undefined}
        />
        {errorMessage && (
          <p
            className="error-message"
          >
            {errorMessage}
          </p>
        )}
      </Box>
    )
  }

  renderGroups = (values: BusinessAccountForm) => {
    const { groups, catalogues, groupItems } = this.state
    const { account_group_configurations_attributes } = values

    return (
      <Box gridColumn="span 2" position="relative" display="flex" flexDirection="column" gridGap={24}>
        <CustomLoader loading={this.state.isLoadingItems} />
        {
          account_group_configurations_attributes.map((groupConfig) => {
            const { business_customer_group_id } = groupConfig
            const optionList = groupItems[business_customer_group_id] || []

            return (
              <BusinessAccountCustomerGroupConfig
                key={groupConfig.business_customer_group_id}
                groupName={groups.find(item => item.id === business_customer_group_id)?.option}
                groupConfig={groupConfig}
                handleUpdateGroup={this.handleUpdateGroup}
                handleCatalogueAutoCompleteChange={this.handleCatalogueAutoCompleteChange}
                handleScrollCatalogueDropdown={this.handleScrollCatalogueDropdown}
                handleBlurCatalogueDropdown={this.handleBlurCatalogueDropdown}
                catalogueOptions={catalogues as OptionWithNumberId[]}
                itemOptions={optionList as OptionWithNumberId[]}
              />
            )
          })
        }
      </Box>
    )
  }

  paperComponent = (props: PaperProps) => <Paper {...props} /> 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { managers, companies, subCompanies, regions, areas, stores, catalogues, groups, initialFormValue } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledWrapper data-test-id="business-account-form-wrapper">
        <h3 className="page-title">{sortStringCondition(Boolean(this.businessAcountIdParam), "Edit", "Add")} Business Account</h3>
        <Formik
          initialValues={initialFormValue}
          onSubmit={this.handleSubmit}
          enableReinitialize
          data-test-id="formik"
          innerRef={this.formRef}
          validationSchema={this.FormSchema}
        >
          {({
            values,
            setFieldValue,
            setValues,
            errors,
            touched,
            getFieldProps,
          }: FormikProps<BusinessAccountForm>) => {
            const getErrorMessage = (fieldName: keyof BusinessAccountForm) => (errors[fieldName] && touched[fieldName]) ? toString(errors[fieldName]) : ""
            const companyError = getErrorMessage("company_id")
            const salesOrderError = getErrorMessage("sales_order")
            const managerRegions = values.contract_manager?.attributes?.regions?.map(
              region => ({ id: region.id, option: region.branch_name })
            ) || []
            const catalogueOptions = this.getSingleDropdownOptions(values.price_list, catalogues as OptionWithNumberId[])
            const contractManagerOptions = this.getSingleDropdownOptions(values.contract_manager 
              ? {id: values.contract_manager.id, name: values.contract_manager.attributes.full_name, attributes: values.contract_manager.attributes}
              : null,
              managers
            )

            return (
              <Form>
                <Box className="paper" marginBottom={3}>
                  <Box className="autocomplete-field">
                    <p className="label">Company Name*</p>
                    <AutocompleteSelect
                      placeholder="Type to Select"
                      fieldName="company_id"
                      disableClear
                      clearOnBlur
                      onChange={(event: React.ChangeEvent, value: { id: string; option: string }) => {
                        setValues({
                          ...values,
                          company_id: value.id,
                          sub_company_id: null,
                          price_list: null,
                          sub_company_name: "",
                          no_of_groups: [],
                          account_group_configurations_attributes: []
                        })
                        this.handleChangeCompany(value)
                      }}
                      handleScroll={() => { }}
                      onBlur={() => { }}
                      debouncedFunction={() => { }}
                      value={values.company_id}
                      options={companies}
                      listBoxStyle={{ maxHeight: 310 }}
                      error={Boolean(companyError)}
                    />
                    {this.renderErrorMessage(companyError)}
                  </Box>
                  <Box className="autocomplete-field">
                    <p className="label">Sub-Company Name</p>
                    <AutocompleteSelect
                      className="company-dropdown"
                      placeholder="Enter Sub-Company Name"
                      fieldName="sub_company_name"
                      disableClear
                      clearOnBlur
                      onChange={(_item: React.ChangeEvent, value: { id: string; option: string }) => {
                        setFieldValue("sub_company_id", value.id)
                        setFieldValue("sub_company_name", value.option)
                      }}
                      handleScroll={() => { }}
                      onBlur={() => { }}
                      debouncedFunction={() => { }}
                      value={values.sub_company_id}
                      options={subCompanies}
                      listBoxStyle={{ maxHeight: 310 }}
                    />
                  </Box>
                  {
                    INPUT_FIELDS.map(field =>
                      <Box key={field.fieldName}>
                        {this.renderInputField(
                          field.fieldName,
                          field.label,
                          field.placeholder,
                          getErrorMessage,
                          getFieldProps,
                          setFieldValue,
                          field.isNumericField
                        )}
                      </Box>
                    )
                  }
                  <Box>
                    <p className="label">Sales Order*</p>
                    <SalesOrders 
                      data-test-id="sales-order"
                      value={values.sales_order} 
                      placeholder="Enter Sales Order Number"
                      onChange={(sales_order) => setFieldValue("sales_order", sales_order)}
                      error={Boolean(salesOrderError)} 
                    />
                    {
                      this.renderErrorMessage(salesOrderError)
                    }
                  </Box>
                  <Box>
                    <p className="label">Cost Center</p>
                    <SalesOrders 
                      data-test-id="cost-center"
                      value={values.cost_center} 
                      placeholder ="Enter Cost Center"
                      onChange={(cost_center) => setFieldValue("cost_center", cost_center)}
                    />
                  </Box>
                  <Box>
                    <p className="label">Sub-Cost Center</p>
                    <SalesOrders 
                      data-test-id="sub-cost-center"
                      value={values.sub_cost_center} 
                      placeholder ="Enter Sub-Cost Center"
                      onChange={(sub_cost_center) => setFieldValue("sub_cost_center", sub_cost_center)}
                    />
                  </Box>
                  <Box className="date-field">
                    <p className="label">Business Start Date*</p>
                    <StyledInlineDatePicker
                      data-test-id='business_start_date'
                      name='business_start_date'
                      id='business_start_date'
                      error={Boolean(getErrorMessage("business_start_date"))}
                      value={values.business_start_date}
                      maxDate={values.business_end_date || undefined}
                      onChange={(dateValue: Moment | null) => {
                        setFieldValue("business_start_date", dateValue ? moment(dateValue).format("YYYY/MM/DD") : null);
                      }}
                      keyboardIcon={<img src={commonAsset.calendarIcon} />}
                      InputProps={{
                        startAdornment: null,
                        style: {},
                        readOnly: true
                      }}
                      PopoverProps={{
                        disablePortal: true,
                      }}
                    />
                  </Box>

                  <Box className="date-field">
                    <p className="label">Business End Date</p>
                    <StyledInlineDatePicker
                      data-test-id='business_end_date'
                      name='business_end_date'
                      id='business_end_date'
                      error={false}
                      minDate={values.business_start_date || undefined}
                      maxDate={undefined}
                      value={values.business_end_date}
                      onChange={(dateValue: Moment | null) => {
                        setFieldValue("business_end_date", dateValue ? moment(dateValue).format("YYYY/MM/DD") : null);
                      }}
                      keyboardIcon={<img src={commonAsset.calendarIcon} />}
                      InputProps={{
                        startAdornment: null,
                        style: {},
                        readOnly: true
                      }}
                      PopoverProps={{
                        disablePortal: true,
                      }}
                    />
                  </Box>

                  <StyledBox>
                    <Box className="autocomplete-field">
                      <p className="label">Contract Manager Name</p>
                      <AutocompleteSelect
                        className="contract-manager-dropdown"
                        placeholder="Enter Manager Name"
                        fieldName="contract_manager"
                        disableClear
                        clearOnBlur
                        onChange={(_item: React.ChangeEvent, value: ManagerOption) => {
                          setFieldValue("contract_manager", value)
                        }}
                        handleScroll={this.handleScrollManagerDropdown}
                        onBlur={() => this.debouncedFunction(values.contract_manager?.option || "", this.handleManagerAutoCompleteChange)}
                        debouncedFunction={(inputValue: string) => this.debouncedFunction(inputValue, this.handleManagerAutoCompleteChange)}
                        value={toString(values.contract_manager?.id)}
                        options={contractManagerOptions}
                        listBoxStyle={{ maxHeight: 310 }}
                        paperComponent={this.paperComponent}
                      />
                    </Box>
                    <Box className="autocomplete-field view-only">
                      <p className="label">Region</p>
                      <CustomSearchableMultiSelect
                        dataTestId={`dropdown_manager_region`}
                        checkSelectAllText={() => { }}
                        value={managerRegions}
                        optionList={managerRegions}
                        placeHolderText=""
                        changeInputOnClear
                        handleScroll={() => { }}
                        handleClickOnSelectAll={() => { }}
                        isOnBlurFunction={() => { }}
                        debouncedFunction={() => { }}
                        handleSelectOptions={() => { }}
                        emptyDataMessage={configJSON.noDataMsg}
                        handleEmptyAutoSelectValue={() => { }}
                        selectedOptionForList={[]}
                        isError={false}
                        disabled
                      />
                    </Box>
                    <Box>
                      <p className="label">Email ID</p>
                      <StyledTextField
                        value={values.contract_manager?.attributes.email}
                        placeholder=""
                        variant='outlined'
                        disabled
                      />
                    </Box>
                    <Box>
                      <p className="label">Phone Number</p>
                      <StyledTextField
                        value={sortStringCondition(
                          Boolean(values.contract_manager?.attributes.full_phone_number),
                          `+${values.contract_manager?.attributes.full_phone_number}`,
                          ""
                        )}
                        placeholder=""
                        variant='outlined'
                        disabled
                      />
                    </Box>
                  </StyledBox>

                  {this.renderMultiSelectDropdown("Region", "Type to Select", "region_ids", values, regions)}
                  {this.renderMultiSelectDropdown("Area", "Type to Select", "area_ids", values, areas)}
                  {this.renderMultiSelectDropdown("Store", "Type to Select", "store_management_ids", values, stores)}

                  <Box display="flex" flexDirection="column" justifyContent="flex-end">
                    <Box className="switch-item">
                      <p className="switch-label">
                        Auto Renewal
                      </p>
                      <CustomSwitch
                        onChange={(newValue) => setFieldValue("auto_renewal", newValue)}
                        checked={Boolean(values.auto_renewal)}
                      />
                    </Box>
                  </Box>

                  <Box className="autocomplete-field">
                    <p className="label">Catalogue</p>
                    <AutocompleteSelect
                      className="catalogue-dropdown"
                      placeholder="Type to Select"
                      fieldName="price_list"
                      clearOnBlur
                      data-test-id='catalogue-dropdown'
                      onChange={(_item: React.ChangeEvent, value: OptionWithNumberId | null) => {
                        this.handleChangeCatalogue(value, setFieldValue)
                      }}
                      handleScroll={this.handleScrollCatalogueDropdown}
                      onBlur={this.handleBlurCatalogueDropdown}
                      debouncedFunction={(inputValue: string) => this.debouncedFunction(inputValue, this.handleCatalogueAutoCompleteChange)}
                      value={values.price_list?.id || null}
                      options={catalogueOptions}
                      listBoxStyle={{ maxHeight: 310 }}
                      isDisabled={this.state.isLoadingItems}
                      paperComponent={this.paperComponent}
                    />
                  </Box>

                  {
                    this.renderMultiSelectDropdown(
                      "No. of Groups",
                      "Select Number of Groups",
                      "no_of_groups",
                      values,
                      groups
                    )
                  }

                  {this.renderGroups(values)}

                  <BusinessAccountCustomerGroupConfig
                    key={1}
                    dataTestId="businessAccountGroupEmployee"
                    groupName={''}
                    hasCataloge={false}
                    showPeriodType={false}
                    groupConfig={{
                      business_customer_group_id: 1,
                      no_of_order: values.no_of_items_per_order,
                      order_period_type: values.order_period_type_for_employee,
                      price_list: null,
                      pieces_period_type: values.pieces_period_type_for_employee,
                      item_pics_configurations_attributes: values.item_pics_configurations_attributes_for_employee,
                      product_master: values.product_master_for_employee
                    }}
                    handleUpdateGroup={this.handleUpdateEmployee}
                    handleCatalogueAutoCompleteChange={this.onChangeBlank}
                    handleScrollCatalogueDropdown={this.onChangeBlank}
                    handleBlurCatalogueDropdown={this.onChangeBlank}
                    catalogueOptions={[] as OptionWithNumberId[]}
                    itemOptions={this.state.items_for_employee}
                    buttonName="No. of Pieces Per Order"
                    noOfOrderText="Total No. of Order (Per Employee)"
                  />
                  <BusinessAccountCustomerGroupConfig
                    key={2}
                    dataTestId="businessAccountGroupContract"
                    groupName={''}
                    hasCataloge={false}
                    groupConfig={{
                      business_customer_group_id: 2,
                      no_of_order: values.total_no_of_order_for_contract,
                      order_period_type: values.order_period_type_for_contract,
                      price_list: null,
                      pieces_period_type: values.pieces_period_type_for_contract,
                      item_pics_configurations_attributes: values.item_pics_configurations_attributes_for_contract,
                      product_master: values.product_master_for_contract
                    }}
                    handleUpdateGroup={this.handleUpdateContract}
                    handleCatalogueAutoCompleteChange={this.onChangeBlank}
                    handleScrollCatalogueDropdown={this.onChangeBlank}
                    handleBlurCatalogueDropdown={this.onChangeBlank}
                    catalogueOptions={[] as OptionWithNumberId[]}
                    itemOptions={this.state.items_for_contract}
                    buttonName="Total No. of Pieces Per Order (Per Contract)"
                    noOfOrderText="Total No. of Order (Per Contract)"
                  />

                  <Box>
                    <p className="label">Available Time</p>
                    <CustomTimeRangePicker
                      timeRange={values.business_available_times_attributes}
                      onChangeTimeRange={(fromTime, toTime) =>
                        setFieldValue("business_available_times_attributes", {
                          id: values.business_available_times_attributes.id,
                          from: fromTime, to: toTime
                        })
                      }
                    />
                  </Box>
                  {
                    this.renderMultiSelectDropdown(
                      "Available Days",
                      "Select Available Days",
                      "available_days",
                      values,
                      this.weekdayOptions
                    )
                  }

                </Box>
                <Box className="actions">
                  <Button
                    data-test-id='cancel-btn'
                    variant="outlined"
                    className="cancel-btn"
                    onClick={() => this.redirect()}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    className="submit-btn"
                    data-test-id="submit-btn"
                    type="submit"
                  >
                    <span>{sortStringCondition(Boolean(this.businessAcountIdParam), "Save", "Add")}</span>
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
        {
          renderBaseOnConditions(
            Boolean(this.state.snackbar),
            <CustomSnackbar
              open
              errorMessage={this.state.snackbar?.message as string}
              severity={this.state.snackbar?.severity as "error" | "success"}
              autoHideDuration={2000}
              onClose={this.closeSnackbar}
            />,
            null
          )
        }
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .page-title": {
    fontSize: 24,
    lineHeight: 2,
    fontWeight: 600,
    color: colors().viewTextColor
  },
  "& form": {
    "& .paper": {
      borderRadius: 12,
      padding: 24,
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
      display: "grid",
      gridTemplateColumns: "0.5fr 0.5fr",
      gap: 24,
    },
    "& .contract-manager": {
      gridColumn: "span 2",
      display: "grid",
      gridTemplateColumns: "0.5fr 0.5fr",
      gap: 24,
      padding: 16,
      border: `solid 1px ${colors().lightborder}`,
      borderRadius: 8
    },
    "& .label": {
      fontWeight: 600,
      lineHeight: 1.5,
      color: colors().charcoal
    },
    "& .error-field": {
      "& fieldset": {
        borderColor: "red"
      }
    },
    "& .error-message": {
      fontSize: 14,
      color: "red",
      marginTop: 6
    },
    "& .phone-number-field": {
      display: "flex",
      gap: 8,
      "& > div:first-of-type": {
        width: "25%",
        minWidth: 80,
        maxWidth: 143
      }
    },
    "& .date-field": {
      "& .MuiFormControl-root": {
        width: "100%",
        paddingTop: 6
      },
      "& .MuiInputBase-root": {
        borderWidth: 1,
        "&.Mui-error": {
          border: "1px solid red",
        }
      },
      "& .MuiInputBase-input": {
        padding: "18.5px 14px !important"
      },
      "& .MuiPickersBasePicker-pickerView .MuiTypography-root": {
        fontFamily: "Montserrat",
        fontSize: "0.875rem"
      },
      "& .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root": {
        fontWeight: 700,
        color: colors().darkjunglegreen
      },
      "& .MuiPickersDay-daySelected": {
        border: `solid 1px ${colors().dividerColor}`,
        background: colors().brightgray,
        color: colors().cyancobaltblue
      },
      "@media only screen and (max-width: 1024px)": {
        "& .PickerWithState-root-35 div button": {
          padding: 6
        }
      },
    },
    "& .autocomplete-field": {
      "& .MuiAutocomplete-root": {
        paddingTop: 6,
        "& .MuiOutlinedInput-root": {
          border: 0
        },
        "& .MuiInputBase-input": {
          "&::placeholder": {
            opacity: 1,
            color: colors().darkliver,
            fontSize: 14,
            fontFamily: "Montserrat",
            fontWeight: 400,
            "@media only screen and (max-width: 1024px)": {
              fontSize: 12,
            },
          },
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "red"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors().lightborder
        },
      },
      "&.view-only": {
        "& .MuiChip-root.Mui-disabled": {
          opacity: 1
        },
        "& .MuiAutocomplete-endAdornment": {
          visibility: "hidden"
        }
      }
    },
    "& .switch-item": {
      borderRadius: 8,
      border: `solid 1px ${colors().lightborder}`,
      padding: "10px 16px",
      display: "flex",
      gap: 8,
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 56
    },
    "& .switch-label": {
      fontWeight: 500,
      lineHeight: "22.4px",
      color: colors().darkjunglegreen
    },
    "& .actions": {
      paddingBottom: 20,
      "& > button": {
        padding: 16,
        borderRadius: 8,
        width: 126,
        border: 0,
        fontWeight: 700,
        lineHeight: 1.5,
        textTransform: "none",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
          padding: "10px 0"
        },
      },
      "& .cancel-btn": {
        backgroundColor: colors().antiFlashWhite,
        color: colors().slateGray,

      },
      "& .submit-btn": {
        backgroundColor: colors().cyancobaltblue,
        color: "white",
        marginLeft: 32,
      }
    }
  }
});
const StyledWrapperGroupName = styled('p')({
  gridColumn: "span 2",
  fontSize: 18,
  fontWeight: 600,
  lineHeight: "22px"
})
// Customizable Area End