import React from "react";
import { styled, Box, Tooltip, withStyles, Typography,StyledComponentProps } from "@material-ui/core";
import { colors } from "../../../../blocks/utilities/src/Colors";

const IconDefault = require("./sorting.png");
const IconDesc = require("./arrow-down.png");
const IconAsc = require("./arrow-up.png");

export type SortingType = "" | "asc" | "desc";
export interface ISortingData {
  [field: string]: SortingType;
}

interface Props {
  header?: boolean;
  contentFontSize?: string;
  disabled?: boolean;
  sortingKey: string;
  truncateAfter?: number;
  content: React.ReactNode;
  sortingData: ISortingData;
  sortingDisabled?: boolean;
  style?: React.CSSProperties;
  sortingIcons?: Array<string>; // default, ascending, descending icon
  handleHeaderClick?: () => void;
  onQueryChange?: (query: string) => void;
  type: "left" | "right" | "middle" | "none";
  onChange?: (sortingData: ISortingData) => void;
  className?: string;
  handleClick?: () => void;
  dataid?: string | number;
  breakWord?: boolean
}

interface RowProps {
  header?: boolean;
  contentFontSize?: string;
  breakWord?:boolean;
}

function TableCell(props: Props) {
  const {
    type,
    style,
    header,
    contentFontSize,
    content,
    disabled,
    onChange,
    sortingKey,
    sortingData,
    sortingIcons,
    truncateAfter,
    onQueryChange,
    sortingDisabled,
    handleHeaderClick,
    handleClick,
    dataid,
    breakWord,
    ...rest
  } = props;
  const defaultStyle = getTypeBasedStyle(type);
  const headerStyle = header ? getHeaderStyle() : {};
  
  const onSortingClick = () => {
    handleHeaderClick && handleHeaderClick();
    if (!sortingData && !sortingKey) return;
    else {
      const newSortingData = JSON.parse(JSON.stringify({ ...sortingData }));
      const keyArray = ["", "asc", "desc", ""];
      let index = keyArray.indexOf(sortingData[sortingKey]);
      newSortingData[sortingKey] = keyArray[index + 1] as SortingType;

      Object.keys(newSortingData).forEach(
        (key) => key !== sortingKey && (newSortingData[key] = "")
      );

      onChange && onChange(newSortingData);

      const queryString = Object.keys(newSortingData)
        .map((key) =>
          newSortingData[key] ? `${key}=${newSortingData[key]}` : null
        )
        .filter((item) => item)
        .join("&");

      onQueryChange && onQueryChange(queryString);
    }
  };

  const getTruncatedText = (text: string) => {
    if (text.length > (truncateAfter as number) && truncateAfter) {
      let textEclipsed =
        String(text).substring(0, (truncateAfter as number) - 3) + "...";
      return (
        <NotesTooltip title={<p>{text}</p>} arrow>
          <ResponsiveTypo contentFontSize={contentFontSize} header={header}>{textEclipsed}</ResponsiveTypo>
        </NotesTooltip>
      );
    }
    return (<ResponsiveTypo breakWord={breakWord} contentFontSize={contentFontSize} header={header}>{text}</ResponsiveTypo>);
  };

  const getIcon = () => {
    const index = ["", "asc", "desc"].indexOf(sortingData[sortingKey]);
    let arrayOfIcon = sortingIcons || [IconDefault, IconAsc, IconDesc];
    return arrayOfIcon[index];
  };

  const getContentWithSorting = () => {
    const icon = getIcon();
    return (
      <Box
        className="txtSortingIconGap"
        sx={{ display: "flex", alignItems: "center", height: "100%" }}
      >
        {getTruncatedText(content as string)}{" "}
        {icon && (
          <SortingImgStyle
            data-test-id='sortingIcon'
            aria-label={"sorting-table-header-" + sortingKey}
            src={icon}
            height={28}
            width={32}
            className="table-sorting-icons"
            style={{ cursor: "pointer" }}
            onClick={() => onSortingClick()}
          />
        )}
      </Box>
    );
  };

  return (
    <Cell
      style={{
        ...defaultStyle,
        ...style,
        ...headerStyle,
        opacity: disabled ? 0.6 : 1,
        wordBreak:'break-word'
      }}
      {...rest}
      data-test-id={`row-click-${dataid}`}
      onClick={handleClick}
    >
      {!sortingDisabled && header
        ? getContentWithSorting()
        : typeof content == "string"
          ? getTruncatedText(content)
          : content}
    </Cell>
  );
}

const getHeaderStyle = () => {
  return {
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: 600,
    borderRadius: 0,
    backgroundColor: "#204B9C",
    borderBottom: "none",
    borderRight: "none",
    borderLeft: "none",
    borderTop: "none",
  };
};

const getTypeBasedStyle = (type: string) => {
  switch (type) {
    case "left":
      return {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        borderLeft: "1px solid var(--Border, #ECECEC)",
      };

    case "right":
      return {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        borderRight: "1px solid var(--Border, #ECECEC)",
      };

    case "middle":
      return {};

    default:
      return {
        borderRadius: 0,
        border: "unset",
      };
  }
};

const Cell = styled("div")({
  padding: 12,
  fontSize: 16,
  fontWeight: 500,
  display: "flex",
  color: "#1A1A1A",
  maxWidth: "100%",
  maxHeight: "100%",
  lineHeight: "22px",
  alignItems: "flex-start",
  fontFamily: "Montserrat",
  borderTop: "1px solid var(--Border, #ECECEC)",
  borderBottom: "1px solid var(--Border, #ECECEC)",
  "@media only screen and (max-width: 1366px)": {
    padding: '10px',
    fontSize: 14,
  },
  "@media only screen and (max-width: 1280px)": {
    padding: '8px',
    fontSize: 12,
    fontWeight: 400,
   },
  "@media only screen and (max-width: 1024px)": {
    padding: '5px',
    fontSize: 12,
    fontWeight: 400,
    },
  "& .txtSortingIconGap": {
    gap:"8px",
    "@media (max-width: 1366px)": {
      gap: "6px"
    },
    "@media (max-width: 1028px)": {
      gap: "4px"
    },
    "@media (max-width: 1024px)": {
      gap: "2px"
    }
  }
});

const NotesTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);

const SortingImgStyle = styled("img")({
  height: '28px',
  width: "32px",
  "@media only screen and (max-width: 1024px)": {
    height: '20px',
    width: "23px"
  }
});

const ResponsiveTypo = styled(Typography)((props: StyledComponentProps & RowProps) => ({
  fontSize: props.contentFontSize || "16px",
  fontFamily: "Montserrat",
  wordBreak: props.breakWord ? "break-word": "unset",
  "@media only screen and (max-width: 1024px)": {
    fontSize: props.header ? "13px": "12px",
    fontWeight: props.header ? 500 : 400,
    color: props.header ? colors().background : colors().viewTextColor,
  }
}));

TableCell.defaultProps = {
  content: "",
  sortingKey: "",
  sortingData: {},
  type: "middle",
};

export default TableCell;
