// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import {
  navigateTo
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { concat, uniqBy,toString } from "lodash";
export const configJSON = require("./config");
import {
  CustomerDataType
} from "../../../components/src/Interface";
import { BusinessAccountDetail, BusinessAccountGroupConfigure } from "./utils";


const initialBusinessAccountCustomer = {
  id: 0,
  attributes: {
    activated: false,
      id: 0,
      full_phone_number: "",
      email: "",
      department_id: "",
      department_name: "",
      cost_center: "",
      sub_cost_center: "",
      address: "",
      postal_code: "",
      name: "",
      private_notes: "",
      notes: "",
      no_of_pieces: 0,
      no_of_order: 0,
      employee_id: "",
      used_no_of_pieces: null,
      business_account_id: 0,
      used_no_of_orders: null,
      sub_company_name: null,
      business_account: {
        business_customer: "",
        id: 0,
    },
      company: {
        name: "",
          id: 0,
      },
      price_list: {
        name: "",
          id: 0,
      },
      business_customer_group: {
        group_name: "",
          id: 0,
      },
    
      city: null,
      gender: {
        label: "",
          id: 0,
      },
      phone_number: "",
      full_name: "",
      country_code: "",
  },
  type: "business_account_customer",
};

export interface BusinesCustomerDetails {
    type: string;
    id: string;
    attributes: {
      name: string;
      full_phone_number: string;
      activated: boolean;
      email: string | null;
      department_name: string | null;
      id: number;
      department_id: string | null;
      cost_center: string | null;
      sub_cost_center: string | null;
      address: string | null;
      postal_code: string | null;
      notes: string | null;
      company: {
        id: number;
        name: string;
      };
      private_notes: string | null;
      no_of_order: number | null;
      no_of_pieces: number | null;
      price_list: {
        id: number;
        name: string;
      };
      employee_id: string;
      business_account_id: number;
      gender: {
        id: number;
        label: string;
      };
      city:  {
        "id": number,
        "name_en": string
        },
        "business_customer_group": {
          "id": number,
          "group_name": string
        },
      "business_account": {
        "id": number,
        "business_customer": string
      },
    };
}

export interface ICustomerInitialValues {
    name: string;
    mobile_number_prefix: string;
    mobile_number: string;
    email: string;
    company: string;
    business_customer_name: string;
    department_name: string;
    department_id: string;
    employee_id: string;
    cost_center: string;
    subcost_center: string;
    gender: string;
    street_address: string;
    city: string;
    postal_code: string;
    notes: string;
    private_notes: string;
    price_list: string;
    no_of_orders: number;
    no_of_pieces_per_order: number;
    customer_group: string
}

export interface IB2BCompanyDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "number_of_vats": string
    },
}

export interface IBusinessCustomerDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "business_customer": string,
        "contract_person": string
    }
}

export interface IB2BPriceListDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "price_list_type": string,
        "is_master_price_list": boolean
    }
  }

export interface IB2BCustomerGroupDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    group_name: string;
    vip: boolean;
  };
}


interface IGenderDropDownItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    label: string;
    module_name: string;
    created_at: string;
    updated_at: string; 
    model_name: string; 
  };
}

interface CustomerAttributes {
  id: number;
  name: string;
  email: string;
  full_phone_number: string;
  order: any | null;
  full_name: string;
  country_code: string;
  phone_number: string;
  is_linked: any | null;
}

interface CustomerData {
  id: string;
  type: string;
  attributes: CustomerAttributes;
}

interface Customer {
  data: CustomerData;
}

interface ApiResponse {
  data: string;
  customer: Customer;
}

type DropdownApiResponseData = IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[];


interface ICityDropDown {
  id: number;
  name_en: string;
  name_ar: string;
  name: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function
  };
  open?: boolean;
  id: string;
  customerData?: any;
  handleClose?: Function;
  handleGetCustomerPhone?: (customerData:unknown) => void;
  priceId?: string;
  isEdit?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerInitialValues: ICustomerInitialValues
  buttonLoading: boolean
  isB2bActive: boolean
  b2bCompanyList: IB2BCompanyDropDown[]
  b2bBusinessCustomerList: IBusinessCustomerDropDown[]
  b2bCustomerGroupList: IB2BCustomerGroupDropdown[]
  b2bPriceList: IB2BPriceListDropDown[]
  errorSnackbarOpen: boolean;
  isLoading: boolean;
  dropdownOptions: {
    genderList: {
      id: string,
      option: string
    }[],
    cityList: {
      id: string,
      option: string
    }[]
  },
  cost_center: {
    id: string;
    option: string;
  }[],
  subcost_center: {
    id: string;
    option: string;
  }[],
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  isEditLoading: boolean;
  selectedCompany: { id: string, option: string } | null
  selectedPriceListVal: { id: string, option: string } | null
  selectedGender: { id: string, option: string } | null
  errorMessage: string;
  selectedCustGroup: { id: string, option: string } | null
  selectedCity: { id: string, option: string } | null
  lastCreatedCustomer:{
    full_name: string;
    customer_id?: string;
    country_code: string;
    full_phone_number: string;
  },
  selectedBusinessCustomer: { id: string, option: string } | null
  snackbarMessage: string;
  customerData: any;
  snackbarOpen: boolean;
  snackbarServerity: 'success' | 'error';
  editId: string
  noOfOrdrers: number | null;
  noOfPiecesOrders: number | null
  selectedPricelist: {
    id: number;
    name: string;
  } | null;
  selectedBusinessAccountData: BusinessAccountDetail | null;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BCustomerModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomerViewDetailsCallId: string = ""
  getCustomerGroupDropdownApiCallId: string = "";
  authToken: string = "";
getPriceListDropdownApiCallId: string = "";
  getCompanyDropdownApiCallId: string = "";
  addB2BCustomerApiCallId: string = "";
  getGenderDropdownOptionsApiCallId: string = ""
  getCompanyDropdownDetailsApiCallId: string = ""
  getGenderDropdownOptionsApiCallId2:string=""
  getCityDropdownOptionsApiCallId: string = ""
  isEdit: boolean = this.props.navigation?.getParam('navigationBarTitleText')? true : false;
  getBusinessCustDropdownOptionsApiCallId: string = ""
  getBusinessCustomerDetailAPICallId: string = "";
  getBusinessCustomerDetailAPICallId2: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccountTypeInfoMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      buttonLoading: false,
      customerInitialValues: {
        name: '',
        mobile_number_prefix: '966',
        mobile_number: '',
        email: '',
        company: "",
        business_customer_name: '',
        department_name: '',
        department_id: '',
        employee_id: '',
        cost_center: '',
        subcost_center: '',
        gender: '',
        street_address: '',
        city: '',
        postal_code: '',
        notes: '',
        private_notes: '',
        price_list: '',
        no_of_orders:0,
        no_of_pieces_per_order:0,
        customer_group: ""
      },
      customerData: initialBusinessAccountCustomer,
      isB2bActive: false,
      b2bCompanyList: [],
      b2bCustomerGroupList: [],
      dropdownOptions: {
        genderList:[],
        cityList: []
      },
      cost_center: [],
      subcost_center: [],
      b2bBusinessCustomerList: [],
      errorSnackbarOpen: false,
      errorMessage: '',
      b2bPriceList: [],
      selectedCompany: null,
      snakcbarSeverity: 'error',
      selectedCity: null,
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarServerity: 'success',
      selectedPriceListVal: null,
      selectedGender: null,
      lastCreatedCustomer:{ full_name: '',
        customer_id: '',
        country_code: '',
        full_phone_number: ''},
      selectedCustGroup:null,
      selectedBusinessCustomer: null,
      isLoading: false,
      editId: "",
      isEditLoading: false,
      noOfOrdrers: null,
      noOfPiecesOrders: null,
      selectedPricelist: null,
      selectedBusinessAccountData: null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.addB2bCustomerApiCallResponse(apiRequestCallId, responseJson);
    this.getDropdownApiCallResponse(apiRequestCallId, responseJson);
    this.getModalCityDropdownApiCallRes(apiRequestCallId, responseJson);
    this.getModalGenderDropdownOptionApiCallResponse(apiRequestCallId,responseJson)
    this.gerBusinessAccountDetailsApiResponse(apiRequestCallId,responseJson)
    if (apiRequestCallId === this.getCustomerViewDetailsCallId) {
      this.handleGetCustomerResponse(responseJson);
    }
    
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken1 = localStorage.getItem("token");
    if (typeof (authToken1) === "string") {
      this.authToken = authToken1;
    }
    if(this.props.isEdit){
      this.getCustomerViewData();
    } else {
      this.isCreateDetails();
    }
    this.getModalDropDownApiCall()
    this.getGenderDropAPI();
    this.getNewOrderListApi('10')
    // Customizable Area End
  }
  // Customizable Area Start
  isCreateDetails() {
    const country_code = this.props.customerData?.attributes?.country_code?.replace(/[^0-9]+/g, '')
    const phone_number = this.props.customerData?.attributes?.phone_number

    this.setState(({
      customerInitialValues: {
        ...this.state.customerInitialValues,
        mobile_number_prefix: country_code || '',
        mobile_number: phone_number || '',
      }
    }));
  }
  b2bCustomerGrouptData = () => this.state.b2bCustomerGroupList.filter((group: IB2BCustomerGroupDropdown) => !this.state.selectedBusinessAccountData || this.state.selectedBusinessAccountData.attributes.no_of_groups.includes(group.attributes.group_name)).map(
    (item: IB2BCustomerGroupDropdown) => ({
      id: item.id,
      option: item.attributes.group_name,
    })
  );
  getCustomerViewData = () => {
    this.setState({ buttonLoading: true });

    const { customerData } = this.props;
    const customerId=customerData.id
    const ApiUrl = configJSON.B2BCustomerAPIEndpoint;
 
    let requestMessage = makeApiMessage({
      url: ApiUrl + "/" + customerId,
      method: "GET",
    });
    this.getCustomerViewDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCustomerResponse = (response:any) => {
      const customerData = response.data.attributes;
      if(customerData.company.id) this.getB2BBusinessCustomerDropDownApi(customerData.company.id)
      this.setState({
        selectedPricelist: customerData.price_list,
        cost_center: customerData.cost_center?.split(',').map((costCenter: string) => ({id: costCenter, option: costCenter}))??[],
        subcost_center: customerData.sub_cost_center?.split(',').map((subcostCenter: string) => ({id: subcostCenter, option: subcostCenter}))??[],
        customerInitialValues: {
          name: customerData.name ,
          mobile_number_prefix: this.getPhoneSplit(customerData.full_phone_number)?.prefix || "",
          mobile_number: this.getPhoneSplit(customerData.full_phone_number)?.phoneno || "",
          email: customerData.email as string,
          company: String(customerData.company.id) ,
          business_customer_name: String(customerData.business_account_id),
          department_name: customerData.department_name as string,
          department_id: customerData.department_id as string,
          employee_id: customerData.employee_id,
          cost_center: customerData.cost_center as string,
          subcost_center: customerData.sub_cost_center as string,
          gender: String(customerData.gender?.id),
          street_address: customerData.address as string,
          city: customerData.city ? String(customerData.city.id) : "" ,
          postal_code: customerData.postal_code as string,
          notes: customerData.notes as string,
          private_notes: customerData.private_notes as string,
          price_list: String(customerData.price_list.name),
          no_of_orders: Number(customerData.no_of_order),
          no_of_pieces_per_order: Number(customerData.no_of_pieces),
          customer_group: String(customerData.business_customer_group?.id)
        },

        b2bBusinessCustomerList: customerData.business_account ? [
          {
            "id": customerData.business_account.id.toString(),
            "type": "business_account_dropdown",
            "attributes": {
                "id": customerData.business_account.id,
                "business_customer": customerData.business_account.business_customer,
                "contract_person": ""
            }
        }
        ] : []
      },()=>{
        this.getB2BCustomerGroupDropDownApi(String(customerData.company.id) )
        this.handleFetchBusinessAccountDetails(String(customerData.business_account_id))
        this.setState({buttonLoading:false})
      })
  };

  getPhoneSplit = (phone: string) => {
    if(phone){
      const prefix =  phone.slice(0, 3);
      const phoneno = phone.slice(3);
      return {prefix,phoneno}
    }
  }

  getModalDropDownApiCall = () => {
    this.getB2BCompanyDropDownApi()
    this.getB2BPriceListDropDownApi()
    this.getCityList()
    this.getGenderDropdownAPI()
  }

 

  getB2BCustomerGroupDropDownApi = (id: string) => {
    let headers = {
        token: this.authToken,
        "Content-type": "application/json", 
    }

    const url = configJSON.B2BCustomerGroupDropdownAPIEndPoint + 
                "&company_id=" + id

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCustomerGroupDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BCompanyDropDownApi = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    const url = configJSON.B2BCompanyDropdownAPIEndPoint

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCompanyDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BBusinessCustomerDropDownApi = (companyId: string | number) => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

   const url = configJSON.B2BBusinessCustomerDropdownAPIEndPoint + `&company_id=${companyId}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getBusinessCustDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getGenderDropdownAPI() {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Gender`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getGenderDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BPriceListDropDownApi = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    const url = configJSON.B2BPriceListDropdownAPIEndPoint

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }


  getCityList() {    
    let headers = {
        token: this.authToken,
        "Content-type": "application/json", 
    }
    
    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getCityListApiEndPoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCityDropdownOptionsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 


  getDropdownApiCallResponse = (apiRequestCallId: string, responseJson: { data: DropdownApiResponseData, status: number, message?: string, errors?: { message: string } }) => {
    if (apiRequestCallId === this.getCompanyDropdownApiCallId) {
      this.getModalCompanyDropdownAPiRes(responseJson)
    } else if (apiRequestCallId === this.getPriceListDropdownApiCallId){
      if(responseJson?.status == 500) {
        this.setState({
          errorMessage: "Internal server error",
          errorSnackbarOpen: true,
          snakcbarSeverity: 'error'
        })
      } 
      else {
        if (this.checkGetResponse(responseJson) && !responseJson.message) {
            this.setState({ b2bPriceList: responseJson.data as IB2BPriceListDropDown[] });
        }
      }
    } else if(apiRequestCallId === this.getCustomerGroupDropdownApiCallId) {
      this.getCustomerGroupDropdownApiCallResponse(apiRequestCallId, responseJson)
    } else if(apiRequestCallId === this.getBusinessCustDropdownOptionsApiCallId) {
      this.getModalBusinessCustApiCallResponse(apiRequestCallId, responseJson)
    }

  }

  
  getModalGenderDropdownOptionApiCallResponse(apiRequestCallId: string,responseJson:{data:IGenderDropDownItem[] ,status: number, message?: string, errors?: { message: string }}) {
    if(apiRequestCallId === this.getGenderDropdownOptionsApiCallId){ 
      if (this.checkGetResponse(responseJson)) {
          this.setState(() => ({
            dropdownOptions: {
              ...this.state.dropdownOptions,
              genderList: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
            }
          }));
      }
    }
  }

  getModalCompanyDropdownAPiRes = (responseJson: { data: IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) => {
    if(responseJson?.status == 500) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: "Internal server error",
        snakcbarSeverity: 'error'
      })
    } 
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
          this.setState({ b2bCompanyList: responseJson.data as IB2BCompanyDropDown[] });
      }
    }
  }


  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getCustomerGroupDropdownApiCallResponse(apiRequestCallId: string, responseJson: { data: IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if(responseJson?.status == 500) {
      this.setState({
        errorMessage: "Internal server error",
        snakcbarSeverity: 'error',
        errorSnackbarOpen: true,
      })
    } 
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
          this.setState({ b2bCustomerGroupList: responseJson.data as IB2BCustomerGroupDropdown[] });
      }
    }
  }
  
  checkError(error: boolean, touch: boolean) {
    if (error && touch) {
      return true
    } else {
      return false
    }
  }

  getModalBusinessCustApiCallResponse (apiRequestCallId: string, responseJson: { data:IBusinessCustomerDropDown[] | IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if(responseJson?.status == 500) {
      this.setState({
        errorMessage: "Internal server error",
        errorSnackbarOpen: true,
        snakcbarSeverity: 'error'
      })
    } 

     else {
          if (this.checkGetResponse(responseJson) && !responseJson.message) {
            const businessCustomerList = (responseJson.data || []) as IBusinessCustomerDropDown[]
              this.setState((prev) => ({ b2bBusinessCustomerList:  uniqBy(concat(prev.b2bBusinessCustomerList, businessCustomerList), "id")}));
          }}
  }


  getSelectedPricelistForAdd = () => {
    const { selectedPricelist } = this.state;
    if (selectedPricelist) {
      return selectedPricelist.id;
    } else {
      return "";
    }
  };
 

  handleSubmit = (values:ICustomerInitialValues) => {
    const { customerData } = this.props;
    const customerId=customerData?.id
    const countryCode = toString(values.mobile_number_prefix).replace(/\D+/g, '')
    this.setState({isLoading:true})
    let headers = {
      token: this.authToken,
      "Content-type": "application/json", 
    }

    let modalUrl = configJSON.B2BCustomerAPIEndpoint
    let modalMethodVal = configJSON.exampleAPiMethod
    if (this.props.isEdit) {
      modalUrl = configJSON.B2BCustomerAPIEndpoint + "/" + customerId
      modalMethodVal = configJSON.sellerDetailsAPIMethodPUT
    }
    const modalBody = {
      data: {
        "b2b_price_list_id": this.getSelectedPricelistForAdd(), 
        "company_id": values.company, 
        city_id :values.city,
        "business_account_id": values.business_customer_name, 
        gender_id: values.gender,
        name: values.name,
        full_phone_number: values.mobile_number_prefix + values.mobile_number,
        email: values.email,
        business_customer_group_id : values.customer_group && values.customer_group !== "undefined" ? values.customer_group : "",
        department_name:  values.department_name,
        "cost_center": values.cost_center, 
        "sub_cost_center": values.subcost_center, 
        "department_id": values.department_id,
        "postal_code": values.postal_code, 
        "notes": values.notes, 
        "private_notes":  values.private_notes, 
        "address": values.street_address , 
        "employee_id": values.employee_id,
        "activated": true, 
        "no_of_order": this.handleCheckNullVal(this.state.noOfOrdrers),
        "no_of_pieces": this.handleCheckNullVal(this.state.noOfPiecesOrders),
      }
    }
    this.setState({ lastCreatedCustomer: {full_name:modalBody.data.name, customer_id:customerId,full_phone_number:modalBody.data.full_phone_number,country_code:countryCode} });

    const getAccount = apiCall({
      header: headers,
      httpBody: modalBody,
      url: modalUrl,
      httpMethod: modalMethodVal,
    });

    this.addB2BCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleModalGenderSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedGender: value
     },() => setFieldValue("gender", value.id))
  }

  handleSetStateCallback=(responseJson:ApiResponse)=>
  {
    setTimeout(() => {
      this.setState({isLoading:false})
      if(this.props.open){
        this.handleSetCustomer(responseJson)
      }else{
        this.handleRedirect()
      }
      if (this.props.handleGetCustomerPhone) {
        this.state.lastCreatedCustomer.full_phone_number = this.state.lastCreatedCustomer.full_phone_number.replace(
          "+",
          ""
        );
        this.props.handleGetCustomerPhone({
          customer_id: responseJson.customer.data.id,
          ...this.state.lastCreatedCustomer
        });
      }
    }, 1000);
  }


  addB2bCustomerApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.addB2BCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          snackbarMessage: this.props.isEdit ? "Customer Updated Successfully." : "Customer Created Successfully.",
          snakcbarSeverity:"success", 
          snackbarOpen: true
         }, () => {
            this.handleSetStateCallback(responseJson)
        });
      } else {
        this.setState({
          isLoading: false,
          snakcbarSeverity:"error", 
          snackbarOpen: true, 
          snackbarMessage: responseJson && responseJson.errors.full_messages[0] 
        })
      }
    }
  }

  handleCustomerGroupSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedCustGroup: value,
      selectedPricelist: null
     },() => setFieldValue("customer_group", value.id))
     this.updateDataOnGroupSelection(value.id)
  }

  updateDataOnGroupSelection=(groupId:string)=>{
    this.state.selectedBusinessAccountData?.attributes.account_group_configurations.data.filter((value)=>{
      if(value.attributes.business_customer_group.id.toString()==groupId)
      {
        this.compareAndSetValue(value,'group')
      }
    })
}

compareAndSetValue(value:BusinessAccountGroupConfigure, groupType:string)
{ 
   if(value.attributes.configuration_type==groupType)
  {
    if(groupType=='group' && (!value.attributes.remaining_no_of_order || !value.attributes.total_no_of_pieces))
      {
        this.updateDataOnBusinessCustomerSelection('contract')
        return
      }
    this.setState({selectedPricelist:value.attributes.price_list,
      noOfOrdrers:value.attributes.remaining_no_of_order,
      noOfPiecesOrders:value.attributes.total_no_of_pieces === 0 ? null : value.attributes.total_no_of_pieces
    })
  }
}

  handleBusinessCustomerSelection = (
    value: { id: string; option: string },
    setFieldValue: (field: string, fieldValue: string) => void
  ) => {
    this.setState(
      {
        selectedPricelist: null,
        selectedBusinessCustomer: value,
      },
      () => {
        setFieldValue("customer_group", "");
        setFieldValue("business_customer_name", value.id);
        this.handleFetchBusinessAccountDetails(value.id);
        setTimeout(() => {
          if (this.state.selectedBusinessAccountData) {
            setFieldValue('cost_center', Array.isArray(this.state.selectedBusinessAccountData.attributes.cost_center) ? this.state.selectedBusinessAccountData.attributes.cost_center.join() : (this.state.selectedBusinessAccountData.attributes.cost_center || this.state.customerInitialValues.cost_center))
            setFieldValue('subcost_center', Array.isArray(this.state.selectedBusinessAccountData.attributes.sub_cost_center) ? this.state.selectedBusinessAccountData.attributes.sub_cost_center.join() : (this.state.selectedBusinessAccountData.attributes.sub_cost_center || this.state.customerInitialValues.subcost_center))
          }
        }, 500);
      }
    );
  };

  handlePriceListSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedPriceListVal: value
     },() => setFieldValue("price_list", value.id))
  }

  handleComapnySelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    setFieldValue("company", value.id)
    this.setState({
      selectedCompany: value,
      selectedBusinessCustomer: null,
      selectedBusinessAccountData: null,
      selectedPricelist: null,
      b2bBusinessCustomerList: []
     },() => {
      setFieldValue("company", value.id)
      setFieldValue("customer_group", "")
      setFieldValue("business_customer_name","")
      this.getB2BBusinessCustomerDropDownApi(value.id)
      this.getB2BCustomerGroupDropDownApi(value.id)
  })
  }


  handleRedirect = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BCustomerList",
    })
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  handleCloseModal = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }  
  }

  handleSetCustomer = (responseJson:any) => {
    this.handleCloseModal()
  }

  handleModalCitySelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedCity: value
     },() => setFieldValue("city", value.id))
  }

  getModalCityDropdownApiCallRes(apiRequestCallId: string,responseJson:{cities: ICityDropDown[] ,status: number, message?: string, errors?: { message: string }}) {
    if(apiRequestCallId === this.getCityDropdownOptionsApiCallId){
      if (this.checkGetResponse((responseJson as {status: number, message?: string, errors?: { message: string }}))) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            cityList: responseJson.cities.map((item: any) => { return { id: item.id, option: item.name } })
          }
       }));
     }
    }
  }

  handleFetchBusinessAccountDetails = (accountId: string) => {
    const ApiUrl = configJSON.B2BBusinessAccountsAPIEndPoint + "/" + accountId;
    let requestMessage = makeApiMessage({
      url: ApiUrl,
      method: "GET",
    });
    this.getBusinessCustomerDetailAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckNullVal = (value: number | null) => {
    if(value || value == 0) {
      return value;
    } else {
      return "";
    }
  }

 

  gerBusinessAccountDetailsApiResponse = (
    apiRequestCallId1: string,
    responseJson: { data: BusinessAccountDetail }
  ) => {
    if (apiRequestCallId1 === this.getBusinessCustomerDetailAPICallId) {
      const priceList = responseJson.data.attributes.price_list;
      const { editId, selectedPricelist } = this.state;
      this.setState({
        cost_center: (responseJson.data.attributes.cost_center as unknown as string[]).map((costCenter) => ({id: costCenter, option: costCenter})),
        subcost_center: (responseJson.data.attributes.sub_cost_center as unknown as string[]).map((subcostCenter) => ({id: subcostCenter, option: subcostCenter})),
        noOfOrdrers: responseJson.data.attributes.no_of_items_per_order,
        selectedBusinessAccountData: responseJson.data,
        selectedPricelist: editId && selectedPricelist?.id ? selectedPricelist : priceList,
        noOfPiecesOrders: responseJson.data.attributes.no_of_pieces_per_order,
      });
    }
    this.updateDataOnBusinessCustomerSelection('normal')
  };

  updateDataOnBusinessCustomerSelection = (groupType:string)=>{
    this.state.selectedBusinessAccountData?.attributes.account_group_configurations.data.filter((value)=>{
       this.compareAndSetValue(value,groupType)
      })
  }

  getGenderDropAPI() {
    let headers = {
      token: this.authToken,
      "Content-type": "application/json"
    };
    const getAccount = apiCall({
      header: headers,
      url: `${configJSON.getDropdownOptionApiEndPoint}?module_name=Email`,
      httpMethod: configJSON.validationApiMethodType,
      httpBody: {},
    });

this.getGenderDropdownOptionsApiCallId2 = getAccount.messageId;
runEngine.sendMessage(getAccount.id, getAccount);
  }

  getNewOrderListApi = (accountId: string) => {
    const ApiUrl = configJSON.B2BBusinessAccountsAPIEndPoint + "/" + accountId;
    let requestMessage = makeApiMessage({
      url: ApiUrl,
      method: "GET",
    });
    this.getBusinessCustomerDetailAPICallId2 = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}