import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import lodash from "lodash";
import { getStorageData } from "framework/src/Utilities";
import { IUserContext, ReceiptPrinterSetting } from "../../../blocks/navigationmenu/src/HeaderController.web";
import {
  CustomEnums,
  getCustomEnumName,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  sortCondition,
  conditionalString
} from "../../utilities/src/CustomBlockHelpers";
import { makeApiMessage } from "../../../components/src/common";
import {
  ICustomerDataWithPreference,
  IPreferenceItem,
} from "../../../blocks/ProductDescription/src/CustomerPreferenceViewController.web";
import { ICartProductItem, UpchargeIds, UpchargeMainList, PaymentMethodType, EditSpecificationType } from "./utils";
import { IMyUser, PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import {
  CartsItemsPreferencesData,
  CatalogueList,
  CreatePreferencesParams,
  CustomerDataType,
  CustomerSpecification,
  IOrderSpecification,
  IlaundryOrder,
  IlaundryOrderItem,
  IpreferenceDataList,
  Service,
  UpdateOrderBody,
} from "../../../components/src/Interface";
import {
  SelectedPreferenceData
} from "../../../blocks/ProductDescription/src/EditPreferenceController";

export const ORDER_TYPES = {
  STORE_ORDER: "storeOrders",
  PLANT_ORDER: "plantOrders",
  CLEANING_ORDER: "cleaningOrders",
};

export const Actions = {
  ADD_QUANTITY: "add_quantity",
  CHANGE_NOTES: "change_note",
  CLEAR_CUSTOMER: "clear_customer",
  REMOVE_QUANTITY: "remove_quantity",
  CHANGE_MAIN_TAB: "change_main_tab",
  SUMMARY_NEW_ORDER: "summary_new_order",
  CHANGE_ORDER_NOTES: "change_order_notes",
  CHANGE_COUNTRY_CODE: "change_country_code",
  CHANGE_CUSTOMER_PHONE: "change_customer_phone",
  OPEN_ADD_QUANTITY_POPUP: "open_add_quantity_popup",
  CLOSE_ADD_QUANTITY_POPUP: "close_add_quantity_popup",
};

export interface IPhoneValue {
  id: number;
  option: string;
  full_phone_number: string;
  country_code: number;
}

export interface IFormValues {
  id?: number;
  notes?: string;
  width?: string;
  height?: string;
  weight?: string;
  quantity: number;
  measurement_type?: string[];
  catalogue_id?: number | string;
  isNew: boolean;
  [field: string]: string | number | boolean | undefined | string[];
}

export interface ICatalogueTypes {
  data: {
    id: string;
    attributes: {
      id: number;
      name: string;
      price: string;
      second_name: string;
      name_translation: string;
      home_cleaning_catalogue_id: number;
    };
  };
}

export interface IPlantProduct {
  id: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    active: boolean;
    product_name: string;
    measurement_type: string[];
    product_second_name: string;
    home_cleaning_catalogue_types: Array<ICatalogueTypes>;
  };
}

export interface ISnackbar {
  open: boolean;
  message: string;
}
export const ProductType = {
  NORMAL: "noraml",
  PARENT: "Parent",
  SUBPRODUCT: "Sub-Product",
};
export interface IService {
  id: string;
  attributes: {
    id: number;
    name: string;
    second_name: string;
    short_name: string;
    icon_id: number;
    active: boolean;
    online_order: boolean;
    order_number: number;
    icon: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          image: string;
          name: string;
          second_name: string;
          image_type: string;
        };
      };
    };
  };
}

interface ISpecification {
  id: number;
  name: string;
  options_attributes: {
    id: number;
    label: string;
    selected: boolean;
  }[];
}

interface IOrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    quantity: number;
    no_of_tags: number;
    no_of_pieces: number;
    unit_price: string;
    notes: string;
    total_price: string;
    catalogue_id: number;
    catalogue_variant_id: number;
    upcharge_price: string;
    preference_id: null | string;
    service_id: number;
    parent_catalogue_id: null | string;
    specifications: null | string;
    category_id: number;
    tag_numbers: string[];
    order_statuses: {
      order_number: string;
      placed_at: null | string;
      confirmed_at: null | string;
      in_transit_at: null | string;
      delivered_at: null | string;
      cancelled_at: null | string;
      refunded_at: null | string;
    };
    catalogue: {
      id: number;
      product_name: string;
      product_second_name: string;
      name: string;
    };
    product_image: string;
    preference: null | string;
    service: IService;
    upcharge_list_ids: Array<UpchargeIds>;
  };
}

export interface IOrder {
  id: number;
  order_number: string;
  amount: null | string;
  account_id: number;
  coupon_code_id: null | number;
  delivery_address_id: null | number;
  sub_total: string;
  total_upcharge_amount: string;
  customer: {
    id: number;
    full_phone_number: string;
    email: string;
    full_name: string;
  };
  total: string;
  status: string;
  edit_order?: boolean;
  custom_label: null | string;
  applied_discount: string;
  cancellation_reason: null | string;
  order_date: null | string;
  is_gift: boolean;
  placed_at: null | string;
  confirmed_at: null | string;
  in_transit_at: null | string;
  delivered_at: null | string;
  cancelled_at: null | string;
  refunded_at: null | string;
  source: null | string;
  shipment_id: null | string;
  delivery_charges: null | string;
  tracking_url: null | string;
  schedule_time: null | string;
  payment_failed_at: null | string;
  payment_pending_at: null | string;
  returned_at: null | string;
  tax_charges: string;
  store_name: string;
  deliver_by: null | string;
  tracking_number: null | string;
  is_error: boolean;
  delivery_error_message: null | string;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: null | string;
  shipping_discount: null | string;
  shipping_net_amt: null | string;
  shipping_total: null | string;
  total_tax: string;
  no_of_items: number;
  no_of_pieces: number;
  created_at: string;
  updated_at: string;
  delivery_addresses: [] | null;
  razorpay_order_id: null | string;
  charged: null | string;
  invoice_id: null | string;
  invoiced: null | string;
  save_for_future: boolean;
  notes: string;
  is_quick_drop?: boolean;
  promo_code_id: null | string;
  tax_percentage: string;
  order_items: IOrderItem[];
  service_charge?: string;
  order_transactions: {
    id: string;
    attributes: {
      id: number;
      account_id: number;
      order_management_order_id: number;
      amount: string;
      payment_method: {
        id: number;
      };
    };
  }[];
  paid_at: string | null;
  order_created_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
  currency: string;
}
interface ClickedProductInfo {
  price: string;
  image: string;
  productType: string;
  tabsId: undefined;
  parentProductId: string;
  title: string;
  catalogue_id: number;
  catalogue_variant_id: string;
}
export interface IProduct {
  id: string;
  attributes: {
    id: number;
    name: string;
    price_list: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      product_currency_id: string;
    };
    pieces: null;
    price: string;
    active: boolean;
    created_at: string;
    updated_at: string;
    catalogue: {
      id: string;
      attributes: {
        id: number;
        product_name: string;
        name: string;
      };
    };
    image: {
      image: string;
    };
    measurement: {
      id: number;
      value: string;
    };
    product_currency_id: string;
  };
}
export interface Store {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
  };
}
export interface ICustomer {
  id: string;
  attributes: {
    full_name: string;
    activated: boolean;
    email: string;
    date_of_birth: string;
    phone_number: number;
    country_code: number;
    customer_profile?: {
      data: {
        id: string;
        type: string;
        attributes: {
          street_address: null | string;
          city: null | string;
          employee_id: string;
          customer_id: string;
          post_code: null | string;
          business_id: null | string;
          price_list: {};
          notes: null | string;
          gender_id: null | string;
          private_note: null | string;
          customer_type: string;
          profession: {
            data: null;
          };
          organisation_tax: {
            data: null;
          };
          payment: {
            id: null;
            name: null;
          };
          organization: {
            data: null;
          };
        };
      };
    };
    customer_preferences_attributes: IPreferenceItem[];
    order: IOrder;
    saved_notes: null;
  };
}

interface PreferenceData {
  id: string;
  type: string;
  attributes: {
    specifications: never[];
    id: number;
    icon_id: number;
    preference_first_name: string;
    preference_second_name: string;
    active: boolean;
    gallery_icon: {
      id: number;
      image: string;
      name_translation: string;
    };
    name: string;
  };
}
export interface SubProductWithId {
  open: boolean;
  catalogueId: number;
}

interface Meta {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number | undefined;
  pervious_page: number | undefined;
}
interface ParametersType {
  productSecondName: string | undefined;
  serviceName: string;
  serviceShortName: string | undefined;
  parentProductId: number;
  catalogue_variant_id: number;
  tabsId: number;
  serviceId: number;
  productType: string;
  catalogue_id: number;
}

export interface ICleaningOrderItem {
  id: string;
  attributes: {
    id: number;
    width: string;
    notes: string;
    height: string;
    quantity: number;
    currency: string;
    unit_price: string;
    total_price: string;
    weight: string | null;
    home_cleaning_order_id: number;
    home_cleaning_catalogue_id: number;
    home_cleaning_catalogue_type_id: number;
    catalogue: {
      id: number;
      name: string;
      second_name: string;
      product_image: string;
      name_translation: string;
      measurement_type: Array<string>;
    };
    product_type: {
      id: number;
      name: string;
      price: string;
      second_name: string;
      name_translation: string;
    };
  };
}
export interface ICleaningOrder {
  id: number;
  paid_at: null;
  total: string;
  address_id: null;
  is_mobile: false;
  sub_total: string;
  status: "in_cart" | "placed";
  total_tax: string;
  account_id: number;
  order_number: string;
  notes: string | null;
  shipping_charge: null;
  refund_amount: string;
  service_charge: string;
  applied_discount: string;
  cancellation_reason: null;
  store_management_id: number;
  confirmed_at: null | string;
  min_non_refundable_amount: string;
  order_items: Array<ICleaningOrderItem>;
  order_type?: "PlantCleaningOrder";
  order_transactions: {
    id: number;
    name: string;
    amount: string;
    active: boolean;
    created_at: string;
    second_name: string;
    name_translation: string;
  }[];
  order_created_by: {
    id: number;
    last_name: string;
    full_name: string;
    first_name: string;
  };
  currency: string;
  store_name: string;
  no_of_items: number;
  no_of_pieces: number;
  tax_percentage: string;
  store_management: {
    data: {
      id: string;
      attributes: {
        id: number;
        store_name: string;
      };
    };
  };
  promo_code: null;
}

export interface IPaymentOption {
  id: number;
  name: string;
  active: boolean;
}

export interface ICartProduct {
  catalogue_id: number;
  catalogue_variant_id: number;
  title: string;
  price: string | number;
  image: string;
  quantity: number;
  serviceName: string;
  serviceId: number | string;
  parentProductId: null | string | number;
  tabsId: number;
  productSecondName?: string;
  serviceShortName?: string;
  upcharge_price: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  tabsId: number;
  cartTotalOpen: boolean;
  paymentOptions: IPaymentOption[];
  paymentMethod: string;
  subProduct: { open: boolean } | SubProductWithId;
  cataloguesList: CatalogueList[];
  catalogueListEmptyFlag: boolean;
  cataloguesListMeta: Meta;
  userData: IMyUser | undefined;
  userRefresh: () => void;
  storeId: number;
  storeList: { id: string; option: string }[];
  cartProducts: ICartProductItem[];
  beforePopupCartProducts: ICartProductItem[];
  addCustomerPopup: boolean;
  addProductPopup: boolean;
  viewCustomerPopup: boolean;
  isLoading: boolean;
  mobileNumberError: string;
  customerData?: ICustomer;
  countryCodeInput: string;
  customerId: null | string;
  previousPhoneNumber: string;
  previousCustomer?: ICustomer;
  subtotalOrder?: { attributes: IOrder | ICleaningOrder };
  orderSummaryVisible?: boolean;
  clearPhoneNumber?: boolean;
  isEdit: boolean | null;
  productPreferenceOpen: boolean;
  saveForFuture: boolean;
  orderNotes: string;
  snackbarOpen: boolean;
  paymentModalVisible: boolean;
  orderTryAgainModalVisible: boolean;
  sameProductDifferentServiceModalVisible: boolean;
  clickedProductIndex: number;
  errorSnackbarOpen: boolean;
  snackbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  preferenceModalVisible: boolean;
  clickedProduct: ClickedProductInfo;
  customerPhone: string;
  preferenceData: PreferenceData;
  successSnackBarMessage: string;
  order?: { attributes: IOrder };
  cartItemHoverId: {
    catalogue_id: number | null;
    tabsId: number | null;
    serviceName: string | null;
  };
  customerDataWithPreference: ICustomerDataWithPreference | undefined;
  customersList: unknown[];
  clickedProductInfo: ClickedProductInfo;
  itemsAddedToCartForPreference: {
    catalogue_variant_id: number;
    preferenceId: number;
    serviceId: number;
  }[];
  isQuickDrop: boolean;
  quickDropCount: number;
  currentPage: number;
  editId?: boolean;
  temporaryProducts: CatalogueList[];
  paymentClicked: boolean;
  quickDropReceivedCount: number;
  storeListPageNo: number;
  storeSearchText: string;

  enableHomeCleaningSettings: boolean;
  selectedMainTab: string;
  productPopup: boolean;
  plantProducts: Array<IPlantProduct | never>;
  carpetFurnitureProductPopupData: CatalogueList | null;
  cleaningOrder?: null | ICleaningOrder;
  pickupDate: Date | null;
  UpchargeMainList: UpchargeMainList[];
  searchText: string;
  isCatalogueCardLoading: boolean;
  subProductModalOpen: boolean;
  clickedParentCatalougeId: number | null;
  selectedCustomer: CustomerDataType | null;
  editSelectedCustomer: { id: number;
    fullPhoneNumber: string;
    email: string;
    fullName: string;
    business?: string | null;
    fullAddress: string
  } | null;
  preferenceDataList: IpreferenceDataList | null;
  selectedService: {
    name: string;
    id: number;
  } | null;
  laundryOrderItems: Array<IlaundryOrderItem>;
  laundryOrder: IlaundryOrder | null;
  idQuickDropUpdatApiCall: boolean;
  cartsItemsPreferencesData: Array<CartsItemsPreferencesData>;
  clickedProductCardInfo: ICartProductItem | null;
  isIncreaseBtnClicked: boolean;
  selectedCatalogueData: CatalogueList | null;
  notes: string;
  isPopuDisabledWithOrderCreate: boolean;
  isEditPreferencesPopupOpen: boolean;
  permissionStatus: PermissionStatus;
  userCustomerPriceList: boolean;
  userCustomerPriceListId: number | null
  highlightedSectionIds: number[];
  printerSetting: ReceiptPrinterSetting | null;
  isClearCartActionCall: boolean;
  isB2bActive: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number | string;
  // Customizable Area End
}

export default class OrderCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  getCatalogueListApiId: string = "";
  getTemporaryProductListApiId: string = "";
  getProductPreferencesApiId: string = "";
  updateEmployeeCurrentStoreApiId: string = "";
  createNewOrderApiId: string = "";
  getOrderApiId: string = "";
  createPreferenceApiId: string = "";
  getStoreListApiId: string = "";
  getCustomerListApiId: string = "";
  searchCustomerApiCallId: string = "";
  orderNotesTimeout: NodeJS.Timeout | string = "";
  searchCustomerApiMessageId: string = "";
  plantProductListMessageId: string = "";
  homeCleaningOrderMessageId: string = "";
  mobileNumberInput: string = "";
  orderTimeout: string | number | NodeJS.Timeout | undefined = undefined;
  storeListEmptyFlag: boolean = false;
  getPreferencesDataApiCallId: string = "";
  updateOrderApiCallID: string = "";
  createOrderApiCallID: string = "";
  updatePreferencesApicallId: string = "";
  getPaymentMethodsApiCallId: string = "";

  shouldUseCommonUpchargesPreference = false
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      idQuickDropUpdatApiCall:false,
      isQuickDrop: false,
      quickDropCount: 0,
      previousPhoneNumber: "",
      paymentMethod: "",
      subProduct: { open: false },
      cataloguesList: [],
      catalogueListEmptyFlag: false,
      cataloguesListMeta: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: undefined,
        pervious_page: undefined,
      },
      userData: {} as IMyUser,
      userRefresh: () => {},
      storeId: -1,
      storeList: [],
      cartProducts: [],
      beforePopupCartProducts: [],
      addCustomerPopup: false,
      addProductPopup: false,
      viewCustomerPopup: false,
      isLoading: false,
      mobileNumberError: "",
      countryCodeInput: "+966",
      customerId: null,
      isEdit: false,
      productPreferenceOpen: false,
      saveForFuture: false,
      paymentModalVisible: false,
      orderTryAgainModalVisible: false,
      sameProductDifferentServiceModalVisible: false,
      clickedProductIndex: -1,
      snackbarOpen: false,
      orderNotes: "",
      snackbarSeverity: 'warning',
      errorSnackbarOpen: false,
      preferenceModalVisible: false,
      errorMessage: "",
      clickedProduct: {
        price: "",
        image: "",
        productType: "",
        tabsId: undefined,
        parentProductId: "",
        title: "",
        catalogue_id: 0,
        catalogue_variant_id: "",
      },
      customerPhone: "",
      preferenceData: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          icon_id: 0,
          preference_first_name: "",
          preference_second_name: "",
          active: false,
          gallery_icon: {
            id: 0,
            image: "",
            name_translation: "",
          },
          name: "",
          specifications: [],
        },
      },
      successSnackBarMessage: "",
      cartItemHoverId: {
        catalogue_id: null,
        tabsId: null,
        serviceName: null,
      },
      customerDataWithPreference: undefined,
      customersList: [],
      clickedProductInfo: {
        catalogue_variant_id: "",
        price: "",
        image: "",
        productType: "",
        tabsId: undefined,
        parentProductId: "",
        title: "",
        catalogue_id: 0,
      },
      itemsAddedToCartForPreference: [],
      currentPage: 1,
      temporaryProducts: [],
      paymentClicked: false,
      tabsId: 0,
      cartTotalOpen: false,
      paymentOptions: [],
      quickDropReceivedCount: 0,
      storeListPageNo: 1,
      storeSearchText: "",
      selectedMainTab: ORDER_TYPES.STORE_ORDER,
      productPopup: false,
      plantProducts: [],
      carpetFurnitureProductPopupData: null,
      cleaningOrder: null,
      enableHomeCleaningSettings: false,
      pickupDate: null,
      UpchargeMainList: [],
      searchText: "",
      isCatalogueCardLoading: false,
      subProductModalOpen: false,
      clickedParentCatalougeId: null,
      selectedCustomer: null,
      editSelectedCustomer:null,
      preferenceDataList: null,
      selectedService: null,
      laundryOrderItems: [],
      laundryOrder: null,
      cartsItemsPreferencesData: [],
      clickedProductCardInfo: null,
      isIncreaseBtnClicked: false,
      selectedCatalogueData: null,
      notes: "",
      isPopuDisabledWithOrderCreate: false,
      isEditPreferencesPopupOpen: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: true,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      userCustomerPriceList: false,
      userCustomerPriceListId: null,
      highlightedSectionIds: [],
      printerSetting: null,
      isClearCartActionCall: false,
      isB2bActive: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message);
    } else if (
      getCustomEnumName(CustomEnums.CustomActionReducers) === message.id
    ) {
      this.handleCustomReducer(message);
    }

    this.retrieveSearchTextData(message)
    this.extractDataFromLayout(message)
    this.receiveDataFromHeader(message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(!this.state.isB2bActive) this.handleGetPaymentAPI()
  }

  retrieveAccountTypeInfo = async() => {
    const isB2b = await getStorageData("accountType")

    if(isB2b === "b2b"){
      this.setState({isB2bActive: true})
    }else{
      this.setState({isB2bActive: false})
    }
  }

  retrieveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearch(recievedData.searchText)
        }
    }
  }

  extractDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleOnUserChange(recievedData.userContext)
        }
    }
  }

  receiveDataFromHeader = async(message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      let storeID = await getStorageData("store_id")
      if (recievedData.singleStoreID != storeID && recievedData.singleStoreID) {
        this.handleStoreChange();
        this.updateEmployeeCurrentStore(recievedData.singleStoreID);
      }
    }
  }
  
  handleCheckApiCallWithId = (apiRequestCallId: string) => {
    const excludedApiCalls = [
      this.getPaymentMethodsApiCallId,
      this.getCatalogueListApiId,
      this.getTemporaryProductListApiId,
      this.getStoreListApiId,
      this.updateEmployeeCurrentStoreApiId,
      this.createOrderApiCallID,
      this.updateOrderApiCallID,
      this.getPreferencesDataApiCallId,
    ];
  
    return !excludedApiCalls.includes(apiRequestCallId);
  }

  // API response handling start
  handleResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    // Prevent handling API calls from other components
    if (this.handleCheckApiCallWithId(apiRequestCallId)) return true;
  
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (!apiRequestCallId || !responseJson || responseJson?.errors || responseJson?.error) {
      this.handleErrorResponse(apiRequestCallId, responseJson, errorReponse);
      return;
    }
  
    switch (apiRequestCallId) {
      case this.getPaymentMethodsApiCallId:
        this.handlePaymentMethodsResponse(responseJson);
        break;
      case this.getCatalogueListApiId:
        this.handleCatalogueListResponse(responseJson);
        break;
      case this.getTemporaryProductListApiId:
        this.setState({
          cataloguesList: [
            ...responseJson.data,
            ...this.state.cataloguesList,
          ],
          temporaryProducts: responseJson.data,
        });
        break;
      case this.getStoreListApiId:
        this.handelStoreListResponse(responseJson);
        break;
      case this.updateEmployeeCurrentStoreApiId:
        localStorage.setItem("store_id",responseJson.data?.attributes.store_management_id);
        this.handleSendAction('CLEAR_CUSTOMER_CART',null)
        this.state.userRefresh();
        break;
      case this.createOrderApiCallID:
      case this.updateOrderApiCallID:
        this.handleOrderProcessing(responseJson);
        break;
      case this.getPreferencesDataApiCallId:
        this.processPreferencesData(responseJson);
        break;
    }
  };
  
  handleOrderProcessing = (responseJson: any) => {
    if (responseJson.status === 422) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: responseJson.message,
      });
    }
    this.handleOrderResponse(responseJson);
  };
  
  processPreferencesData = (responseJson: any) => {
    const updateBodyPayload = sortCondition(
      this.shouldUseCommonUpchargesPreference,
      this.getCommonUpchargesPrefence(
        this.state.laundryOrder,
        responseJson.data.attributes
      ),
      undefined
    ) as UpdateOrderBody | undefined;
  
    this.shouldUseCommonUpchargesPreference = false;
  
    this.setState(
      {
        preferenceDataList: responseJson.data,
        cartsItemsPreferencesData: [
          ...this.state.cartsItemsPreferencesData,
          {
            catalogue_variant_id: responseJson.data.attributes.catalogue_variant_id,
            customer_preference_id: responseJson.data.attributes.id,
            service_id: responseJson.data.attributes.service.id,
          },
        ],
      },
      () => this.handleDisanledPopupAPIcall(updateBodyPayload)
    );
  };
  
  handleErrorResponse = (apiRequestCallId: any, responseJson: any, errorResponse: any) => {
    this.orderTimeout = undefined;
  
    if (typeof responseJson?.errors !== "string") {
      this.setState({
        errorMessage: conditionalString(
          responseJson?.errors?.length,
          conditionalString(
            typeof responseJson.errors[0] === "string",
            responseJson.errors[0],
            lodash.toString(responseJson.errors[0]?.message)
          ),
          lodash.toString(responseJson.message)
        ),
        errorSnackbarOpen: true,
      });
    } else {
      this.setState(
        {
          isLoading: false,
          errorMessage: typeof responseJson?.errors === "string" && responseJson.status !== 500
            ? responseJson?.errors
            : "Internal server error",
          snackbarSeverity: "error",
        },
        () => this.setState({ errorSnackbarOpen: true })
      );
    }
  
    if(apiRequestCallId === this.updateOrderApiCallID || apiRequestCallId === this.createOrderApiCallID) {
      this.handleSendAction("SET_ORDER", this.state.laundryOrder);
      this.setState({
        cartProducts: this.state.cartProducts.filter((cartProduct) => cartProduct.id !== undefined),
        cartsItemsPreferencesData: []
      });
    }
  
    this.handleCatalogueListErrorResponse(apiRequestCallId,responseJson);
    this.handleSendAction("SHOPPING_CART_LOADING", false);
    this.setState({ isLoading: false });
  };

  handleCatalogueListErrorResponse = (apiRequestCallId: string,responseJson:any) => {
    if(apiRequestCallId === this.getCatalogueListApiId) {
      this.setState({cataloguesList: [],isCatalogueCardLoading: false})
    }
    if(apiRequestCallId === this.updateOrderApiCallID ){
      this.setState({
        errorSnackbarOpen:true,
        errorMessage:responseJson.message
      })
      if(responseJson.is_load){
        setTimeout(()=>{
          window.location.reload();
        },3000)
      }     
    }
  }

  handleOrderResponse(responseJson: { data: { attributes: IlaundryOrder } }) {
        this.setState({
      laundryOrder: responseJson.data.attributes,
      laundryOrderItems: responseJson.data.attributes.order_items,
      cartsItemsPreferencesData: responseJson.data.attributes.order_items.map(
        (item) => {
          return {
            customer_preference_id: item.attributes.customer_preference_id,
            catalogue_variant_id: item.attributes.catalogue_variant_id,
            service_id: item.attributes.service_id,
          };
        }
      ),
      isPopuDisabledWithOrderCreate: false,
      isLoading: false
    })
    this.handlePreCustomerOrder(responseJson.data.attributes.order_items);
    this.handleSendAction("SET_ORDER", responseJson.data.attributes);
    this.handleClearCartActionCalled();
    this.handleSendAction("SHOPPING_CART_LOADING", false);
    this.handleUpdateQuickDrop(responseJson.data.attributes.order_items)
  }

  handleUpdateQuickDrop = (orderItemArr: Array<IlaundryOrderItem>) => {
    if (this.state.idQuickDropUpdatApiCall) {
      return this.setState({idQuickDropUpdatApiCall : false})
    }

    if(this.state.editId && orderItemArr.length === 0){
      const { laundryOrder } = this.state;
      
      const customer_Id = this.state.isB2bActive ? 
                            {business_account_customer_id: (laundryOrder as IlaundryOrder).customer.id} : {customer_id: (laundryOrder as IlaundryOrder).account_id}
      const updateBody = {
        edit_order: this.state.editId ? true : false,
        data: {
         "status": "placed",
          ...customer_Id,
          is_quick_drop: true,
          store_management_id: laundryOrder?.store_management_id,
          order_items_attributes: []
        },
      };
  
      let message = makeApiMessage({
        url: this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
        body: JSON.stringify(updateBody),
        method: "PUT",
      });
      this.updateOrderApiCallID = message.messageId;
      runEngine.sendMessage(message.id, message);
      this.setState({idQuickDropUpdatApiCall : true})
    }
  }


  handleClearCartActionCalled = () => {
    const { isClearCartActionCall, editId } = this.state;
    if(isClearCartActionCall && !editId) {
      this.handleSendAction("CLEAR_CUSTOMER")
    };

    this.setState({ isClearCartActionCall: false });
  };

  handelStoreListResponse(responseJson: { data: Store[] }) {
    const { userData, storeListPageNo, storeList } = this.state;
    const { store_id, store_name } =
      userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management || {};
    let currentStore = storeList.find(
      (store) => store.id === lodash.toString(store_id)
    );
    if (store_id !== undefined && currentStore === undefined) {
      currentStore = {
        id: lodash.toString(store_id),
        option: lodash.toString(store_name),
      };
    }
    const array = (responseJson?.data || []).map((item) => ({
      id: item.id,
      option: item.attributes.store_name,
    }));
    const newStoreList =
      storeListPageNo === 1 ? array : [...storeList, ...array];
    currentStore && newStoreList.unshift(currentStore);
    this.storeListEmptyFlag = array.length < 10;
    this.setState({
      storeList: lodash.uniqBy(newStoreList, "id"),
    });
  }

  handleCatalogueListResponse = (responseJson: {
    data: CatalogueList[];
    meta: Meta;
  }) => {
    this.setState(
      (prevState) => ({
        cataloguesList:
          this.state.currentPage > 1
            ? [...prevState.cataloguesList, ...responseJson.data]
            : [...responseJson.data],
        catalogueListEmptyFlag: responseJson.data.length ? false : true,
        cataloguesListMeta: responseJson.meta,
        isCatalogueCardLoading: false,
        highlightedSectionIds: []
      }),
      () => {
        if(this.state.currentPage === 1 && this.state.editId) {
        this.handleGetTemporaryProductList();
        }

        if(this.state.searchText && this.state.cataloguesList?.length) {
         this.filterCatalogueReponseForGlobalSearchQuery();
        }
      }
    );
  };

  // api response handling end

  filterCatalogueReponseForGlobalSearchQuery = () => {
    const result: CatalogueList[] = JSON.parse(JSON.stringify(this.state.cataloguesList));

    // get unique section ids 
    let getSectionIdsFromCatalogueResponse:number[] = []
    result.forEach(element => {
      if(element.attributes.sections?.length) {
        const section_ids = element.attributes.sections.map(section => section.id);
  
        const combineArray = [...getSectionIdsFromCatalogueResponse, ...section_ids]
        getSectionIdsFromCatalogueResponse = [...new Set(combineArray)];
      }
    });
    
    this.setState({highlightedSectionIds: getSectionIdsFromCatalogueResponse });

    // if product is visisble but not present in that selected tab section then remove that product
    const productInSelectedCatalogue = result.filter((catalogueRow) => !catalogueRow.attributes.sections?.length || catalogueRow.attributes.sections.map((section) => section.id).includes(this.state.tabsId))
    this.setState({cataloguesList: productInSelectedCatalogue });
    console.log("")
    // redirect to other store
    if(getSectionIdsFromCatalogueResponse.length > 0 && !getSectionIdsFromCatalogueResponse.includes(this.state.tabsId)) {
      let highlightedId = getSectionIdsFromCatalogueResponse[0];
      this.redirectToSectionTab(highlightedId);
    }
  }

  redirectToSectionTab = (value: number) => {
    this.setState({ tabsId: value, cataloguesList: [] }, () => {
      this.getCatalogueList(1);
    });
  }

  // api calling start
  getStoreList = () => {
    const searchQuery = this.state.storeSearchText
      ? `&filter_by[query]=${this.state.storeSearchText}`
      : "";
    const apiUrl =
      configJSON.getStoreListApi +
      `?dropdown=true${searchQuery}&page_no=${this.state.storeListPageNo}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.storeListEmptyFlag = true;
    this.getStoreListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCatalogueList = (page: number = this.state.currentPage) => {

    if(this.state.isB2bActive) {
      this.getBussinessCatalogueList(page);
    } else{
    this.setState({ isCatalogueCardLoading: true });
    const { userCustomerPriceList, userCustomerPriceListId} = this.state;
    const store_management =
      this.state?.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes;
    const priceId = userCustomerPriceList
      ? userCustomerPriceListId
      : store_management?.price_list_id;

    const filterSearch = this.state.searchText
      ? `&filter_by[query]=${this.state.searchText}`
      : "";

    const sectionIds = (this.state.userData as IMyUser).attributes.employee_proifle.data.attributes.store_management?.attributes?.sections?.length ? (this.state.userData as IMyUser).attributes.employee_proifle.data.attributes.store_management.attributes.sections.map((section)=> section.id).join(","): "";
    const catagoryIdOrSectionIds = this.state.searchText ? `&section_ids=${sectionIds}` : `&category_id=${this.state.tabsId}`;
    if(!sectionIds) {
      this.setState({ isCatalogueCardLoading: false, cataloguesList: [] });
      return;
    }

    // on global search pass &section_ids key

    const apiUrl =
      configJSON.getCatalogueListDataApi +
      `?price_list_id=${priceId}${catagoryIdOrSectionIds}&per_page=100&page_no=${page}${filterSearch}&pos=true&allow_access=true`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.getCatalogueListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getBussinessCatalogueList = (page: number) => {
    if(!this.state.selectedCustomer?.id) {
      return;
    }
    this.setState({ isCatalogueCardLoading: true });
    const filterSearch = this.state.searchText
      ? `&filter_by[query]=${this.state.searchText}`
      : "";
    
    const storeId = this.state.userData?.attributes.employee_proifle.data.attributes.store_management_id;

    let apiUrl = ""
      
    apiUrl =
    configJSON.bussinessGetCatalogueListDataApi +
    `?id=${this.state.selectedCustomer?.id}&category_id=${this.state.tabsId}&store_id=${storeId}&per_page=100&page_no=${page}${filterSearch}&from_group=true&allow_access=true&pos=true`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.getCatalogueListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetTemporaryProductList = () => {
    const apiUrl =
      configJSON.getCatalogueListApi +
      `/get_store_products?store_id=${localStorage.getItem(
        "store_id"
      )}&customer_id=${this.state.selectedCustomer?.id}&category_id=${this.state.tabsId}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.getTemporaryProductListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateEmployeeCurrentStore = (storeId: number) => {
    const bodyData = {
      store_id: storeId,
    };

    const apiUrl = configJSON.updateEmployeeCurrentStoreApi;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypePut,
      body: JSON.stringify(bodyData),
    });

    this.updateEmployeeCurrentStoreApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // api calling end

  getOrderTypeList = () => {
    const { enableHomeCleaningSettings } = this.state;
    const Strings = configJSON.Strings;

    let orderTypes = [
      { label: Strings.plant_cleaning, value: ORDER_TYPES.STORE_ORDER },
      // { label: Strings.plant_tab, value: ORDER_TYPES.PLANT_ORDER }
    ];

    if (enableHomeCleaningSettings) {
      orderTypes.push({
        label: Strings.home_cleaning_tab,
        value: ORDER_TYPES.CLEANING_ORDER,
      });
    }

    return orderTypes;
  };

  getOrderRenderBasedOnOrderType = (
    trueValue: React.ReactNode,
    falseValue: React.ReactNode
  ) => {
    const condition = [
      ORDER_TYPES.CLEANING_ORDER,
      ORDER_TYPES.PLANT_ORDER,
    ].includes(this.state.selectedMainTab);
    return condition ? trueValue : falseValue;
  };

  tabSectionChangeHandler = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ tabsId: newValue, cataloguesList: [] }, () => {
      this.getCatalogueList(1);
    });
  };

  handleDisanledPopupAPIcall = (updateBody?: UpdateOrderBody) => {
    const { laundryOrder, isPopuDisabledWithOrderCreate} = this.state;
    if(isPopuDisabledWithOrderCreate) {
      if (laundryOrder?.id) {
        this.handleUpdateOrderAPI(updateBody || null);
      } else {
        this.handleCreateNewOrder(null);
      }
    }
  };

  resetForm = () => {};

  handleStoreChange = () => {
    this.handleResetCartCustomerPhone();
  };
  handleResetCartCustomerPhone = () => {
    this.setState({
      cartProducts: [],
      cartTotalOpen: false,
      customerId: null,
      customerData: undefined,
      orderNotes: "",
      saveForFuture: false,
      paymentMethod: "",
      tabsId: 0
    });
    this.resetForm();
  };
  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    if (this.storeListEmptyFlag) return;
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.3) {
      this.setState(
        (prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }),
        () => {
          this.getStoreList();
        }
      );
    }
  };

  handleCatalogueListScroll = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLElement;
    const position = target.scrollTop + target.clientHeight;
    if (
      !this.state.isCatalogueCardLoading &&
      target.scrollHeight - position <= 1.3 &&
      !this.state.catalogueListEmptyFlag
    ) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () =>
        this.getCatalogueList()
      );
    }
  };

  parentProductClickHandler = (catalogueId: number) => {
    this.setState({
      subProductModalOpen: true,
      clickedParentCatalougeId: catalogueId,
      beforePopupCartProducts: lodash.cloneDeep(this.state.cartProducts),
    });
  };

  handleCloseSubProductDialog = () => {
    this.setState((prevState) => ({
      subProductModalOpen: false,
      clickedParentCatalougeId: null,
      cartProducts: lodash.cloneDeep(prevState.beforePopupCartProducts),
      beforePopupCartProducts: [],
    }));
  };

  handleSubmitSubProductDialog = () => {
    this.setState({
      subProductModalOpen: false,
      clickedParentCatalougeId: null,
      beforePopupCartProducts: [],
    });
  };

  handleSameProductDifferentService = () => {
    this.setState({ sameProductDifferentServiceModalVisible: true });
  };

  sameProductDiffServiceModalClose = () => {
    // if product measurement is SQM and weight type then display carpet and funiture popup again don't increase quantity
    const carpetFurnitureMeasurementTypes = ["SQM", "Weight"];
    let isCarpetFurnitureProductType = this.state.selectedCatalogueData?.attributes.catalogue_variants.data[0].attributes?.measurement_type?.some(type => carpetFurnitureMeasurementTypes.includes(type) );
    
    if(isCarpetFurnitureProductType) {
      this.setState({
        productPopup: true,
        carpetFurnitureProductPopupData: this.state.selectedCatalogueData,
        sameProductDifferentServiceModalVisible: false,
        clickedProductCardInfo: null,
      });
      return;
    }

    const { cartProducts, clickedProductCardInfo, tabsId } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedProductIndex = tempCartProduct.findIndex(
      (item) =>
        item.catalogue_id === clickedProductCardInfo?.catalogue_id &&
        item.category_id === tabsId &&
        item.service_id === clickedProductCardInfo.service_id
    );
    tempCartProduct[selectedProductIndex].quantity =
      tempCartProduct[selectedProductIndex].quantity + 1;
      this.setState({
        sameProductDifferentServiceModalVisible: false,
        clickedProductCardInfo: null,
        cartProducts: tempCartProduct,
      });
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
  };

  sameProductDiffServiceConfirmClick = () => {
    this.setState({
      sameProductDifferentServiceModalVisible: false,
      isIncreaseBtnClicked: true,
    });
    // on increase clicked add item to cart to display qunatity as 1 
    const {clickedProductCardInfo, cartProducts} = this.state;
    const tempCartProduct = cartProducts;
    const catalogue = clickedProductCardInfo as ICartProductItem;
    const newProductInCart = {
      id: undefined,
      catalogue_id: catalogue.catalogue_id,
      catalogue_variant_id:
        catalogue.catalogue_variant_id,
      customer_preference_id: null,
      name: catalogue.name,
      preference_id: catalogue.preference_id,
      product_name: catalogue.product_name,
      product_second_name: catalogue.product_second_name,
      unit_price: 0,
      image:
        catalogue.image,
      quantity: 1,
      service_name: "",
      service_id: -1,
      parent_Product_id: this.state.clickedParentCatalougeId,
      category_id: this.state.tabsId,
      upcharge_list_ids: [],
    };
    tempCartProduct.push(newProductInCart);
    console.log("result=>before1",tempCartProduct)

    this.setState({
      cartProducts: tempCartProduct,
    });
  };

  addQuantityClickHandler = (params: { catalogue: CatalogueList }) => {
    const catalogue = params.catalogue;

    if (catalogue.attributes.product_type?.value === ProductType.PARENT) {
      this.parentProductClickHandler(catalogue.attributes.id);
      return;
    }

    const { tabsId, cartProducts } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId
    );

    const selectedLastProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId &&
       (this.state.selectedService?.id ? product.service_id === this.state.selectedService?.id : false)
    );

    const selectedLastProductIndexData = this.state.selectedService?.id ? selectedLastProductIndex : selectedProductIndex;
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: catalogue.attributes.id,
        catalogue_variant_id:
          catalogue.attributes.catalogue_variants?.data[0].attributes.id,
        customer_preference_id: null,
        name: catalogue.attributes.name,
        preference_id: catalogue.attributes.preference_id,
        product_name: catalogue.attributes.product_name,
        product_second_name: catalogue.attributes.product_second_name,
        unit_price: 0,
        image:
          catalogue.attributes.catalogue_variants.data[0].attributes.image?.image,
        quantity: 1,
        service_name: "",
        service_id: -1,
        parent_Product_id: this.state.clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
      };
      tempCartProduct.push(newProductInCart);
    } else {
      const activeServices =
        catalogue.attributes.catalogue_variants.data[0].attributes.catalogue_variants_services.filter(
          (item) => item.service.active
        );
      if (
        tempCartProduct[selectedProductIndex].service_id !== -1 &&
        activeServices.length > 1
      ) {
        this.setState({
          clickedProductCardInfo: tempCartProduct[selectedLastProductIndexData],
          selectedCatalogueData: catalogue
        });
        this.handleSameProductDifferentService();
        return;
      } else if (
        tempCartProduct[selectedProductIndex].service_id !== -1 &&
        activeServices.length == 1
      ) {
        tempCartProduct[selectedProductIndex].quantity =
          tempCartProduct[selectedProductIndex].quantity + 1;
        this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
        return;
      }
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
    }
    this.setState({
      cartProducts: tempCartProduct,
    });
  };

  removeQuantityClickHandler = (params: { catalogue: CatalogueList }) => {
    const catalogue = params.catalogue;

    if (catalogue.attributes.product_type?.value === ProductType.PARENT) {
      this.parentProductClickHandler(catalogue.attributes.id);
      return;
    }

    const { tabsId, cartProducts } = this.state;
    const tempCartProduct = [...cartProducts];
    let selectedProductData = this.getCatalogueDataBasedOnCondition(Number(catalogue.attributes.id));

    const selectedServiceId =
              selectedProductData?.service_id || undefined;
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId &&
        product.service_id === selectedServiceId
    );
    if (tempCartProduct[selectedProductIndex].quantity == 0) {
      return;
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity - 1;
      if (tempCartProduct[selectedProductIndex].service_id !== -1) {
        this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      }
      if (tempCartProduct[selectedProductIndex].quantity == 0) {
        tempCartProduct.splice(selectedProductIndex, 1);
      }
    }
    this.setState({
      cartProducts: tempCartProduct,
    });
  };

  handleCartOnCarpetAndFunrnitureProduct = async (carpetFurniturebodyParams: IFormValues[]) => {
    const { tabsId, cartProducts, selectedService } = this.state;
    const productPopupData = this.state.carpetFurnitureProductPopupData as CatalogueList;
    const tempCartProduct = [...cartProducts];
    carpetFurniturebodyParams.forEach((param) => {
      const newProductInCart = {
        id: undefined,
        catalogue_id: productPopupData.attributes.id || 0,
        catalogue_variant_id:
          productPopupData.attributes.catalogue_variants?.data[0].attributes.id || 0,
        customer_preference_id: null,
        name: productPopupData.attributes.name,
        product_name: productPopupData?.attributes.product_name,
        product_second_name: productPopupData?.attributes.product_second_name,
        unit_price: 0,
        image: 
          productPopupData?.attributes.catalogue_variants.data[0].attributes?.image
            ?.image,
        quantity: param.quantity,
        service_name: selectedService?.name || "",
        service_id: selectedService?.id || -1,
        parent_Product_id: this.state.clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
        preference_id: productPopupData.attributes.preference_id,
        isCarpetAndFurnitureProduct: true,
        notes: param.notes,
        height: Number(param.height),
        width: Number(param.width),
        weight: Number(param.weight),
      };
      tempCartProduct.push(newProductInCart);
    });

    const filterCartProducts = tempCartProduct.filter((product) => product.service_id !== -1)
    this.setState({
      cartProducts: filterCartProducts,
    });
  }

  async handleSubmitCarpetAndFunrnitureProduct(items: IFormValues[]) {
    const filteredItems = items.map((item) => {
      if (item.isNew) item.id = undefined
      return item
    })
    
    await this.handleCartOnCarpetAndFunrnitureProduct(filteredItems)
    if (this.state.laundryOrder?.id) {
      this.handleUpdateOrderAPI(null);
    } else {
      this.handleCreateNewOrder(null);
    }

    this.setState({
      subProductModalOpen: false,
      clickedParentCatalougeId: null,
      productPopup: false, 
      carpetFurnitureProductPopupData: null
    })
  }

  onCloseCareptAndFurnitureProductPopup = () => {
    this.setState({productPopup: false, carpetFurnitureProductPopupData: null})
  }

  handleOnUserChange = async (userContext: IUserContext) => {
    const { attributes } =
      userContext?.user?.attributes?.employee_proifle?.data?.attributes
        ?.store_management || {};
    const { is_process_cleaning_products } = attributes || {};
    const accountType = await getStorageData("accountType")
    const isB2bActive = accountType === "b2b"

    this.setState(
      {
        enableHomeCleaningSettings:
          String(is_process_cleaning_products) == "true",
        userData: userContext?.user,
        userRefresh: userContext?.refreshUser,
        tabsId: userContext?.user?.attributes?.employee_proifle?.data
          ?.attributes?.store_management?.attributes?.sections?.[0]
          ?.id as number,
        storeListPageNo: 1,
        isB2bActive
      },
      () => {
        this.getStoreList();
        this.getCatalogueList()
      }
    );

    const apiKey = customPermissionApiKey.orderPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    const b2bPermission = checkForNewPermissonStatus(customPermissionApiKey.b2BOrderPermission, userData as Array<PermissionGroupArray>)
    this.setState({
      permissionStatus: {
        ...value, 
        ...(isB2bActive ? { 
          createPermission: b2bPermission.createPermission,
          viewPermission: b2bPermission.viewPermission,
          editPermission: b2bPermission.editPermission
        } : {})
      },
    })

  };

  handleSearch = (value: string) => {
    this.setState(
      {
        currentPage: 1,
        searchText: value,
      },
      () => {
        ORDER_TYPES.STORE_ORDER == this.state.selectedMainTab &&
          this.getCatalogueList(1);
      }
    );
  };

  handleSearchStore = (inputValue: string) => {
    if (inputValue === this.state.storeSearchText) return;
    this.setState(
      {
        storeListPageNo: 1,
        storeSearchText: inputValue,
      },
      () => this.getStoreList()
    );
  };

  debouncedStoreSearch = lodash.debounce(
    (newInputValue: string) => this.handleSearchStore(newInputValue),
    700,
    { maxWait: 2000 }
  );

  checkForServices = (
    clickedCatalouge: CatalogueList,
    clickedService: Service
  ) => {
    const { tabsId, cartProducts, clickedParentCatalougeId } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === clickedCatalouge.attributes.id &&
        product.category_id === tabsId &&
        product.service_id === clickedService.id
    );
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: clickedCatalouge.attributes.id,
        catalogue_variant_id:
          clickedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
          customer_preference_id: null,
        name: clickedCatalouge.attributes.name,
        product_name: clickedCatalouge.attributes.product_name,
        preference_id: clickedCatalouge.attributes.preference_id,
        unit_price: 0,
        product_second_name: clickedCatalouge.attributes.product_second_name,
        image:
          clickedCatalouge.attributes.catalogue_variants.data[0].attributes
            .image?.image,
        quantity: 1,
        category_id: tabsId,
        service_id: clickedService.id,
        service_name: "",
        parent_Product_id: clickedParentCatalougeId,
        upcharge_list_ids: [],
      };
      tempCartProduct.push(newProductInCart);
      this.getProductPreferencesCall(
        clickedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
        clickedService.id
      );
      this.setState({
        cartProducts: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: clickedService.name,
          id: clickedService.id,
        },
        preferenceModalVisible: true,
      });
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      this.setState({
        cartProducts: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: clickedService.name,
          id: clickedService.id,
        },
      });
    }
    return true;
  };

  handleReturnIndexOfCatalogue = (
    catalogue: CatalogueList,
    tempCartProduct: ICartProductItem[]
  ) => {
    const { tabsId } = this.state;
    const selectedCatalogueIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId
    );
    return selectedCatalogueIndex;
  };

  checkForServicesWhenPopupDisabled = (
    selectedCatalouge: CatalogueList,
    selectedService: Service
  ) => {
    const { tabsId, cartProducts, clickedParentCatalougeId } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === selectedCatalouge.attributes.id &&
      product.service_id === selectedService.id &&
      product.category_id === tabsId
    );
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: selectedCatalouge.attributes.id,
        catalogue_variant_id:
          selectedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
        name: selectedCatalouge.attributes.name,
        customer_preference_id: null,
        product_name: selectedCatalouge.attributes.product_name,
        preference_id: selectedCatalouge.attributes.preference_id,
        product_second_name: selectedCatalouge.attributes.product_second_name,
        unit_price: 0,
        image:
          selectedCatalouge.attributes.catalogue_variants.data[0].attributes
            .image?.image,
        quantity: 1,
        service_name: "",
        service_id: selectedService.id,
        parent_Product_id: clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
      };
      tempCartProduct.push(newProductInCart);
      this.setState(
        {
          isPopuDisabledWithOrderCreate: true,
        },
        () => {
          this.getProductPreferencesCall(
            selectedCatalouge.attributes.catalogue_variants.data[0].attributes
              .id,
              selectedService.id
          );
        }
      );
      this.setState(
        {
          cartProducts: tempCartProduct,
          isIncreaseBtnClicked: false,
          selectedService: {
            name: selectedService.name,
            id: selectedService.id,
          },
        },
      );
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      this.setState({
        cartProducts: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: selectedService.name,
          id: selectedService.id,
        },
      });
    }
    return true;
  };

  handleCheckPreferencesPopup = (
    clickedCatalouge: CatalogueList,
    service: Service,
    cartProduct: ICartProductItem
  ) => {
    const {
      userData,
      isIncreaseBtnClicked
    } = this.state;
    const isPopupDisabled =
      !userData?.attributes.employee_proifle.data.attributes.store_management
        .attributes.item_preference_popup;
    
    this.shouldUseCommonUpchargesPreference = isPopupDisabled

    if (isPopupDisabled) {
      if (isIncreaseBtnClicked) {
        this.checkForServicesWhenPopupDisabled(clickedCatalouge, service);
        return true;
      }

      this.setState(
        {
          isPopuDisabledWithOrderCreate: true,
        },
        () => {
          this.getProductPreferencesCall(
            clickedCatalouge.attributes.catalogue_variants.data[0].attributes
              .id,
            service.id
          );
          cartProduct.service_id = service.id;
        }
      );
      return true;
    }
    return false;
  };

  mainServiceHandler = (tempCartProduct: ICartProductItem[], selectedCatalogueIndex: number,catalogue: CatalogueList, service: Service) => {
    const { isIncreaseBtnClicked } = this.state;
    if (tempCartProduct[selectedCatalogueIndex].service_id !== -1) {
      if (isIncreaseBtnClicked) {
        if (this.checkForServices(catalogue, service)) return 1;
      } else {
        return 1;
      }
    }
  }

  serviceClickHandler = (params: {
    catalogue: CatalogueList;
    service: Service;
  }) => {
    const { catalogue, service } = params;
    const { cartProducts, selectedCustomer,editSelectedCustomer, editId } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedCatalogueIndex = this.handleReturnIndexOfCatalogue(
      catalogue,
      tempCartProduct
    );

    if (selectedCatalogueIndex < 0) {
      return;
    }
    
    if (!editId ? !selectedCustomer : !editSelectedCustomer) {
      this.setState({
        errorMessage: "Please select customer",
        errorSnackbarOpen: true,
      });
      return;
    } else {
      // if product measurement is SQM or weight then display popup
      const carpetFurnitureMeasurementTypes = ["SQM", "Weight"];
      let isCarpetFurnitureProductType = catalogue.attributes.catalogue_variants.data[0].attributes?.measurement_type?.some(type => carpetFurnitureMeasurementTypes.includes(type) );
      
      if(isCarpetFurnitureProductType) {
        this.getProductPreferencesCall(
          catalogue.attributes.catalogue_variants.data[0].attributes.id,
          service.id
        );
        
        this.setState({
          productPopup: true,
          carpetFurnitureProductPopupData: catalogue,
          selectedService: {
            name: service.name,
            id: service.id,
          },
        });
        return;
      }

      if (
        this.handleCheckPreferencesPopup(
          catalogue,
          service,
          tempCartProduct[selectedCatalogueIndex]
        )
      )
        return;

      const value = this.mainServiceHandler(tempCartProduct,selectedCatalogueIndex, catalogue, service)
      if(value === 1) {
        return;
      }
      tempCartProduct[selectedCatalogueIndex].service_id = service.id;
    }

    this.getProductPreferencesCall(
      catalogue.attributes.catalogue_variants.data[0].attributes.id,
      service.id
    );

    this.setState({
      selectedService: {
        name: service.name,
        id: service.id,
      },
      cartProducts: tempCartProduct,
      preferenceModalVisible: true,
    });
  };

  editQuickDropSelectCust = (message: Message) => {
    const payload = message.getData(
      getCustomEnumName(CustomEnums.CustomReducerPayload)
    );
    if (payload) {
      const isOrderItems = payload
        ? payload.order_items
        : [];

      localStorage.setItem('orderId', payload.id)
      const isB2bOrder = !payload.account_id
      const shouldFetchCatalogue = isB2bOrder || (isB2bOrder !== this.state.isB2bActive)
      this.setState(
        {
          editId: true,
          selectedCustomer: payload.customer,
          editSelectedCustomer: payload.customer,
          laundryOrder: payload,
          laundryOrderItems: isOrderItems,
          isB2bActive: isB2bOrder
        }, () => {
          if (this.state.userData?.attributes && shouldFetchCatalogue) {
            this.getCatalogueList()
          } 
        }
      );
    }
  }
 
  handleCustomReducer = (message: Message) => {
    const action = message.getData(
      getCustomEnumName(CustomEnums.CustomReducerAction)
    );
    const payload = message.getData(
      getCustomEnumName(CustomEnums.CustomReducerPayload)
    );

    const value = payload ? payload : null;

    switch(action) {
      case 'SELECT_CUSTOMER':
        if (payload) {
          const isOrderItems = payload.attributes.order
            ? payload.attributes.order.order_items
            : [];
          localStorage.setItem('orderId', payload.attributes.order?.id)
          this.setState(
            {
              selectedCustomer: value,
              laundryOrder: payload.attributes.order,
              laundryOrderItems: isOrderItems,
              isLoading: false,
              cartsItemsPreferencesData: isOrderItems.map(
                (item: {
                  attributes: {
                    customer_preference_id: number;
                    catalogue_variant_id: number;
                    service_id: number;
                  };
                }) => {
                  return {
                    customer_preference_id:
                      item.attributes.customer_preference_id,
                    catalogue_variant_id: item.attributes.catalogue_variant_id,
                    service_id: item.attributes.service_id,
                  };
                }
              ),
            },
            () => {
              this.handleFetchCatalogues()
              this.handleQuickdropAndlundryorder()
              this.handleGetTemporaryProductList()
            }
          );
        } else {
          this.handleSelectCustomerWithoutPayload();
        }
        break ;
      case 'EDIT_SELECT_CUSTOMER':
          this.editQuickDropSelectCust(message)
          break ;
      case 'SET_ORDER':
        if(payload) {
          localStorage.setItem('orderId', payload.id)
        }
        this.setState(
          {
            laundryOrder: value,
            laundryOrderItems: payload ? payload.order_items : [],
          },
          () => this.handleQuickdropAndlundryorder()
        );
        break ;
      case 'DELETE_CART_PRODUCT':
        this.deleteOrderItemHandler(payload);
        break ;
      case 'UPDATE_CART_QTY':
        this.updateCartQtyApicall(payload);
        break ;
      case "QUICKDROP_ERROR_SNACKBAR":
        this.handleShowQuickdropErrorSnackbar();
        break;
      case 'CLEAR_CART':
        this.handleClearAllCart();
        break ;
      case 'ADD_CUSTOM_QTY':
        this.handleCustomInpputQty(payload);
        break ;
      case 'NAVIGATE_EDIT_PREFERENCE':
        // this.handleEditPreference()
        this.setState({
          isEditPreferencesPopupOpen: true
        })
        break ;
      case 'SET_SAVE_FOR_FUTURE_&_NOTES':
        this.setState(
          {
            notes: value.notesTextArea,
            saveForFuture: value.saveForFuture
          },
          () => {
            if(value.callUpdateApiForSaveForFutureNote) {
              // this update api will call at time of notes and save for future changes
              this.saveForFutureAndNotesApicall()
            }
        }
        );
        break ;
      case 'REMOVE_TEMP_PRODUCT_AFTER_PLACING_ORDER':
        // remove temprory products from catalogue list after placing order
        const {cataloguesList , temporaryProducts} = this.state;
        const temporaryProductsIds = temporaryProducts.map((tempProduct) => Number(tempProduct.id));
        const catalogueListWithoutTempProducts = cataloguesList.filter((catalogue) => !temporaryProductsIds.includes(Number(catalogue.id)));
        this.setState(
          {
            cataloguesList: catalogueListWithoutTempProducts,
            temporaryProducts: []
          }
        );
        break;
    }
  };

  handleSelectCustomerWithoutPayload = () => {
    // remove temprory products if added when no customer selected
    const {cataloguesList , temporaryProducts} = this.state;
    const temporaryProductsIds = temporaryProducts.map((tempProduct) => Number(tempProduct.id));
    const catalogueListWithoutTempProducts = cataloguesList.filter((catalogue) => !temporaryProductsIds.includes(Number(catalogue.id)));
    this.setState(
      {
        selectedCustomer: null,
        laundryOrder: null,
        laundryOrderItems: [],
        cataloguesList: this.state.isB2bActive ? [] : catalogueListWithoutTempProducts,
        temporaryProducts: [],
        userCustomerPriceListId: null,
        userCustomerPriceList: false,
        currentPage: 1
      },
      () => {
        this.getCatalogueList();
        this.handleQuickdropAndlundryorder();
      }
    );
  }

  handleQuickdropAndlundryorder = () => {
    const { laundryOrder, laundryOrderItems } = this.state;
    if (laundryOrder?.is_quick_drop && laundryOrder.order_items.length > 0) {
      this.setState({
        isQuickDrop: true,
      });
    } else {
      this.setState({
        isQuickDrop: false,
      });
      this.handlePreCustomerOrder(laundryOrderItems);
    }
  };

  handlePreCustomerOrder = (orderItems: Array<IlaundryOrderItem>) => {
    if (orderItems.length > 0) {
      const tempCart = orderItems.map((item) => {
        return {
          id: item.attributes.id,
          catalogue_id: item.attributes.catalogue_id,
          catalogue_variant_id: item.attributes.catalogue_variant_id,
          name: item.attributes.catalogue.name,
          product_name: item.attributes.catalogue.product_name,
          preference_id: item.attributes.preference_id,
          product_second_name: item.attributes.catalogue.product_second_name,
          unit_price: Number(item.attributes.unit_price),
          image: item.attributes.product_image,
          quantity: item.attributes.quantity,
          service_name: item.attributes.service?.attributes.name,
          service_id: Number(item.attributes.service_id),
          parent_Product_id: item.attributes.parent_catalogue_id,
          category_id: item.attributes.category_id,
          upcharge_list_ids: item.attributes.upcharge_list_ids,
          customer_preference_id: item.attributes.customer_preference_id
        };
      });
      this.setState({
        cartProducts: tempCart,
      });
    } else {
      this.setState({
        cartProducts: [],
      });
    }
  };

  getProductPreferencesCall = (
    catalogue_variant_id: number,
    service_id: number
  ) => {
    const { selectedCustomer, userData } = this.state;
    const store_id =
      userData?.attributes.employee_proifle.data.attributes.store_management_id;
    
    const apiUrl =
      configJSON.getProductPreferences +
      `?customer_id=${selectedCustomer?.id}&catalogue_variant_id=${catalogue_variant_id}&service_id=${service_id}&allow_access=true&store_id=${store_id}${this.state.isB2bActive ? "&request_type=B2b":""}`;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getPreferencesDataApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleClosePreferenceModal = () => {
    const { laundryOrder } = this.state;

    if (laundryOrder?.id) {
      this.handleUpdateOrderAPI(null);
    } else {
      this.handleCreateNewOrder(null);
    }

    this.setState({
      preferenceModalVisible: false,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      errorSnackbarOpen: false,
      errorMessage: "",
      snackbarSeverity: 'warning'
    });
  };

  handleCreatePreferencesOrder = (data: CreatePreferencesParams) => {
    const { laundryOrder, preferenceDataList, selectedCustomer } = this.state;

    const selectedSpecifications = data.selectedOptions
      ?.filter((element) => element.option_id.length) || [];

    const spesificationList: IOrderSpecification[] =
      (preferenceDataList as IpreferenceDataList).attributes?.specifications ??
      [];

    const spesificationData: { [key: string]: string[] | undefined } = {};

    selectedSpecifications.forEach((selectedSpecification) => {
      const spesification = spesificationList.find((elem) =>
        elem.id === selectedSpecification.specification_id
      );
      if (spesification) {
        spesificationData[spesification.name] =
          spesification.options_attributes.filter(
            (element) => selectedSpecification.option_id.includes(element.id) 
          ).map(element => element.label);
      }
    });

    const updateOrderData = {
      specifications: spesificationData,
      preference_id: data.preferenceId,
      upcharge_list_ids: data.upchargeListIds,
      catalogue_variant_id: Number(data.id),
      notes: data.notes
    };

    if (laundryOrder?.id) {
      this.handleUpdateOrderAPI(updateOrderData);
    } else {
      this.handleCreateNewOrder(updateOrderData);
    }

    const bodyData = {
      data: {
        attributes: {
          customer_id: selectedCustomer?.id,
          preferences_attributes: [
            {
              id: Number(
                (preferenceDataList as IpreferenceDataList).attributes?.id
              ),
              catalogue_variant_id: Number(data.id),
              preference_id: data.preferenceId,
              upcharge_list_ids: data.upchargeListIds,
              service_id: data.serviceId,
              notes: data.notes,
              save_for_future: data.saveForFuture,
              pref_spec_options_attributes: selectedSpecifications,
            },
          ],
        },
      },
    };

    let message = makeApiMessage({
      url: configJSON.updatePreferenceApiEndPoint + `${this.state.isB2bActive ? "?request_type=B2b" : ""}`,
      body: JSON.stringify(bodyData),
      method: "PUT",
    });
    this.updatePreferencesApicallId = message.messageId;
    runEngine.sendMessage(message.id, message);

    this.setState({
      preferenceModalVisible: false,
    });
  };

  getUpdateOrderBody = (params: UpdateOrderBody | null) => {
    const { laundryOrder, cartProducts, cartsItemsPreferencesData } =
      this.state;

    let preferencesParamsData: {
      specifications: unknown;
      upcharge_list_ids: number[];
      notes: string | undefined;
    };
    if (params) {
      preferencesParamsData = {
        specifications: params.specifications,
        upcharge_list_ids: params.upcharge_list_ids,
        notes: params.notes
      };
    }

    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (laundryOrder as IlaundryOrder).customer.id} : {customer_id: (laundryOrder as IlaundryOrder).account_id}

    const updateBody = {
      edit_order: this.state.editId ? true : false,
      data: {
        ...customer_Id,
        status: this.state.editId && 'in_cart',
        is_quick_drop: false,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: cartProducts
          .filter((item) => item.id === undefined && item.service_id !== -1)
          .map((item) => {
              const isCarpetAndFurnitureProduct = item.isCarpetAndFurnitureProduct;

              let carpetAndFurnitureProductBody: 
              { notes: string | undefined; height: number; width: number; weight: number; } | undefined = undefined;
              if(isCarpetAndFurnitureProduct) {
                carpetAndFurnitureProductBody = {
                  notes: item.notes,
                  weight: Number(item.weight),
                  height: Number(item.height),
                  width: Number(item.width),
                }
              } 

            return {
              catalogue_id: item.catalogue_id,
              catalogue_variant_id: item.catalogue_variant_id,
              category_id: item.category_id,
              quantity: item.quantity,
              customer_preference_id: cartsItemsPreferencesData.find(
                (preferenceitem) => {
                  return (
                    preferenceitem.catalogue_variant_id ==
                      item.catalogue_variant_id &&
                    preferenceitem.service_id == item.service_id
                  );
                }
              )?.customer_preference_id,
              service_id: item.service_id,
              preference_id: params!== null ? params.preference_id : item.preference_id,
              ...preferencesParamsData,
              ...carpetAndFurnitureProductBody
            };
          }),
      },
    };

    return updateBody;
  };

  getCreateOrderBody = (params: UpdateOrderBody | null) => {
    const {
      selectedCustomer,
      cartProducts,
      cartsItemsPreferencesData,
      userData,
    } = this.state;

    let preferencesParamsData: {
      specifications: unknown;
      upcharge_list_ids: number[];
      notes: string | undefined;
    };
    if (params) {
      preferencesParamsData = {
        specifications: params.specifications,
        upcharge_list_ids: params.upcharge_list_ids,
        notes: params.notes
      };
    }

    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    const updateBody = {
      data: {
        ...customer_Id,
        is_quick_drop: false,
        store_management_id:
          userData?.attributes.employee_proifle.data.attributes
            .store_management_id,
        order_items_attributes: cartProducts
          .filter((item) => item.id === undefined && item.service_id !== -1)
          .map((item) => {
            const isCarpetAndFurnitureProduct = item.isCarpetAndFurnitureProduct;
              let carpetAndFurnitureProductBody: { notes: string | undefined; height: number; width: number; weight: number; } | undefined = undefined;
              if(isCarpetAndFurnitureProduct) { 
                carpetAndFurnitureProductBody = {
                  notes: item.notes,
                  height: Number(item.height),
                  width: Number(item.width),
                  weight: Number(item.weight)
                }
              } 
            return {
              catalogue_id: item.catalogue_id,
              catalogue_variant_id: item.catalogue_variant_id,
              quantity: item.quantity,
              category_id: item.category_id,
              customer_preference_id: cartsItemsPreferencesData.find(
                (preferenceitem) => {
                  return (
                    preferenceitem.catalogue_variant_id ==
                      item.catalogue_variant_id &&
                    preferenceitem.service_id == item.service_id
                  );
                }
              )?.customer_preference_id,
              service_id: item.service_id,
              preference_id: params!== null ? params.preference_id : item.preference_id,
              ...preferencesParamsData,
              ...carpetAndFurnitureProductBody
            };
          }),
      },
    };

    return updateBody;
  };

  handleUpdateOrderAPI = (params: UpdateOrderBody | null) => {
    this.handleSendAction("SHOPPING_CART_LOADING", true);

    const { laundryOrder } = this.state;

    let message = makeApiMessage({
      url: this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(this.getUpdateOrderBody(params)),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleCreateNewOrder = (params: UpdateOrderBody | null) => {
    this.handleSendAction("SHOPPING_CART_LOADING", true);
    
    const apiEndPoint = this.state.isB2bActive ? 
                          configJSON.bussinessCreateNewOrderEndpoint
                          :configJSON.createNewOrderEndpoint;
    let message = makeApiMessage({
      url: apiEndPoint,
      body: JSON.stringify(this.getCreateOrderBody(params)),
      method: "POST",
    });
    this.createOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  updateQtyApicall(cartItem: ICartProductItem) {
    const { laundryOrder , selectedCustomer} = this.state;

    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: [
          {
            id: cartItem.id,
            quantity: cartItem.quantity,
            _destroy: cartItem.quantity == 0 ? true : false,
          },
        ],
      },
    };

    let message = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleShowQuickdropErrorSnackbar = () => {
    this.setState({
      errorSnackbarOpen: true,
      errorMessage: "You have a quick drop order in cart"
    });
  };

  updateCartQtyApicall(cartItem: IlaundryOrderItem) {
    this.handleSendAction("SHOPPING_CART_LOADING", true);
    const { laundryOrder , selectedCustomer} = this.state;
    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: [
          {
            id: cartItem.id,
            quantity: cartItem.attributes.quantity,
            _destroy: cartItem.attributes.quantity == 0 ? true : false,
          },
        ],
      },
    };

    let qtymessage = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = qtymessage.messageId;
    runEngine.sendMessage(qtymessage.id, qtymessage);
  }

  saveForFutureAndNotesApicall() {
    const { laundryOrder , selectedCustomer, notes, saveForFuture} = this.state;
    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        notes: notes,
        save_for_future: saveForFuture,
        order_items_attributes: []
      },
    };

    let message = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleSendAction(action: string, payload?: unknown) {
    let message = new Message(
      getCustomEnumName(CustomEnums.CustomActionReducers)
    );
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), action);
    message.addData(
      getCustomEnumName(CustomEnums.CustomReducerPayload),
      payload
    );
    runEngine.sendMessage(message.id, message);
  }

  deleteOrderItemHandler(data: IlaundryOrderItem) {
    this.handleSendAction("SHOPPING_CART_LOADING", true);

    const { cartProducts } = this.state;
    const tempCartProduct = [...cartProducts];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === data.attributes.catalogue_id &&
        product.service_id === data.attributes.service_id
    );
    this.deleteOrderItemApicall(tempCartProduct[selectedProductIndex]);
  }

  deleteOrderItemApicall(cartItem: ICartProductItem) {
    const { laundryOrder, selectedCustomer} = this.state;
    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: [
          {
            id: cartItem.id,
            _destroy: true,
          },
        ],
      },
    };

    let message = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleClearAllCart() {
    this.setState({ isClearCartActionCall: true });
    this.handleSendAction("SHOPPING_CART_LOADING", true);
    
    const { laundryOrder, cartProducts, selectedCustomer } = this.state;
    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: cartProducts
          .filter((item) => item.id !== undefined)
          .map((item) => {
            return {
              id: item.id,
              _destroy: true,
            };
          }),
      },
    };

    let message = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleEditPreference = () => {
    localStorage.setItem(
      "preferenceCustomer",
      JSON.stringify(
        this.state.selectedCustomer
      )
    );
    localStorage.setItem("preferenceCustomerId", this.state.selectedCustomer?.id as string);

    const itemsOnTheCartForPreference =
      this.state.cartsItemsPreferencesData.filter((item: {
        catalogue_variant_id: number,
        service_id: null | number
      }) => {
        return this.state.cartProducts.some(
          (cartItem: ICartProductItem) =>
            cartItem.catalogue_variant_id === item.catalogue_variant_id &&
            cartItem.service_id === item.service_id
        );
      });

    localStorage.setItem(
      "itemsAddedToCart",
      JSON.stringify(itemsOnTheCartForPreference)
    );

    this.props.navigation.navigate("ProductPreference");
  }

  handleAddProductClick = () => {
    this.setState({ addProductPopup: true });
  };

  handleAddProductPopupClose = () => {
    this.setState({ addProductPopup: false, isEdit: false });
  };

  handleGetTemporaryProduct = (productInfo: {data: CatalogueList}) => {
    this.setState(
      {
        cataloguesList: [productInfo.data, ...this.state.cataloguesList],
        temporaryProducts: [...this.state.temporaryProducts, productInfo.data],
      },
      () => {
        this.setState({
          addProductPopup: false,
        });
      }
    );
  };

  handleCloseEditPreferencesPopup = () => {
    this.setState({
      isEditPreferencesPopupOpen: false,
    });
  };

  getSpecificatonBodyObj = (selecredSpecifications: EditSpecificationType) => {
    const transformedObj = Object.keys(selecredSpecifications).reduce(
      (
        accVal: {
          [key: string]: string[];
        },
        keyVal
      ) => {
        const options = selecredSpecifications[keyVal].map(item => item.option);
        if (options.length > 0) {
          accVal[keyVal] = options;
        }
        return accVal;
      },
      {}
    );

    return transformedObj;
  };

  getUpdatePreferenceBody = (
    updatedPreferencesData: SelectedPreferenceData[]
  ) => {
    const { laundryOrder } = this.state;

    const editedPreference = updatedPreferencesData.map((updatePreference) => {
      return {
        id: updatePreference.order_items_preference_id,
        order_item_id: updatePreference.order_items_id,
        preference_id: updatePreference.selected_preference,
        upcharge_list_ids: updatePreference.selected_upcharges,
        specifications: this.getSpecificatonBodyObj(
          updatePreference.selected_specifications
        ),
      };
    });

    const updateBody = {
      id: laundryOrder?.id,
      order_item_preferences: editedPreference,
    };

    return updateBody;
  };

  handleSavePreference = (updatedPreferencesData: SelectedPreferenceData[]) => {
    this.setState({
      isEditPreferencesPopupOpen: false,
    });

    this.handleSendAction("SHOPPING_CART_LOADING", true);

    let message = makeApiMessage({
      url: configJSON.orderItemPreferenceUpdateApiEndPoint,
      body: JSON.stringify(this.getUpdatePreferenceBody(updatedPreferencesData)),
      method: "PATCH",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleFetchCatalogues = () => {
    const { selectedCustomer } = this.state;
    if(this.state.isB2bActive) {
      this.setState(
        {
          currentPage: 1
        },
        () => this.getCatalogueList()
      );
      return;
    } else{
    const use_customer_price_list =
      selectedCustomer?.attributes.customer_profile.data.attributes
        .use_customer_price_list;
    if (use_customer_price_list && selectedCustomer) {
      this.setState(
        {
          userCustomerPriceList: true,
          userCustomerPriceListId: selectedCustomer.attributes.customer_profile
            .data.attributes.price_list.data.attributes.id,
          currentPage: 1
        },
        () => this.getCatalogueList()
      );
    }
    }
  };

  handleCustomInpputQty = (orderItem: IlaundryOrderItem) => {
    const { laundryOrder , selectedCustomer} = this.state;
    const customer_Id = this.state.isB2bActive ? 
                              {business_account_customer_id: (selectedCustomer as CustomerDataType).id} : {customer_id: (selectedCustomer as CustomerDataType).id}
    const putBody = {
      data: {
        ...customer_Id,
        store_management_id: laundryOrder?.store_management_id,
        order_items_attributes: [
          {
            id: orderItem.id,
            quantity: orderItem.attributes.quantity,
            _destroy: orderItem.attributes.quantity == 0 ? true : false,
          },
        ],
      },
    };

    this.setState({ isLoading: true });
    let message = makeApiMessage({
      url:  this.state.isB2bActive ? `${configJSON.bussinessUpdateOrderEndPoint}?id=${(laundryOrder as IlaundryOrder).id}` : configJSON.actionOrderByIdEndpoint + (laundryOrder as IlaundryOrder).id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCatalogueDataBasedOnCondition = (catalogueId: number) => {
    let selectedProductData = null;
    const selectedServicePresentInCart = this.state.cartProducts.some((cartItem) => catalogueId === cartItem.catalogue_id  && cartItem.service_id ===  this.state.selectedService?.id)
    if(this.state.selectedService?.id && selectedServicePresentInCart) {
      selectedProductData = this.state.cartProducts.find(
        (product: ICartProductItem) =>
          product?.catalogue_id === catalogueId &&
          product?.category_id === this.state.tabsId && 
          product.service_id === this.state.selectedService?.id
      );
      if(this.state.isIncreaseBtnClicked && this.state.cartProducts.find(cart => cart.catalogue_id === catalogueId)) {
        selectedProductData = lodash.findLast(this.state.cartProducts ,
          (product: ICartProductItem) =>
            product?.catalogue_id === catalogueId &&
          product?.category_id === this.state.tabsId
        );
      } 
    } else {
      if(this.state.isIncreaseBtnClicked && this.state.cartProducts.find(cart => cart.catalogue_id === catalogueId)) {
        selectedProductData = lodash.findLast(this.state.cartProducts ,
          (product: ICartProductItem) =>
            product?.catalogue_id === catalogueId &&
          product?.category_id === this.state.tabsId
        );
      } else {
        selectedProductData = this.state.cartProducts.find(
          (product: ICartProductItem) =>
            product?.catalogue_id === catalogueId &&
          product?.category_id === this.state.tabsId
        );
      }
    }
    return selectedProductData
  }


  getPaymentMethods() {
    const message = makeApiMessage({
      url: configJSON.getPaymentMethodsListApi,
      method: "GET",
    });

    this.getPaymentMethodsApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handlePaymentMethodsResponse(response: {
    status: 200;
    data: PaymentMethodType[];
  }) {
    if(response.status == 200 && response.data) {
      const data = response.data;
      if (data) {
        const string_data = JSON.stringify(response.data);
        localStorage.setItem("payment_method_lists", string_data);
      }; 
    };
  };

  handleGetPaymentAPI = () => {
    setTimeout(() => this.getPaymentMethods(), 3000)
  };

  getCommonUpchargesPrefence = (
    laundryOrder: IlaundryOrder | null,
    productPreferenceAttributes?: {
      catalogue_variant_id: number
      preferences: {id: number}[],
      upcharges: {id: number}[]
    }
  ) => {
    if (
      !laundryOrder?.id 
      || !laundryOrder.order_items.length
      || !productPreferenceAttributes
    ) return undefined
    let result : Partial<UpdateOrderBody> = {}
    const firstOrderItem = laundryOrder.order_items[0]
    const {
      catalogue_variant_id,
      preferences: productPreferences, 
      upcharges: productUpcharges
    } = productPreferenceAttributes
    if (productPreferences.some(productPreference => productPreference.id === firstOrderItem.attributes.preference_id)) {
      result.preference_id = firstOrderItem.attributes.preference_id
    }
    const selectedUpchargeIds = laundryOrder.order_items.flatMap(
      order_item => order_item.attributes.upcharge_list_ids.map(upcharge_list_id => upcharge_list_id.attributes.id)
    )
    const defaultUpcharges = lodash.uniq(selectedUpchargeIds).filter(
      selectedUpchargeId => productUpcharges.some(productUpcharge => productUpcharge.id === selectedUpchargeId)
    )
    if (defaultUpcharges.length) {
      result.upcharge_list_ids = defaultUpcharges
    }
    if (lodash.isEmpty(result)) return undefined
    return {...result, catalogue_variant_id} as UpdateOrderBody
  }
  // Customizable Area End
}
