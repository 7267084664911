import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton
  // Customizable Area Start
  , styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { uploadImage } from "../../../components/src/commonAssets/assets"
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import { cloudIcon } from "./assets";

export interface IImportCSVResponse {
  status?: number;
  message: string;
  error?: string;
  errors: {
    [key: number]: string[];
  };
  url:string
}
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledDialog open={this.state.modalClose}
        onClose={() => { this.handleModalClose('routeName') }}
      >
        <DialogContent className="dialogContentBox">
          <DialogTitle className="modalTitle">{configJSON.modalTitle}</DialogTitle>
          <Box className="dialogUpload">
            <Box className="contentCont">
              <img
                src={cloudIcon}
                alt="Upload"
                className="uploadImage"
                data-test-id="icon"
              />

              <Box>
                <Typography gutterBottom className="label-20-700">Upload File</Typography>
                <Typography className="label-14-400">File Must be CSV or Excel</Typography>
              </Box>

            </Box>
            <input
              data-test-id="fileUploadsection"
              className="Input"
              id="fileInput"
              type="file"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event) }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
            />
          </Box>
          { !this.state.isFileUploadError ?
          <>
         { this.state.uploadedFile !== null && this.state.setLoaded < 100 && <>
            <Box className="progressBar">
              <LinearProgress variant="determinate" value={this.state.setLoaded} />
              <Box className="progressTitle">
                <p className="progressTitlep">
                  uploading file {this.state.uploadedFile?.name} {this.state.setLoaded}%
                </p>
                <CloseIcon className="closeIcon" onClick={this.handleClearFile} />
              </Box>
            </Box>
          </>}
           {this.state.setLoaded >= 100 && <>
            <div className="uploaded-file-name">
              {this.state.uploadedFile?.name}
              <CloseIcon className="closeIcon" onClick={this.handleClearFile} data-test-id='closeIcon' />
            </div>
          </>}
          </>
          :
          <p className="fileuploaderror">
            Please upload a valid Excel file.
          </p>
           }
         
          <Box className="button-container">
            <StyledButton
              data-test-id="cancleButton"
              onClick={() => {this.handleModalClose('routeName')}}
              className="cancleButton"
            >
              Cancel
            </StyledButton>
            <StyledButton
              data-test-id="doneBtn"
              className="doneButton"
              onClick={() => { this.handleDoneClick() }}
              disabled={!this.state.uploadedFile || this.state.setLoaded < 100 || this.state.importButtonLoading || this.state.isFileUploadError}
            >
              { this.state.importButtonLoading ? <CircularProgress style={{ width: 16, height: 16, color: "white" }}/> : "Done" } 
            </StyledButton>
          </Box>
          <CustomSnackbar
            open={this.state.errorSnackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={this.state.errorMessage}
            severity="error"
          />
        </DialogContent>
      </StyledDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({

  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "24px",
  },
  "& .MuiDialog-paper": {
    boxShadow: 'none'
  },
  "& .dialogContentBox": {
    width: 590,
    textAlign: 'center',
    maxHeight: '100%',
  },
  "& .dialogUpload": {
    display:"grid",
    placeItems:"center",
    borderRadius: 10,
    padding: 10,
    border: "1px dashed grey",
    position: 'relative',
    cursor: 'pointer'
  },
  "& .progressBar": {
    marginTop: 30,
  },
  "& .progressTitle": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  "& .uploaded-file-name": {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: 5
  },
  "& .closeIcon": {
    cursor: 'pointer',
    height: 20
  },
  "& .button-container": {
    marginTop: 20,
    marginBottom: 20,
  },
  "& .doneButton": {
    background: "#204b9c",
    padding: "8px 50px",
    color: "white",
    fontWeight: 600,
  },
  "& .cancelButton": {
    background: "lightgray",
    marginRight: 15,
    padding: "8px 50px",
    color: "black",
    fontWeight: 600,
  },
  "& .progressTitlep": {
    fontSize: 13,
  },
  "& .fileuploaderror": {
    fontSize: 13,
    marginTop: "10px",
    color: "red"
  },
  "$ .outerBox": {
    width: 600,
    textAlign: 'center',
    margin: 10,
  },
  "& .uploadImage": {
    cursor: "pointer", margin: "10px 10px",
    width:"44px",
    height:"32px"
  },
  "& .Input": {
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: '100%',
    opacity: 0
  },
  "& .StyledDialog": {
    fontWeight: 'bold'
  },
  "& .label-20-700":{
    fontWeight:700,
    fontSize:"20px",
    fontFamily: "Montserrat !important",
  },
  "& .label-14-400":{
    fontWeight:400,
    fontSize:"14px",
    fontFamily: "Montserrat !important",
  },
  "& .contentCont":{
    display:"flex",
    gap:"16px",
    alignItems:"center"
  }
});
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  buttonStyle: {
    marginTop: "30px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  }
};

export const StyledButton = styled(Button)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  height: "56px",
  width: "184px",
  borderRadius: "8px",
  margin: '5px',
  textTransform: "unset",
  "&.doneButton": {
    background: "#204B9C",
    color: "#FFFFFF",
    "&.Mui-disabled":{
      backgroundColor: "rgba(32, 75, 156, 0.5) !important",
      color: "#FFFFFF",
      opacity: 0.7
    },
  },
  "&.cancleButton": {
    backgroundColor: '#F1F5F9',
    color: '#64748B'
  }
});
// Customizable Area End
