// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FormikValues } from "formik";
import { makeApiMessage } from "../../../components/src/common";
import { debounce, uniqBy, concat } from "lodash";
import { navigateTo } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface CompanyDropdownResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    number_of_vats: string;
  };
}

interface DropdownOption {
  option: string;
  id: string;
}

interface CustomerDropdownResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    full_phone_number: string;
    employee_id: string;
    b2b_price_list_id: number;
  };
}

interface CustomerAccount {
  id: number;
  full_phone_number: string;
  name: string;
  email: null | string;
}
interface CustomerGroup {
  id: string;
  type: string;
  attributes: {
    id: number;
    group_name: string;
    no_of_pieces_per_item: null | number;
    no_of_items_per_wash: null | number;
    designation: string;
    vip: boolean;
    accounts_count: number;
    description: string;
    activated: boolean;
    accounts: CustomerAccount[];
    company: {
      id: number;
      name: string;
    };
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  companyDropdownList: Array<DropdownOption>;
  customersDropdownList: Array<DropdownOption>;
  addCustomerGroupIntialValue: FormikValues;
  customerNextPage: number;
  autoCopmpleteValue: string;
  selectedCustomersForAdd: Array<DropdownOption>;
  addCustomerSelectedOptionForList: Array<DropdownOption>;
  searchQuery: boolean;
  isEditForm: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "warning" | "error" | "success";
  formSubmitLoading: boolean;
  selectedCompanyId: string;
  isDataLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bAddCustomerGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyListApiCallId: string = "";
  getCustomerListApiCallId: string = "";
  createCustomerApiCallId: string = "";
  disableLoadMoreCustomerList: boolean = false;
  itemsPerPage = 10;
  getCustomerGroupApiCallId: string = "";
  editCustomerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      companyDropdownList: [],
      customersDropdownList: [],
      customerNextPage: 1,
      autoCopmpleteValue: "",
      addCustomerGroupIntialValue: {
        customer_group_name: "",
        company: "",
        designation: "",
        no_item_per_wash: "",
        no_pieces_per_item: "",
        vip_previleges: false,
        group_description: "",
        customers: [],
      },
      selectedCustomersForAdd: [],
      addCustomerSelectedOptionForList: [],
      searchQuery: false,
      isEditForm: this.props.navigation.getParam("id") ? true : false,
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      formSubmitLoading: false,
      selectedCompanyId: "",
      isDataLoading: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCompanyDropdownList();

    // call get customer group details API while editing
    if (this.state.isEditForm) {
      this.getCustomerGroupDetails();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCompanyListApiCallId) {
        this.handleCompanyDropdownListResponse(responseJson);
      } else if (apiRequestCallId === this.getCustomerListApiCallId) {
        this.handleCustomerDropdownListResponse(responseJson);
      } else if (apiRequestCallId === this.createCustomerApiCallId) {
        this.handleCreateCustomerApiResponse(responseJson);
      } else if (apiRequestCallId === this.editCustomerApiCallId) {
        this.handleCreateCustomerApiResponse(responseJson);
      } else if (apiRequestCallId === this.getCustomerGroupApiCallId) {
        this.handleGetCustomerApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCompanyDropdownList = () => {
    const apiUrl = configJSON.companyDropdonListEndPoint;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCompanyListApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getCustomerDropdownList = (searchQuery: string = "") => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });

    const { autoCopmpleteValue, selectedCompanyId } = this.state;

    const searchQueryString = autoCopmpleteValue
      ? `&filter_by[query]=${autoCopmpleteValue}`
      : "";

    const pageQuery = `&page_no=${this.state.customerNextPage}&per_page=10`;

    const companyId = selectedCompanyId
      ? `&company_id=${selectedCompanyId}`
      : "";

    const apiUrl =
      configJSON.customerDropdownListEndPoint +
      searchQueryString +
      pageQuery +
      companyId;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCustomerListApiCallId = message.messageId;
    this.disableLoadMoreCustomerList = true;
    runEngine.sendMessage(message.id, message);
  };

  handleCompanyDropdownListResponse = (response: {
    data: Array<CompanyDropdownResponse>;
  }) => {
    if (response) {
      const optionlist = response.data.map((data) => {
        return {
          id: data.id,
          option: data.attributes.name,
        };
      });
      this.setState({ companyDropdownList: optionlist });
    }
  };

  handleProductListResAsPerQuery(
    existingData: Array<DropdownOption>,
    responseOptionList: Array<DropdownOption>
  ) {
    if (this.state.searchQuery) {
      return concat(existingData, responseOptionList);
    }
    return responseOptionList;
  }

  handleReturnCustomerDataDropdownList = (response: {
    data: Array<CustomerDropdownResponse>;
  }) => {
    const existingData = this.state.customersDropdownList;

    const optionList = response.data.map((customer) => ({
      id: customer.id,
      option: customer.attributes.name,
    }));

    this.disableLoadMoreCustomerList = optionList.length < this.itemsPerPage;

    if (optionList.length > 0) {
      return uniqBy(
        this.handleProductListResAsPerQuery(existingData, optionList),
        "id"
      );
    } else {
      if (existingData.length > 0) {
        return existingData;
      } else {
        return [];
      }
    }
  };

  handleCustomerDropdownListResponse = (response: {
    data: Array<CustomerDropdownResponse>;
  }) => {
    if (response) {
      this.setState({
        customersDropdownList:
          this.handleReturnCustomerDataDropdownList(response),
      });
    }
  };

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleScrollCompanyDropdown = (event: React.SyntheticEvent) => {
    console.debug(event);
  };

  handleAutoCompleteChange = (getValue: string) => {
    console.debug(getValue);
  };

  handleChangeCompany = (
    newSelectedCompany: { id: number; option: string } | null,
    setFieldValue: (
      field: string,
      value: string | number | Array<number>
    ) => void
  ) => {
    if (newSelectedCompany) {
      setFieldValue("company", newSelectedCompany.id);
      this.setState(
        {
          selectedCompanyId: String(newSelectedCompany.id),
          customerNextPage: 1,
          customersDropdownList: [],
          selectedCustomersForAdd: [],
          addCustomerSelectedOptionForList: [],
        },
        () => this.getCustomerDropdownList()
      );
    } else {
      setFieldValue("company", "");
      this.setState({
        selectedCompanyId: "",
        customerNextPage: 1,
        customersDropdownList: [],
        selectedCustomersForAdd: [],
        addCustomerSelectedOptionForList: [],
      });
    }
  };

  handleSubmitForm = (values: FormikValues) => {
    const { isEditForm } = this.state;
    if (!isEditForm) {
      this.handleCreateCustomerGroup(values);
    } else {
      this.handleEditCustomerGroup(values);
    }
  };

  handleCreateCustomerGroup = (values: FormikValues) => {
    this.setState({ formSubmitLoading: true });

    const bodyData = {
      data: {
        company_id: values.company,
        group_name: values.customer_group_name,
        no_of_items_per_wash: values.no_item_per_wash,
        no_of_pieces_per_item: values.no_pieces_per_item,
        designation: values.designation,
        vip: values.vip_previleges,
        description: values.group_description,
        business_account_customer_ids: this.handleGetSelectedCustomers(),
      },
    };

    const stringifyBody = JSON.stringify(bodyData);

    let requestMessage = makeApiMessage({
      url: configJSON.createCustomerEndPoint,
      method: "POST",
      body: stringifyBody,
    });
    this.createCustomerApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditCustomerGroup = (values: FormikValues) => {
    const groupId = this.props.navigation.getParam("id");

    this.setState({ formSubmitLoading: true });

    const bodyData = {
      data: {
        company_id: values.company,
        group_name: values.customer_group_name,
        no_of_items_per_wash: values.no_item_per_wash,
        no_of_pieces_per_item: values.no_pieces_per_item,
        designation: values.designation,
        vip: values.vip_previleges,
        description: values.group_description,
        business_account_customer_ids: this.handleGetSelectedCustomers(),
      },
    };

    const stringifyBody = JSON.stringify(bodyData);

    let requestMessage = makeApiMessage({
      url: configJSON.createCustomerEndPoint + `/${groupId}`,
      method: "PUT",
      body: stringifyBody,
    });
    this.editCustomerApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetSelectedCustomers = () => {
    const customerIds = this.state.selectedCustomersForAdd.map(
      (data: DropdownOption) => data.id
    );
    return customerIds;
  };

  handleScrollCustomerDropdown = (event: React.SyntheticEvent) => {
    const checkListboxNode = event.currentTarget;

    const boxPosition =
      checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    const scrollTop = checkListboxNode.scrollTop;

    if (
      checkListboxNode.scrollHeight - boxPosition <= 1.3 &&
      !this.disableLoadMoreCustomerList
    ) {
      this.setState(
        { customerNextPage: this.state.customerNextPage + 1 },
        () => {
          this.getCustomerDropdownList("");
          checkListboxNode.scrollTop = scrollTop;
        }
      );
    }
  };
  handleClearCustomerGroup = () => {
    this.setState({
      selectedCustomersForAdd: [],
      addCustomerSelectedOptionForList: [],
      autoCopmpleteValue: '',
      customerNextPage: 1,
    });
  }
  handleAutoCompltCustomerChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    this.setState({ autoCopmpleteValue: getValue, customerNextPage: 1 });

    this.getCustomerDropdownList(getValue);
  };

  handleCustomerSelectOptions = (
    value: { id: string; option: string }[],
    option: { id: string; option: string }
  ) => {
    const isValueContained = value.some((item) => item.id === option.id);

    if (isValueContained) {
      this.setState({
        addCustomerSelectedOptionForList: value.filter(
          (item) => item.id !== option.id
        ),
        selectedCustomersForAdd: value.filter((item) => item.id !== option.id),
      });
    } else {
      this.setState({
        addCustomerSelectedOptionForList: [...value, option],
        selectedCustomersForAdd: [...value, option],
      });
    }
  };

  handleCreateCustomerApiResponse = (response: {
    data: string;
    errors: Array<{ [key: string]: string }>;
  }) => {
    if (response.data) {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: response.data,
        severity: "success",
      });
      setTimeout(() => {
        navigateTo({ props: this.props, screenName: "B2bCustomerGroup" });
      }, 500);
    } else {
      this.setState({ formSubmitLoading: false });
    }
  };

  handleGoBackToListing = () => {
    navigateTo({ props: this.props, screenName: "B2bCustomerGroup" });
  };

  handleSnackbarClose = () => {
    this.setState({ snackBarOpen: false, snackBarMessage: "" });
  };

  getCustomerGroupDetails = () => {
    this.setState({ isDataLoading: true });

    const groupId = this.props.navigation.getParam("id");

    let requestMessage = makeApiMessage({
      url: configJSON.createCustomerEndPoint + `/${groupId}`,
      method: "GET",
    });

    this.getCustomerGroupApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleReturnValue = (value: number | null) => {
    if (value !== null) {
      return value;
    } else {
      return "";
    }
  };

  handleGetCustomerApiResponse = (response: { data: CustomerGroup }) => {
    const {
      group_name,
      designation,
      description,
      no_of_items_per_wash,
      no_of_pieces_per_item,
      vip,
      company,
      accounts,
    } = response.data.attributes;

    this.setState(
      {
        addCustomerGroupIntialValue: {
          customer_group_name: group_name,
          designation: designation,
          no_item_per_wash: this.handleReturnValue(no_of_items_per_wash),
          no_pieces_per_item: this.handleReturnValue(no_of_pieces_per_item),
          vip_previleges: vip,
          group_description: description,
          company: company.id,
        },
        selectedCompanyId: String(company.id),
        selectedCustomersForAdd: this.getSelectedCustomerData(accounts),
        addCustomerSelectedOptionForList:
          this.getSelectedCustomerData(accounts),
        isDataLoading: false,
      },
      () => this.getCustomerDropdownList()
    );
  };

  getSelectedCustomerData = (data: CustomerAccount[]) => {
    const selectedIds = data.map((customer) => {
      return {
        id: String(customer.id),
        option: customer.name,
      };
    });
    return selectedIds;
  };
  // Customizable Area End
}
