// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  Box,
  styled,
  Snackbar,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { Formik } from "formik";
import Alert from "@material-ui/lab/Alert";
import {
  InputField,
  CustomPaper,
} from "../../../components/src/customComponents/CustomTags.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { colors } from "../../utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InfiniteScroll from "react-infinite-scroll-component";
import B2BCustomerCreationController, {
  Props,
  configJSON,
  IB2BCompanyDropDown,
  PriceListForm,
  IB2BProductList,
} from "./B2BPriceListCreationController";
const configString = configJSON.strings.b2bAdPriceListString;
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { renderBaseOnConditions, conditionalString } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import { StyledCheckBox } from "../../../components/src/CustomTheme.web";

// Customizable Area End

export class B2BPriceListCreation extends B2BCustomerCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  finalErrorMessage(error: boolean, touch: boolean) {
    if (error) {
      if (touch) {
        return (
          <div
            style={{
              color: "red",
              fontSize: "14px",
              margin: "5px 5px 5px 0",
              fontFamily: "Montserrat",
            }}
          >
            {error}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return;
    }
  }

  headerText() {
    return conditionalString(
      this.isEdit,
      configString.txtEditPriceList,
      configString.txtAddPriceList
    )
  }

  buttonText() {
    if (this.isEdit) {
      return configString.btnEditTxtSave;
    } else {
      return configString.btnTxtSave;
    }
  }

  checkPriceListEmptyError(
    errors: string | undefined,
    touched: boolean | undefined
  ) {
    if (errors && touched) {
      return "fieldBorderError";
    } else {
      return "fieldBorder";
    }
  }

  renderMultiSelectDropdown = (
    label: string,
    placeholder: string,
    fieldName: keyof PriceListForm,
    values: PriceListForm,
    options: { id: number | string; option: string }[],
    errorMessage: React.ReactNode,
    isError?: boolean | undefined
  ) => {
    const selectedOptionIds = values[fieldName] as Array<string | number>;
    const selectedOptions = options.filter((option) =>
      selectedOptionIds.includes(option.id)
    );
    const isSelectedAll =
      selectedOptions.length && selectedOptions.length === options.length;
    const value = isSelectedAll
      ? [{ id: "-1", option: "Select All" }]
      : selectedOptions;
    const selectedOptionForList = isSelectedAll
      ? [{ id: "-1", option: "Select All" }, ...selectedOptions]
      : value;

    return (
      <Box className='autocomplete-field'>
        <StyledInputLabelAutoComplete data-test-id='lblTxtName'>
          {label}
        </StyledInputLabelAutoComplete>
        <CustomSearchableMultiSelect
          dataTestId={`dropdown_${fieldName}`}
          id={fieldName}
          name={fieldName}
          checkSelectAllText={() => {}}
          value={value}
          optionList={options}
          placeHolderText={placeholder}
          changeInputOnClear
          handleScroll={() => {}}
          handleClickOnSelectAll={() => {}}
          isOnBlurFunction={() => {}}
          debouncedFunction={(getValue: string) => {
            if (getValue === '') {
              this.handleClearMultiSelect(fieldName)
            }
          }}
          handleSelectOptions={(
            value: { id: string | number; option: string }[],
            option: { id: string; option: string }
          ) => {
            this.handleChangeMultipleSelectDropdown(option, options, fieldName);
          }}
          emptyDataMessage={configJSON.noDataMsg}
          handleEmptyAutoSelectValue={() => {}}
          selectedOptionForList={selectedOptionForList}
          isError={isError}
        />
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
      </Box>
    );
  };

  getValidation = () => {
    return Yup.object({
      name: Yup.string()
        .trim()
        .required(configJSON.validationMessage.nameValidation),
      company_id: Yup.string().required(
        configJSON.validationMessage.companyValidation
      ),
      business_account_ids : Yup.string().required(
        configJSON.validationMessage.businessAccountValidation
      ),
      status: Yup.string().required(
        configJSON.validationMessage.statusValidation
      ),
      price_list_to_copy: Yup.string()
        .required("Please select price list to copy")
        .nullable(),
      section_ids: Yup.string().required(
        configJSON.validationMessage.sectionValidation
      ),
      region_ids: Yup.string().required(
        configJSON.validationMessage.regionValidation
      ),
      area_ids: Yup.string().required(
        configJSON.validationMessage.areanValidation
      ),
      store_management_ids: Yup.string().required(
        configJSON.validationMessage.storeValidation
      ),
    });
  };

  renderHeader = () => {
    return (
      <TableRow columns={"1fr"}>
        {[
          {
            title: "Product Name",
            sortingKey: "name",
          },
        ]
          .filter((item: { title: string }) => {
            return item.title;
          })
          .map(
            ({ title, sortingKey }: { title: string; sortingKey?: string }) => (
              <TableCell
                header
                key={title}
                content={title}
                className={"f-16 align-item-center"}
                sortingKey={sortingKey}
                sortingIcons={[
                  IconSortingNormal,
                  IconSortingUp,
                  IconSortingDown,
                ]}
                sortingDisabled={!Boolean(sortingKey)}
                {...this.getSortingProps()}
              />
            )
          )}
      </TableRow>
    );
  };

  handleTableNullValue = (value: string | number | null) => {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <>---</>;
    }
  };

  renderIcons = (data: IB2BProductList) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
      </div>
    );
  };

  renderPorudctRender = (company_id: string) => {
    const companyName = this.state.b2bCompanyList.find(b2bCompany => b2bCompany.id === company_id)?.attributes.name
    return (
      <>
        <CustomPaper>
          <StyleMainProductDiv>
            <StyleTitleDiv>
              <Typography>{companyName}</Typography>
              <Typography>{this.state.productPriceListName}</Typography>
            </StyleTitleDiv>
           {this.state.selectedPriceListCopyId && <StyledAddProdButton
              onClick={() => this.handleOpenModal("Add")}
              data-test-id='add-product'
            >
              Add Product
            </StyledAddProdButton>}
          </StyleMainProductDiv>
          <StyleScollBox
            data-test-id={"table-container-scrolldiv"}
            onScroll={this.fetchMoreTableCustomers}
          >
              <OrderTable>
                <RowContainer header>{this.renderHeader()}</RowContainer>
                {renderBaseOnConditions(
                  this.state.productListLoading,
                  <RowContainer>
                    <>
                      {Array(3)
                        .fill(1)
                        .map((_, index) => (
                          <RowContainer>
                            <TableRow
                              key={index}
                              className='table-rows'
                              columns='1fr'
                            >
                                <TableRowsSkeleton />
                            </TableRow>
                          </RowContainer>
                        ))}
                    </>
                  </RowContainer>,
                  <>
                    {renderBaseOnConditions(
                      this.state.productList.length > 0,
                      <RowContainer>
                        {this.state.productList.map((data) => {
                          return (
                            <div>
                              <TableRow
                                style={{ cursor: "pointer" }}
                                key={data.id}
                                className='table-rows'
                                data-test-id='tableData'
                                columns='1fr'
                              >
                                {[
                                  this.handleTableNullValue(
                                    data.attributes.name
                                  ),
                                ].map((item, index) => (
                                  <TableCell
                                    key={`cell-${index}`}
                                    style={{
                                      color: "#1A1A1A",
                                      wordBreak: "break-all",
                                    }}
                                    content={item as React.ReactNode}
                                    className='f-12'
                                    type={this.getTypeOfOrderTable(index)}
                                  />
                                ))}
                              </TableRow>
                            </div>
                          );
                        })}
                      </RowContainer>,
                      <RowContainer>
                        <Box className={"w-100"}>
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            No records found!!
                          </div>
                        </Box>
                      </RowContainer>
                    )}
                  </>
                )}
              </OrderTable>
          </StyleScollBox>
        </CustomPaper>
      </>
    );
  };

  renderAddProduct = () => {
    const { productSelectToggle, productSelectedIds } = this.state;
    const productsFiltered = this.filteredProducts()
    return (
      <>
        <CustomPaper style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flexShrink: 0, paddingBottom: "12px" }}>
            <StyledInputLabel
              style={{ textAlign: "start" }}
              data-test-id='lblTxtName'
            >
              Add Product
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant='outlined'
              inputProps={{
                "data-test-id": "field-product-dropdown",
              }}
              placeholder='Select Product'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }}
              onClick={this.handleProductSelecToggle}
              value={this.state.addProductList}
              onChange={(event) => this.searchProduct(event.target.value)}
            />
          </div>

          {productSelectToggle && (
              <InfiniteScroll
                dataLength={productsFiltered.length}
                next={this.fetchMoreDropdownProducts}
                hasMore={this.state.hasMore}
                loader={<></>}
                height={200}
              >
              {renderBaseOnConditions(
                productsFiltered.length > 0,
                productsFiltered.map((product, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                    }}
                  >
                    <StyleCheckLable>{product.attributes.name}</StyleCheckLable>
                    <StyledCheckBox
                    dataTestId="product-check"
                      style={
                        !productSelectedIds.includes(Number(product.attributes.catalogue?.attributes?.id))
                          ? {
                              border: "1px solid #64748B",
                              borderRadius: "6px",
                            }
                          : {}
                      }
                      onClick={this.handleProductCheck.bind(this,Number(product.attributes.catalogue?.attributes?.id))
                      }
                      checked={productSelectedIds.includes(Number(product.attributes.catalogue?.attributes?.id))}
                    />
                  </Box>
                )),
                <>No Product Found</>
              )}
              </InfiniteScroll>
          )}
        </CustomPaper>
      </>
    );
  };

  renderEditProduct = () => {
    const { productNameError, productName } = this.state;
    return (
      <>
        <CustomPaper style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flexShrink: 0, paddingBottom: "12px" }}>
            <StyledInputLabel
              style={{ textAlign: "start" }}
              data-test-id='lblTxtName'
            >
              Name
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant='outlined'
              inputProps={{
                "data-test-id": "field-edit",
              }}
              value={productName}
              placeholder='Product Name'
              onChange={(event) => this.handleEditChange(event)}
            />
            {productNameError && (
              <div
                style={{
                  color: "red",
                  fontSize: "14px",
                  margin: "5px 5px 5px 0",
                  fontFamily: "Montserrat",
                  textAlign: "start",
                }}
              >
                Product name is required
              </div>
            )}
          </div>
        </CustomPaper>
      </>
    );
  };

  renderModalContent = () => {
    const { productModalType } = this.state;
    switch (productModalType) {
      case "Add":
        return this.renderAddProduct();
      case "Edit":
        return this.renderEditProduct();
      case "Delete":
        return <></>;
    }
  };

  renderProductModal = (isModalOpen: boolean) => {
    let confirmButtonText;
    let title;
    const { productModalType, productNameError, productAddEditLoader } = this.state;
    switch (productModalType) {
      case "Add":
        confirmButtonText = "Add";
        title = "Add Product";
        break;
      case "Edit":
        confirmButtonText = "Update";
        title = "Edit Product Price";
        break;
      case "Delete":
        confirmButtonText = "Delete";
        title = "Are you sure you want to delete?";
        break;
      default:
        break;
    }

    return (
      <>
        <CustomDialog
          className='main-dialog-container'
          open={isModalOpen}
          data-test-id='commonConfirmModal'
          onClose={this.handleClose}
        >
          <Box className='dialog-main'>
            <DialogTitle>{title}</DialogTitle>
            <StyledDialogContent>
              <DialogContentText>{this.renderModalContent()}</DialogContentText>
            </StyledDialogContent>
            <MainBox>
              <SecondaryButtonBox>
                <Button
                  data-test-id='cancelModelBtn'
                  aria-label='cancel-modal-button'
                  className={`secondaryButtonStyle`}
                  onClick={this.handleClose}
                  color='primary'
                >
                  Cancel
                </Button>
              </SecondaryButtonBox>
              <PrimaryButtonBox>
                <Button
                  data-test-id='confirmModelBtn'
                  aria-label='confirm-modal-button'
                  className='primaryButtonStyle'
                  color='primary'
                  autoFocus
                  disabled={productNameError || productAddEditLoader}
                  onClick={()=> this.hanldeSubmit()}
                >
                  {productAddEditLoader && (
                    <CircularProgress size={20} style={{color:"white",marginRight: 10,}} />
                  )}
                  {confirmButtonText}
                </Button>
              </PrimaryButtonBox>
            </MainBox>
          </Box>
        </CustomDialog>
      </>
    );
  };

  render() {
    const b2bCompanyListData = this.state.b2bCompanyList.map(
      (item: IB2BCompanyDropDown) => ({
        id: item.id,
        option: item.attributes.name,
      })
    );

    return (
      <>
        <Box data-test-id={configJSON.priceListCreationTest}>
          <CustomLoader loading={this.state.isLoading} />
          <Box data-test-id={configJSON.priceListCreationContainerTest}>
            <StyleDiv data-test-id={configJSON.priceListFormHeaderTest}>
              <PageTitle>{this.headerText()}</PageTitle>
            </StyleDiv>
            <Formik
              data-test-id='priceListCreationFormik'
              initialValues={this.state.priceListInitialValues}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
              validationSchema={this.getValidation()}
              innerRef={this.formRef}
              enableReinitialize
            >
              {({
                errors,
                touched,
                handleSubmit,
                getFieldProps,
                setFieldValue,
                handleBlur,
                values,
                validateForm,
              }: any) => (
                <form onSubmit={handleSubmit} data-test-id='addPriceForm'>
                  <CustomPaper>
                    <StyleGrid container direction='column'>
                      <Grid container spacing={2} id='modal-content'>
                        <Grid item xs={12} sm={12} md={12}>
                          <StyledInputLabel data-test-id='lblTxtName'>
                            {configString.txtLblName}*
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-name",
                            }}
                            data-test-id='txtName'
                            error={this.checkError(errors.name, touched.name)}
                            id='name'
                            name='name'
                            fullWidth={true}
                            placeholder='Enter Price List Name'
                            variant='outlined'
                            {...getFieldProps("name")}
                          />
                          {this.finalErrorMessage(errors.name, touched.name)}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          <StyledInputLabelAutoComplete data-test-id='lblTxtCompany'>
                            {configString.txtLblCompany}*
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            data-test-id='companyListDropdown'
                            fieldName='company_id'
                            placeholder='Type to Search Company Name'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleComapnySelection(value, setFieldValue)
                            }
                            value={values.company_id}
                            disableClear
                            options={b2bCompanyListData}
                            listBoxStyle={{ maxHeight: 270 }}
                            error={this.checkError(
                              errors.company_id,
                              touched.company_id
                            )}
                          />
                          {this.finalErrorMessage(
                            errors.company_id,
                            touched.company_id
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          <StyledInputLabelAutoComplete data-test-id='txtLblBusinessAccount'>
                            {configString.txtLblBusinessAccount}*
                          </StyledInputLabelAutoComplete>
                          <CustomSearchableMultiSelect
                            id='business_account_ids'
                            name='business_account_ids'
                            dataTestId='businessAccountDropdown'
                            checkSelectAllText={() => {}}
                            value={this.state.selectedBusinessAccount}
                            optionList={this.state.busAccOptions}
                            placeHolderText='Select Business Account'
                            changeInputOnClear
                            handleScroll={this.handleScrollBusAccDropdown}
                            handleClickOnSelectAll={() => {}}
                            isOnBlurFunction={() =>
                              this.debouncedFunctionSection(
                                "",
                                this.handleBusAccAutoCompleteChange
                              )
                            }
                            debouncedFunction={(getValue: string) => {
                              if (getValue === '') {
                                this.handleClearMultiSelect('business_account_ids')
                                return;
                              }
                              this.debouncedFunctionSection(
                                getValue,
                                this.handleBusAccAutoCompleteChange
                              );
                            }}
                            handleSelectOptions={(
                              value: { id: string | number; option: string }[],
                              option: { id: string | number; option: string }
                            ) => {
                              this.handleSelectBusAccOptions(
                                value,
                                option,
                                setFieldValue
                              );
                            }}
                            keepSelectAll={false}
                            emptyDataMessage='No Business Account Found'
                            handleEmptyAutoSelectValue={() => {}}
                            selectedOptionForList={
                              this.state.selectedBusinessAccountForList
                            }
                            isError={this.checkError(
                              errors.business_account_ids,
                              touched.business_account_ids
                            )}
                            style={{
                              maxWidth: "100%",
                              marginTop: 12,
                              minWidth: "250px",
                            }}
                          />
                          {this.finalErrorMessage(
                            errors.business_account_ids,
                            touched.business_account_ids,
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          <StyledInputLabelAutoComplete data-test-id='txtLblPriceListType'>
                            {configString.txtLblPriceListToCopy}*
                          </StyledInputLabelAutoComplete>

                          <AutocompleteSelect
                            data-test-id='priceListCopyDropdown'
                            fieldName='price_list_to_copy'
                            placeholder='Select Price List To Copy'
                            handleScroll={this.handleScrollPricelistDropdown.bind(
                              this
                            )}
                            debouncedFunction={(getValue: string) => {
                              this.debouncedFunction(
                                getValue,
                                this.handleAutoCompleteChange
                              );
                            }}
                            className={this.checkPriceListEmptyError(
                              errors.price_list_to_copy,
                              touched.price_list_to_copy
                            )}
                            onChange={(
                              _blank: React.ChangeEvent<HTMLInputElement>,
                              value: { id: string; option: string } | null
                            ) => {
                              this.handleChangeSelectedPriceList(
                                value,
                                setFieldValue
                              );
                            }}
                            onBlur={(event: React.MouseEvent) => {
                              handleBlur(event);
                              this.handleAutoCompleteChange(
                                this.state.selectedPriceListToCopy
                                  ? this.state.selectedPriceListToCopy.option
                                  : ""
                              );
                            }}
                            value={values.price_list_to_copy}
                            options={this.state.priceListToCopy}
                            listBoxStyle={{ maxHeight: 270 }}
                          />

                          {this.finalErrorMessage(
                            errors.price_list_to_copy,
                            touched.price_list_to_copy
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          <StyledInputLabelAutoComplete data-test-id='txtLblSection'>
                            {configString.txtLblSection}*
                          </StyledInputLabelAutoComplete>
                          <CustomSearchableMultiSelect
                            id='section_ids'
                            name='section_ids'
                            dataTestId='sectionDropdown'
                            checkSelectAllText={() => {}}
                            value={this.state.selectedSections}
                            optionList={this.state.sectionOptions}
                            placeHolderText='Select Section'
                            changeInputOnClear
                            handleScroll={this.handleScrollSectionDropdown}
                            handleClickOnSelectAll={() => {}}
                            isOnBlurFunction={() =>
                              this.debouncedFunctionSection(
                                "",
                                this.handleSectionAutoCompleteChange
                              )
                            }
                            debouncedFunction={(getValue: string) => {
                              if (getValue === '') {
                                this.handleClearMultiSelect('section_ids')
                              }
                              this.debouncedFunctionSection(
                                getValue,
                                this.handleSectionAutoCompleteChange
                              );
                            }}
                            handleSelectOptions={(
                              value: { id: string | number; option: string }[],
                              option: { id: string | number; option: string }
                            ) => {
                              this.handleSelectSectionOptions(
                                value,
                                option,
                                setFieldValue
                              );
                            }}
                            emptyDataMessage='No Section Found'
                            handleEmptyAutoSelectValue={() => {}}
                            selectedOptionForList={
                              this.state.selectedSectionsForList
                            }
                            isError={this.checkError(
                              errors.section_ids,
                              touched.section_ids
                            )}
                            style={{
                              maxWidth: "100%",
                              marginTop: 12,
                              minWidth: "250px",
                            }}
                          />
                          {this.finalErrorMessage(
                            errors.section_ids,
                            touched.section_ids
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          {this.renderMultiSelectDropdown(
                            "Region*",
                            "Type to Select",
                            "region_ids",
                            values,
                            this.state.regions,
                            this.finalErrorMessage(
                              errors.region_ids,
                              touched.region_ids
                            ),
                            this.checkError(
                              errors.region_ids,
                              touched.region_ids
                            )
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          {this.renderMultiSelectDropdown(
                            "Area*",
                            "Type to Select",
                            "area_ids",
                            values,
                            this.state.areas,
                            this.finalErrorMessage(
                              errors.area_ids,
                              touched.area_ids
                            ),
                            this.checkError(errors.area_ids, touched.area_ids)
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          {this.renderMultiSelectDropdown(
                            "Store*",
                            "Type to Select",
                            "store_management_ids",
                            values,
                            this.state.stores,
                            this.finalErrorMessage(
                              errors.store_management_ids,
                              touched.store_management_ids
                            ),
                            this.checkError(
                              errors.store_management_ids,
                              touched.store_management_ids
                            )
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          style={{ marginTop: "20px" }}
                        >
                          <StyledInputLabelAutoComplete data-test-id='txtLblStatus'>
                            {configString.txtLblStatus}*
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            data-test-id='statusDropdown'
                            fieldName='status'
                            placeholder='Select Status'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleStatusSelection(value, setFieldValue)
                            }
                            value={values.status}
                            disableClear
                            options={this.state.statusList}
                            listBoxStyle={{ maxHeight: 270 }}
                            error={this.checkError(
                              errors.status,
                              touched.status
                            )}
                          />
                          {this.finalErrorMessage(
                            errors.status,
                            touched.status
                          )}
                        </Grid>
                      </Grid>
                    </StyleGrid>
                  </CustomPaper>

                  {this.renderPorudctRender(values.company_id)}

                  <StyleBox className={"actionButtonSection"}>
                    <StyledCancelButton
                      data-test-id='cancelBtn'
                      onClick={() => this.handleRedirect()}
                    >
                      {configString.btnTxtCancel}
                    </StyledCancelButton>
                    <StyledButton data-test-id='btn-submit' type='submit'>
                      {this.buttonText()}
                    </StyledButton>
                  </StyleBox>
                </form>
              )}
            </Formik>

            {this.renderProductModal(this.state.productModalOpen)}
          </Box>
          <Snackbar
            data-test-id='errorMsg'
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity={this.state.snackbarServerity}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyleDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "30px 0px",
  alignItems: "center",
});

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "29px",
  fontSize: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  color: "#334155",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  },
});

const StyledInputLabelAutoComplete = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  color: "#334155",
  marginBottom: "8px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  },
});

const StyledButton = styled(Button)({
  height: "56px",
  width: "184px",
  background: "rgb(32, 75, 156)",
  backgroundColor: "rgb(32, 75, 156)",
  color: "rgb(255, 255, 255)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  cursor: "pointer",
  border: "none",
  "&:hover": {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyledCancelButton = styled(Button)({
  height: "56px",
  width: "184px",
  background: "rgb(32, 75, 156)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  backgroundColor: "#F1F5F9 !important",
  color: "#64748B !important",
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyleGrid = styled(Grid)({
  "& .dropdownTextbox": {
    borderRadius: 8,
  },
  "& .dropdownDefaultValue": {
    display: "none",
  },
  "& .actionButtonSection": {
    marginTop: "48px",
    marginBottom: "40px",
    display: "flex",
    flexWrap: "wrap-reverse",
  },
});

const StyleBox = styled(Box)({
  marginTop: "48px",
  marginBottom: "40px",
  display: "flex",
  flexWrap: "wrap-reverse",
});

const StyleTitleDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  fontWeight: 500,
  fontSize: "16px",
  fontFamily: "Montserrat",
  color: colors().black,
});

const StyledAddProdButton = styled(Button)({
  height: "56px",
  width: "137px",
  background: "rgb(32, 75, 156)",
  backgroundColor: "rgb(32, 75, 156)",
  color: "rgb(255, 255, 255)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  cursor: "pointer",
  border: "none",
  "&:hover": {
    background: colors().cyancobaltblue,
    color: colors().background,
  },
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyleMainProductDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
});

const CustomDialog = styled(Dialog)({
  "@media only screen and (max-width: 1024px)": {
    fontSize: 14,
    "& .icon-section": {
      marginBottom: "15px",
    },
    "& .dialog-main": {
      width: "471px",
      margin: " 40px 20px !important",
    },
  },
});

export const StyledDialogContent = styled(DialogContent)({
  paddingTop: "0px",
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "22px",
  color: colors().darkliver,
  textAlign: "center",
  "@media only screen and (max-width: 1024px)": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color: colors().viewTextColor,
    textAlign: "center",
  },
});

export const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const SecondaryButtonBox = styled(Box)({
  "& .secondaryButtonStyle": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    width: 128,
    height: 56,
    marginRight: "20px",
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    },
  },
});

export const PrimaryButtonBox = styled(Box)({
  "& .primaryButtonStyle": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 128,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    },
  },
  "& .primaryButtonForConfirm": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 292,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 200,
      height: 44,
    },
  },
});

const StyleCheckLable = styled("span")({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "22px",
  color: colors().viewTextColor,
});

export const StyledTextField = styled(TextField)({
  marginTop: "10px",
  border: "1px solid var(--border, #ECECEC)",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "14.9px 14px",
    },
  },
});

const StyleScollBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  height: 300
});

export default B2BPriceListCreation;
// Customizable Area End
