// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { apiCall, handleLogout, updateMultiSelectData, makeApiMessage } from "../../../components/src/common";
import { toString } from "lodash";
export const configJSON = require("./config");
import {
  conditionalString,
  navigateTo
} from "../../utilities/src/CustomBlockHelpers";
import { DropdownOption } from "./AddPricelistModalController.web";
import { uniqBy, concat, debounce } from "lodash";
import { FormikProps, FormikHelpers } from "formik";
import { ISortingData } from "../../../components/src/SortingTableHeader";

interface IPriceListDetails {
  "data": {
    "id": string,
    "type": string,
    "attributes": {
      "id": 193,
      "name": string,
      "price_list_type": string,
      "product_currency_id": null,
      "created_at": string,
      "updated_at": string,
      "tax_type": string,
      "activated": boolean,
      "price_list_to_copy": { id: number; name: string } | null,
      "type": string,
      "company_id": number | null,
      "region_ids": number[],
      "area_ids": number[],
      "store_management_ids": number[],
      "business_account_ids": number[],
      "business_accounts": { id: number; business_customer: string }[]
      "model_name": string,
      "company_name": string,
      "is_master_price_list": boolean,
      "sections": {
        "data": {
          attributes: {
            id: number
            name: string
            section_name: string
          }
        }[]
      },
      products: {
        id: number;
        catalogue_variant_id: number;
        service_id: number;
        name: string;
        icon: string;
        price: string;
        sqm: number | null;
        sqm_price: string;
        weight: number | null;
        weight_price: string;
        default: boolean;
        services: {
          data: Array<{
            id: string;
            type: string;
            attributes: {
              id: number;
              name: string;
              icon: string;
              name_translation: string;
            };
          }>;
        };
      }[]
    }
  }
}

export type Option = {
  id: string | number
  option: string
}

type DropdownResponse = { id: string; attributes: { id: number; name: string } }

export type PriceListForm = {
  id?: string
  name: string
  company_id: string
  price_list_to_copy: string
  region_ids: number[]
  area_ids: number[]
  store_management_ids: number[]
  business_account_ids: number[]
  section_ids: number[]
  product_ids: number[]
  status: string
}

export interface IB2BCompanyDropDown {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name": string,
    "number_of_vats": string
  },
}

interface IEditApiResponse {
  message: string;
  data: {
    id: number;
    catalogue_label: string;
    active: boolean;
  };
}

interface ISectionDropdown {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "section_name": string,
    "name": string
  }
}

interface IBusAccDropdown {
  "id": string,
  "type": string
  "attributes": {
    "id": number,
    "business_customer": string,
    "contract_person": string
  }
}

export interface IB2BProductList {
  id: string;
  type: string;
  attributes: {
    id: number;
    price_list: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      product_currency_id: string | null;
      tax_type: string;
      activated: boolean;
      price_list_type: string;
      price_list_to_copy: number | null;
      add_by: string | null;
      company_id: number | null;
    };
    pieces: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    measurement_type: string[];
    catalogue_label: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
        product_type: {
          id: number;
          value: string;
        };
      };
    };
    name: string;
    image: {
      id: number;
      name: string;
      image: string;
    };
    measurement: {
      id: number | null;
      value: number | null;
    };
    product_currency_id: string | null;
  };
}

interface ProductMeta {
  "total_pages": number,
  "total_count": number,
  "current_page": number,
  "next_page": number | null,
  "pervious_page": number | null
}


// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarServerity: 'success' | 'error';
  snackbarMessage: string;
  priceListInitialValues: PriceListForm,
  b2bCompanyList: IB2BCompanyDropDown[]
  selectedCompany: string
  selectedBusinessAccount: { id: string | number, option: string }[];
  selectedBusinessAccountForList: { id: string | number, option: string }[];
  busAccOptions: { id: number, label?: string, option: string, active?: boolean }[];
  businessAccount: { id: number, label?: string, option: string, active?: boolean }[];
  isBusAccSelectAll: boolean;
  busAccNextPage: number
  selectedStatus: { id: string, option: string } | null
  selectedPriceListType: string
  selectedPriceListToCopy: { id: string, option: string } | null
  statusList: { id: string, option: string }[]
  priceListType: { id: string, option: string }[]
  autoCopmpleteValue: string;
  pricelistDropPage: number;
  priceListToCopy: { id: string, option: string }[],
  mobileDeactiveMsg: string;
  mobileConfirmModal: boolean;
  selectedSections: { id: string | number, option: string }[];
  selectedSectionsForList: { id: string | number, option: string }[];
  selectedPriceListCopyId: string
  productPriceListName: string,
  productPriceListId: string
  sectionOptions: { id: number, label?: string, option: string, active?: boolean }[];
  section: { id: number, label?: string, option: string, active?: boolean }[];
  sectionNextPage: number
  isSectionSelectAll: boolean;
  sectionSelectedData: { id: number, option: string }[];
  sectionAutoCopmpleteValue: string;
  busAccAutoCopmpleteValue: string;
  regions: Option[]
  areas: Option[]
  stores: Option[]
  editId: string
  sortingData: ISortingData;
  query: string
  prodNextPage: number
  prodDropDownNextPage: number
  productList: IB2BProductList[]
  productListMeta: ProductMeta,
  productListLoading: boolean
  productModalOpen: boolean
  productModalType: string
  productSelectToggle: boolean
  productSelectedIds: number[],
  productSelectedIdsCopy: number[],
  editProductDetails: {
    id: string,
    name?: string
  }
  productName: string
  productNameError: boolean
  productAddEditLoader: boolean
  isProductSwitchActive: boolean
  productListDropdown: IB2BProductList[]
  productListDropdownMeta: ProductMeta,
  productMasterIds: number[]
  masterPriceID: number,
  hasMore: boolean,
  addProductList: string
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BPriceListCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  formRef = React.createRef<FormikProps<PriceListForm>>()
  authToken: string = "";
  getCompanyDropdownApiCallId: string = "";
  itemsPerPage = 10;
  disableLoadMorePriceList = false;
  disableLoadMoreSection = false;
  disableLoadMoreBusAcc = false;
  getPriceListApiCallId: string = "";
  getSectionListAsPerPriceListApiCallId: string = "";
  getRegionsCallId: string = ""
  getAreasCallId: string = ""
  getStoresCallId: string = ""
  addB2BPriceListApiCallId: string = ""
  isEdit: boolean = this.props.navigation?.getParam('navigationBarTitleText') ? true : false;
  getPriceListDetailsApiCallId: string = ""
  getBusAccDropdownApiCallId: string = ""
  getB2BProductListApiCallId: string = ""
  getB2BProductAddEditApiCallId: string = ""
  getB2BProdListDropDownCallId: string = ""
  getB2BMasterPriceListApiCallId2: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      snackbarOpen: false,
      snackbarServerity: 'success',
      snackbarMessage: "",
      priceListInitialValues: {
        name: "",
        company_id: "",
        price_list_to_copy: "",
        business_account_ids: [],
        region_ids: [],
        area_ids: [],
        store_management_ids: [],
        product_ids: [],
        section_ids: [],
        status: ''
      },
      section: [],
      b2bCompanyList: [],
      selectedCompany: '',
      statusList: [
        {
          id: "Active",
          option: "Active"
        },
        {
          id: "DeActive",
          option: "DeActive"
        }
      ],
      selectedStatus: null,
      priceListType: [
        {
          id: "1",
          option: 'Store',
        },
        {
          id: "2",
          option: 'Mobile',
        },
      ],
      selectedPriceListType: '',
      selectedPriceListToCopy: null,
      autoCopmpleteValue: "",
      pricelistDropPage: 1,
      priceListToCopy: [],
      mobileDeactiveMsg: '',
      mobileConfirmModal: false,
      selectedSections: [],
      selectedSectionsForList: [],
      sectionOptions: [],
      sectionNextPage: 1,
      isSectionSelectAll: false,
      sectionSelectedData: [],
      sectionAutoCopmpleteValue: "",
      regions: [],
      areas: [],
      stores: [],
      editId: "",
      selectedBusinessAccount: [],
      selectedBusinessAccountForList: [],
      busAccAutoCopmpleteValue: "",
      busAccNextPage: 1,
      isBusAccSelectAll: false,
      busAccOptions: [],
      businessAccount: [],
      sortingData: {
        name: "",
      },
      query: "",
      prodNextPage: 1,
      prodDropDownNextPage: 1,
      productList: [],
      productListLoading: false,
      productModalOpen: false,
      productModalType: '',
      productListMeta: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": null,
        "pervious_page": null
      },
      productSelectToggle: false,
      productSelectedIds: [],
      productSelectedIdsCopy: [],
      editProductDetails: {
        id: '',
        name: ''
      },
      productName: '',
      productAddEditLoader: false,
      productNameError: false,
      isProductSwitchActive: false,
      selectedPriceListCopyId: '',
      productListDropdown: [],
      productListDropdownMeta: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": null,
        "pervious_page": null
      },
      productMasterIds: [],
      productPriceListName: "",
      productPriceListId: "",
      masterPriceID: 0,
      hasMore: true,
      addProductList: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getCompanyDropdownApiCallId:
          this.getCompanyApiCallResponse(responseJson);
          break;
        case this.getPriceListApiCallId:
          this.getPricelistApiCallResponse(responseJson);
          break;
        case this.getSectionListAsPerPriceListApiCallId:
          this.getSectionListOptionApiCallResponse(responseJson);
          break;
        case this.getRegionsCallId:
          this.setState({ regions: this.parseOptions(responseJson) })
          break;
        case this.getAreasCallId:
          const areas = responseJson.data?.map(
            (item: { id: string; attributes: { id: number; area_name: string } }) => ({ id: item.attributes.id, option: item.attributes.area_name }))
            || []
          this.setState({ areas })
          break;
        case this.getStoresCallId:
          const stores = responseJson.data?.map(
            (item: { id: string; attributes: { id: number; store_name: string } }) => ({ id: item.attributes.id, option: item.attributes.store_name }))
            || []
          this.setState({ stores })
          break;
        case this.addB2BPriceListApiCallId:
          this.getAddEditAPIResponse(responseJson)
          break;
        case this.getPriceListDetailsApiCallId:
          this.getPricelistDetailsApiCallResponse(responseJson)
          break;
        case this.getB2BMasterPriceListApiCallId2:
          this.getB2BMasterPriceListApiCallResponse2(responseJson)
          break;
        case this.getBusAccDropdownApiCallId:
          this.getBusAccDropApiCallResponse(responseJson);
          break;
        case this.getB2BProductListApiCallId:
          this.getProductListApiCallResponse(responseJson);
          break;
        case this.getB2BProdListDropDownCallId:
          this.getProdDropdownApiCallResponse(responseJson);
          break;
        case this.getB2BProductAddEditApiCallId:
          this.getProductAddEditApiCallResponse(responseJson);
          break;
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    if (this.isEdit) {
      const editId = this.props.navigation.getParam('navigationBarTitleText')
      this.setState({ editId: editId }, () => this.getPriceListDetailAPI()
      )
    }
    this.getDropDownApiCall()
    this.getRegions()
    // Customizable Area End
  }
  // Customizable Area Start

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getDropDownApiCall = () => {
    this.getB2BCompanyDropDownAPI()
  }

  getB2BCompanyDropDownAPI = () => {
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    const url = configJSON.B2BCompanyDropdownAPIEndPoint

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCompanyDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getCompanyApiCallResponse = (responseJson: { data: IB2BCompanyDropDown[], status: number, message?: string, errors?: { message: string } }) => {
    if (responseJson?.status == 500) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Internal server error",
        snackbarServerity: 'error'
      })
    }
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({ b2bCompanyList: responseJson.data });
      }
    }

  }

  handleSubmit = (values: PriceListForm) => {
    const deleted_products_ids = this.state.productSelectedIdsCopy.filter(
      item => !this.state.productSelectedIds.includes(item)
    )
    this.setState({ isLoading: true });
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    let url = configJSON.B2bAddPriceListAPIEndPoint
    let methodVal = configJSON.exampleAPiMethod

    if (this.isEdit) {
      url = configJSON.B2BPriceListAPIEndpoint + "/" + this.state.editId
      methodVal = configJSON.editProductListApiMethod
    }

    const body = {
      data: {
        "name": values.name,
        "price_list_to_copy": Number(values.price_list_to_copy),
        "product_ids": this.state.productSelectedIds,
        company_id: values.company_id,
        "category_ids": values.section_ids,
        "activated": values.status === "Active" ? true : false,
        "region_ids": values.region_ids,
        "area_ids": values.area_ids,
        "store_management_ids": values.store_management_ids,
        "business_account_ids": values.business_account_ids,
        "deleted_product_ids": this.isEdit ? deleted_products_ids : []
      }
    }

    const getAccount = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: methodVal,
    });

    this.addB2BPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  checkError(error: boolean, touch: boolean) {
    if (error && touch) {
      return true
    } else {
      return false
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  handleRedirect = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BPriceList",
    });
  }

  handleComapnySelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue: string | Array<number>) => void) => {
    setFieldValue("business_account_ids", [])

    this.setState({
      selectedCompany: value.id,
      businessAccount: [],
      busAccOptions: [],
      busAccNextPage: 1,
      selectedBusinessAccount: [],
      selectedBusinessAccountForList: [],
      selectedPriceListCopyId: "",
      sectionOptions: [],
      sectionAutoCopmpleteValue: "",
      productList: [],
      prodNextPage: 1,
    }, () => {
      setFieldValue("company_id", value.id)
      setFieldValue("price_list_to_copy", "")
      setFieldValue("product", [])
      setFieldValue("section_ids", [])
      this.getBusinessAccountList(value.id)
      this.getB2BPriceList(value.id)
    })
  }

  getBusinessAccountList = (companyId: string) => {
    this.disableLoadMoreBusAcc = true;

    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const url = configJSON.B2BGetBusinessAccDropAPIEndPoint + `&page_no=${this.state.busAccNextPage}&company_id=${companyId}&filter_by[query]=${this.state.busAccAutoCopmpleteValue}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getBusAccDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleStatusSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue: string) => void) => {
    setFieldValue("status", value.id)
    this.setState({
      selectedStatus: value
    }, () => {
      setFieldValue("status", value.id)
    })
  }


  handleChangeSelectedPriceList = (newSelectedPriceList: { id: string, option: string } | null, setFieldValue: (field: string, value: string | number | Array<number>) => void) => {
    setFieldValue("product", [])
    setFieldValue("section_ids", [])
    setFieldValue("price_list_to_copy", newSelectedPriceList ? newSelectedPriceList.id : "");
    this.setState({
      selectedPriceListCopyId: newSelectedPriceList ? newSelectedPriceList.id : "",
      productList: [],
      prodNextPage: 1,
      productPriceListName: toString(newSelectedPriceList?.option),
      productPriceListId: toString(newSelectedPriceList?.id)
    }, () => {
      this.handleCatergoriesOptions(newSelectedPriceList)
      newSelectedPriceList && this.handleProductList(newSelectedPriceList.id)
    })
  }

  getB2BPriceList(companyId: string) {
    const { autoCopmpleteValue } = this.state;
    let header = {
      token: this.authToken
    }
    const page_no = this.state.pricelistDropPage;
    const searchQuery = autoCopmpleteValue ? `&filter_by[name]=${this.state.autoCopmpleteValue}` : '';
    let apiUrl = `${configJSON.B2BPriceListAPIEndpoint}?page_no=${page_no}&per_page=${this.itemsPerPage}&company_id=${companyId}&price_list_to_copy_dropdown=true&dropdown=true` + searchQuery;

    const getAccount = apiCall({
      header: header,
      httpBody: {},
      httpMethod: configJSON.getPriceListApiMethod,
      url: apiUrl,
    });
    this.disableLoadMorePriceList = true;
    this.getPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPricelistApiCallResponse(responseJson: { data: Array<DropdownOption>, status: number, message?: string, errors?: { message: string } }) {
    if (this.checkGetResponse(responseJson)) {
      const array = (responseJson?.data || []).map((item: DropdownOption) => {
        return {
          id: item.id,
          option: item.attributes.name
        }
      });
      this.disableLoadMorePriceList = array.length < this.itemsPerPage;
      this.setState((previous) => {
        let newPriceListToCopy = previous.pricelistDropPage === 1 ? array : concat(previous.priceListToCopy, array)
        previous.selectedPriceListToCopy && newPriceListToCopy.unshift(previous.selectedPriceListToCopy)
        return {
          priceListToCopy: uniqBy(newPriceListToCopy, "id")
        }
      });
    }
  }


  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1.3 &&
      !this.disableLoadMorePriceList
    ) {
      this.setState({
        pricelistDropPage: this.state.pricelistDropPage + 1
      },
        () => this.getB2BPriceList(this.state.selectedCompany)
      )
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    if (getValue.length < 1 || getValue.length > 2) {
      this.setState({
        autoCopmpleteValue: getValue,
        pricelistDropPage: 1,
      }, () => this.getB2BPriceList(this.state.selectedCompany));
    }
  }

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;

    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionNextPage: prevState.sectionNextPage + 1 }), () => {
        this.handleCatergoriesOptions(this.state.selectedPriceListToCopy)
      })
    }
  };

  debouncedFunctionSection = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleCatergoriesOptions(value: { id: string, option: string } | null) {
    this.setState({
      selectedPriceListToCopy: value,
      isSectionSelectAll: false,
      sectionSelectedData: [],
    });
    this.disableLoadMoreSection = true;

    if (!value) return;

    const searchQuery = this.state.sectionAutoCopmpleteValue ? `&filter_by[query]=${this.state.sectionAutoCopmpleteValue}` : ''

    let apiUrl = `${configJSON.getSectionListApiEndPoint}?token=${this.authToken}&price_list_id=${value?.id}&dropdown=true&page_no=${this.state.sectionNextPage}${searchQuery}&per_page=${this.itemsPerPage}`;

    const getAccount = apiCall({
      header: {},
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getSectionListApiMethod,
    });

    this.getSectionListAsPerPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCopmpleteValue) return;
    this.setState({ sectionAutoCopmpleteValue: getValue, sectionNextPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.handleCatergoriesOptions(this.state.selectedPriceListToCopy)
      }
    });
  }

  handleSelectSectionOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S>
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { isSectionSelectAll: false, selectedSections: [], selectedSectionsForList: [] }
        :
        {
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.sectionOptions, 'isSectionSelectAll', 'selectedSections', 'selectedSectionsForList');
      updateStateData.autoCopmpleteValue = '';
      fieldValue = newItems.map((item) => item.id)
    }
    updateStateData.productList = []
    updateStateData.productListDropdown = []
    updateStateData.prodDropDownNextPage = 1
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("section_ids", fieldValue)
      this.handleProductList(this.state.productPriceListId);
    })
  };

  getSectionListOptionApiCallResponse(responseJson: { data: ISectionDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if (this.checkGetResponse(responseJson)) {
      this.setState({
        section: responseJson.data.map((item: ISectionDropdown) => { return { id: item.attributes.id, option: item.attributes.section_name } }),
        sectionOptions: responseJson.data?.map((item: ISectionDropdown) => { return { id: item.attributes.id, option: item.attributes.section_name } })
      })
      this.disableLoadMoreSection = responseJson.data?.length < this.itemsPerPage
    } else {
      this.setState({ isLoading: false })
    }
  }

  getRegions = () => {
    this.getDropdownOptions("getRegionsCallId", configJSON.apiEndPoints.regionList)
  }

  getAreas = (regionIds: number[]) => {
    if (regionIds.length) {
      this.getDropdownOptions("getAreasCallId", configJSON.apiEndPoints.areaList + regionIds.join(","))
    } else {
      this.setState({ areas: [], stores: [] })
    }
  }

  getStores = (areaIds: number[]) => {
    if (areaIds.length) {
      this.getDropdownOptions("getStoresCallId", configJSON.apiEndPoints.b2bStoresList + areaIds.join(","))
    } else {
      this.setState({ stores: [] })
    }
  }

  getDropdownOptions = (
    apiCallId:
      | "getRegionsCallId"
      | "getAreasCallId"
      | "getStoresCallId",
    endPoint: string
  ) => {
    const message = makeApiMessage({
      url: endPoint,
      method: "GET"
    })
    this[apiCallId] = message.messageId
    this.send(message)
  }

  parseOptions = (responseJson: { data?: DropdownResponse[] }) =>
    responseJson?.data?.map(item => ({ id: item.attributes.id, option: item.attributes.name })) || []

  handleChangeMultipleSelectDropdown = (option: Option, allOptions: Option[], fieldName: keyof PriceListForm) => {
    const updateCallbackObject: {
      [key: string]: (
        newValues: Array<string | number>,
        setFieldValue: FormikHelpers<PriceListForm>["setFieldValue"],
        values: PriceListForm
      ) => void,
    } = {
      "region_ids": (newRegionIds, setFieldValue) => {
        setFieldValue("area_ids", [])
        setFieldValue("store_management_ids", [])
        this.setState({ areas: [], stores: [] }, () => this.getAreas(newRegionIds as number[]))
      },
      "area_ids": (newAreaIds, setFieldValue) => {
        setFieldValue("store_management_ids", [])
        this.setState({ stores: [] }, () => this.getStores(newAreaIds as number[]))
      },

    }
    this.handleSelectOptions(option, allOptions, fieldName, updateCallbackObject[fieldName])

  }

  handleSelectOptions = (
    option: Option, allOptions: Option[],
    fieldName: keyof PriceListForm,
    onUpdate?: (
      newValues: Array<string | number>,
      setFieldValue: FormikHelpers<PriceListForm>["setFieldValue"],
      values: PriceListForm
    ) => void
  ) => {
    if (!this.formRef.current) return;
    const values = this.formRef.current.values[fieldName] as Array<string | number>
    let newValues: Array<string | number> = []
    if (option.id === "-1") {
      if (allOptions.length && values.length < allOptions.length) {
        newValues = allOptions.map(item => item.id) as number[]
      }

    } else {
      const isSelected = values.includes(option.id)
      newValues = isSelected ? values.filter((selected) => selected != option.id) : [...values, option.id]
    }
    this.formRef.current.setFieldValue(fieldName, newValues)
    onUpdate?.(newValues, this.formRef.current.setFieldValue, this.formRef.current.values)
  }

  getPriceListDetailAPI = () => {
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const url = configJSON.B2BPriceListAPIEndpoint + "/" + this.state.editId

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPricelistDetailsApiCallResponse = (responseJson: IPriceListDetails) => {
    if (responseJson && responseJson.data) {
      const {
        id,
        name, company_id, business_account_ids, region_ids,
        area_ids, store_management_ids, activated,
        business_accounts,
        price_list_to_copy,
        sections
      } = responseJson.data.attributes
      const selectedBusinessAccount = business_accounts.map(business_account => ({ id: business_account.id, option: String(business_account.business_customer) }))
      const selectedSections = sections.data.map(section => ({ id: section.attributes.id, option: section.attributes.section_name }))
      this.setState({
        priceListInitialValues: {
          name: name,
          company_id: String(company_id),
          price_list_to_copy: toString(price_list_to_copy?.id),
          business_account_ids: business_accounts.map(busID => busID.id),
          region_ids: region_ids,
          area_ids: area_ids,
          store_management_ids: store_management_ids,
          product_ids: [],
          section_ids: sections.data.map(section => section.attributes.id),
          status: activated ? "Active" : "DeActive",
        },
        productPriceListName: name,
        productPriceListId: responseJson.data.id,
        selectedPriceListToCopy: price_list_to_copy ? {
          id: toString(price_list_to_copy.id),
          option: price_list_to_copy.name
        } : null,
        busAccOptions: selectedBusinessAccount,
        selectedBusinessAccount,
        selectedBusinessAccountForList: selectedBusinessAccount,
        selectedSections,
        selectedSectionsForList: selectedSections
      }, () => {
        if (company_id) {
          this.getBusinessAccountList(String(company_id))
          this.getB2BPriceList(String(company_id))
        }
        if (region_ids.length > 0) {
          this.getAreas(region_ids)
        }
        if (area_ids.length > 0) {
          this.getStores(area_ids)
        }
        this.fetchInitialProductCategories(responseJson)
        this.getB2BMasterPriceListId2();
        this.handleProductList(String(id));
      })
    }
  }

  getB2BMasterPriceListApiCallResponse2 = (responseJson: { data: { id: number; name: string } }) => {
    this.setState({
      masterPriceID: responseJson?.data?.id
    }, () => {
      if (responseJson?.data?.id) {
        this.handleProductList(this.state.priceListInitialValues.price_list_to_copy, true);
      }
    })
  }

  getB2BMasterPriceListId2 = () => {
    const apiMessage = apiCall({
      url: configJSON.getB2BMasterPriceListEndPoint,
      httpBody: {},
      httpMethod: "GET",
      header: {
        token: this.authToken
      }
    })
    this.getB2BMasterPriceListApiCallId2 = apiMessage.messageId
    this.send(apiMessage)
  }

  fetchInitialProductCategories = (priceListDetail?: IPriceListDetails) => {
    if (priceListDetail?.data && priceListDetail.data.attributes.products.length) {
      this.handleProductList(priceListDetail.data.id)
    }
    if (priceListDetail?.data.attributes.price_list_to_copy) {
      this.handleCatergoriesOptions({
        id: priceListDetail.data.attributes.price_list_to_copy.id.toString(),
        option: priceListDetail.data.attributes.price_list_to_copy.name
      })
    }
  }

  getAddEditAPIResponse = (response: {
    data: string;
    errors: { [key: string]: string[] };
  }) => {
    if (response.data) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: response.data,
        snackbarServerity: "success",
        isLoading: false
      });
      this.handleRedirect()
    } else {
      if (response.errors) {
        this.formRef.current && this.formRef.current.setErrors(
          response.errors
        )
      }
      this.setState({ isLoading: false });
    }
  };

  handleScrollBusAccDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreBusAcc) return;

    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ busAccNextPage: prevState.busAccNextPage + 1 }), () => {
        this.getBusinessAccountList(this.state.selectedCompany)
      })
    }
  }

  handleBusAccAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.busAccAutoCopmpleteValue) return;
    this.setState({ busAccAutoCopmpleteValue: getValue, busAccNextPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getBusinessAccountList(this.state.selectedCompany)
      }
    });
  }

  handleSelectBusAccOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S>
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isBusAccSelectAll ?
        { isBusAccSelectAll: false, selectedBusinessAccount: [], selectedBusinessAccountForList: [] }
        :
        {
          isBusAccSelectAll: true,
          selectedBusinessAccount: [{ id: "-1", option: "Select All" }],
          selectedBusinessAccountForList: [{ id: "-1", option: "Select All" }, ...this.state.busAccOptions]
        };
      fieldValue = updateStateData.isBusAccSelectAll ? ["All"] : []

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.busAccOptions, 'isBusAccSelectAll', 'selectedBusinessAccount', 'selectedBusinessAccountForList');
      updateStateData.autoCopmpleteValue = '';
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("business_account_ids", fieldValue)
    })
  };

  getBusAccDropApiCallResponse(responseJson: { data: IBusAccDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if (this.checkGetResponse(responseJson)) {
      const array = (responseJson?.data || []).map((item: IBusAccDropdown) => {
        return {
          id: item.attributes.id,
          option: item.attributes.business_customer
        }
      });
      this.disableLoadMoreBusAcc = array.length < this.itemsPerPage;
      this.setState((previous) => {
        const newPriceListToCopy = concat(previous.businessAccount, array)
        return {
          businessAccount: uniqBy(newPriceListToCopy, "id"),
          busAccOptions: uniqBy(newPriceListToCopy, "id")
        }
      });
    } else {
      this.setState({ isLoading: false })
    }
  }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(`${query}`),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query, prodNextPage: 1, productList: [], productListLoading: true }, () => {
      this.state.productPriceListId && this.handleProductList(this.state.productPriceListId)
    });
  };

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 1) {
      return "right";
    } else {
      return "middle";
    }
  };

  handleProductList = (priceId: string, isMasterPriceList?: boolean) => {
    const { prodDropDownNextPage, prodNextPage } = this.state
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    const sortQuery = conditionalString(!isMasterPriceList && this.state.query, `&${this.state.query}`, "")
    const isPriceListId = conditionalString(!this.isEdit || !isMasterPriceList, `&price_list_id=${priceId}`, "")
    const masterPriceListQuery = isMasterPriceList && this.isEdit
      ? `&dropdown=true&master_price_list_id=${this.state.masterPriceID}`
      : '';
    const  sectionsIds = this.state.selectedSections.map(item => item.id).join(",")
    const  categoryIds = sectionsIds && sectionsIds !== '-1' ? `&filter_by[category_ids]=${sectionsIds}` : ""
    const filterQuery = conditionalString(
      isMasterPriceList && this.state.addProductList,
      `&filter_by[query]=${this.state.addProductList}`,
      ""
    )
    const url = configJSON.B2bGetProductListAPIEndpoint + `?page_no=${isMasterPriceList ? prodDropDownNextPage : prodNextPage}${masterPriceListQuery}${isPriceListId}${filterQuery}${sortQuery}${categoryIds}`
    
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    if (isMasterPriceList) {
      this.getB2BProdListDropDownCallId = getAccount.messageId;
    } else {
      this.getB2BProductListApiCallId = getAccount.messageId;
    }
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductListApiCallResponse(responseJson: { data: IB2BProductList[], meta: ProductMeta, status: number, message?: string, errors?: { message: string } }) {
    if (this.checkGetResponse(responseJson) && !this.isEdit) {
      const { productListDropdown } = this.state;
      const dataList = Array.from(
        new Map(
          productListDropdown
            .concat(responseJson.data)
            .map((product) => [product.id, product])
        ).values()
      );
      this.setState({
        productListDropdown: dataList,
        productListMeta: responseJson.meta,
        productListLoading: false,
        productMasterIds: dataList.map((product) => Number(product.id)),
        hasMore: Boolean(responseJson.meta.next_page)
      });
    } else if (this.checkGetResponse(responseJson) && this.isEdit) {
      const { productList } = this.state;
      const dataList = Array.from(
        new Map(
          productList
            .concat(responseJson.data)
            .map((product) => [product.id, product])
        ).values()
      );

      this.setState((prev) => ({
        productList: dataList,
        productListLoading: false,
        productMasterIds: dataList.map((product) => Number(product.id)),
        selectedPriceListCopyId: this.state.editId,
        productSelectedIds: dataList.map((product) => Number(product.attributes.catalogue?.attributes?.id)),
        productSelectedIdsCopy: dataList.map((product) => Number(product.attributes.catalogue?.attributes?.id)),
      }));
    } else {
      this.setState({ productListLoading: false })
    }
  }

  getProdDropdownApiCallResponse = (responseJson: { data: IB2BProductList[], meta: ProductMeta, status: number, message?: string, errors?: { message: string } }) => {
    if (this.checkGetResponse(responseJson) && !this.isEdit) {
      const { productListDropdown } = this.state;
      const dataList = Array.from(
        new Map(
          productListDropdown
            .concat(responseJson.data)
            .map((product) => [product.id, product])
        ).values()
      );
      this.setState({
        productListDropdown: dataList,
        productListDropdownMeta: responseJson.meta,
      });
    } else if (this.checkGetResponse(responseJson) && this.isEdit) {
      const { productListDropdown } = this.state;
      const dataList = Array.from(
        new Map(
          productListDropdown
            .concat(responseJson.data)
            .map((product) => [product.id, product])
        ).values()
      );
      this.setState((prev) => ({
        productListDropdown: dataList,
        productListDropdownMeta: responseJson.meta,
      }));
    }
  }

  handleOpenModal = (type: string, data?: IB2BProductList) => {
    this.handleProductList(this.state.productPriceListId, true);

    this.setState({
      productModalOpen: true,
      productModalType: type,
      editProductDetails: {
        id: data?.id as string,
        name: data?.attributes.name as string
      },
      productName: data?.attributes.name as string
    })
  }

  handleClose = () => {
    this.setState({
      productModalOpen: false,
      productModalType: '',
      productSelectToggle: false,
      productAddEditLoader: false,
      productName: '',
      addProductList:'',
      editProductDetails: {
        id: '',
        name: ''
      },
      hasMore: true
    })
  }

  fetchMoreTableCustomers = (event: React.SyntheticEvent) => {
    if (
      this.state.prodNextPage === this.state.productListMeta.total_pages
    ) {
      return;
    }
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30 && this.state.productPriceListId) {
      this.setState(
        {
          prodNextPage: this.state.prodNextPage + 1,
        },
        () => {
          this.handleProductList(this.state.productPriceListId);
        }
      );
    }
  };

  handleProductSelecToggle = () => {
    this.setState({ productSelectToggle: !this.state.productSelectToggle })
  }

  handleProductCheck = (id: number) => {
    this.setState((prevState) => {
      const { productSelectedIds } = prevState;
      const isAlreadySelected = productSelectedIds.includes(id);
      const updatedIds = isAlreadySelected
        ? productSelectedIds.filter((productId) => productId !== id)
        : [...productSelectedIds, id];

      return {
        productSelectedIds: updatedIds,
      };
    });
  }

  handleEditChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value
    this.setState({
      productName: value,
      productNameError: value === ""
    })
  }

  hanldeEditProductAPICall = (isEditSwitch: boolean) => {
    const { productName, editProductDetails, isProductSwitchActive } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    let body
    if (!isEditSwitch) {
      body = {
        "data": {
          "catalogue_label": productName,
        }
      }
    } else {
      body = {
        "data": {
          "active": isProductSwitchActive,
        }
      }
    }
    const url = configJSON.B2bProductEditAPIEndpoint + `/${editProductDetails.id}/update_catalogue_label`

    const getAccount = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: configJSON.editProductListApiMethod,
    });

    this.getB2BProductAddEditApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  hanldeDeleteProductAPICall = () => {
    const { editProductDetails } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const url = configJSON.getProductListApiEndPoint + `/${editProductDetails.id}?token=${this.authToken}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.deleteApiMethod,
    });

    this.getB2BProductAddEditApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductAddEditApiCallResponse = (responseJson: IEditApiResponse) => {
    const { selectedPriceListCopyId } = this.state
    if (responseJson.message) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: responseJson.message,
        snackbarServerity: 'success',
        productAddEditLoader: false,
      }, () => {
        this.handleProductList(selectedPriceListCopyId)
        this.handleClose()
      })
    }
  }

  hanldeAddProductAPICall = () => {
    const { productSelectedIds, selectedPriceListCopyId } = this.state
    this.setState({ productAddEditLoader: true })
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    const body = {
      "data": {
        "id": selectedPriceListCopyId,
        "product_ids": productSelectedIds
      }
    }

    const url = configJSON.B2bProductAddAPIEndpoint

    const getAccount = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: configJSON.exampleAPiMethod,
    });

    this.getB2BProductAddEditApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }


  handleSwitch = (checked: boolean, productId: string) => {
    this.setState({
      editProductDetails: {
        id: productId,
      },
      productModalType: "Switch",
      isProductSwitchActive: checked
    },
      () => this.hanldeSubmit())
  }

  getSelectedProductDetails = () => {
    const selectedProductDetails = this.state.productListDropdown.filter((product) =>
      this.state.productSelectedIds.includes(Number(product.attributes.catalogue?.attributes?.id))
    );
    this.setState(
      (prevState) => {
        let updatedProductList = selectedProductDetails;
    
        if (prevState.productList.length > 1) {
          const existingIds = prevState.productList.map(
            (product) => product.attributes.catalogue?.attributes?.id
          );
          const newItems = selectedProductDetails.filter(
            (product) => !existingIds.includes(product.attributes.catalogue?.attributes?.id)
          );
    
          updatedProductList = [...prevState.productList, ...newItems];
        }
    
        return {
          productList: updatedProductList,
        };
      },
      () => {
        this.handleClose();
      }
    );
  }

  hanldeSubmit = () => {
    const { productModalType } = this.state;
    switch (productModalType) {
      case "Add":
        this.getSelectedProductDetails();
        break;
      case "Edit":
        this.hanldeEditProductAPICall(false);
        break;
      case "Switch":
        this.hanldeEditProductAPICall(true);
        break;
      case "Delete":
        this.hanldeDeleteProductAPICall();
        break;
    }
  }

  fetchMoreDropdownProducts = () => {
    if (!this.state.hasMore) {
      return;
    }
    this.setState({
        prodDropDownNextPage: this.state.prodDropDownNextPage + 1,
      },() => {
        this.handleProductList(this.state.productPriceListId,true);
      }
    );
  };

  searchProduct = (inputValue: string) => {
    this.setState(
      { addProductList: inputValue },
      this.debouncedProductSelection
    );
  };
  
  debouncedProductSelection = debounce(() => {
    this.handleProductList(this.state.productPriceListId, true);
  }, 700);
  
  filteredProducts = () => {
    const { addProductList, productListDropdown } = this.state;
  
    if (!addProductList) {
      return productListDropdown;
    }
  
    return productListDropdown.filter((product) =>
      product.attributes.name.toLowerCase().includes(addProductList.toLowerCase())
    );
  };
  handleClearMultiSelect = (fieldName: string) => {
    this.formRef.current?.setFieldValue(fieldName, [])
    if (fieldName === 'business_account_ids') {
      this.handleClearBusinessAccountState()
    } else if (fieldName === 'section_ids') {
      this.handleClearSectionState()
    }
  }
  handleClearBusinessAccountState = () => {
    this.setState({
      selectedBusinessAccount: [],
      selectedBusinessAccountForList: []
    })
  }
  handleClearSectionState = () => {
    this.setState({
      selectedSections: [],
      productList: [],
      prodDropDownNextPage: 0,
      productListDropdown: [],
      selectedSectionsForList: []
    })
  }
  // Customizable Area End
}