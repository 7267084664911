import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  CustomEnums,
  getCustomEnumName,
} from "../../utilities/src/CustomBlockHelpers";

// Customizable Area Start
interface Errors {
  [key: string]: string[];
}

interface ErrorMessageResponse {
  errors: Errors;
  url: string;
  success_count: number;
  error_count: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errorModal: boolean;
  errors: Errors;
  successCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportErrorModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationRaiseMessage),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupData),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      errorModal: true,
      errors: {},
      successCount: 0,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (
      message.id === getCustomEnumName(CustomEnums.ImportExportErrorPopupData)
    ) {
      this.handleErrorData(message.properties.APIresponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  handleModalClose = () => {
    const message = new Message(
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack)
    );
    message.addData("APIresponse", "go back parent route");
    runEngine.sendMessage(message.id, message);
  };

  handleErrorData = (data: ErrorMessageResponse) => {
    this.setState({
      errors: data.errors,
      successCount: data.success_count,
    });
  };

  // Customizable Area End
}
