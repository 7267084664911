// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  styled,
  TablePagination,
  Popover,
  Tooltip
} from "@material-ui/core";
import { toString } from "lodash";
import {
  renderBaseOnConditions,
  sortStringCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import FilterPopover from "../../../components/src/FilterPopover";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";
import B2BPriceListController, {
  IBusinessCustomer,
  IPriceList,
  Props,
  configJSON,
} from "./B2BPriceListController";
import { colors } from "../../utilities/src/Colors";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
const configString = configJSON.strings.b2bPriceListString;
// Customizable Area End

export class B2BPriceList extends B2BPriceListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


checkMultipleValue(data: IBusinessCustomer[], rowId?: string) {
    if (!data || data.length === 0) {
        return "--";
    }

    const sections = data.map((item: IBusinessCustomer) => item?.business_customer);
    const displayText = sections.slice(0, 4).join(", ").substring(0, 25);
    const fullText = sections.join(", ");

    return (
        <Tooltip 
            title={<span style={{fontSize:"14px"}}>{fullText.length > 25 ? fullText : ""}</span>} 
            placement="top"
            arrow
          >
            <span data-test-id="sectionNames">
                {fullText.length > 25 ? `${displayText}...` : fullText}
            </span>
        </Tooltip>
    );
}


  renderTableHeader() {
    return (
      <TableRow className={"customerTableRow"}>
        <SortingTableHeader
          data-test-id='nameSortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerPriceListName}
          sortingKey='name'
          {...this.sortingProps}
          type='left'
        />
        <SortingTableHeader
          data-test-id='priceListToCopySortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerPriceListToCopy}
          sortingKey='price_list_to_copy'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='companySortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerCompany}
          sortingKey='company'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='busAccSortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerBusinessAccount}
          sortingKey='business_account'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='statusSortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerStatus}
          sortingKey='activated'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='noOfProdSortingHeader'
          sortingData={this.state.sortingData}
          title={configString.headerNoOfProduct}
          sortingKey='no_of_products'
          {...this.sortingProps}
        />
        <SortingTableHeader
          sortingData={this.state.sortingData}
          title=''
          type='action'
          sortingKey=''
          {...this.sortingProps}
          sortingDisabled
        />
      </TableRow>
    );
  }

  renderPagination = (priceList: IPriceList[]) => {
    if (priceList.length > 0) {
      return (
        <TablePagination
          data-test-id='priceListPagination'
          rowsPerPageOptions={[10]}
          component='div'
          count={this.state.priceListPagination?.total_count}
          rowsPerPage={this.state.pageSize}
          page={this.state.page}
          onPageChange={(event, page) => {
            this.handlePageChange(page);
          }}
        />
      );
    }
  };

  getPopupOverMenu() {
    const {
      popOverTop,
      popOverLeft,
      popOverOpened,
      rowData,
      permissionStatus
    } = this.state;
    if (this.state.popOverOpened) {
      return (
        <StylePopover
          data-test-id='popoverTest'
          style={{ boxShadow: "none" }}
          anchorReference='anchorPosition'
          open={popOverOpened}
          onClose={() => {
            this.handlePopoverMenuClose();
          }}
          anchorPosition={{
            top: popOverTop + 40,
            left: popOverLeft - 80,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className='popoverContainer'>
            {
              renderBaseOnConditions(
                permissionStatus.viewPermission,
                <Box
                  data-test-id='viewButton'
                  className='popoverButton'
                  style={{display:'none'}}
                  onClick={() => {
                    this.handleViewCustomerDetails(rowData.id);
                  }}
                >
                  View
                </Box>,
                null
              )
            }
            {
              renderBaseOnConditions(
                permissionStatus.editPermission,
                <Box
                  data-test-id='editButton'
                  className='popoverButton'
                  onClick={() => {
                    this.handleEditCustomerDetails(rowData.id);
                  }}
                >
                  Edit
                </Box>,
                null
              )
            }
            {
              renderBaseOnConditions(
                !permissionStatus.editPermission
                && !permissionStatus.viewPermission,
                <Box className={"noPermissions"}>No Permission</Box>,
                null
              )
            }
          </div>
        </StylePopover>
      );
    }
  }

  renderCustomSnackBar = () => {
    const { snackBarOpen, snackBarMessage, severity } = this.state;

    return (
      <CustomSnackbar
        data-test-id={"custom-snackbar"}
        open={snackBarOpen}
        onClose={() => this.handleSnackbarClose()}
        autoHideDuration={1000}
        errorMessage={snackBarMessage}
        severity={severity}
        hideIcon={true}
      />
    );
  };

  render() {
    const { isLoadingPermission, snackBarOpen, openImportExportPopover } = this.state;

    return (
      <>
        <MainStyleWrapper>
          <Box>
            <Box>
              <div className={"headerWrapper"}>
                <div className={"pageHeader"}>{configString.headingTitle}</div>
                <div className={"headerButtonPartAccount"}>
                  <div
                    data-test-id='filterIcon'
                    onClick={(event) => {
                      this.handleFilterClose(event);
                    }}
                    className={sortStringCondition(
                      this.state.isAppliedFilter,
                      "filterButtonAccount active-border",
                      "filterButtonAccount"
                    )}
                  >
                    <FilterIcon color={this.handleReturnColorType()} />
                    {renderBaseOnConditions(
                      this.state.isAppliedFilter,
                      <div className='filterActiveIcon'></div>,
                      <></>
                    )}
                  </div>
                  <FilterPopover
                    data-test-id='filterPopover'
                    onClose={() => {
                      this.handleCloseFilterPopover();
                    }}
                    onClearFilter={this.handleClearFilter}
                    anchor={this.state.filterAnchor}
                    onFilterChange={this.handleFilterChangeAccount}
                    onAutoCompleteChange={this.debouncedFilterSuggestion}
                    filters={this.state.filters}
                    isB2BActive={this.state.isB2bActive}
                  />
                  <LoadingComponent
                    isLoading={this.state.isLoadingPermission}
                    height={56}
                    width={175}
                    isAllowed
                    children={
                      <IconButton
                        data-test-id={`export-import-btn`}
                        id={"IconButton"}
                        onClick={(event) => this.openImportExportPopover(event)}
                        className={"export-btn"}
                      >
                        Export/Import
                        {openImportExportPopover ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    }
                  />
                  <ExportImportPopover
                    popOverId={renderBaseOnConditions(Boolean(openImportExportPopover), "simple-popover", undefined) as string}
                    openAction={Boolean(openImportExportPopover)}
                    popoverOpen={openImportExportPopover}
                    popoverClose={() => this.openImportExportPopover()}
                    exportTemplateButtonClick={() => this.handleExportTemplate()}
                    importCsvButtonClick={() => this.handleOpenImportModal()}
                    exportCsvButtonClick={() => this.handleExportCsv()}
                  />
                  <LoadingComponent
                    isLoading={isLoadingPermission}
                    height={56}
                    width={184}
                    children={
                      <Button
                        data-test-id='add-customer'
                        onClick={() => this.handleNavigateAddCustomer()}
                        className={"addButton"}
                      >
                        Add Price List
                      </Button>
                    }
                    isAllowed={this.state.permissionStatus.createPermission}
                  />
                </div>
              </div>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table className={"table"} aria-label='customized table'>
                  <TableHead>{this.renderTableHeader()}</TableHead>
                  {this.state.isLoading ? (
                    <>
                      {Array(5)
                        .fill(1)
                        .map((_, index) => (
                          <TableBody key={index} data-test-id='tableData'>
                            <TableRow>
                              {[...Array(6)].map((_, cellIndex) => (
                                <TableRowsSkeleton key={cellIndex} />
                              ))}
                            </TableRow>
                          </TableBody>
                        ))}
                    </>
                  ) : (
                    <TableBody data-test-id='tableData'>
                      {this.state.priceList.length > 0 && (
                        <>
                          {this.state.priceList.map(
                            (item: IPriceList, index: number) => {
                              return (
                                <TableRow key={index + "TableRow"}>
                                  <SortingTableCell
                                    type='left'
                                    width='22vw'
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                    }}
                                    content={item.attributes.name}
                                    width1024='100px'
                                  />
                                  <SortingTableCell
                                    type='middle'
                                    width='22vw'
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                    }}
                                    content={toString(item.attributes.price_list_to_copy?.name)}
                                    width1024='100px'
                                  />
                                  <SortingTableCell
                                    type='middle'
                                    width='22vw'
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                    }}
                                    width1024='150px'
                                    content={item.attributes.company_name}
                                  />
                                  <SortingTableCell
                                    type='middle'
                                    width1024='150px'
                                    width='40vw'
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                    }}
                                    content={this.checkMultipleValue(item.attributes.business_accounts)}
                                  />
                                  <SortingTableCell
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                    }}
                                    width='15vw'
                                    content={item.attributes.activated ? "Activate": "DeActivate"}
                                    type='middle'
                                    width1024='150px'
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='right'
                                    style={{
                                      opacity: this.checkOpacity(
                                        item.attributes.activated
                                      ),
                                      
                                    }}
                                    width1024='150px'
                                    content={
                                      <Box 
                                        display="flex" 
                                        justifyContent="space-between"
                                        alignItems="center" 
                                        paddingX={1.5}
                                      >
                                        {item.attributes.products_count}
                                        {
                                          renderBaseOnConditions(
                                            this.state.permissionStatus.editPermission,
                                            <button className="view-products" onClick={() => this.handleViewProductList(item.id)}>View</button>,
                                            null
                                          )
                                        }
                                      </Box>
                                    }
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='action'
                                    data-test-id={`threeDotIcon_${index}`}
                                    width1024='100px'
                                    content={
                                      <IconButton
                                        id={"IconButton" + index}
                                       data-test-id={`moreButton_${index}`}
                                        onClick={(event) => {
                                          const position =
                                            event.currentTarget.getBoundingClientRect();
                                          this.handleMoreMenu(item, position);
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  />
                                </TableRow>
                              );
                            }
                          )}
                        </>
                      )}
                    </TableBody>
                  )}
                </Table>

                {this.state.priceList.length === 0 &&
                  !this.state.isLoading &&
                  !this.state.isLoading && (
                    <div className={`customerAddButton noDataCell`}>
                      No Record Found
                    </div>
                  )}
              </TableContainer>
            </Box>
            <Box>{this.renderPagination(this.state.priceList)}</Box>
          </Box>
          {this.getPopupOverMenu()}
          {snackBarOpen && this.renderCustomSnackBar()}
        </MainStyleWrapper>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyledBox = styled(Box)({
  margin: "0 10px",
});

const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions": {
    padding: "0 8px",
  },
});

const MainStyleWrapper = styled(Box)({
  fontFamily: "Montserrat",
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    minWidth: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      minWidth: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%",
  },
  "& .export-btn": {
    width: "auto !important",
    margin: "0 10px",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
    marginTop: 25,
    flexwrap: "wrap" as "wrap",
  },
  "& .pageHeader": {
    fontSize: "24px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "29px",
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
  },
  "& .headerButtonPartAccount": {
    display: "flex",
  },
  "& .table": {
    minWidth: 700,
  },
  "& .deactivate": {
    opacity: 0.7,
  },
  "& .loadercell": {
    textAlign: "center",
  },
  "& .loadercir": {
    width: "44px",
    height: "none",
  },
  "& .customerAddButton": {
    textAlign: "center",
  },
  "& .noDataCell": {
    fontWeight: 600,
    fontSize: "16px",
    width: "98%",
    paddingTop: "20px",
    paddingBottom: "20px",
    border: "1px solid #ECECEC",
    margin: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "8px",
  },
  "& .customerTableRow": {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  "& .addButton": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    background: "#204B9C",
    color: "#FFFFFF",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset" as "unset",
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
      padding: "10px 20px",
    },
  },
  "& .MuiTableBody-root": {
    "& .view-products": {
      border: `solid 1px ${colors().blueborder}`,
      padding: "6px 10px",
      color: "#1D448E",
      fontSize: 16,
      fontWeight: 600,
      background: "none",
      borderRadius: 8
    },
    "& .sortingTableRightCell": {
      paddingTop: 4,
      paddingBottom: 4
    }
  }
});

const StylePopover = styled(Popover)({
  "& .popoverButton": {
    width: "118px",
    height: "33px",
    paddingTop: "8px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  "& .popoverContainer": {
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  "& .noPermissions": {
    padding: "0 8px",
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
});

export default B2BPriceList;
// Customizable Area End
