import React, {useState, useRef, useEffect} from "react"
import { styled, withStyles, AppBar, Tabs, Tab } from "@material-ui/core"
import { navigateTo, DashboardPermissionStatus, customPermissionApiKey, checkForDashboardPermissonStatus } from "../../blocks/utilities/src/CustomBlockHelpers"
import { colors } from "../../blocks/utilities/src/Colors"
import { PermissionGroupArray } from "../../blocks/navigationmenu/src/utils"

type Props = {
    navigation: unknown
    id: typeof DASHBOARD_PAGES[number]["id"],
    permissionStatus: DashboardPermissionStatus | null;
}

let DASHBOARD_PAGES = [
    {
        id: "MainDashboard",
        label: "Dashboard",
        displayTabPermission: false
    },
    {
        id: "DashboardOverview",
        label: "Overview",
        displayTabPermission: false
    },
    {
        id: "SectionSales",
        label: "Section Sales",
        displayTabPermission: false
    },
    {
        id: "PlantReport",
        label: "Plant Report",
        displayTabPermission: false
    },
    {
        id: "PopularTimesDashboard",
        label: "Popular Time",
        displayTabPermission: false
    },
    {
        id: "SalesDashboard",
        label: "Sales",
        displayTabPermission: false
    },
    {
        id: "RevenueDashboard",
        label: "Revenue",
        displayTabPermission: false
    },
    {
        id: "CustomerDashboard",
        label: "Customer",
        displayTabPermission: false
    },
    {
        id: "DiscountCreditSection",
        label: "Discounts & Credits",
        displayTabPermission: false
    }
] as const

const DashboardTabPanel = (props: Props) => {
    let DASHBOARD_PAGES = [
        {
            id: "MainDashboard",
            label: "Dashboard",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "DashboardOverview",
            label: "Overview",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "SectionSales",
            label: "Section Sales",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "PlantReport",
            label: "Plant Report",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "PopularTimesDashboard",
            label: "Popular Time",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "SalesDashboard",
            label: "Sales",
            showInB2b: true,
            displayTabPermission: false
        },
        {
            id: "RevenueDashboard",
            label: "Revenue",
            showInB2b: false,
            displayTabPermission: false
        },
        {
            id: "CustomerDashboard",
            label: "Customer",
            showInB2b: false,
            displayTabPermission: false
        },
        {
            id: "DiscountCreditSection",
            label: "Discounts & Credits",
            showInB2b: false,
            displayTabPermission: false
        }
    ];
    const { id, navigation, permissionStatus } = props
    const tabsRef = useRef<HTMLButtonElement>(null);
    const lastTabRef = useRef<HTMLDivElement>(null);
    const [scrollable, setScrollable] = useState(false);
    const [dashBoard_pages, setDashboardPages] = useState<{ id: string; label: string; displayTabPermission: boolean; }[]>([]);

    useEffect(() => {
        
        if(permissionStatus) {
            DASHBOARD_PAGES = DASHBOARD_PAGES.map((page:{id: string, label: string, showInB2b: boolean, displayTabPermission: boolean}) => {
                switch (page?.id) {
                    case "MainDashboard":
                        page.displayTabPermission = permissionStatus.dashboardAccessPermission;
                      break;
                    case "DashboardOverview":
                        page.displayTabPermission = permissionStatus.overviewAccessPermission;
                      break;
                    case "SectionSales":
                        page.displayTabPermission = permissionStatus.sectionSalesAccessPermission;
                      break;
                    case "PlantReport":
                        page.displayTabPermission = permissionStatus.plantReportAccessPermission;
                      break;
                    case "PopularTimesDashboard":
                        page.displayTabPermission = permissionStatus.popularTimeAccessPermission;
                      break;
                    case "SalesDashboard":
                        page.displayTabPermission = permissionStatus.salesAccessPermission;
                      break;
                    case "RevenueDashboard":
                        page.displayTabPermission = permissionStatus.revenueAccessPermission;
                      break;
                    case "CustomerDashboard":
                        page.displayTabPermission = permissionStatus.customerAccessPermission;
                      break;
                    case "DiscountCreditSection":
                        page.displayTabPermission = permissionStatus.discountCreditAccessPermission;
                      break;
                    default:
                      break;
                }
                return page;
            });
        }
        const isB2b = localStorage.getItem("accountType") === "b2b";
        DASHBOARD_PAGES = DASHBOARD_PAGES.filter(page => page.displayTabPermission && (page.showInB2b || !isB2b));

        setDashboardPages(DASHBOARD_PAGES)
    }, [permissionStatus]);
    
    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if (entry.target === lastTabRef.current) {
                    setScrollable(!entry.isIntersecting);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: tabsRef.current,
            threshold: 1.0
        });

        if (lastTabRef.current) {
            observer.observe(lastTabRef.current);
        }

        const isB2b = localStorage.getItem("accountType") === "b2b";
        DASHBOARD_PAGES = DASHBOARD_PAGES.filter(page => page.displayTabPermission && (page.showInB2b || !isB2b));
        setDashboardPages(DASHBOARD_PAGES)

        return () => {
            if (lastTabRef.current) {
                observer.unobserve(lastTabRef.current);
            }
        };
    }, []);

    const handleNavigation = (screenId: string) => {
        if (screenId && screenId !== props.id) {
            navigateTo({
                screenName: screenId,
                props: {
                    navigation,
                    id
                }
            })
        }
    }

    return (
        <StyledAppBar position="sticky" data-test-id='main-order-type-tabs'>
            <Tabs
                ref={tabsRef}
                variant='scrollable'
                scrollButtons={scrollable ? "on" : "auto"}
                value={props.id}
            >
                {
                    dashBoard_pages.map((dashboardPage, index) => (
                        <Tab
                            value={dashboardPage.id}
                            label={dashboardPage.label}
                            key={dashboardPage.id}
                            className={"tab-item" + (dashboardPage.id === props.id ? " active" : "")}
                            onClick={() => handleNavigation(dashboardPage.id)}
                            ref={index === dashBoard_pages.length - 1 ? lastTabRef : undefined}
                        >
                            {dashboardPage.label}
                        </Tab>
                    ))
                }
            </Tabs>
        </StyledAppBar>
    )
}

export const redirectToDashboardTabBasedOnPermission = (permission_groups: PermissionGroupArray[]) : string => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = permission_groups;
    const permission: DashboardPermissionStatus = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    // based on permission redirect to dashboard tab
    let redirectToDashboardtab = "MainDashboard"; // default to main dashboard
    switch(true) {
      case permission.dashboardAccessPermission: // The case here is boolean. IS it possible to set boolean case. I did try but the value is not set correctly based on the case in the str variable.
        redirectToDashboardtab = "MainDashboard"
        break;
      case permission.overviewAccessPermission:
        redirectToDashboardtab = "DashboardOverview"
        break;
      case permission.sectionSalesAccessPermission:
        redirectToDashboardtab = "SectionSales"
        break;
      case permission.plantReportAccessPermission:
        redirectToDashboardtab = "PlantReport"
        break;
      case permission.popularTimeAccessPermission:
        redirectToDashboardtab = "PopularTimesDashboard"
        break;
      case permission.salesAccessPermission:
        redirectToDashboardtab = "SalesDashboard"
        break;
      case permission.revenueAccessPermission:
        redirectToDashboardtab = "RevenueDashboard"
        break;
      case permission.customerAccessPermission:
        redirectToDashboardtab = "CustomerDashboard"
        break;
      case permission.discountCreditAccessPermission:
        redirectToDashboardtab = "DiscountCreditSection"
        break;
      
    };
    return redirectToDashboardtab;
} 


const StyledAppBar = withStyles({
    root: {
        color: colors().darkliver,
        marginBottom: 24,
        backgroundColor: colors().white,
        borderBottom: `1px solid ${colors().lightborder}`,
        boxShadow: "none",
        "& .MuiTab-root": {
            textTransform: "none",
            maxWidth: "unset",
            fontSize: 16,
            minWidth: "fit-content",
            fontWeight: 400,
            "@media only screen and (max-width: 1024px)": {
                fontSize: 14,
            },
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "flex-start",
            gap:"1rem"
        },
        "& .Mui-selected": {
            color: colors().cyancobaltblue,
            fontWeight: 700,
            borderBottom: `2px solid ${colors().cyancobaltblue}`,
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiPaper-elevation": {
            boxShadow: "none",
        },
    },
})(AppBar);

export default DashboardTabPanel;

